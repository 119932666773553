(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('documentsSearchIndividualRequest', function () {

        function documentsSearchIndividualRequest() {
            this.simpleSearch = '';
            this.docuTitle = '';
            this.docuStatus = '';
            this.docuSimilarityFactorFrom = '';
            this.docuSimilarityFactorTo = '';
            this.docuSimilarityFactorExtFrom = '';
            this.docuSimilarityFactorExtTo = '';
            this.author = '';
            this.alert = '';
            this.docuSubmitDateFrom = null;
            this.docuSubmitDateTo = null;
        }

        return documentsSearchIndividualRequest;
    });
})();
