(function () {
    'use strict';
    
    angular
        .module('plagiat2017App')
        .constant('POPUP_OPTIONS', [
            {'id': 1, 'key': 'popup.option.dont-show-again'},
            {'id': 2, 'key': 'popup.option.ask-contact'},
            {'id': 4, 'key': 'popup.option.ask-contact-with-tel'}
            ]);
})();