(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ReportsController', ReportsController);

    ReportsController.$inject = [
        'ReportsService', 'ModalService', 'PlagiatValidationService', 'DocumentService', 'DateService',
        '$q', '$mdDialog', '$translate', '$scope', '$localStorage', '$state', '$mdBottomSheet',
        'QueryRequest', 'SearchRequest', 'tableSettingsRequest', 'TableSelectOptions', 'SIMPLE_SEARCH_DELAY', 'DOCUMENT_STATUS', 'DOCUMENT_PAP_STATUSES'
    ];

    function ReportsController(
        ReportsService, ModalService, PlagiatValidationService, DocumentService, DateService,
        $q, $mdDialog, $translate, $scope, $localStorage, $state, $mdBottomSheet,
        QueryRequest, SearchRequest, tableSettingsRequest, TableSelectOptions, SIMPLE_SEARCH_DELAY, DOCUMENT_STATUS, DOCUMENT_PAP_STATUSES
    ) {
        var vm = this;

        vm.loadData = loadData;
        vm.loadPageOfData = loadPageOfData;
        vm.clear = clear;
        vm.deSelectRow = deSelectRow;
        vm.selectRow = selectRow;
        vm.generateReportXls = generateReportXls;
        vm.sendReportXls = sendReportXls;
        vm.showText = showText;
        vm.getDetails = getDetails;
        vm.showSuspectReports = showSuspectReports;
        vm.closeBottomSheet = closeBottomSheet;
        vm.toggleSelection = toggleSelection;
        vm.isBottomSheetOpen = isBottomSheetOpen;

        vm.fixDate = DateService.fixDate;
        vm.documentStatus = DOCUMENT_STATUS;
        vm.documentPapStatuses = DOCUMENT_PAP_STATUSES;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.pageOfData = {};
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.order = '-docuSubmitDate';
        vm.searchRequest = new SearchRequest();
        vm.options = new tableSettingsRequest();
        vm.tableSelectOptions = new TableSelectOptions();
        vm.selected = [];

        vm.showDetailsBlock = false;
        vm.pickedDocument = false;
        vm.details = {};

        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.reportsLimit ? $localStorage.reportsLimit : vm.queryRequest.limit;
            if ($localStorage.reportsSearch) {
                vm.searchRequest.search = $localStorage.reportsSearch;
            }
            vm.searchRequest.search.status = 1;
            loadPageOfData(1);
        };

        function showSuspectReports(row) {
            $state.go('suspect-reports', {documentId : row.documentId});
        }

        function getDetails(item) {
            vm.showDetailsBlock = false;
            vm.pickedDocument = item.documentId;
            if (item.documentId !== DocumentService.documentDetailsObj.documentId) {
                DocumentService.documentDetailsObj = item;
                vm.isLoaderActive = true;

                ReportsService.getDetails(item.documentId).then(function (result) {
                    vm.details = result.data;
                    vm.isLoaderActive = false;
                    vm.showDetailsBlock = true;
                    vm.details.docuSubmitDate = moment(vm.details.docuSubmitDate).format('LL LT');
                    if (vm.details.docuReportDate !== null){
                        vm.details.docuReportDate =  moment(vm.details.docuReportDate).format('LL LT');
                    }
                });
                
            } else {
                DocumentService.documentDetailsObj = {};
            }
        }

        function showText(ev, row) {
            DocumentService.document.id = row.documentId;
            $mdDialog.show({
                templateUrl: 'app/account/documents/document-text/view-text.tmpl.html',
                targetEvent: ev,
                clickOutsideToClose: true,
                multiple: true
            });
        }

        function clear() {
            vm.searchRequest = new SearchRequest();
            loadData();
        }

        function loadPageOfData(pageNumber) {
            vm.pending = true;
            
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = sort();
            $localStorage.reportsLimit = vm.queryRequest.limit;
            $localStorage.reportsSearch = vm.searchRequest.search;
            ReportsService.search(vm.searchRequest).then(function (page) {
                vm.pending = false;
                vm.pageOfData = page;
                vm.totalRows = page.totalElements;
                vm.pageOfData.number++;
            });
        }

        function loadData() {
            closeBottomSheet();
            vm.loadPageOfData(1);
        }

        function sort() {
            var reverse = vm.queryRequest.order.slice(0, 1);
            var _length = vm.queryRequest.order.length;
            var predicate;
            if (reverse === '-') {
                predicate = vm.queryRequest.order.slice(1, _length);
                reverse = false;
            } else {
                predicate = vm.queryRequest.order;
                reverse = true;
            }
            var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
            return result;
        }

        vm.convertToDate = function (date){
            return new Date(date);
        }

        function deSelectRow() {
            if (vm.selected.length === 0) {
                closeBottomSheet();
            }
        }

        function selectRow(pickedId) {
            if (vm.selected.length === 1) {
                openBottomSheet();
            }
            angular.forEach(vm.users, function (value) {
                if (value.id === pickedId) {
                    if (vm.tableSelectOptions.selectedHelper.indexOf(pickedId) === -1) {
                        vm.tableSelectOptions.selectedHelper.push(value);
                    }
                }
            });
        }

        function selectRows(obj) {
            angular.forEach(obj, function (value) {
                if (vm.selected.indexOf(value.id) === -1) {
                    vm.selected.push(value.id);
                }
            })
        }

        function toggleSelection(total) {
            if (!vm.tableSelectOptions.selectedAll) {
                selectRows(vm.pageOfData.content);
            }
            vm.tableSelectOptions.selectedAll = !vm.tableSelectOptions.selectedAll;
            vm.tableSelectOptions.selectedNo = total;
            if (!vm.tableSelectOptions.selectedAll) {
                closeBottomSheet();
            }
        }

        function closeBottomSheet() {
            vm.selected.length = 0;
            vm.tableSelectOptions = new TableSelectOptions();
            $mdBottomSheet.hide();
        }

        function isBottomSheetOpen() {
            return vm.selected && vm.selected.length > 0;
        }

        function openBottomSheet() {
            $mdBottomSheet.show({
                templateUrl: 'app/account/pap/reports/reports-bottom-sheet.html',
                controllerAs: 'bootomSheet',
                parent: angular.element(document.getElementById('reports')),
                controller: function GridBottomSheetCtrl(){
                    var bootomSheet = this;
                    bootomSheet.checked = true;
                    bootomSheet.closeBottomSheet = vm.closeBottomSheet;
                    bootomSheet.selected = vm.selected;
                    bootomSheet.tableSelectOptions = vm.tableSelectOptions;
                    bootomSheet.total = vm.totalRows;
                    bootomSheet.toggleSelection = vm.toggleSelection;
                    bootomSheet.generateReportXls = vm.generateReportXls;
                    bootomSheet.sendReportXls = vm.sendReportXls;
                },
                isLockedOpen: true,
                disableParentScroll: false,
                disableBackdrop: true
            });
        }

        function generateReportXls() {
            prepareMassActions().then(function(response) {
                ReportsService.generateReportXls(response, vm.selected, vm.searchRequest).then(
                    function (ok) {
                        closeBottomSheet();
                    }, function (errorResponse) {
                        PlagiatValidationService.getModalError(errorResponse.data);
                    }
                );
            });
        }

        function sendReportXls() {
            prepareMassActions().then(function(response) {
                ReportsService.sendReportXls(response, vm.selected, vm.searchRequest).then(function (ok) {
                    closeBottomSheet();
                }, function (errResponse) {
                    // nothing?
                });
            });
        }

        function prepareMassActions() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                vm.tableSelectOptions.ids.push(value.id);
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

    }

})();
