(function () {
    'use strict';

    function DetectInstanceController($scope, $log, DetectInstanceService) {
        // var vm = this;

    //$log.error( 'DetectInstanceController',DetectInstanceService.instance());

    }

    angular.module('plagiat2017App')
  .component('detectInstance', {
      controller: DetectInstanceController
  });

})();
