(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('FeedbackListController', FeedbackListController);

    FeedbackListController.$inject = [
        '$rootScope', '$scope', '$stateParams', '$translate', '$state', 'SIMPLE_SEARCH_DELAY',
        'FeedbackRequest', 'QueryRequest', 'AccountService', 'DocumentService', 'ModalService',
        'DocumentSearchRequestModel', 'SearchService', 'PlagiatValidationService',
        'PeerReviewService', 'PeerReviewFeedbackService'
    ];

    function FeedbackListController(
        $rootScope, $scope, $stateParams, $translate, $state, SIMPLE_SEARCH_DELAY,
        FeedbackRequest, QueryRequest, AccountService, DocumentService, ModalService,
        DocumentSearchRequestModel, SearchService, PlagiatValidationService,
        PeerReviewService, PeerReviewFeedbackService
    ) {
        var vm = this;
        var feedbacks = [];
        var pending = true;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.getPeerReviews = getPeerReviews;
        vm.getPeerReviewsPage = getPeerReviewsPage;
        vm.submitReview = submitReview;
        vm.clear = clear;
        vm.toggleSearch = toggleSearch;
        
        vm.searchRequest = new FeedbackRequest();
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.limit = 10;
        vm.queryRequest.order = '-id';
        vm.simpleSearch = true;
        vm.statuses = [
            {id: 0, translateKey: 'peer-review.status.in-progress'},
            {id: 1, translateKey: 'peer-review.status.filled'}
        ];
        vm.isAdmin = AccountService.role.isAdmin();
        vm.peerReviews = [];
        vm.expandedFeedbacksToReview = {};
        vm.expandedReceivedReviews = {};
        vm.expandedSelfSelectReviews = {};

        vm.getPeerReviews();

        function getPeerReviews() {
            vm.pending = true;
            vm.searchRequest.pageNumber = vm.queryRequest.page - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            
            PeerReviewService.getList(vm.searchRequest).then(
                function (response) {
                    vm.pending = false;
                    vm.peerReviews = response.content;
                    // vm.totalFeedbacks = response.totalElements;
                }, function (error) {
                  vm.pending = false;
                  PlagiatValidationService.getModalError(error.data);
                }
            );
        }
        
        function getPeerReviewsPage(page) {
            vm.queryRequest.page = page;
            vm.getPeerReviews();
        }
        
        function toggleSearch() {
            vm.searchRequest.simpleSearch = '';
            vm.searchRequest.search = {};
            vm.simpleSearch = !vm.simpleSearch;
        }

        function clear() {
            vm.searchRequest.simpleSearch = '';
            vm.searchRequest.search = {};
            getPeerReviews();
        }

        function submitReview(row) {
            $state.go('add-feedback', {id: row.id});
        }

        $scope.readReview = function(row) {
            $state.go('view-feedback', {id: row.id});
        };

        $scope.onClickExpandFeedbacksToReview = function(peerReviewId) {
            vm.expandedReceivedReviews[peerReviewId] = false;
            vm.expandedSelfSelectReviews[peerReviewId] = false;
            vm.expandedFeedbacksToReview[peerReviewId] = !vm.expandedFeedbacksToReview[peerReviewId];
        }

        $scope.onClickExpandReceivedReviews = function(peerReviewId) {
            vm.expandedFeedbacksToReview[peerReviewId] = false;
            vm.expandedSelfSelectReviews[peerReviewId] = false;
            vm.expandedReceivedReviews[peerReviewId] = !vm.expandedReceivedReviews[peerReviewId];
        }

        $scope.onClickSelfSelect = function(peerReviewId) {
            vm.expandedReceivedReviews[peerReviewId] = false;
            vm.expandedFeedbacksToReview[peerReviewId] = false;
            vm.expandedSelfSelectReviews[peerReviewId] = !vm.expandedSelfSelectReviews[peerReviewId];
        }

        $scope.onClickSelfSelectReview = function (feedback) {
            ModalService.showConfirmModal('peer-review.feedbacks.self-select').then(function () {
                vm.pending = true;
                PeerReviewFeedbackService.createBySelfSelect(feedback).then(
                    function (response) {
                        vm.pending = false;
                        $state.go('add-feedback', {id: response.data.id});
                    }, function (error) {
                        vm.pending = false;
                        PlagiatValidationService.getModalError(error.data);
                    }
                );
            });
        }

    }
})();
