(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('JhiLanguageController', JhiLanguageController);

    JhiLanguageController.$inject = ['$scope', '$rootScope', '$translate', '$stateParams', 'JhiLanguageService', 'tmhDynamicLocale', 'findLanguageFromKeyFilter', 'AccountService', '$timeout', 'InstanceService'];

    function JhiLanguageController ($scope, $rootScope, $translate, $stateParams, JhiLanguageService, tmhDynamicLocale, findLanguageFromKeyFilter, AccountService, $timeout, InstanceService) {
        var vm = this;

        vm.isIndividual = AccountService.role.isIndividual();
        vm.isOperator = AccountService.role.isOperator();
        vm.isLecturer = AccountService.role.isLecturer();
        vm.pickedLanguage = findLanguageFromKeyFilter(AccountService.account.langKey);
        vm.pickedLanguageKey = AccountService.account.langKey;

        vm.changeLanguage = changeLanguage;
        vm.instanceLang = InstanceService.instance().lang;
        vm.setLang = setLang;
        vm.availableLanguages = null;

        JhiLanguageService.getAll().then(function (allLanguages) {
            vm.availableLanguages = allLanguages.data.availableLanguages;
        });

        function changeLanguage (languageKey) {
            vm.pickedLanguage = findLanguageFromKeyFilter(languageKey);
            AccountService.account.pickedLang = languageKey;
            
            tmhDynamicLocale.set(languageKey).then(function(){
                $translate.use(languageKey).then(function(){
                    $translate.fallbackLanguage(languageKey);
                    $translate.preferredLanguage(languageKey);
                    $rootScope.$broadcast("langChanged");
                })
            });
        }

        function setLang(languageKey){
            AccountService.setLang(languageKey);
            AccountService.account.langKey = languageKey;
        }
        
        $scope.$on("langChangedOnStart",function () {
        	vm.pickedLanguage = findLanguageFromKeyFilter(AccountService.account.langKey);
        } );

    }
})();
