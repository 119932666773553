(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('TagsService', TagsService);
    
    TagsService.$inject = ['$http', '$q'];

    function TagsService($http, $q) {

        var service = {
            searchTagGroups: searchTagGroups,
            getTagGroupForEditing: getTagGroupForEditing,
            saveOrUpdateTagGroup: saveOrUpdateTagGroup,
            saveOrUpdateTags: saveOrUpdateTags
        };
        var requestUrl = 'api/tags';
        return service;

        function getTagGroupForEditing(id) {
            return $http.get(requestUrl + '/tag-group/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function saveOrUpdateTagGroup(obj) {
            return $http.post(requestUrl + '/tag-group/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveOrUpdateTags(obj) {
            return $http.post(requestUrl + '/tag/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function searchTagGroups(searchRequest) {
            return $http.post(requestUrl + '/tag-group/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
    }
})();
