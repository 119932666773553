(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PopupController', PopupController);

    function PopupController($mdDialog, ModalService, PopupAdministrationService, popup, $localStorage, closeCallback) {
        var vm = this;
        vm.popup = popup;
        vm.closePopup = closeCallback;

        vm.close = function () {
            vm.closePopup(popup.id);
        };

        vm.dontShowAgain = function () {
            if (!$localStorage.dontShowPopupMap) {
                $localStorage.dontShowPopupMap = {};
            }
            $localStorage.dontShowPopupMap[vm.popup.id] = true;
            vm.close();
        };

        vm.callMe = function () {
            closeCallMeWithTel('');
        };

        function closeCallMeWithTel(telephone) {
            vm.pending = true;
            PopupAdministrationService.orderContactByRegularPopup(vm.popup.id, telephone).then(function () {
                    vm.pending = false;
                    vm.dontShowAgain();
                    ModalService.showSuccessModal("popup.order-contact.success");
                }, function () {
                    vm.pending = false;
                    vm.close();
                }
            );
        }

        vm.callMeWithTel = function () {

            $mdDialog.show({
                controller: 'PopupCallMeWithTelController',
                controllerAs: 'vm',
                templateUrl: 'app/account/popup/popup-call-me-with-tel.template.html',
                clickOutsideToClose: true,
                locals: {
                    popup: popup,
                    closeCallback: closeCallMeWithTel
                },
                multiple: true
            });

        };
    }

})();
