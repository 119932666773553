(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('landing-page', {
            parent: 'landing',
            url: '/sprawdz?key',
            data: {
                authorities: ['open'],
                pageTitle: 'global.menu.landing'
            },
            views: {
                'content@': {
                    templateUrl: 'app/landing/landing-page/landing-page.html',
                    controller: 'LandingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {

                    $translatePartialLoader.addPart('landing');
                    $translatePartialLoader.addPart('register');
                    $translatePartialLoader.addPart('dictionary');
                    $translatePartialLoader.addPart('payments');
                    $translatePartialLoader.addPart('document');

                    return $translate.refresh();
                }]
            }
        });
    }

})();
