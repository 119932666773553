(function() {
	'use strict';
	angular.module('plagiat2017App').factory(
			'DocumentParameterDefinitionService',
			DocumentParameterDefinitionService);

	DocumentParameterDefinitionService.$inject = [ '$http', '$q', '$translate' ];

	function DocumentParameterDefinitionService($http, $q, $translate) {
		var service = {
			findOneByName : findOneByName,
		};
		return service;

		function findOneByName(dopadeName) {
			return $http.get(
					'api/document-parameter-definitions?dopadeName='
							+ dopadeName).then(function(response) {
				return response.data;
			}, function(errResponse) {
				return $q.reject(errResponse);
			});
		}

	}

})();
