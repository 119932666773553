(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('TranslationModel', TranslationModel);
    TranslationModel.$inject = ['JhiLanguageService'];

    function TranslationModel(JhiLanguageService) {
        function Model() {
            this.id = null;
            this.code = null;
            generateTranslations(this);
        }

        function generateTranslations(model) {
            JhiLanguageService.getAll().then(function (allLanguages) {
                allLanguages.data.availableLanguages.forEach(function (lang) {
                    model[lang] = null;
                });
            });
        }

        return Model;
    }

})();
