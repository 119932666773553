(function () {
    'use strict';

    function UploadFileController($translate, $mdDialog) {
        var vm = this;
        vm.isLoading = false;
        vm.getTheFiles = getTheFiles;

        function getTheFiles($files, $invalidFiles) {
            vm.files = $files;
            vm.errFiles = [];
            vm.errMessage = null;
            vm.onGetTheFiles($files, $invalidFiles);
            angular.forEach($invalidFiles, function (value, key) {
                if (value.$error) {
                    vm.errMessage = value.$error;
                }
                vm.errFiles[vm.errFiles.length] = value.name.substring(value.name.lastIndexOf(".") + 1);
            });

            if (vm.errMessage && vm.errMessage !== 'pattern') {
                vm.errorContent = $translate.instant('document-import.files.' + vm.errMessage);
            } else {
                vm.errorContent = $translate.instant("document-import.files.invalid-extension", { wrongExtension: vm.errFiles.join(", "), validExtensions: vm.onAvailableExtensions });
            }
            
            if (vm.errFiles.length > 0) {
                $mdDialog.show(
                  $mdDialog.confirm()
                  .clickOutsideToClose(true)
                  .title($translate.instant("global.messages.error.label"))
                  .textContent(vm.errorContent)
                  .ariaLabel('ok')
                  .ok('OK')
                  );
            }
        }

    }

    angular.module('fileUpload', ['ngFileUpload'])
    .component('uploadFile', {
        templateUrl: 'app/components/upload-file/upload-file.template.html',
        controller: UploadFileController,
        bindings: {
            documentFormFile: '<',
            onGetTheFiles: '<',
            onAvailableExtensions: '<',
            files: '=',
            isLoading: '=',
            allowMultipleFiles: '<',
            isDisabled: '<',
            maxSize: '<'
        }
    });

})();
