(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ClientService', ClientService);

    ClientService.$inject = ['$http', '$q'];

    function ClientService($http, $q) {

        var service = {
            searchClients: searchClients,
            getClients: getClients,
            getClient: getClient,
            getClientForEditing: getClientForEditing,
            getClientProtocols: getClientProtocols,
            addClientProtocol: addClientProtocol,
            removeClientProtocol: removeClientProtocol,
            getClientDocumentKinds: getClientDocumentKinds,
            updateClientDocumentKinds: updateClientDocumentKinds,
            getClientGroups: getClientGroups,
            getClientRegions: getClientRegions,
            getClientCountries: getClientCountries,
            clientDetailsObj: {},
            client: null,
            clientTypes: getClientTypes(),
            listDatabaseExchangeProgramMembers: listDatabaseExchangeProgramMembers,
            isDatabaseExchangeProgramMember: isDatabaseExchangeProgramMember,
            addTestClient: addTestClient,
            save: save,
            update: update,
            remove: remove,
            freeze: freeze,
            unfreeze: unfreeze,
            searchObject: null,
            queryRequest: null
	    };
      
        return service;

        function getClientTypes(){
            return {
                'other': {
                    'suffix' : ''
                },
                'notIndividual': {
                    'suffix': '_notindividual'
                },
                'individual': {
                    'suffix': '.individual'
                },
                'publisher': {
                    'suffix': '.publisher'
                },
                'school': {
                    'suffix': '.school'
                },
                'codeAccount': {
                    'suffix': '.code-account'
                }
            };
        }

        function getClient(id) {
            return $http.get('/api/client/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getClientForEditing(id) {
            return $http.get('/api/client/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function getClientProtocols(id) {
            return $http.get('/api/client/protocols/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function removeClientProtocol(clientId, protocolId) {
            return $http.delete('/api/client/protocols/remove/' + clientId + '/' + protocolId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
        function addClientProtocol(clientId, protocolId) {
            return $http.post('/api/client/protocols/add/' + clientId + '/' + protocolId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
        function getClientDocumentKinds(id) {
            return $http.get('/api/client/document-kinds/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function updateClientDocumentKinds(obj) {
            return $http.post('/api/client/update-document-kinds', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
  
        function getClientGroups() {
            return $http.get('/api/client/client-groups/').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
  
        function getClientRegions() {
            return $http.get('/api/client/client-regions/').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
  
        function getClientCountries() {
            return $http.get('/api/client/client-countries/').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
  
      function searchClients(obj) {
            return $http.post('/api/client/search', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getClients(obj) {
            return $http.post('/api/client/list', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function listDatabaseExchangeProgramMembers(obj) {
            return $http.post('/api/client/list-database-exchange-program-members', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function isDatabaseExchangeProgramMember() {
            return $http.get('/api/client/is-database-exchange-program-member').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function save(obj) {
            return $http.post('/api/client', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function addTestClient(obj) {
            return $http.post('/api/client/add-test-client', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function update(obj) {
            return $http.put('/api/client', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function remove(id) {
            return $http.delete('/api/client/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
        function freeze(id) {
            return $http.get('/api/client/freeze/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
        function unfreeze(id) {
            return $http.get('/api/client/unfreeze/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
    }

})();
