(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('TranslationImportController', TranslationImportController);

    TranslationImportController.$inject = ['JhiLanguageService', 'Utils',
        'TranslationsService', 'ModalService',
        '$state'];

    function TranslationImportController(JhiLanguageService, Utils,
        TranslationsService, ModalService,
        $state
    ) {
        var vm = this;

        vm.availableLanguages;
        vm.availableExtensions = '.xls,.xlsx';
        vm.files = null;
        vm.data = null;
        vm.duplicates = null;
        vm.conflictingExternalTranslationCodes = null;
        vm.isLoaderActive = false;
        vm.isLoading = false;
        vm.getTheFiles = getTheFiles;
        vm.uploadFile = uploadFile;
        vm.removeTranslationRow = removeTranslationRow;
        vm.saveImportedTranslations = saveImportedTranslations;

        this.$onInit = function () {
            initAvailableLanguages();
        };

        function getTheFiles($files, $invalidFiles) {
            if ($files === null) {
                vm.files = null;
            } else {
                if ($invalidFiles.length === 0) {
                    var formData = new FormData();
                    vm.files = $files;
                    angular.forEach(vm.files, function (value, key) {
                        vm.fileName = Utils.removeSpecialCharsForFileName(
                            value.name);
                        formData.append(key, value);
                    });
                    if (vm.files.length > 0) {
                        vm.uploadFile(formData, vm.fileName);
                    }
                }
            }
        }

        function uploadFile(obj, filename) {
            vm.isLoaderActive = true;

            TranslationsService.uploadTranslationsFile(obj, filename).then(
                function (responseData) {
                    vm.isLoaderActive = false;
                    vm.data = responseData.data;
                    vm.availableLanguages = vm.data.langs;
                    vm.duplicates = vm.data.duplicates;
                    vm.conflictingExternalTranslationCodes = vm.data.conflictingExternalTranslationCodes;
                }, function (errorResponse) {
                    vm.isLoaderActive = false;
                    ModalService.showErrorModal(errorResponse);
                });
        }

        function removeTranslationRow(row) {
            var filteredAry = vm.data.data.filter(function (e) {
                return e !== row
            });
            vm.data.data = filteredAry;
        }

        function saveImportedTranslations() {
            vm.isLoaderActive = true;

            TranslationsService.saveImportedTranslations(vm.data).then(
                function () {
                    vm.isLoaderActive = false;
                    vm.data = null;
                    vm.availableLanguages = ["en"];
                    vm.duplicates = null

                    ModalService.showSuccessModal().then(
                        function () {
                            $state.go("translations");
                        }, function () {
                            $state.go("translations");
                        });
                }, function (errorResponse) {
                    vm.isLoaderActive = false;
                    ModalService.showErrorModal(errorResponse);
                });
        }

        function initAvailableLanguages() {
            JhiLanguageService.getAll().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
            });
        }
    }

})();
