(function () {
    'use strict';

    function DocumentFormAssignmentController($scope, $q, _, AccountService, AssignmentsService, PlagiatValidationService, Utils) {
        var vm = this;
        vm.loading = false;
        vm.submitAssignment = submitAssignment;
        vm.resetUploadForm = resetUploadForm;
        vm.clientTypeSchool = AccountService.clientType.isSchool();
        
        function submitAssignment(form) {
            if (!Utils.validateForm(form)) {
                return;
            }
            vm.loading = true;
            AssignmentsService.submitAssignment(vm.onAssignmentSubmitForm)
                .then(function (responseData) {
                    vm.resetUploadForm();
                    vm.onReloadDocuments();
                    vm.loading = false;
            }).catch(function (response) {
                vm.loading = false;
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function resetUploadForm() {
            vm.onUploadObj = {
                documentSkipAddresses: []
            };
            vm.onUploadIsActive = false;
            vm.onUploadTextFlag = false;
            vm.isLoading = false;
            vm.onGetTheFiles(null, {});
            vm.authorIndex = 0;
            vm.selectedItem  = null;
            vm.searchText    = null;
        }

    }

    angular.module('plagiat2017App')
    .component('documentFormAssignment', {
        templateUrl: 'app/account/documents/document/document-form/document-form-assignment.template.html',
        controller: DocumentFormAssignmentController,
        bindings: {
            onUploadIsActive: '=',
            onUploadTextFlag: '=',
            onUploadObj: '<',
            onReloadDocuments: '<',
            onGetTheFiles: '<',
            onUploadText: '<',
            isLoading: '=',
            onAssignmentSubmitForm: '<',
        }
    });

})();
