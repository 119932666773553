(function() {
	'use strict';

	function DocumentOperatorDetailsController($scope, $log, DOCUMENT_STATUS, AccountService) {
		var vm = this;
		vm.DOCUMENT_STATUS = DOCUMENT_STATUS;
		vm.addPriorityFromContractEnabled = AccountService.getClientSettings().addPriorityFromContractEnabled;
	}

	angular
	.module('plagiat2017App')
	.component(
		'documentOperatorDetails',
		{
			templateUrl : 'app/components/document/document-operator-details/document-operator-details.template.html',
			controller : DocumentOperatorDetailsController,
			bindings: {
				onDocument: '<',
				onDocumentDetails: '<',
                onIsSuperadmin: '<',

			}
		});

})();
