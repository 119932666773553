(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .constant('CONTRACTTYPECONSTANT', function() {
            var constant = {
                CONT_TYPE_PER_DOCUMENT: {id: 0, key: 'contract.type.documents'},
                CONT_TYPE_PER_CHARACTER: {id: 1, key: 'contract.type.characters'},
                CONT_TYPE_PER_USER: {id: 2, key: 'contract.type.users'},
                CONT_TYPE_PER_AUTHOR: {id: 3, key: 'contract.type.authors'}
            };
            constant.arrayOfValues = [constant.CONT_TYPE_PER_DOCUMENT, constant.CONT_TYPE_PER_CHARACTER, constant.CONT_TYPE_PER_USER, constant.CONT_TYPE_PER_AUTHOR];
            return constant;
            }()
        )
        .constant('ASAP_CONTRACT_INSTALLATION_CONSTANT', function () {
            var constant = {
                INSTALL_TYPE_BASIC: {id: 0, key: 'contract.asap.install.basic'},
                INSTALL_TYPE_STANDARD: {id: 1, key: 'contract.asap.install.standard'}
            };
            constant.arrayOfValues = [constant.INSTALL_TYPE_BASIC, constant.INSTALL_TYPE_STANDARD];
            return constant;
            }()
        )
        .constant('ASAP_CONTRACT_INTEGRATION_CONSTANT', function () {
                var constant = {
                    INTEGRATION_AUTHORIAL: {id: 0, key: 'contract.asap.integration.authorial'},
                    INTEGRATION_EORDO: {id: 1, key: 'contract.asap.integration.eordo'},
                    INTEGRATION_EHMS: {id: 2, key: 'contract.asap.integration.ehms'},
                    INTEGRATION_PROAKADEMIA: {id: 3, key: 'contract.asap.integration.proakademia'},
                    INTEGRATION_AZURE: {id: 4, key: 'contract.asap.integration.azure'}
                };
                constant.arrayOfValues = [constant.INTEGRATION_AUTHORIAL, constant.INTEGRATION_EORDO, constant.INTEGRATION_EHMS, constant.INTEGRATION_PROAKADEMIA, constant.INTEGRATION_AZURE];
                return constant;
            }()
        )
})();
