(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DocumentIndividualService', DocumentIndividualService);

    DocumentIndividualService.$inject = ['$http', '$q'];

    function DocumentIndividualService($http, $q) {

        var service = {
            getForm: getForm,
            editDetails: editDetails
		};
        
        return service;

        function getForm(id) {
            return $http.get('/api/individual/document/form/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function editDetails(obj) {
            return $http.post('api/individual/document', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
