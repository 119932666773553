(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('PopupAdministrationService', PopupAdministrationService);

    PopupAdministrationService.$inject = ['$http', '$q'];

    function PopupAdministrationService($http, $q) {

        var service = {
            orderContactByRegularPopup: orderContactByRegularPopup,
            search: search,
            findAllToShowForCurrentUser: findAllToShowForCurrentUser,
            remove: remove,
            switchOff: switchOff,
            copy: copy,
            getPopupForEditing: getPopupForEditing,
            saveOrUpdate: saveOrUpdate
        };
        var requestUrl = 'api/popup';
        return service;

        function orderContactByRegularPopup(id, telephone) {
            return $http.post(requestUrl + '/order-contact', {id: id, telephone: telephone}).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getPopupForEditing(id) {
            return $http.get(requestUrl + '/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function remove(id) {
            return $http.get(requestUrl + '/remove?id=' + id).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function switchOff(id) {
            return $http.get(requestUrl + '/switch-off?id=' + id).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function copy(id) {
            return $http.get(requestUrl + '/copy?id=' + id).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveOrUpdate(obj) {
            return $http.post(requestUrl + '/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findAllToShowForCurrentUser() {
            return $http.post(requestUrl + '/all-to-show-for-current-user').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }
})();
