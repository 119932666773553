(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('client', {
            parent: 'app',
            url: '/client',
            data: {
                authorities: ['PERM_USER_SUPERADMIN', 'PERM_USER_RESELLER', 'PERM_USER_MINISTRY'],
                pageTitle: 'global.menu.clients'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client.html',
                    controller: 'ClientController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'userName,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('client');
                    $translatePartialLoader.addPart('contract');
                    $translatePartialLoader.addPart('country');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-add', {
            parent: 'client',
            url: '/add',
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'client.add.header'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client-add/client-add.html',
                    controller: 'ClientAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('client');
                    $translatePartialLoader.addPart('contract');
                    $translatePartialLoader.addPart('finder');
                    $translatePartialLoader.addPart('validation');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-add-test', {
            parent: 'client',
            url: '/add-test',
            data: {
                authorities: ['PERM_USER_RESELLER'],
                pageTitle: 'client.header.add-test'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client-add/client-add-test.html',
                    controller: 'ClientAddTestController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('client');
                    $translatePartialLoader.addPart('validation');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-edit', {
            parent: 'client',
            url: '/:id/:step/edit',
            data: {
                authorities: ['PERM_USER_SUPERADMIN', 'PERM_USER_RESELLER'],
                pageTitle: 'client.edit.header'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client-add/client-add.html',
                    controller: 'ClientAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('client');
                    $translatePartialLoader.addPart('contract');
                    $translatePartialLoader.addPart('finder');
                    $translatePartialLoader.addPart('validation');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-document-kinds', {
            parent: 'client',
            url: '/:clientId/client-document-kinds',
            data: {
                authorities: ['PERM_USER_SUPERADMIN', 'PERM_USER_RESELLER'],
                pageTitle: 'client.edit.header'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client-document-kinds/client-document-kinds.html',
                    controller: 'ClientDocumentKindsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-protocols', {
            parent: 'client',
            url: '/:clientId/client-protocols',
            data: {
                authorities: ['PERM_USER_SUPERADMIN', 'PERM_USER_RESELLER'],
                pageTitle: 'client.edit.header'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client-protocols/client-protocols.html',
                    controller: 'ClientProtocolsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client');
                    $translatePartialLoader.addPart('protocol');
                    return $translate.refresh();
                }]
            }
        })
        .state('client-protocol-add', {
            parent: 'client-protocols',
            url: '/:clientId/client-protocol-add',
            data: {
                authorities: ['PERM_USER_SUPERADMIN', 'PERM_USER_RESELLER'],
                pageTitle: 'client.edit.header'
            },
            views: {
                'content@': {
                    templateUrl: 'app/client/client-protocols/client-protocol-add.html',
                    controller: 'ClientProtocolAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('client');
                    $translatePartialLoader.addPart('protocol');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
