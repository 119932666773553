(function () {
    'use strict';

    function PaymentsListController($localStorage, PaymentsService, $translate) {
        var vm = this;
        vm.documentRequest = {};
        vm.searchRequest = {};
        vm.showPayments = vm.onGetKey;
        vm.tooglePayments = tooglePayments;
        vm.clear = clear;
        vm.getPayments = getPayments;
        vm.downloadInvoice = downloadInvoice;
        vm.selected = [];
        vm.promise;
        vm.dateNow = new Date();
        vm.dateFormat = 'YYYY-MM-DD HH:mm';

        vm.options = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: false,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: false
        };


        vm.query = {
            order: '-paymentDate',
            limit: $localStorage.paymentListLimit ? $localStorage.paymentListLimit : 5,
            page: 1,
            search: vm.searchRequest
        };

        vm.startPaging = startPaging;
        vm.goTo = goTo;
        vm.changeLimit = changeLimit;
        vm.onGetPayments = onGetPayments;

        vm.tableProperties = {};


        function goTo() {
            vm.getPayments();
        }

        function startPaging(page) {
            vm.query.page = page;
            vm.getPayments();
        }

        function changeLimit(n) {
            vm.query.limit = n;
            vm.getPayments();
        }
        
        function downloadInvoice(invoiceNumber) {
            PaymentsService.downloadInvoice(invoiceNumber).then(
                function () {},
                function (response) {
                    PlagiatValidationService.getModalError(response.data);
                });
        }

        function getPayments() {
            $localStorage.paymentListLimit = vm.query.limit;
            var reverse = vm.query.order.slice(0, 1);
            if (reverse === '-') {
                var _length = vm.query.order.length;
                vm.tableProperties.sort = {
                    predicate: vm.query.order.slice(1, _length),
                    reverse: false
                };
            } else {
                vm.tableProperties.sort = {
                    predicate: vm.query.order,
                    reverse: true
                };
            }
            vm.tableProperties.paging = {
                page: vm.query.page,
                limit: vm.query.limit
            };

            vm.tableProperties.search = vm.searchRequest;

            vm.onGetPayments(vm.tableProperties);
        }

        function clear() {
            vm.searchRequest = {};
            getPayments();
        }

        function tooglePayments() {
            !(vm.showPayments) ? getPayments() : '';
            vm.showPayments = !vm.showPayments;
        }

        function onGetPayments(obj) {
            vm.predicate = obj.sort.predicate;
            vm.reverse = obj.sort.reverse;
            vm.page = obj.paging.page;
            vm.itemsPerPage = obj.paging.limit;
            vm.search = obj.search;
            PaymentsService.get({
                pageNumber: (vm.page - 1),
                pageSize: vm.itemsPerPage,
                sortTab: sort(),
                search: vm.search
            }, onSuccess, onError).then(function (responseData) {
                var responseObj = responseData.content;
                angular.forEach(responseObj, function (value, key) {
                    value.providerStatusId = value.providerStatus;
                    value.providerStatusTooltipKey = value.providerStatus.replace('payment.status', 'payments.status.tooltip');
                    value.providerStatus = $translate.instant("payments." + value.providerStatus);
                    value.userPaymentType = $translate.instant("payments." + value.userPaymentType);
                    value.paymentDate = value.paymentDate ? moment(value.paymentDate).format(vm.dateFormat) : value.paymentDate;
                    value.codeExpiryDate = value.codeExpiryDate ? moment(value.codeExpiryDate).format('YYYY-MM-DD') : value.codeExpiryDate;
                    value.codeUseDate = value.codeUseDate ? moment(value.codeUseDate).format(vm.dateFormat) : value.codeUseDate;
                });
                vm.payments = responseObj;
                vm.totalPayments = responseData.totalElements;
            });
        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function onSuccess(data, headers) {
            var hiddenUsersSize = 0;
            for (var i in data) {
                if (data[i]['login'] === 'anonymoususer') {
                    data.splice(i, 1);
                    hiddenUsersSize++;
                }
            }
            vm.totalItems = headers('X-Total-Count') - hiddenUsersSize;
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.payments = data;
        }

        function onError(error) {
            AlertService.error(error.data.message);
        }

    }

    angular.module('paymentsList', [])
    .component('paymentsList', {
        templateUrl: 'app/account/payments/payments-list/payments-list.template.html',
        controller: PaymentsListController,
        controllerAs: 'vm',
        bindings: {
            onGetKey: '=',
            onCodePayments: '='
        }
    });

})();
