(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('documentParameterDefinitionsSearchRequest', function () {

        function searchRequest() {
            this.pageNumber = 0;
            this.pageSize = 5;
            this.sortTab = '';
            this.search = {};
        }

        return searchRequest;
    });
})();