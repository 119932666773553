(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('InstanceService', InstanceService);

    InstanceService.$inject = ['$http', '$q', '$log', '$location', 'INSTANCES', 'DetectInstanceService'];

    function InstanceService($http, $q, $log, $location, INSTANCES, DetectInstanceService) {

        var service = {
            instance: getInstance,
            findInstanceByBrand: findInstanceByBrand,
            getSubdomain: getSubdomain
        };

        var instanceSettings;
        var host = $location.host();

        function getUserTimezone() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        }

        function getSubdomain() {
            if (host === 'localhost') {
                return 'st';
            } else {
                if (host.indexOf('.') < 0) {
                    return null;
                } else {
                    return host.split('.')[0];
                }
            }
        }

        function getInstance() {
            getInstanceEngine(DetectInstanceService.instance());
            return instanceSettings;
        }

        function findInstanceByBrand(brand) {
            getInstanceEngine(brand);
            return instanceSettings;
        }

        function getInstanceEngine(brand) {
            var instance = INSTANCES.find(function (currentValue) {
                return brand === currentValue.instance;
            });
            if (!instance) return null;
            instanceSettings = instance.settings;

            var timezone = instanceSettings.timezones.find(function (currentValue) {
                return getSubdomain() === currentValue.subdomain;
            });
            if (brand === 'int') {
                instanceSettings.timezone = getUserTimezone();
                instanceSettings.timezoneDocuments = getUserTimezone();
                instanceSettings.timezonePayments = getUserTimezone();
            } else {
                instanceSettings.timezone = timezone.timezone;
                instanceSettings.timezoneDocuments = timezone.documents;
                instanceSettings.timezonePayments = timezone.payments;
            }
        }

        return service;
    }
})();
