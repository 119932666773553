(function() {
	'use strict';

	function FbPixelPlController($cookies) {
		var vm = this;
		vm.theme = $cookies.get('plagiat-theme');

		if (vm.theme === 'pl'){
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};
				if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
				n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];
				s.parentNode.insertBefore(t,s)}(window,document,'script',
				'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '2793300560737963');
			fbq('track', 'PageView');
		}
	}

	angular.module('plagiat2017App')
	.component('fbPixelPl', {
		templateUrl: 'app/components/fb-pixel/fb-pixel.template.html',
		controller: FbPixelPlController
	});

})();
