(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .controller('ClientAddTestController', ClientAddTestController);

    ClientAddTestController.$inject = [
        'ClientService', 'ClientEditRequestModel', '$state',
        'ModalService', 'JhiLanguageService', 'FinderService'
    ];

    function ClientAddTestController(
        ClientService, ClientEditRequestModel, $state,
        ModalService, JhiLanguageService, FinderService
    ) {
        var vm = this;

        this.$onInit = function () {
            onInit();
        };

        // general
        vm.client;

        vm.availableLanguages = [];
        vm.availableFinders = [];
        vm.findersSelection = {};
        vm.toggleFinderSelection = toggleFinderSelection;
        vm.save = save;

        function onInit() {
            JhiLanguageService.getAll().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
            });
            
            //tworzenie klienta
            vm.client = new ClientEditRequestModel();
            vm.client.basicData.clientType.id = 0;
            JhiLanguageService.getCurrent().then(function (language) {
                vm.client.basicData.clieLang = language;
            });
            initializeFindersSelection();
        }

        function initializeFindersSelection() {
            FinderService.getAllAvailableForClientType(vm.client.basicData.clientType.id, vm.client.basicData.countryId, true)
                .then(function (success) {
                    vm.availableFinders = success.data;
                    vm.availableFinders.forEach(function (entry) {
                        if (entry.defaultQueriesPerKilo > 0) {
                            if ((entry.description !== 'finder.database.shukalka') && (entry.description !== 'finder.database.whitedocs')) {
                                vm.findersSelection[entry.id] = true;
                                vm.toggleFinderSelection(entry.id);
                            }
                        }
                    });
                });
        }

        function toggleFinderSelection(finderId) {
            if (vm.findersSelection[finderId]) {
                if (!vm.client.findersConfiguration[finderId]) {
                    vm.client.findersConfiguration[finderId] = {id: null, queriesPerKilo: 0, visible: true};
                }
                vm.client.findersConfiguration[finderId].queriesPerKilo = vm.availableFinders.find(function (finder) {
                    return finder.id === finderId;
                }).defaultQueriesPerKilo;
                vm.client.findersConfiguration[finderId].visible = true;
                //teraz sprawdzam, czy nie trzeba wylaczyc innego findera intenetowego (0 lub 18)
                var currentFinder = vm.availableFinders.find(function (finder) {
                    return finder.id === finderId;
                });

                if (currentFinder && (currentFinder.finderType === 0 || currentFinder.finderType === 18)) {
                    var otherWebFinder = vm.availableFinders.find(function (finder) {
                        return finder.id !== finderId && (finder.finderType === 0 || finder.finderType === 18);
                    });
                    if (otherWebFinder) {
                        vm.findersSelection[otherWebFinder.id] = false;
                        if(!vm.client.findersConfiguration[otherWebFinder.id]) {
                            vm.client.findersConfiguration[otherWebFinder.id] = {}
                        }
                        vm.client.findersConfiguration[otherWebFinder.id].queriesPerKilo = 0;
                        vm.client.findersConfiguration[otherWebFinder.id].visible = false;
                    }
                }
            } else {
                vm.client.findersConfiguration[finderId].queriesPerKilo = 0;
                vm.client.findersConfiguration[finderId].visible = false;
            }
        }

        function save() {
            vm.isSaving = true;
            
            ClientService.addTestClient(vm.client).then(
                function () {
                    vm.isSaving = false;
                    ModalService.showSuccessModal();
                    $state.go("client");
                },
                function (response) {
                    vm.isSaving = false;
                    ModalService.showErrorModal(response);
                }
            );
            
        }

    }
})();
