(function() {
	'use strict';

	function DocumentFormIndividualController($scope, $mdDialog,
			DocumentService, DocumentIndividualService, $localStorage, $log, $q, _, $timeout) {
		var vm = this;
		vm.closeDialog = closeDialog;
		vm.saveData = saveData;
		vm.idDocument = DocumentService.document.id;

		function getForm(idDocument) {
			DocumentIndividualService.getForm(idDocument).then(
				function(responseData) {
					vm.form = responseData.data;
			});
		}

		function closeDialog() {
			$mdDialog.hide();
		}

		function saveData(form) {
			DocumentIndividualService.editDetails(form).then(function(responseData) {
				$mdDialog.hide();
			});
		}

		if (vm.idDocument !== null) {
			getForm(vm.idDocument);
		}

	}

	angular
	.module('plagiat2017App')
	.component(
		'documentFormIndividual',
		{
			templateUrl : 'app/components/document/document-form-individual/document-form-individual.template.html',
			controller : DocumentFormIndividualController
		});

})();
