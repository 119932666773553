(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('PortalOfClientService', PortalOfClientService);
    
    PortalOfClientService.$inject = ['$http', '$q'];

    function PortalOfClientService($http, $q) {

        var service = {
            addToClientPortals: addToClientPortals,
            search: search,
            getForEditing: getForEditing,
            remove: remove,
            saveOrUpdate: saveOrUpdate
        };
        var requestUrl = 'api/portal-of-client';
        return service;

        function addToClientPortals(obj) {
            return $http.post(requestUrl + '/add-to-client-portals', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getForEditing(id) {
            return $http.get(requestUrl + '/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function saveOrUpdate(obj) {
            return $http.post(requestUrl + '/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function remove(id) {
            return $http.get(requestUrl + '/remove?id=' + id).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
    
    }
})();
