angular.module('plagiat2017App')
.component('selfEnrollmentModal', {
    templateUrl: 'app/account/assignments/peer-review/add-from-library.template.html',
    controller: AddFromLibraryController,
    controllerAs: 'vm'
});

AddFromLibraryController.$inject = ['ModalService', 'PeerReviewLibraryService', '$mdDialog', 'items'];

function AddFromLibraryController(ModalService, PeerReviewLibraryService, $mdDialog, items) {
    var vm = this;

    vm.cancel = cancel;
    vm.addSelectedQuestionsFromLibrary = addSelectedQuestionsFromLibrary;

    vm.library = items.library;
    vm.questions = [];
    vm.checkedQuestions = {};

    this.$onInit = function () {
        if (vm.library.questions) {
            vm.questions = vm.library.questions;
        }
        vm.selectAllQuestions();
    };

    function addSelectedQuestionsFromLibrary() {
        items.onAddFromLibrary(vm.getSelectedQuestions());
        cancel();
    }

    vm.deleteQuestion = function (index, questionId) {
        vm.isLoading = true;
        PeerReviewLibraryService.deleteLibraryQuestion(questionId).then(function () {
            vm.questions.splice(index, 1);
            vm.isLoading = false;
            vm.checkedQuestions.splice(index, 1);
        })
    }

    vm.selectAllQuestions = function () {
        for (var i = 0; i < vm.questions.length; i++) {
            vm.checkedQuestions[i] = true;
        }
    };

    vm.deselectAllQuestions = function () {
        for (var i = 0; i < vm.questions.length; i++) {
            vm.checkedQuestions[i] = false;
        }
    };

    vm.getSelectedQuestions = function() {
        var selectedQuestions = [];
        for (var i = 0; i < vm.questions.length; i++) {
            if (vm.checkedQuestions[i]) {
                selectedQuestions.push(vm.questions[i]);
            }
        }
        return selectedQuestions;
    };

    function cancel() {
        return $mdDialog.hide();
    }

}
