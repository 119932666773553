(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .directive('inputDate', inputDate);

    function inputDate(Utils) {
    	return {
    	    restrict: 'A',
    	    link: function ($scope, element, attrs, ngModel) {
    	        element.bind('keypress', function (e, inputs) {
    	            if(!Utils.isAllowedCharForDate(e)) {
    	            	e.preventDefault();
    	            }
    	        })
    	    }
    	  };
    }
})();
