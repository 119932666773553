(function () {
    'use strict';
    
    angular
        .module('plagiat2017App')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];
    
    function stateConfig($stateProvider) {
        $stateProvider
            .state('document-kinds', {
                parent: 'account',
                url: '/document-kinds',
                data: {
                    authorities: ['PERM_USER_ADMIN'],
                    pageTitle: 'global.menu.document-kinds'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/document-kinds/document-kinds-list.template.html',
                        controller: 'DocumentKindsListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('users');
                        $translatePartialLoader.addPart('client');
                        return $translate.refresh();
                    }]
                }
            })
            .state('edit-thresholds', {
                parent: 'document-kinds',
                url: '/:documentKindId/edit-thresholds',
                data: {
                    authorities: ['PERM_USER_ADMIN'],
                    pageTitle: 'global.menu.document-kinds'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/document-kinds/edit-thresholds/edit-thresholds.html',
                        controller: 'EditThresholdsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('users');
                        $translatePartialLoader.addPart('client');
                        return $translate.refresh();
                    }]
                }
            })

        ;
    }
})();
