(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('DocumentParameterDefinitionsController', DocumentParameterDefinitionsController);

    DocumentParameterDefinitionsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate',
    	'documentParameterDefinitionsQueryRequest', 'documentParameterDefinitionsSearchRequest', 'tableSettingsRequest',
    	'DocumentParameterDefinitionsService', 'SearchService', '$mdDialog', '$mdToast', 'PlagiatValidationService',
        '$localStorage', '$scope'];

    function DocumentParameterDefinitionsController (Principal, Auth, JhiLanguageService, $translate,
    		documentParameterDefinitionsQueryRequest, documentParameterDefinitionsSearchRequest, tableSettingsRequest,
    		DocumentParameterDefinitionsService, SearchService, $mdDialog, $mdToast, PlagiatValidationService,
            $localStorage) {

        var vm = this;
        vm.activeTab='documentParameterDefinitions';
        vm.queryRequest = new documentParameterDefinitionsQueryRequest();
        vm.queryRequest.limit = $localStorage.documentParameterDefinitionListLimit;
        vm.searchRequest = new documentParameterDefinitionsSearchRequest();
        vm.options = new tableSettingsRequest();
        vm.selected = [];
        vm.startPaging = startPaging;
        vm.prepareMapping = prepareMapping;
        vm.getData = getData;
        vm.removeParameters = removeParameters;
        vm.pickedIds = [];

        prepareMapping();

        function prepareMapping(){
        	getData(vm.documentParameterDefinitionsSearchRequest);
        }

        function getData(documentParameterDefinitionsSearchRequest){
            vm.selected.length = 0;
            vm.showDetailsBlock = false;
            vm.documentParameterDefinitionsSearchRequest = documentParameterDefinitionsSearchRequest;
            vm.searchRequest.pageNumber = (vm.queryRequest.page - 1);
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);

            vm.isLoadingData = true;
            DocumentParameterDefinitionsService.getDocumentParameterDefinitions(vm.searchRequest).then(function (responseData) {
                var responseObj = responseData.content;

                vm.documentParameterDefinitions = responseObj;
                vm.totalElements = responseData.totalElements;

                vm.isLoadingData = false;
            });
        }

        function startPaging(page) {
         	vm.isLoadingData = true;
             vm.queryRequest.page = page;
             prepareMapping();
         }

        function transformSelectedObj() {
            vm.pickedIds.length = 0;
            angular.forEach(vm.selected, function (value) {
                vm.pickedIds.push(value.id);
            });
        }

        function removeParameters(ev) {
            var confirm = $mdDialog.confirm()
            .title($translate.instant("global.confirmremove"))
            .textContent($translate.instant("global.areyousure"))
            .ariaLabel($translate.instant("global.areyousure"))
            .ok($translate.instant("global.button.remove"))
            .cancel($translate.instant("global.button.cancel"))
            .openFrom('#left');
            $mdDialog.show(confirm).then(function () {
                transformSelectedObj();
                DocumentParameterDefinitionsService.removeMultiple(vm.pickedIds).then(function () {
                    vm.selected.length = 0;
                    prepareMapping();

            		$mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .title('')
                            .textContent($translate.instant("global.table.delete-multiple.msg.success"))
                            .ariaLabel('msgSuccess')
                            .ok('Ok')
                            .targetEvent(ev)
                            );
                }, function () {
                	PlagiatValidationService.getModalError(response.data);
                });
            });
        }

    }
})();
