(function () {
    'use strict';

    function ContractDetailController(CONTRACTTYPECONSTANT, ASAP_CONTRACT_INSTALLATION_CONSTANT, ASAP_CONTRACT_INTEGRATION_CONSTANT) {
        var vm = this;
        this.$onInit = function () {
            vm.contract = this.contract;
            if (vm.contract.isAsap) {
                var installationType = ASAP_CONTRACT_INSTALLATION_CONSTANT.arrayOfValues.find(function (installation) {
                    return installation.id === vm.contract.installationType;
                });
                vm.installationTypeKey = installationType ? installationType.key : '---';
                var integrationType = ASAP_CONTRACT_INTEGRATION_CONSTANT.arrayOfValues.find(function (integration) {
                    return integration.id === vm.contract.integrationType;
                });
                vm.integrationTypeKey = integrationType ? integrationType.key : '---';
            } else {
                var contractType = CONTRACTTYPECONSTANT.arrayOfValues.find(function (contType) {
                    return contType.id === vm.contract.contType;
                });
                vm.contractTypeKey = contractType ? contractType.key : '---';
            }
        }
    }

    angular
        .module('plagiat2017App')
        .component('contractDetails',
            {
                templateUrl: 'app/client/client-add/contract-details/contract-details.template.html',
                controller: ContractDetailController,
                bindings: {
                    contract: '<',
                }
            }
        );

})();
