(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$timeout', 'Auth', 'PlagiatValidationService'];

    function RequestResetController ($timeout, Auth, PlagiatValidationService) {
        var vm = this;

        vm.error = null;
        vm.errorKey = null;
        vm.errorEmailNotExists = null;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function (){angular.element('#email').focus();});

        function requestReset () {

            vm.error = null;
            vm.errorEmailNotExists = null;

            Auth.resetPasswordInit({email: vm.resetAccount.email}).then(
            		function () {
            			vm.success = true;
            			vm.error = false;
            		},
            		function (errResponse) {
            			vm.error = true;
            			vm.success = false;
            			vm.errorKey = PlagiatValidationService.getTranslationKeyError(errResponse.data.code);
            		}
            );
        }
    }
})();
