(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('SimilarityReportSettingsService', SimilarityReportSettingsService);

    SimilarityReportSettingsService.$inject = ['$http', '$q'];

    function SimilarityReportSettingsService($http, $q) {

        var service = {
        	getForm: getForm,
        	edit: edit,
		}
        
        return service;
        
        function getForm(clientId) {
        	return $http.get('api/similarity-report-settings/' + clientId).then(
        			function (response) {
        				return response.data;
        			},
        			function (errResponse) {
        				return $q.reject(errResponse);
        			});
        }

        function edit(obj) {
            return $http.post('api/similarity-report-settings', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
