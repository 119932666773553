(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .filter('percentage', percentage);

    percentage.$inject = ['$filter'];

    function percentage($filter) {
        return function (input, decimals) {
            return $filter('number')(input * 100, decimals) + '%';
        };
    }

})();
