(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ConfirmEmailController', ConfirmEmailController);

    ConfirmEmailController.$inject = ['$stateParams', 'UserService', 'Auth', 'Principal'];

    function ConfirmEmailController ($stateParams, UserService, Auth, Principal) {
        var vm = this;
        vm.key = $stateParams.key;
        
        if(Principal.isAuthenticated()){
        	Auth.logout();
        }
        
        function confirmEmail(key){
        	UserService.confirmEmail({key: key}).then(function () {
                vm.error = null;
                vm.success = 'OK';
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
        
        confirmEmail(vm.key);
    }
})();
