(function () {
    'use strict';

    function DocumentTextController($rootScope, $mdDialog, DocumentService, $translate) {
        var vm = this;
        vm.closeDialog = closeDialog;
        vm.textToUpload = '';
        vm.uploadTextFlag = false;
        vm.sendUploadedText = sendUploadedText;
        vm.documentId = DocumentService.document.id;

        function closeDialog() {
            $mdDialog.hide();
        }

        if( vm.documentId !== null ){
            getText(vm.documentId);
        }

        function getText(id) {
            DocumentService.getDocument(id).then(function (responseData) {
                vm.hideButton = true;
                vm.textToUpload = responseData.data.text;
            });
        }


        function sendUploadedText() {
            DocumentService.uploadText({text: vm.textToUpload}).then(function (responseData) {
                $mdDialog.hide();
                DocumentService.isUploaded = true;
                DocumentService.uploadedData = responseData.data;
                $rootScope.$broadcast('uploadActive');
            }, function (error) {
                PlagiatValidationService.getModalError(error.data);
            });
        }

    }

    angular.module('plagiat2017App')
    .component('documentText', {
        templateUrl: 'app/account/documents/document-text/document-text.template.html',
        bindings: {
            onUploadIsActive: '=',
            onUploadObj: '='
        }
    });

})();
