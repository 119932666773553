(function () {
    'use strict';
    angular.module('plagiat2017App')
        .factory('DOCUMENT_KINDS', function () {
            var documentKinds = {
                PHD: {id: 1, label: 'Praca doktorska'},
                MASTER: {id: 2, label: 'Praca magisterska'},
                BACHELOR: {id: 3, label: 'Praca licencjacka'},
                ENGINEERING: {id: 9, label: 'Praca inżynierska'},
                OTHER: {id: 29, label: 'Inne'}
            };
            documentKinds.jsaDocumentKinds = [documentKinds.BACHELOR, documentKinds.MASTER, documentKinds.ENGINEERING, documentKinds.PHD];
            return documentKinds;
        });

})();
