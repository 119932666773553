angular.module('plagiat2017App')
.component('documentCheckCodeModal', {
    templateUrl: 'app/account/documents/document-code-check/document-check-code.template.html',
    controller: DocumentCheckCodeController,
    controllerAs: 'vm',
    bindings: {
        onDocumentId: "<",
        onFileId: "<",
        onSize: "<",
        onIsAssignment: "<",
        onAddDocumentCallback: "=",
        onCheckDocumentCallback: "="
    }
});

    DocumentCheckCodeController.$inject = ['ModalService', 'AccountService', 'DocumentService', 'CurrencyService', 'CodeService', 'ContractService',
    'DOCUMENT_STATUS', 
    '$mdDialog', '$state', '$translate', '$q', '$rootScope'];


function DocumentCheckCodeController(ModalService, AccountService, DocumentService, CurrencyService, CodeService, ContractService,
                                     DOCUMENT_STATUS, 
                                     $mdDialog, $state, $translate, $q, $rootScope) {

    var vm = this;

    vm.useContract = useContract;
    vm.usePurchasedCode = usePurchasedCode;
    vm.buyNewCode = buyNewCode;
    vm.isUseCodeByTransactionIdAvailable = isUseCodeByTransactionIdAvailable;
    vm.useCodeByTransactionId = useCodeByTransactionId;
    vm.isCheckLaterAvailable = isCheckLaterAvailable;
    vm.isCheckWithCheckIdAvailable = isCheckWithCheckIdAvailable;
    vm.checkLater = checkLater;
    vm.checkWithCheckId = checkWithCheckId;
    vm.cancel = cancel;

    vm.isUseContractDisabled = true;
    vm.isUsePurchasedCodeDisabled = true;
    vm.isBuyCodeDisabled = false;
    vm.currencyForTerminalPayment = null;
    vm.availableCodesInfo = [];
    vm.areTerminalsAvailableInRegion = false;
    vm.isLoading = false;

    vm.alreadyPurchasedCode = '';
    vm.codeToBuy = '';

    this.$onInit = function () {
        vm.isLoading = true;
        vm.isStudent = AccountService.role.isStudent();
        ContractService.getInfo().then(function (contractResponse) {
            checkTheContract(contractResponse.data);
            CodeService.getAvailableCodeInfo().then(function (availableCodesResponse) {
                vm.availableCodesInfo = availableCodesResponse.data.availableCodes;
                checkIfThereIsValidCode(availableCodesResponse.data);
                vm.isLoading = false;
                vm.isBuyCodeDisabled = false;
                findSmallestFittingCode(availableCodesResponse.data);
            });
        });

        CodeService.getCurrencyForTerminalPayment().then(function (currencyForTerminalPaymentResponse) {
            vm.currencyForTerminalPayment = currencyForTerminalPaymentResponse.data;
        });
    };
    
    function checkTheContract(d) {
        // console.log('checkTheContract ' + JSON.stringify(d));
        if (vm.onIsAssignment && AccountService.role.isStudent()) {
            // gdy jest Assignment, to dla studentów zawsze ukryta opcja "Z kontraktu"
            return;
        }
        
        if (d.isContractActive) {
            if (d.contractType === 2 || d.contractType === 3) {
                // dla kontraktow na userow i na autorow zawsze pokazujemy przycisk 'z kontraktu'
                vm.isUseContractDisabled = false;
            } else {
                if (AccountService.role.isStudent()) {
                    if (d.userQueryCounterType === 0 && d.userQueryCounter > 0) {
                        vm.isUseContractDisabled = false;
                    }
                    if (d.userQueryCounterType === 1 && d.userQueryCounter >= vm.onSize) {
                        vm.isUseContractDisabled = false;
                    }
                } else {
                    if (d.contractType === 0 && d.contNumOfPlagReportsLeft > 0) {
                        vm.isUseContractDisabled = false;
                    }
                    if (d.contractType === 1 && d.contNumOfPlagReportsLeft >= vm.onSize) {
                        vm.isUseContractDisabled = false;
                    }
                }
            }
        }
    }

    function checkIfThereIsValidCode(d) {
        angular.forEach(d.availableCodes, function (aCode) {
            if (aCode.size >= vm.onSize) {
                if (!vm.alreadyPurchasedCode) {
                    vm.alreadyPurchasedCode = '(' + aCode.name + ')';
                }
                vm.isUsePurchasedCodeDisabled = false;
            }
        })
    }

    function findSmallestFittingCode(d) {
        var smallest;
        angular.forEach(d.configuredCodes, function (aCode) {
            if (aCode.size >= vm.onSize) {
                if (!smallest || aCode.size < smallest.size) {
                    smallest = aCode;
                }
            }
        });
        if (smallest) {
            vm.codeToBuy = '(' + smallest.name + ')';
        }
    }

    function useContract(){
        if(vm.onAddDocumentCallback){
            vm.onAddDocumentCallback();
        } else {
            vm.onCheckDocumentCallback(vm.onDocumentId);
        }
    }

    function buyNewCode() {

        cancel();
        
        var  documentIdPromise = null;
        if (vm.onDocumentId) {
            documentIdPromise = $q.when(vm.onDocumentId);
        } else {
            documentIdPromise = vm.onAddDocumentCallback({checkLater: true});
        }
        documentIdPromise.then(
            function (documentId) {
                $state.go('code-payments', {documentId: documentId});
            }
        );
        
    }

    function usePurchasedCode() {
        var documentIdPromise = null;
        if (vm.onDocumentId) {
            documentIdPromise = $q.when(vm.onDocumentId);
        } else {
            documentIdPromise = vm.onAddDocumentCallback({checkLater: true});
        }
        documentIdPromise.then(
            function (documentId) {
                CodeService.findCodeForDocumentChecking(documentId, false).then(
                    function (codeForDocumentCheckingResponse) {
                        var codeForDocumentChecking = codeForDocumentCheckingResponse.data;
                        if (codeForDocumentChecking && codeForDocumentChecking.codeName === codeForDocumentChecking.minimalRequiredCodeName) {
                            vm.onCheckDocumentCallback({documentId: documentId, codeId: codeForDocumentChecking.codeId});
                        } else if (codeForDocumentChecking && codeForDocumentChecking.codeName !== codeForDocumentChecking.minimalRequiredCodeName) {
                            $mdDialog.show(
                                $mdDialog.confirm()
                                    .textContent(
                                        $translate.instant('document.code.smaller-codes-exist.buy-proposal',
                                            {
                                                foundCodeName: codeForDocumentChecking.codeName,
                                                cheaperCodeName: codeForDocumentChecking.minimalRequiredCodeName
                                            }))
                                    .ok($translate.instant('document.code.smaller-codes-exist.buy-proposal.agree'))
                                    .cancel($translate.instant("document.code.smaller-codes-exist.buy-proposal.refuse")))
                                .then(
                                    function () {
                                        $state.go('code-payments', {documentId: documentId});
                                    },
                                    function () {
                                        vm.onCheckDocumentCallback({documentId: documentId,
                                            codeId: codeForDocumentChecking.codeId});
                                    }
                                )
                        } else {
                            ModalService.showConfirmModal("document.code.no-fitting-code.buy-proposal",
                                {customCancelButton: true}).then(
                                function () {
                                    $state.go('code-payments', {documentId: documentId});
                                }
                            )
                        }
                    }
                )
            }
        );
    }

    function cancel() {
        return $mdDialog.hide();
    }

    function useCodeByTransactionId() {
        var documentIdPromise = null;
        var documentIdToCheck = null;
        if (vm.onDocumentId) {
            documentIdPromise = $q.when(vm.onDocumentId);
        } else {
            documentIdPromise = vm.onAddDocumentCallback({checkLater: true});
        }
        documentIdPromise.then(function (documentId) {
            documentIdToCheck = documentId;
            return findCodeByTransactionId(documentId);
        }).then(function (codeId) {
                vm.onCheckDocumentCallback({documentId: documentIdToCheck, codeId: codeId})
            }
        );
    }

    function isUseCodeByTransactionIdAvailable() {
        return vm.currencyForTerminalPayment && AccountService.role.isOperator();
    }

    function findCodeByTransactionId(documentId) {
        return $mdDialog.show({
            templateUrl: 'app/account/documents/document-code-check/find-code-by-transaction-id.dialog.html',
            controllerAs: 'vm',
            controller: function ($mdDialog, CodeService, CODE_STATUS) {
                var vm = this;
                vm.transactionId = null;
                vm.shouldShowCodeNotFoundMessage = false;
                vm.shouldShowCodeNotValidMessage = false;
                vm.shouldShowCodeNotAdequateForDocumentMessage = false;
                vm.code = null;
                vm.codeStatuses = CODE_STATUS;
                vm.keyReturn = KeyCode.KEY_RETURN;

                this.findCodeByTransactionId = function () {
                    CodeService.findCodeByTransactionId(vm.transactionId, documentId).then(function (result) {
                        vm.shouldShowCodeNotValidMessage = false;
                        vm.shouldShowCodeNotAdequateForDocumentMessage = false;
                        var code = result.data;
                        if (!code) {
                            vm.code = null;
                            vm.shouldShowCodeNotFoundMessage = true;
                            return;
                        }
                        vm.shouldShowCodeNotFoundMessage = false;
                        code.paymentDate = new Date(code.paymentDate);
                        vm.code = code;
                    });
                };

                this.useCode = function () {
                    if (vm.code === null) {
                        vm.shouldShowCodeNotFoundMessage = true;
                        return;
                    }
                    if (vm.code.status !== vm.codeStatuses.CODE_STATUS_AVAILABLE.id) {
                        vm.shouldShowCodeNotValidMessage = true;
                        return;
                    }
                    if (!vm.code.adequateForDocument) {
                        vm.shouldShowCodeNotAdequateForDocumentMessage = true;
                        return;
                    }
                    $mdDialog.hide(vm.code.id);
                };
                this.cancel = function () {
                    $mdDialog.cancel();
                }
            }
        });
    }

    function isCheckLaterAvailable() {
        return !vm.onDocumentId;
    }

    function isCheckWithCheckIdAvailable() {
        return vm.currencyForTerminalPayment && !vm.onDocumentId && AccountService.role.isOperator();
    }

    function checkWithCheckId() {
        vm.onAddDocumentCallback({generateCheckId: true}).then(function (documentId) {
            return DocumentService.getCheckId(documentId, vm.currencyForTerminalPayment);
        }).then(function (checkIdData) {
            $mdDialog.show({
                templateUrl: 'app/account/documents/document-code-check/document-check-code-check-id.dialog.html',
                controllerAs: 'vm',
                escapeToClose: false,
                locals: {
                    data: checkIdData.data
                },
                controller: function ($mdDialog, data) {
                    this.data = data;
                    this.closeDialog = function () {
                        $rootScope.$broadcast('on-get-documents');
                        $mdDialog.hide();
                    }
                }
            });
        }).catch(function (error) {
            ModalService.showErrorModal(error);
        });
    }

    function checkLater() {
        vm.onAddDocumentCallback({checkLater: true});
    }

}
