(function () {
    'use strict';

    function AddTextController($rootScope, $mdDialog, Principal, DocumentService, $translate, $log, PlagiatValidationService) {
        var vm = this;
        vm.closeDialog = closeDialog;
        vm.textToUpload = '';
        vm.uploadTextFlag = false;
        vm.sendUploadedText = sendUploadedText;
        vm.documentId = DocumentService.document.id;

        Principal.hasAuthority('PERM_USER_PAYMENT').then(function(response){
            vm.authorities = {
                PERM_USER_PAYMENT: response
            }
        });

        function closeDialog() {
            $mdDialog.hide();
        }

        function sendUploadedText() {
            vm.upTextFn = vm.authorities.PERM_USER_PAYMENT ? DocumentService.uploadText({text: vm.textToUpload}) : DocumentService.operator.uploadText({text: vm.textToUpload});
            vm.upTextFn.then(function (responseData) {
                $mdDialog.hide();
                DocumentService.isUploaded = true;
                DocumentService.uploadedData = responseData.data;
                $rootScope.$broadcast('uploadActive');
            }, function (error) {
                PlagiatValidationService.getModalError(error.data);
            });
        }

    }

    angular.module('plagiat2017App')
    .component('addText', {
        templateUrl: 'app/account/documents/document-text/add-text/add-text.template.html',
        controller: AddTextController,
        bindings: {
            onUploadIsActive: '=',
            onUploadObj: '='
        }
    });

})();
