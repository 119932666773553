(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ProtocolAddController', ProtocolAddController);
    
    ProtocolAddController.$inject = ['ProtocolsService', 'ModalService',
        '$localStorage', '$state', '$stateParams', 'ClientService', 'DocumentParameterDefinitionsService'];

    function ProtocolAddController(ProtocolsService, ModalService,
        $localStorage, $state, $stateParams, ClientService, DocumentParameterDefinitionsService) {
        var vm = this;
        vm.pending = false;
        vm.activeTab = 'protocolList';
        vm.availableDocumentParameterDefinitions = [];
        vm.selectedDocumentParameterDefinitionId = null;

        vm.obj = {};
        vm.parameters = [];
        vm.methods = [];
        vm.insertSimpleParam = insertSimpleParam;
        vm.insertConditionalParam = insertConditionalParam;
        vm.insertDocumentParameter = insertDocumentParameter;
        vm.saveOrUpdate = saveOrUpdate;
        vm.clientSearch = clientSearch;
        vm.selectedClientChange = selectedClientChange;

        vm.tinymceOptions = {
            plugins: 'link image code fullpage',
            menubar: false,
            height: 400,
            fullpage_default_doctype: '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">',
            doctype : '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">',
            image_list: [
                {title: 'Client Logo', value: 'content/images/client-logo.png'}
            ],
            image_class_list: [
                {title: 'Client Logo - right', value: 'client-logo-right'},
                {title: 'None', value: ''}
            ],
            content_style: '.left { text-align: left; } ' +
                'img.left { float: left; } ' +
                'table.left { float: left; } ' +
                '.right { text-align: right; } ' +
                'img.right { float: right; } ' +
                'table.right { float: right; } ' +
                '.center { text-align: center; } ' +
                'img.center { display: block; margin: 0 auto; } ' +
                'table.center { display: block; margin: 0 auto; } ' +
                '.full { text-align: justify; } ' +
                'img.full { display: block; margin: 0 auto; } ' +
                'table.full { display: block; margin: 0 auto; } ' +
                '.bold { font-weight: bold; } ' +
                '.italic { font-style: italic; } ' +
                '.underline { text-decoration: underline; } ' +
                '.client-logo-right {float: right;} ' +
                '.tablerow1 { background-color: #D3D3D3; }'
            ,
            toolbar: 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image | removeformat | help | code | fullpage'
        };
        
        this.$onInit = function () {
            vm.pending = true;
            ProtocolsService.getProtocolParameters().then(function (obj) {
                vm.parameters = obj.data.parameters;
                vm.methods = obj.data.methods;
            });
    
            if ($stateParams.id) {
                ProtocolsService.getProtocolForEditing($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                });
            }
            vm.pending = false;
        };
    
        function insertSimpleParam() {
            tinymce.activeEditor.execCommand('mceInsertContent', false, 
                "${" + vm.simpleParam + "}"
            );
        }
    
        function insertConditionalParam() {
            tinymce.activeEditor.execCommand('mceInsertContent', false, 
                "{boxSelectedIf " + vm.conditionalParam + " " + vm.conditionalParamMethod + " " + vm.conditionalParamValue + "}"
            );
        }

        function insertDocumentParameter() {
            tinymce.activeEditor.execCommand('mceInsertContent', false,
                "${document.parameter." + vm.selectedDocumentParameterDefinitionId + "}"
            );
        }
    
        function saveOrUpdate() {
            if (vm.obj && vm.obj.htmlTemplate) {
                vm.obj.htmlTemplate = vm.obj.htmlTemplate.replace('class="client-logo-right"', 'style="float:right;"');
            }
            ProtocolsService
                .saveOrUpdate(vm.obj)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('protocol-list');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }

        function clientSearch(query) {
            return ClientService.searchClients({pageSize: 10, simpleSearch: query}).then(function(response) {
                return response.content;
            })
        }

        function selectedClientChange(client) {
            if (!client) {
                vm.availableDocumentParameterDefinitions = [];
                vm.selectedDocumentParameterDefinitionId = null;
            }
            DocumentParameterDefinitionsService.getAllDocumentParameterDefinitionsForClient(client.id).then(function (response) {
                vm.availableDocumentParameterDefinitions = response;
            })
        }
    
    }
})();
