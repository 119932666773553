(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('CodesController', CodesController);

    CodesController.$inject = [
        'CodeService', 'AccountService', 'SearchRequest', 'ClientSearchRequest',
        'SearchService', '$mdBottomSheet', 'USERROLES', '$localStorage', 'CodeSearchModel', 'ModalService'
    ];

    function CodesController(
        CodeService, AccountService, SearchRequest, ClientSearchRequest,
        SearchService, $mdBottomSheet, USERROLES, $localStorage, CodeSearchModel, ModalService
    ) {
        var vm = this;

        vm.codes;
        vm.selectedRows = [];
        vm.pagingModel = {order: '-id', page: 1, limit: 10};
        vm.requestPayload = {
            pageNumber: null,
            pageSize: null,
            sortTab: [],
            simpleSearch: '',
            search: new CodeSearchModel()
        };

        this.$onInit = function () {
            vm.findAll();
            userRoleIdTranslationMapEngine()
        };

        function userRoleIdTranslationMapEngine() {
            vm.userRoleIdTranslationMap = {};
            var roles = USERROLES.getRolesForCurrentClient(AccountService);
            for (var key in roles) {
                vm.userRoleIdTranslationMap[roles[key].type.id] = roles[key].translationKey;
            }
        }

        vm.pagingAction = function (page) {
            vm.pagingModel.page = page;
            vm.findAll();
        };

        vm.search = function () {
            vm.pagingModel.page = 1;
            vm.findAll();
        };

        vm.findAll = function () {
            vm.pending = true;

            vm.requestPayload.pageNumber = vm.pagingModel.page - 1;
            vm.requestPayload.pageSize = vm.pagingModel.limit;
            vm.requestPayload.sortTab = SearchService.sort(vm.pagingModel);

            CodeService.findAll(vm.requestPayload).then(
                function (response) {
                    vm.pending = false;
                    vm.items = response.data.content;
                    vm.totalItems = response.data.totalElements;
                    vm.queryRequest.page = response.data.number + 1;

                }, function (errorResponse) {
                    vm.pending = false;
                    ModalService.showErrorModal(errorResponse);
                }
            );
        };

    }

})();
