(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('OnlineOrderController', OnlineOrderController);

    OnlineOrderController.$inject = ['$scope', '$location', '$stateParams', '$state', '$translate', '$timeout',
        'AccountService', 'InstanceService', 'DetectInstanceService', 'OnlineOrderService', 'ModalService'];

    function OnlineOrderController($scope, $location, $stateParams, $state, $translate, $timeout,
                                   AccountService, InstanceService, DetectInstanceService, OnlineOrderService, ModalService) {
        var vm = this;

        vm.pending = false;
        vm.success = false;
        vm.uplObj = {};
        vm.uplObj.training1 = false;
        vm.uplObj.training2 = false;
        vm.calculatePrice = calculatePrice;
        vm.instance = InstanceService.instance();
        vm.brand = DetectInstanceService.instance();
        vm.currency = vm.instance.currencies.default;
        vm.uplObj.currency = vm.currency;

        vm.maxCharacters = 40000000;
        vm.aiDetectionPrice = 600;

        vm.pricelist = {
            fixedPart: 200,
            aiDetectionTraining: 1500,
            training1: 1700,
            training2: 4000};
        if ('EUR' === vm.currency) {
            vm.pricelist = {
                fixedPart: 50,
                aiDetectionTraining: 300,
                training1: 350,
                training2: 900};
        }
        
        var rate = 12;
        var unitChars = 'chars';
        var unitPages = 'pages';
        var unitSheets = 'sheets';
        vm.units = [{
            value: unitChars,
            dataTranslate: 'online-order.choose-unit.chars',
        }, {
            value: unitPages,
            dataTranslate: 'online-order.choose-unit.pages',
        }, {
            value: unitSheets,
            dataTranslate: 'online-order.choose-unit.sheets',
        }];

        vm.slider = {
            options: {
                floor:  500000,
                ceil: 40000000,
                // ticksArray: [500000, 2000000, 4000000, 8000000, 12000000, 16000000, 20000000, 30000000, 40000000],
                showTicksValues: false,
                showTicks: true,
                // step: 500000,
                stepsArray: [
                    {value: 500000, legend: '500000'},
                    {value: 1000000},
                    {value: 1500000},
                    {value: 2000000, legend: '2000000'},
                    {value: 2500000},
                    {value: 3000000},
                    {value: 4000000},
                    {value: 5000000, legend: '5000000'},
                    {value: 6000000},
                    {value: 8000000},
                    {value: 10000000, legend: '10000000'},
                    {value: 12000000},
                    {value: 14000000},
                    {value: 16000000, legend: '16000000'},
                    {value: 18000000},
                    {value: 20000000},
                    {value: 25000000, legend: '25000000'},
                    {value: 30000000},
                    {value: 35000000},
                    {value: 40000000, legend: '40000000'}
                ],
                translate: function (value, sliderId, label) {
                    if ('model' == label) {
                        // console.log('val ' + value + ' ' + label);
                        return value / getFactor(vm.uplObj.unit);
                    }
                    return '';
                },
                onChange: function () {
                    vm.calculatePrice();
                }
            }
        };

        vm.$onInit = function () {
            initStateParams();
            if (vm.confirmationCode) {
                vm.success = true;
            } else {
                getInitData();
            }
            vm.watchConfirmationCodeChange();
            vm.refreshSlider();

            if (vm.plagiatPlMode) {
                angular.element('body').css({
                    'background': '#edeff2'
                });
            } else {
                angular.element('body').css({
                    'background-image': 'url(content/images/landing.jpg)',
                    'background-repeat': 'no-repeat',
                    'background-size': 'cover'
                });
            }
        };

        vm.refreshSlider = function () {

            angular.forEach(vm.slider.options.stepsArray, function (step) {
                if (step.legend) {
                    step.legend = '' + step.value / getFactor(vm.uplObj.unit);
                }
            });

            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        function initStateParams() {
            if ($stateParams.charactersQuantity) {
                vm.uplObj.charactersQuantity = +($stateParams.charactersQuantity.replace('M', '') * 1000000);
            }
            if (!vm.uplObj.charactersQuantity) {
                vm.uplObj.charactersQuantity = 5000000;
            }
            vm.uplObj.unit = $stateParams.unit;
            if (!vm.uplObj.unit) {
                vm.uplObj.unit = unitChars;
            }
            vm.confirmationCode = $stateParams.confirmationCode;
            vm.plagiatPlMode = +$stateParams.plagiatPlMode > 0;
            if ($stateParams.training1 === 'true') {
                vm.uplObj.training1 = true;
                vm.calculatePrice();
            } else if ($stateParams.training2 === 'true') {
                vm.uplObj.training2 = true;
                vm.calculatePrice();
            }
        }

        function getInitData() {
            vm.pending = true;
            OnlineOrderService.getInitData(vm.currency).then(function (response) {
                onGetInitDataSuccess(response);
            }, function (response) {
                vm.pending = false;
                ModalService.showErrorModal(response);
            });
        }

        function onGetInitDataSuccess(response) {
            vm.pending = false;
            rate = +response.data.rate;
            vm.calculatePrice();
        }

        function getFactor(unitType) {
            switch (unitType) {
                case unitChars:
                    return 1000000;
                case unitPages:
                    return 2000;
                case unitSheets:
                    return 40000;
                default:
                    return '';
            }
        }
    
        vm.toggleTraining1 = function () {
            if (vm.uplObj.training1 && vm.uplObj.training2) {
                vm.uplObj.training2 = false;
            }
            calculatePrice();
        }
    
        vm.toggleTraining2 = function () {
            if (vm.uplObj.training1 && vm.uplObj.training2) {
                vm.uplObj.training1 = false;
            }
            calculatePrice();
        }
    
        function calculatePrice() {
            if (vm.uplObj.charactersQuantity > vm.maxCharacters) {
                vm.uplObj.charactersQuantity = vm.maxCharacters;
            }
            // wyliczona kwota do zapłaty: liczba znaków razy stawka - 9 pln za 10tyś znaków + 200 pln za fatygę.
            vm.aiDetectionPrice = Math.round(vm.uplObj.charactersQuantity / 10000 * 1.2) + 480;
            vm.uplObj.amountToPay = Math.round(vm.uplObj.charactersQuantity / 10000 * rate) + vm.pricelist.fixedPart;
            vm.uplObj.amountDiscounted = (Math.round(vm.uplObj.amountToPay * (100 - vm.getDiscount())) / 100);
            vm.uplObj.totalPrice =
                vm.uplObj.amountDiscounted
                + (vm.uplObj.training1 ? vm.pricelist.training1 : 0)
                + (vm.uplObj.training2 ? vm.pricelist.training2 : 0)
                + (vm.uplObj.aiDetectionTraining ? vm.pricelist.aiDetectionTraining : 0)
                + (vm.uplObj.aiDetection ? vm.aiDetectionPrice : 0)
            ;
        }

        vm.getDiscount = function () {
            if (vm.uplObj.charactersQuantity < 4000000) {
                return 0;
            } else if (vm.uplObj.charactersQuantity > 40000000) { //nie powinno się wydarzyć
                return 0;
            } else if (vm.uplObj.charactersQuantity > 16000000) {
                return 14 + (1 * vm.uplObj.charactersQuantity / 1000000);
            } else {
                return (2 * vm.uplObj.charactersQuantity / 1000000) - 3;
            }
        };

        vm.submitForm = function (form) {
            if (form.$invalid) {
                for (var i = 0; i < form.$$controls.length; i++) {
                    form.$$controls[i].$touched = true;
                }
                return;
            }
            vm.pending = true;
            
            vm.uplObj.langKey = AccountService.account.pickedLang;
            if (!vm.uplObj.langKey) {
                vm.uplObj.langKey = InstanceService.instance().lang;
            }
            OnlineOrderService.sendObj(vm.uplObj).then(function (response) {
                vm.pending = false;
                vm.success = true;
                vm.confirmationCode = response.data;
            }, function (response) {
                vm.pending = false;
                ModalService.showErrorModal(response);
            });
        };

        vm.watchConfirmationCodeChange = function () {
            $scope.$watch(function () {
                return vm.confirmationCode;
            }, function (newVal) {
                $location.search("confirmationCode", newVal);
            });
        };

        vm.getProformaInvoiceClick = function () {
            vm.pending = true;
            var lang = AccountService.account.pickedLang;
            if (!lang) {
                lang = InstanceService.instance().lang;
            }
            
            OnlineOrderService.getProformaInvoice(vm.confirmationCode, lang, 'Plagiat.pl - Faktura proforma').then(function () {
                vm.pending = false;
            }, function (response) {
                vm.pending = false;
                ModalService.showErrorModal(response);
            });
        };

        vm.redirectFromMainPage = function() {
            var url = $state.href('order', {
                plagiatPlMode: "0",
                charactersQuantity: (vm.uplObj.charactersQuantity/1000000) + 'M',
                unit: vm.uplObj.unit,
                training1: vm.uplObj.training1,
                training2: vm.uplObj.training2,
            });

            window.open(url,'_blank');
        }
    }
})();
