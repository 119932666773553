(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('TagsListController', TagsListController);
    
    TagsListController.$inject = ['TagsService', 'ModalService', 'SearchRequest', 'QueryRequest',
        'SIMPLE_SEARCH_DELAY', '$localStorage'];

    function TagsListController(TagsService, ModalService, SearchRequest, QueryRequest,
                                    SIMPLE_SEARCH_DELAY, $localStorage) {
        var vm = this;
        vm.activeTab = 'tags';
        vm.pending = false;
    
        vm.loadData = loadData;
        vm.loadPage = loadPage;
        vm.transformTagChip = transformTagChip;
        vm.onChangeChip = onChangeChip;
        vm.onRemoveChip = onRemoveChip;
        vm.saveTags = saveTags;

        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.queryRequest = new QueryRequest();
        vm.searchRequest = new SearchRequest();
        vm.tags = [];
        vm.tagsPage = {};
    
        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.tagsLimit ? $localStorage.tagsLimit : vm.queryRequest.limit;
            vm.searchRequest.sortTab = ['id,desc'];
            vm.loadData();
        };

        function saveTags(tagGroup) {
            vm.pending = true;
            TagsService
                .saveOrUpdateTags(tagGroup)
                .then(function (result) {
                    vm.pending = false;
                    ModalService.showSuccessModal("global.messages.success.label");
                    tagGroup.changesMade = false;
                    tagGroup.tags = result.data.tags;
                }, function (errorResponse) {
                    vm.pending = false;
                    ModalService.showErrorModal(errorResponse);
                });
        }

        function transformTagChip(chip, row) {

            if (row.tags) {
                row.tags = row.tags.slice(0); // workaround na bug w md-chips
            }

            const chipObj = {name: chip};
            if (row.tags.indexOf(chipObj) < 0) {
                row.tags.push(chipObj);
                row.changesMade = true;
            }

            return null;
        }

        function onChangeChip(row) {
            console.log('onChangeChip ' + JSON.stringify(row));
            row.changesMade = true;
        }

        function onRemoveChip(chip, row) {
            row.changesMade = true;
        }

        function loadData() {
            if (vm.tagsPage.number) {
                vm.loadPage(vm.tagsPage.number);
            } else {
                vm.loadPage(1);
            }
        }

        function loadPage(pageNumber) {
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            $localStorage.tagsLimit = vm.queryRequest.limit;
            vm.pending = true;
            TagsService.searchTagGroups(vm.searchRequest).then(function (tagsPage) {
                vm.pending = false;
                vm.tagsPage = tagsPage;
                vm.tags = tagsPage.content;
                vm.tagsPage.number++;
                vm.queryRequest.page = vm.tagsPage.number;
            });
        }
    
    }
})();
