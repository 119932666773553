(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter'];

    function DateUtils($filter) {

        var service = {
            convertDateTimeFromServer: convertDateTimeFromServer,
            convertLocalDateFromServer: convertLocalDateFromServer,
            convertLocalDateToServer: convertLocalDateToServer,
            dateformat: dateformat,
            getDateTimeForFileName: getDateTimeForFileName
        };

        return service;

        function convertDateTimeFromServer(date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer(date) {
            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                var utcOffset = moment(date).utcOffset();
                var adjustedDate = new Date(moment(date).add(utcOffset, 'm'));
                return $filter('date')(adjustedDate, dateformat());
            } else {
                return null;
            }
        }

        function dateformat() {
            return 'yyyy-MM-dd';
        }
        
        function getDateTimeForFileName(date) {
            if (date) {
                return $filter('date')(date, 'yyyyMMdd_HHmm');
            } else {
                return null;
            }
        }
    }

})();
