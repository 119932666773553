(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('TokenService', TokenService);

    TokenService.$inject = ['$http', '$q', '$rootScope'];

    function TokenService($http, $q, $rootScope) {


        var service = {
            getInfo: getInfo,
            getTokenPriceList: getTokenPriceList,
            broadcastTokens: broadcastTokens,
            buyTokens: false,
            tokensNedded: false
        };

        return service;

        function getInfo() {
            return $http.get('api/tokens/info').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function getTokenPriceList(obj) {
            return $http.post('api/tokens/pricelist', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function broadcastTokens() {
            $rootScope.$broadcast("availableTokens");
        }

    }



})();
