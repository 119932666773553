(function () {
    'use strict';

    angular.module('DocumentsRequestModel', [])
    .factory('DocumentsRequest', function () {

        function DocumentsRequest() {
            this.documentId = '',
            this.docuTitle = '';
            this.tokensNeeded = '';
            this.docuAuthor = '';
            this.docuSubmitDate = '';
            this.docuSimilarityFactor = '';
            this.docuSimilarityFactorExt = '';
            this.docuStatus = '';
        }

        return DocumentsRequest;
    });
})();
