(function () {
    'use strict';

    function UsersMenuController(AccountService) {
        var vm = this;

        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();
        
	}

    angular.module('plagiat2017App')
    .component('usersMenu', {
        templateUrl: 'app/account/users/users-menu/users-menu.template.html',
        controller: UsersMenuController,
        bindings: {
        	activeTab: '<',
            onIsSuperadmin: '<'
        }
    });

})();
