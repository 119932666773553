(function () {
    'use strict';
    angular
    .module('plagiat2017App')
    .constant('CharCode', {
    	'NULL': '0',
    	'ENTER': '13',
    	'COMMA': '44',
    	'DASH': '45',
        'DOT': '46',
        'SLASH': '47',
        'CHAR_0': '48',
        'CHAR_1': '49',
        'CHAR_2': '50',
        'CHAR_3': '51',
        'CHAR_4': '52',
        'CHAR_5': '53',
        'CHAR_6': '54',
        'CHAR_7': '55',
        'CHAR_8': '56',
        'CHAR_9': '57'
    }
        );
})();
