(function () {
    'use strict';
    function OrganisationalUnitsButtonsController($state, $mdDialog, $mdToast, $translate, OrganisationalUnitsService, PlagiatValidationService) {
        var vm = this;
        vm.arrIdis = [];

        vm.edit = function(organisationalUnitId){
        	$state.go('organisationalUnitsAddEdit', {organisationalUnitId : organisationalUnitId});
        }
        
        vm.remove = function(id, ev) {
            var confirm = $mdDialog.confirm()
            .title($translate.instant("global.confirmremove"))
            .textContent($translate.instant("global.areyousure"))
            .ariaLabel($translate.instant("global.areyousure"))
            .ok($translate.instant("global.button.remove"))
            .cancel($translate.instant("global.button.cancel"))
            .openFrom('#left');
            $mdDialog.show(confirm).then(function () {
            	OrganisationalUnitsService.remove([id]).then(function () {
                    vm.getData();
            		$mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .title('')
                            .textContent($translate.instant("global.table.delete.msg.success"))
                            .ariaLabel('msgSuccess')
                            .ok('Ok')
                            .targetEvent(ev)
                            );
                }, function (response) {
                	PlagiatValidationService.getModalError(response.data);
                });
            });
        }
    }

    angular.module('organisationalUnitsButtons', [])
    .component('organisationalUnitsButtons', {
        templateUrl: 'app/account/settings/organisational-units/organisational-units-buttons/organisational-units-buttons.template.html',
        controller: OrganisationalUnitsButtonsController,
        bindings: {
        	organisationalUnitId: '<',
        	getData: '<',
        	buttonLp: '<'
        }
    });

})();
