(function () {
    'use strict';

    function UsersSearchontroller(
        $scope, $state, $log, $localStorage, $q, _, $timeout,
        DateService, SearchService, SearchRequest, ClientQueryRequest, ClientService, OrganisationalUnitsService, AccountService,
        SIMPLE_SEARCH_DELAY, CLIENTTYPECONSTANT, USERSTATUSES, USERSTATUSES_SUPERADMIN, USERROLES
    ) {
        var vm = this;
        vm.simpleSearch = true;
        vm.getUsers = getUsers;
        vm.addUser = addUser;
        vm.roles = USERROLES.getRolesForCurrentClient(AccountService);
        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.canFilterOrganisationalUnits = !AccountService.role.isOrgUnitAdmin();
        vm.statuses = vm.isSuperAdmin ? USERSTATUSES_SUPERADMIN : USERSTATUSES;
        vm.toogleSearch = toogleSearch;
        vm.clear = clear;
        vm.changeHasntLogged = changeHasntLogged;
        vm.fixDate = DateService.fixDate;
        vm.searchRequest = new SearchRequest();
        vm.clientQueryRequest = new ClientQueryRequest();
        vm.selectedClientChange = selectedClientChange;
        vm.clientSearch = clientSearch;
        vm.jsaIntegration = AccountService.getClientSettings().jsaIntegration;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.clientTypesForSearch = CLIENTTYPECONSTANT.listForClientSearch;
        vm.countries = [];
        vm.organisationalUnits = [];
        vm.regions = [];

        this.$onInit = function () {
            onInit();
        };

        function onInit() {
            if (vm.isSuperAdmin) {
                ClientService.getClientRegions().then(
                    function (success) {
                        vm.regions = success.data;
                    });

                ClientService.getClientCountries().then(
                    function (success) {
                        vm.countries = success.data;
                    });

            }

            if (ClientService.client) {
                vm.selectedClient = ClientService.client;
                ClientService.client = null;
            } else if (vm.onSearchRequest.search.clientId) {
                vm.clientSearch(vm.onSearchRequest.search.clientId).then(function (clients) {
                    if (clients.length === 1) {
                        vm.selectedClient = clients[0];
                    }
                });
            }
            vm.simpleSearch = vm.onSimpleSearch;
            findOrganisationalUnits();
        }

        function changeHasntLogged(){
            if( vm.onSearchRequest.search.hasntLogged ){
                vm.onSearchRequest.search.userLastLoginDateFrom = '';
                vm.onSearchRequest.search.userLastLoginDateTo = '';
            }
            vm.getUsers();
        }
        
        function toogleSearch() {
            vm.onSearchRequest.simpleSearch = '';
            vm.simpleSearch = !vm.simpleSearch;
        }
        
        function clear() {
            vm.selectedClient = undefined;
            vm.onClear();
        }
        
        function addUser(){
            $state.go('userAdd');
        }

        function getUsers() {
            $localStorage.usersListlimit = vm.onQueryRequest.limit;
            vm.onSearchRequest.simpleSearch.length === 0 ? vm.onGetUsers() : '';
            vm.onSearchRequest.simpleSearch.length > 2 ? vm.onGetUsers() : '';
        }
           
        function findOrganisationalUnits() {
            OrganisationalUnitsService.findAllForClient().then(function(response) {
                vm.organisationalUnits = response.data;
            });
        }

        function selectedClientChange(item) {
            if (angular.isDefined(item)) {
                if(vm.onSearchRequest.search.clientId !== item.id) {
                    vm.onSearchRequest.search.clientId = item.id;
                    getUsers();
                }
            } else {
                vm.onSearchRequest.search.clientId = null;
                getUsers();
            }
        }

        function clientSearch(query) {
            vm.searchRequest.pageNumber = 0;
            vm.searchRequest.pageSize = 20;
            vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
            vm.searchRequest.search = query;
            vm.searchRequest.simpleSearch = query;

            return ClientService.searchClients(vm.searchRequest).then(function(response) {
                return response.content;
            })
        }

        vm.querySearchOrganizations = function () {
            return vm.searchOrganizationText ? vm.organisationalUnits.filter(function (org) {
                return org.orguniLabel.toLowerCase().includes(vm.searchOrganizationText.toLowerCase());
            }) : vm.organisationalUnits;
        };

        vm.selectedOrganizationChange = function (item) {
            if (item) {
                vm.onSearchRequest.search.organisationalUnitId = item.id;
            } else {
                vm.onSearchRequest.search.organisationalUnitId = null;
            }
            vm.getUsers();
        };

    }

    angular.module('plagiat2017App')
        .component('usersSearch', {
            templateUrl: 'app/account/users/users-search/users-search.template.html',
            controller: UsersSearchontroller,
            bindings: {
                onGetUsers: '<',
                onQueryRequest: '<',
                onSearchRequest: '<',
                onClear: '<',
                onIsSuperadmin: '<',
                onSimpleSearch: '<'
            }
        });

})();
