(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('UserImportService', UserImportService);

    UserImportService.$inject = ['$http', '$mdToast'];

    function UserImportService($http, $mdToast) {

        var service = {
        		importUsersFromXls: importUsersFromXls,
        		downloadTemplate: downloadTemplate,
        		getAvailableExtensions: getAvailableExtensions
		}
        
        return service;

        function getAvailableExtensions(){
        	return '.xls,.xlsx';
        }
		function importUsersFromXls() {
			var url = 'api/user/import-from-xls';

			$http({
				url : url,
				method : "GET",
				headers : {
					'Content-type' : 'application/json'
				},
				responseType : 'arraybuffer'
			})
					.then(
							function(success) {
								var blob = new Blob(
										[ success.data ],
										{
											type : "application/vnd.ms-excel"
										});
								saveAs(blob, 'validation-result.xlsx');

							}, function(error) {
								$mdToast.show($mdToast.simple().textContent(error));
							});
		}
		
		
		function downloadTemplate(fileName) {
			var url = 'api/user/import-from-xls/example-of-template';

			$http({
				url : url,
				method : "GET",
				headers : {
					'Content-type' : 'application/json'
				},
				responseType : 'arraybuffer'
			})
					.then(
							function(success) {
								var blob = new Blob(
										[ success.data ],
										{
											type : "application/vnd.ms-excel"
										});
								saveAs(blob, fileName + '.xlsx');

							}, function(error) {
								$mdToast.show($mdToast.simple().textContent(error));
							});
		}
		




    }

})();
