(function() {
  'use strict';

  function ContractController($scope, ContractService, AccountService) {
    var vm = this;
    vm.getContractInfo = getContractInfo;
    vm.getContractDate = getContractDate;
    vm.contractInfo = false;
    vm.showContract = false;
    vm.isReseller = AccountService.role.isReseller(); 
    vm.isMinistry = AccountService.role.isMinistry(); 

    
    $scope.$on("refreshContract",function () {
      getContractInfo();
    } );

    function getContractInfo() {
      ContractService.getInfo().then(function(responseData) {
        vm.contract = responseData.data;
        ContractService.inactive = !responseData.data.isContractActive;
        vm.showContract = true;
      });
    }

      function getContractDate() {
          return new Date(vm.contract.contractDateToAsLocalDate);
      }

    (function () {
      getContractInfo();
    })();

  }

  angular.module('plagiat2017App')
  .component('contract', {
    templateUrl: 'app/components/contract/contract.template.html',
    controller: ContractController,
    bindings: {
      onIsAdmin: '<',
      onShowReports: '<'
    }
  });

})();
