(function () {
    'use strict';

    function SideNavController($translate, $scope, $mdSidenav, Principal, AccountService, $log, $localStorage, $window, $state) {
        var vm = this;

        vm.showAuthoritiesMenu = false;
        vm.collapsed = $localStorage.collapsed;
        vm.toggleMenu = toggleMenu;

        this.$onInit = function () {
            vm.show = isNotOrderPage();
            verifyAuthorities();
        };

        $scope.$on('roleIsSet', function () {
            vm.show = isNotOrderPage();
            vm.showAuthoritiesMenu = false;
            verifyAuthorities();
        });

        function isNotOrderPage() {
            return $state.current.name !== 'order';
        }

        function verifyAuthorities() {
            vm.isIndividual = AccountService.role.isIndividual();
            vm.isStudent = AccountService.role.isStudent();
            vm.isOperator = AccountService.role.isOperator();
            vm.isLecturer = AccountService.role.isLecturer();
            vm.isOrgUnitAdmin = AccountService.role.isOrgUnitAdmin();
            vm.isAdmin = AccountService.role.isAdmin();
            vm.isSuperAdmin = AccountService.role.isSuperAdmin();
            vm.isReseller = AccountService.role.isReseller();
            vm.isMinistry = AccountService.role.isMinistry();
            vm.isCodeAccount = AccountService.clientType.isCodeAccount();
            vm.showAssignments = AccountService.getClientSettings() && AccountService.getClientSettings().assignmentsEnabled 
                                 && !AccountService.role.isIndividual() && !AccountService.role.isStudent();
            vm.showFeedbacks = AccountService.getClientSettings() && AccountService.getClientSettings().assignmentsEnabled
                                 && AccountService.getClientSettings() && AccountService.getClientSettings().peerReviewEnabled
                                 && AccountService.role.isStudent();
            vm.showDocuments =  !AccountService.clientType.isPap();
            vm.showReports =  AccountService.clientType.isPap();
            vm.isAuthenticated = Principal.isAuthenticated;
            vm.showAuthoritiesMenu = true;
        }

        function toggleMenu() {
            vm.collapsed = !vm.collapsed;
            $localStorage.collapsed = vm.collapsed;
        }

        angular.element($window).bind('resize', function () {
            if ($window.innerWidth < 960) {
                vm.collapsed = true;
                $localStorage.collapsed = true;
            }
            $scope.$digest();
        });
    }

    angular.module('sideNav', [])
        .component('sideNav', {
            templateUrl: 'app/layouts/side-nav/side-nav.template.html',
            controller: SideNavController,
            controllerAs: 'vm'
        });

})();
