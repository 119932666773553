(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .constant('CLIENTTYPECONSTANT', function () {
            var constant = {
                CLIE_TYPE_NORMAL: {id: 0, key: 'client.type.standard'},
                CLIE_TYPE_PUBLISHER: {id: 1, key: 'client.type.publisher'},
                CLIE_TYPE_PORTAL: {id: 2, key: 'client.type.portal'},
                CLIE_TYPE_KA: {id: 3, key: 'client.type.pka'},
                CLIE_TYPE_SCHOOL_STANDART: {id: 4, key: 'client.type.school_standard'},
                CLIE_TYPE_SCHOOL_ACADEMY: {id: 5, key: 'client.type.school_academy'},
                CLIE_TYPE_SCHOOL_UEK: {id: 6, key: 'client.type.uek'},
                CLIE_TYPE_ACADEMY_IHUW: {id: 7, key: 'client.type.ihuw'},
                CLIE_TYPE_SCHOOL_BEDNARSKA: {id: 8, key: 'client.type.bednarska'},
                CLIE_TYPE_SUBADMIN: {id: 9, key: 'client.type.subadmin'},
                CLIE_TYPE_SECONDARY_SCHOOL: {id: 10, key: 'client.type.secondary_school'},
                CLIE_TYPE_ALLLOCAL: {id: 11, key: 'client.type.all_local'},
                CLIE_TYPE_ASAP: {id: 12, key: 'client.type.asap'},
                CLIE_TYPE_MINSW: {id: 13, key: 'client.type.minsw'},
                CLIE_TYPE_PUBLISHING_HOUSE: {id: 14, key: 'client.type.publishing_house'},
                SCHOOL: {id: 15, key: 'client.type.school'},
                INDIVIDUAL: {id: 16, key: 'client.type.invidual'},
                CODE_ACCOUNT: {id: 17, key: 'client.type.code-account'},
                COLLEGE: {id: 18, key: 'client.type.college'}
            };
            constant.arrayOfValues = [
                constant.CLIE_TYPE_NORMAL, constant.CLIE_TYPE_PUBLISHER, constant.CLIE_TYPE_PORTAL,
                constant.CLIE_TYPE_KA, constant.CLIE_TYPE_SCHOOL_STANDART, constant.CLIE_TYPE_SCHOOL_ACADEMY,
                constant.CLIE_TYPE_SCHOOL_UEK, constant.CLIE_TYPE_ACADEMY_IHUW, constant.CLIE_TYPE_SCHOOL_BEDNARSKA,
                constant.CLIE_TYPE_SECONDARY_SCHOOL, constant.CLIE_TYPE_ASAP,constant.CLIE_TYPE_MINSW,
                constant.CLIE_TYPE_PUBLISHING_HOUSE, constant.SCHOOL, constant.CODE_ACCOUNT,
                constant.COLLEGE
            ];
            constant.listForClientSearch = [
                constant.CLIE_TYPE_NORMAL, constant.CLIE_TYPE_PORTAL,
                constant.CLIE_TYPE_KA, 
                constant.CLIE_TYPE_SCHOOL_UEK, constant.CLIE_TYPE_ASAP,
                constant.CLIE_TYPE_PUBLISHING_HOUSE, constant.SCHOOL, constant.CODE_ACCOUNT,
                constant.COLLEGE
            ];
            constant.listForEditClient = [
                constant.CLIE_TYPE_NORMAL, constant.CLIE_TYPE_ASAP, constant.CODE_ACCOUNT, constant.CLIE_TYPE_PUBLISHING_HOUSE, 
                constant.SCHOOL, constant.COLLEGE
            ];
            constant.listForResellers = [
                constant.CLIE_TYPE_NORMAL, constant.CODE_ACCOUNT, constant.CLIE_TYPE_PUBLISHING_HOUSE, 
                constant.SCHOOL, constant.COLLEGE
            ];
            constant.listForMinistry = [
                constant.CLIE_TYPE_NORMAL, constant.CODE_ACCOUNT, constant.CLIE_TYPE_PUBLISHING_HOUSE, 
                constant.SCHOOL, constant.COLLEGE
            ];
            return constant;
        }())
})();
