(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PortalsController', PortalsController);

    PortalsController.$inject = [
        'PortalsService', 'ModalService', 'PlagiatValidationService', 'DateService', 'PortalOfClientService',
        '$q', '$mdDialog', '$translate', '$scope', '$localStorage', '$mdBottomSheet', '$state',
        'QueryRequest', 'SearchRequest', 'tableSettingsRequest', 'TableSelectOptions', 'SIMPLE_SEARCH_DELAY', 'SUSPECT_TYPES'
    ];

    function PortalsController(
        PortalsService, ModalService, PlagiatValidationService, DateService, PortalOfClientService,
        $q, $mdDialog, $translate, $scope, $localStorage, $mdBottomSheet, $state,
        QueryRequest, SearchRequest, tableSettingsRequest, TableSelectOptions, SIMPLE_SEARCH_DELAY, SUSPECT_TYPES
    ) {
        var vm = this;

        vm.loadData = loadData;
        vm.loadPageOfData = loadPageOfData;
        vm.clear = clear;
        vm.deSelectRow = deSelectRow;
        vm.selectRow = selectRow;
        vm.generateReportXls = generateReportXls;
        vm.downloadZip = downloadZip;
        vm.addToClientPortals = addToClientPortals;
        vm.closeBottomSheet = closeBottomSheet;
        vm.toggleSelection = toggleSelection;
        vm.isBottomSheetOpen = isBottomSheetOpen;
        vm.showSuspectReports = showSuspectReports;

        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.suspectTypes = SUSPECT_TYPES;
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.fixDate = DateService.fixDate;
        vm.pageOfData = {};
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.order = '-counter';
        vm.searchRequest = new SearchRequest();
        vm.onSearchRequest = vm.searchRequest;
        vm.options = new tableSettingsRequest();
        vm.tableSelectOptions = new TableSelectOptions();
        vm.selected = [];

        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.portalsLimit ? $localStorage.portalsLimit : vm.queryRequest.limit;
            if ($localStorage.portalsSearch) {
                vm.searchRequest.search = $localStorage.portalsSearch;
            }
            if (!vm.searchRequest.search.portalDateFrom) {
                var dateToChange = new Date();
                dateToChange.setTime(dateToChange.getTime() - (7 * 24 * 60 * 60 * 1000)); // 7 days
                vm.searchRequest.search.portalDateFrom = dateToChange;
            }
            loadPageOfData(1);
        };

        function showSuspectReports(row) {
            $state.go('suspect-reports-s', {suspectId : row.minId});
        }

        function clear() {
            vm.searchRequest = new SearchRequest();
            loadData();
        }

        function loadPageOfData(pageNumber) {
            vm.pending = true;
            
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = sort();
            $localStorage.portalsLimit = vm.queryRequest.limit;
            $localStorage.portalsSearch = vm.searchRequest.search;
            PortalsService.search(vm.searchRequest).then(function (page) {
                vm.pending = false;
                vm.pageOfData = page;
                vm.totalRows = page.totalElements;
                vm.pageOfData.number++;
            });
        }

        function sort() {
            var reverse = vm.queryRequest.order.slice(0, 1);
            var _length = vm.queryRequest.order.length;
            var predicate;
            if (reverse === '-') {
                predicate = vm.queryRequest.order.slice(1, _length);
                reverse = false;
            } else {
                predicate = vm.queryRequest.order;
                reverse = true;
            }
            var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
            return result;
        }

        function loadData() {
            closeBottomSheet();
            vm.loadPageOfData(1);
        }
        
        function deSelectRow() {
            if( vm.selected.length === 0) {
                closeBottomSheet();
            }
        }
        
        function isBottomSheetOpen() {
            return vm.selected && vm.selected.length > 0;
        }

        function selectRow(pickedId) {
            if (vm.selected.length === 1) {
                openBottomSheet();
            }
            angular.forEach(vm.users, function (value) {
                if (value.id === pickedId) {
                    if (vm.tableSelectOptions.selectedHelper.indexOf(pickedId) === -1) {
                        vm.tableSelectOptions.selectedHelper.push(value);
                    }
                }
            });
        }

        function selectRows(obj) {
            angular.forEach(obj, function (value) {
                if (vm.selected.indexOf(value.portalName) === -1) {
                    vm.selected.push(value.portalName);
                }
            })
        }

        function toggleSelection(total) {
            if (!vm.tableSelectOptions.selectedAll) {
                selectRows(vm.pageOfData.content);
            }
            vm.tableSelectOptions.selectedAll = !vm.tableSelectOptions.selectedAll;
            vm.tableSelectOptions.selectedNo = total;
            if (!vm.tableSelectOptions.selectedAll) {
                closeBottomSheet();
            }
        }

        function closeBottomSheet() {
            vm.selected.length = 0;
            vm.tableSelectOptions = new TableSelectOptions();
            $mdBottomSheet.hide();
        }

        function openBottomSheet() {
            $mdBottomSheet.show({
                templateUrl: 'app/account/pap/infor-suspect-portal/infor-suspect-portal-bottom-sheet.html',
                controllerAs: 'bootomSheet',
                parent: angular.element(document.getElementById('portals')),
                controller: function GridBottomSheetCtrl(){
                    var bootomSheet = this;
                    bootomSheet.checked = true;
                    bootomSheet.closeBottomSheet = vm.closeBottomSheet;
                    bootomSheet.selected = vm.selected;
                    bootomSheet.tableSelectOptions = vm.tableSelectOptions;
                    bootomSheet.total = vm.totalRows;
                    bootomSheet.toggleSelection = vm.toggleSelection;
                    bootomSheet.generateReportXls = vm.generateReportXls;
                    bootomSheet.downloadZip = vm.downloadZip;
                    bootomSheet.addToClientPortals = vm.addToClientPortals;
                },
                isLockedOpen: true,
                disableParentScroll: false,
                disableBackdrop: true
            });
        }

        function addToClientPortals() {
            vm.rowsToAdd = [];
            angular.forEach(vm.selected, function (value) {
                vm.rowsToAdd.push({portal: value});
            });
            
            $mdDialog.show({
                templateUrl: 'app/account/pap/infor-suspect-portal/add-to-client-portals.dialog.html',
                controllerAs: 'vm',
                escapeToClose: true,
                clickOutsideToClose: true,
                locals: {
                    data: vm.rowsToAdd
                },
                controller: function ($mdDialog, data) {
                    this.data = data;
                    this.deleteRow = function(row) {
                        var filteredAry = this.data.filter(function (e) {
                            return e !== row
                        });
                        this.data = filteredAry;
                    }
                    this.closeDialog = function() {
                        $mdDialog.hide();
                    }
                    this.addThem = function() {
                        $mdDialog.hide();
                        vm.isLoaderActive = true;
                        PortalOfClientService.addToClientPortals(this.data).then(
                            function () {
                                closeBottomSheet();
                                ModalService.showSuccessModal();
                                vm.isLoaderActive = false;
                            },
                            function (response) {
                                PlagiatValidationService.getModalError(response.data);
                                vm.isLoaderActive = false;
                            });
                        
                    }
                }
            });
        }

        function generateReportXls() {
            prepareMassActions().then(function(response){
                PortalsService.generateReportXls(response, vm.selected, vm.searchRequest).then(
                    function (okResponse) {
                        closeBottomSheet();
                    }, function (errorResponse) {
                        PlagiatValidationService.getModalError(errorResponse.data);
                    });
            });
        }

        function downloadZip(){
            prepareMassActions().then(function(response){
                PortalsService.downloadZip(response, vm.selected, vm.searchRequest).then(
                    function (okResponse) {
                        closeBottomSheet();
                    }, function (errorResponse) {
                        PlagiatValidationService.getModalError(errorResponse.data);
                    }
                );
            });
        }

        function prepareMassActions() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                vm.tableSelectOptions.ids.push(value.id);
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

    }

})();
