(function () {
    'use strict';

    SettingsMenuController.$inject = ['AccountService'];

    function SettingsMenuController(AccountService) {
        var vm = this;
        vm.isAdmin = AccountService.role.isAdmin();
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.jsa = AccountService.getClientSettings().jsaIntegration;
        vm.tagsEnabled = AccountService.getClientSettings().tagsEnabled;
        vm.orppdIntegration = AccountService.getClientSettings().orppdIntegration;
    }

    angular.module('plagiat2017App')
    .component('settingsMenu', {
        templateUrl: 'app/account/settings/settings-menu/settings-menu.template.html',
        controller: SettingsMenuController,
        bindings: {
        	activeTab: '<'
        }
    });

})();
