(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('AssignmentsController', AssignmentsController);
    
    AssignmentsController.$inject = [
        '$rootScope', '$scope', '$stateParams', '$translate', '$state', 'SIMPLE_SEARCH_DELAY',
        'AssignmentsService', 'AssignmentsRequest', 'QueryRequest', 'AccountService', 'DocumentService', 'ModalService',
        'DocumentSearchRequestModel', 'SearchService', 'PlagiatValidationService'
    ];

    function AssignmentsController(
        $rootScope, $scope, $stateParams, $translate, $state, SIMPLE_SEARCH_DELAY,
        AssignmentsService, AssignmentsRequest, QueryRequest, AccountService, DocumentService, ModalService,
        DocumentSearchRequestModel, SearchService, PlagiatValidationService
    ) {
        var vm = this;
        var assignments = [];
        var pending = true;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.getAssignments = getAssignments;
        vm.getAssignmentsPage = getAssignmentsPage;
        vm.onClickDuplicate = onClickDuplicate;
        vm.closeAssignment = closeAssignment;
        vm.reopenAssignment = reopenAssignment;
        vm.onOpenScoringClick = onOpenScoringClick;
        vm.clear = clear;
        vm.toggleSearch = toggleSearch;
        vm.showSubmissions = showSubmissions;
        vm.submitToAssignment = submitToAssignment;

        vm.searchRequest = new AssignmentsRequest();
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.limit = 10;
        vm.queryRequest.order = '-id';
        vm.simpleSearch = true;
        vm.statuses = [
            {id: 0, translateKey: 'assignment.table.status.in-progress'},
            {id: 1, translateKey: 'assignment.table.status.terminated'},
            {id: 2, translateKey: 'assignment.table.status.ended'}
        ];
        vm.types = [
            {id: 'PEER_REVIEW', translateKey: 'assignment.type.peer_review'},
            {id: 'STANDARD', translateKey: 'assignment.type.standard'}
        ];
        vm.canAddAssignment = AccountService.role.isLecturer() || AccountService.role.isAdmin() || AccountService.role.isOperator();
        vm.canSubmitToAssignment = AccountService.role.isLecturer() || AccountService.role.isAdmin() || AccountService.role.isOperator();
        vm.isAdmin = AccountService.role.isAdmin();

        vm.getAssignments();
        
        function closeAssignment(row) {
            AssignmentsService.changeAssignmentStatus(row.id, 2).then(
                function (response) {
                    vm.pending = false;
                    ModalService.showSuccessModal("global.messages.info.actionsuccess");
                    vm.getAssignments();
                }, function (errorResponse) {
                    vm.pending = false;
                    ModalService.showErrorModal(errorResponse);
                });
        }

        function onClickDuplicate(assignment) {
            $state.go('assignment-duplicate', {id: assignment.id});
        }
        
        function reopenAssignment(row) {
            AssignmentsService.changeAssignmentStatus(row.id, 0).then(
                function (response) {
                    vm.pending = false;
                    ModalService.showSuccessModal("global.messages.info.actionsuccess");
                    vm.getAssignments();
                }, function (errorResponse) {
                    vm.pending = false;
                    ModalService.showErrorModal(errorResponse);
                });
        }
        
        function submitToAssignment(row) {
            $state.go('assignment-submit', {id: row.id});
        }

        function onOpenScoringClick(row) {
            $state.go('peer-review-scoring', {id: row.peerReviewId});
        }
        
        function getAssignments() {
            vm.pending = true;
            vm.searchRequest.pageNumber = vm.queryRequest.page - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            
            return AssignmentsService.getList(vm.searchRequest).then(
                function (response) {
                    vm.pending = false;
                    vm.assignments = response.content;
                    vm.totalAssignments = response.totalElements;
                }, function (error) {
                    vm.pending = false;
                    PlagiatValidationService.getModalError(error.data);
                });
        }
        
        function getAssignmentsPage(page) {
            vm.queryRequest.page = page;
            vm.getAssignments();
        }
        
        function toggleSearch() {
            vm.searchRequest.simpleSearch = '';
            vm.searchRequest.search = {};
            vm.simpleSearch = !vm.simpleSearch;
        }

        function showSubmissions(assignment) {
            DocumentService.searchObject = new DocumentSearchRequestModel();
            DocumentService.searchObject.assignmentId = assignment.id;
            $state.go('documents');
        }
        
        function clear() {
            vm.searchRequest.simpleSearch = '';
            vm.searchRequest.search = {};
            getAssignments();
        }

    }
})();
