(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('SendToOrppdController', SendToOrppdController);

    SendToOrppdController.$inject = [
        '$scope', '$mdDialog', 'AccountService',
        'DocumentService', '$localStorage', '$log', 'OrganisationalUnitsService',
        'DocumentKindService', 'DocumentParameterDefinitionService', '$q', '_', '$timeout',
        '$stateParams', 'OrppdService', 'form', 'PlagiatValidationService', 'ScrollService', '$rootScope', 'ModalService',
        'DateUtils'
    ];

    function SendToOrppdController(
        $scope, $mdDialog, AccountService,
        DocumentService, $localStorage, $log, OrganisationalUnitsService,
        DocumentKindService, DocumentParameterDefinitionService, $q, _, $timeout, $stateParams,
        OrppdService, form, PlagiatValidationService, ScrollService, $rootScope, ModalService, DateUtils
    ) {
        var vm = this;

        vm.form = form;
        if (form.orppdErrorCode) {
            vm.orppdErrorMessage = PlagiatValidationService.getTextError(form.orppdErrorCode);
            if (form.orppdErrorMessage){
                vm.orppdErrorMessage += ': ' + form.orppdErrorMessage;
            }
        }

        vm.formOfStudies = ["stacjonarne", "niestacjonarne"];
        vm.professionalTitles = ["INZ", "LIC", "MGR", "MGRINZ", "INZARCH", "INZARCHKR", "INZPOZ", "LEK", "LEKDEN",
            "LEKWET", "LICPIEL", "LICPOL", "MGRFAR", "MGRINZARCH", "MGRINZARCHKR", "MGRPOZ", "MGRINZPOZ", "MGRPIEL", "MGRPOL", "MGRSZT", "OD"];
        vm.addSupervisorMode = false;
        vm.addReviewerMode = false;
        vm.searchSupervisorText = null;
        vm.availablePromotersAndReviewers = [];

        OrppdService.getAvailablePromotersAndReviewers(vm.form.documentId).then(
            function (response) {
                vm.availablePromotersAndReviewers = response;
            }
         );

        vm.supervisorsAndReviewerSearch = supervisorsAndReviewerSearch;

        function lowercase(string) {
            return angular.isString(string) ? string.toLowerCase() : string;
        }

        function supervisorsAndReviewerSearch(query) {
            var list = vm.availablePromotersAndReviewers;
            return query ? _.filter(list, function (o) {
                return lowercase(o.firstName).indexOf(lowercase(query)) !== -1 || lowercase(o.lastName).indexOf(lowercase(query)) !== -1;
            }) : list;
        }

        vm.onSupervisorChanged = onSupervisorChanged;
        function onSupervisorChanged(item) {
            if (!item) return;
            if (!vm.form.supervisors) {
                vm.form.supervisors = [];
            }
            vm.form.supervisors.push({
                polonEmployeeId: item.polonEmployeeId,
                firstName: item.firstName,
                lastName: item.lastName,
                pesel: item.pesel,
                documentCountry: item.documentCountry,
                documentType: item.documentType,
                documentNumber: item.documentNumber
            });
            vm.addSupervisorMode = false;
            vm.searchSupervisorText = null;
        }

        vm.onReviewerChanged = onReviewerChanged;
        function onReviewerChanged(item) {
            if (!item) return;
            if (!vm.form.reviewers) {
                vm.form.reviewers = [];
            }
            vm.form.reviewers.push({
                polonEmployeeId: item.polonEmployeeId,
                firstName: item.firstName,
                lastName: item.lastName,
                pesel: item.pesel,
                documentCountry: item.documentCountry,
                documentType: item.documentType,
                documentNumber: item.documentNumber
            });
            vm.searchReviewerText = null;
            vm.addReviewerMode = false;
        }

        vm.addAuthor = addAuthor;
        function addAuthor() {
            vm.form.authors.push({
                firstName: "",
                lastName: "",
                defenceDate: null,
                interterIndividualStudiesCourseId: null,
                interfacultyFieldOfStudyCode: null,
                formOfStudy: null,
                professionalTitle: null,
                dateVersionData: null
            });
        }

        vm.removeAuthor = removeAuthor;
        function removeAuthor(index) {
            vm.form.authors.splice(index, 1);
        }

        vm.onClickRemoveSupervisor  = onClickRemoveSupervisor;
        function onClickRemoveSupervisor(index) {
            vm.form.supervisors.splice(index, 1);
        }

        vm.onClickRemoveReviewer  = onClickRemoveReviewer;
        function onClickRemoveReviewer(index) {
            vm.form.reviewers.splice(index, 1);
        }

        vm.closeDialog = closeDialog;
        function closeDialog() {
            $mdDialog.cancel();
            $rootScope.$broadcast('on-get-documents');
        }

        function correctDatesInForm() {
            for (var authorIdx in vm.form.authors) {
                var author = vm.form.authors[authorIdx];
                author.defenceDate = DateUtils.convertLocalDateToServer(author.defenceDate);
                author.dateVersionData = DateUtils.convertLocalDateToServer(author.dateVersionData);
            }
        }

        vm.send = send;
        function send() {
            vm.isSending = true;
            correctDatesInForm();
            OrppdService
                .sendDocument(vm.form)
                .then(
                    function () {
                        vm.isSending = false;
                        $mdDialog.hide();
                        ModalService.showSuccessModal("document.orppd.send.success");
                        $rootScope.$broadcast('on-get-documents');
                    }, function (errorResponse) {
                        vm.isSending = false;
                        vm.orppdErrorMessage = PlagiatValidationService.getTextError(errorResponse.data.code);
                        if (errorResponse.data.data){
                            vm.orppdErrorMessage += ': ' + errorResponse.data.data;
                        }
                        ScrollService.scrollTo('orppdErrorsCard');
                    }
                );
        }

    }

})();
