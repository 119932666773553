(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('SocialRegisterController', SocialRegisterController);

    SocialRegisterController.$inject = ['$filter', '$stateParams'];

    function SocialRegisterController ($filter, $stateParams) {
        var vm = this;
        vm.success = $stateParams.success;
        vm.error = !vm.success;
        vm.provider = $stateParams.provider;
        vm.providerLabel = $filter('capitalize')(vm.provider);
        vm.errorMessageKey = 'global.activate.messages.error';
        if ($stateParams.message) {
            vm.errorMessageKey = 'login.messages.error.' + $stateParams.message;
        }
    }
})();
