(function() {
	'use strict';

	function AnalyticsGa4Controller($cookies, $log, $rootScope, $window, $location, DetectInstanceService, InstanceService) {
		var vm = this;
		vm.activateGtm = activateGtm;
		vm.allowAnalytics = DetectInstanceService.getUAConfig();
		vm.domainAw = InstanceService.instance().domainUa;
		vm.googleTagManagerId = InstanceService.instance().domainGTM;
		vm.theme = $cookies.get('plagiat-theme');

		if (vm.allowAnalytics) {
			// Setting dataLayer & gtag to window because I'm using a custom code text field in a tag management system
			window.dataLayer = window.dataLayer || [];

			vm.activateGtm(window,document,'script','dataLayer', vm.googleTagManagerId);

			window.gtag =
				window.gtag ||
				function() {
					window.dataLayer.push(arguments);
				};
			window.gtag("js", new Date());
			window.gtag("config", vm.domainAw);

			// Set initial gtag/js?id=<first ID> script to <head>
			var script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.src = "//www.googletagmanager.com/gtag/js?id=" + vm.domainAw;
			document.getElementsByTagName("head")[0].appendChild(script);

			$rootScope.$on('$stateChangeSuccess', function (event) {
				$window.gtag('config', vm.domainAw, {'page_path': $location.path()});
				$window.gtag('event', 'page_view', {'page_location': $location.absUrl()});
			});

		}

		function activateGtm(w,d,s,l,i){
			w[l]=w[l]||[];
			w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
			var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
			j.async=true;
			j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
			f.parentNode.insertBefore(j,f);
		}

	}

	angular.module('plagiat2017App')
	.component('analyticsGa4', {
		templateUrl: 'app/components/analytics/analytics.template.html',
		controller: AnalyticsGa4Controller,
		bindings: {
			onSetCall: '='
		}
	});

})();
