(function () {
    'use strict';

    angular.module('DocumentRequestModel', [])
    .factory('DocumentRequest', function () {

        function DocumentRequest() {
            this.fileId = '';
            this.title = '';
            this.author = '';
        
        }

        return DocumentRequest;
    });
})();
