(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .filter('translateExists', translateExists);

    function translateExists($translate) {

        function translateExistsFilter (input) {

            var translation = $translate.instant(input);
            return translation !== input && translation !== "#";
        }
        translateExistsFilter.$stateful = true;
        return translateExistsFilter;
    }
})();