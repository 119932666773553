angular.module('plagiat2017App')
.component('selfEnrollmentModal', {
    templateUrl: 'app/account/documents/self-enrollment/self-enrollment.template.html',
    controller: SelfEnrollmentController,
    controllerAs: 'vm'
});

SelfEnrollmentController.$inject = ['ModalService', 'AssignmentsService',
                                    '$mdDialog', 'items'];

function SelfEnrollmentController(ModalService, AssignmentsService,
                                  $mdDialog, items) {
    var vm = this;

    vm.findAssignment = findAssignment;
    vm.joinAssignment = joinAssignment;
    vm.cancel = cancel;
    
    vm.assignment = null;
    vm.found = true;
    vm.isLoading = false;

    this.$onInit = function () {
        vm.isLoading = true;
        vm.isLoading = false;
    };
    
    function joinAssignment() {
        vm.isLoading = true;
        AssignmentsService
            .joinAssignment({shortcode: vm.shortcode, assignmentId: vm.assignment.id})
            .then(function (resp) {
                vm.isLoading = false;
                vm.cancel();
                items.onJoin();
                ModalService.showSuccessModal("assignments.self-enrollment.success");
            }, function (errorResponse) {
                ModalService.showErrorModal(errorResponse);
                vm.isLoading = false;
            });
    }
    
    function findAssignment() {
        if (!vm.shortcode) {
            return;
        }
        vm.isLoading = true;
        AssignmentsService
            .findAssignment(vm.shortcode)
            .then(function (resp) {
                vm.assignment = resp.data;
                if (vm.assignment) {
                    vm.found = true;
                } else {
                    vm.found = false;
                }
                vm.isLoading = false;
            }, function (errorResponse) {
                ModalService.showErrorModal(errorResponse);
                vm.isLoading = false;
            });
    }
    
    function cancel() {
        return $mdDialog.hide();
    }

}
