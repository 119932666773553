(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('CodeService', CodeService);

    CodeService.$inject = ['$http', '$q'];

    function CodeService($http, $q) {

        var requestUrl = 'api/codes';

        var service = {
            findAll: findAll,
            findAllCodeTypesForCurrentClient: findAllCodeTypesForCurrentClient,
            findCodeByTransactionId: findCodeByTransactionId,
            findIdOfCodeTypeRequiredForDocument: findIdOfCodeTypeRequiredForDocument,
            findCodeForDocumentChecking: findCodeForDocumentChecking,
            getAvailableCodeInfo: getAvailableCodeInfo,
            getCurrencyForTerminalPayment: getCurrencyForTerminalPayment
        };
        return service;

        function findAll(searchRequest) {
            return $http.post(requestUrl, searchRequest).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findAllCodeTypesForCurrentClient() {
            return $http.get(requestUrl + '/code-types').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findCodeByTransactionId(transactionId, documentId) {
            return $http.get(requestUrl + '/transaction-id/' + transactionId + '?documentId=' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findIdOfCodeTypeRequiredForDocument(documentId) {
            return $http.get(requestUrl + '/code-type/required-for-document/' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findCodeForDocumentChecking(documentId, priority) {
            return $http.get(requestUrl + '/for-document-checking/' + documentId +'?priority=' + priority).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAvailableCodeInfo() {
            return $http.get(requestUrl + '/available').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getCurrencyForTerminalPayment() {
            return $http.get(requestUrl + '/currency-for-terminal-payment').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    }
})();
