(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('QueryRequest', function () {

        function QueryRequest() {
            this.order = '-userRegisterDate';
            this.page = 1;
            this.limit = 5;
        }

        return QueryRequest;
    });
})();
