(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ClientDocumentKindsController', ClientDocumentKindsController);
    
    function ClientDocumentKindsController(
        ClientService, ModalService, JhiLanguageService, PlagiatValidationService,
        $q, $stateParams, $state
    ) {
        var vm = this;
        vm.documentKinds = [];
        vm.availableLanguages = [];
        vm.clientId = $stateParams.clientId;
        vm.save = save;

        this.$onInit = function () {
            vm.isLoaderActive = true;
            JhiLanguageService.getAll().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
            });
            ClientService.getClientDocumentKinds(vm.clientId).then(function (result) {
                vm.isLoaderActive = false;
                vm.documentKinds = result.data;
            });
        };
    
        function save() {
            vm.isLoaderActive = true;
    
            var rows = [];
            angular.forEach(vm.documentKinds, function(row) {
                var item = {};
                item.documentKindId = row.documentKindId;
                item.enabled = row.enabled;
                item.label = row.label;
                rows.push(item);
            });
    
            ClientService.updateClientDocumentKinds({'clientId':vm.clientId, 'dtos': rows}).then(function () {
                vm.isLoaderActive = false;
                ModalService.showSuccessModal().then(
                    function () {
                        $state.go("client");
                    }, function () {
                        $state.go("client");
                    });
            }, function (errResponse) {
                PlagiatValidationService.getModalError(errResponse);
            });
        }

    }

})();
