(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .constant('DOCUMENT_STATUS', {
        // Document statuses as they are defined on back-end (pl.plagiat.datatypes.db.Document.java).
        PROCESSING_ERROR: {id: -7},
        WAITING: {id: -6},
        DRAFT: {id: -11},
        WAITING_FOR_PAYMENT: {id: -2},
        JSA_PENDING: {id: -10},
        JSA_ERROR: {id: -9},
        PROCESSING: {id: 0},
        PLAGIARISM_CHECKED: {id: 1},// sprawdzony
        MARKED_FOR_DATABASE: {id: 2},// zaznaczony do zaindeksowania
        ADDED_TO_DATABASE: {id: 3},// dodany do bazy
        CHECK_AGAIN: {id: 4},
        DISALLOWED_TO_ADD_TO_DATABASE: {id: 5}, // odrzucony
        EDITION_REQUIRED: {id: 6},
        REMOVED: {id: 255}
    })
    .constant('DOCUMENT_PAP_STATUSES', [
       {'id': -6, 'status': 'waiting' },
       {'id': 1,  'status': 'paid' } ])
    .constant('DOCUSTATUSES', [
       {'id': -2, 'status': 'awaiting' },
       {'id': 0, 'status': 'processing' },
       {'id': 1, 'status': 'paid' } ])
    .constant('OPDOCUSTATUSES', [
       {'id': 0, 'status': 'processing' },
       {'id': 1, 'status': 'paid' },
       {'id': 2, 'status': 'picked' },
       {'id': 3, 'status': 'added' },
       {'id': 5, 'status': 'rejected' },
       {'id': 6, 'status': 'edition-required' } ])
    .constant('JSA_ORDER_STATUS', {
        NEW: 'New',
        IN_PROGRESS: 'In progress',
        COMPLETE: 'Complete',
        ERROR: 'Error',
        ACCEPTED: 'Accepted'
    }).constant('ORPPD_STATUS', {
        NOT_SENT: {id: 0, key: "document.model.orppd.status.not-sent"},
        SENT: {id: 1, key: "document.model.orppd.status.sent"},
        NOT_TO_SEND: {id: 2, key: "document.model.orppd.status.not-to-send"},
        ERROR: {id: 3, key: "document.model.orppd.status.error"}
    })
})();
