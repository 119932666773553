(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('UsersEditController', UsersEditController);

    UsersEditController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'USERROLES', 'USERSCHOOLTYPE', 'USER_QUERY_COUNTER_TYPES', 'UserService',
    	'InstanceService', '$state', '$cookies', '$stateParams', 'OrganisationalUnitsService', 'ModalService', 'AccountService', 'JsaService'];

    function UsersEditController (Principal, Auth, JhiLanguageService, $translate, USERROLES, USERSCHOOLTYPE, USER_QUERY_COUNTER_TYPES, UserService,
    		InstanceService, $state, $cookies, $stateParams, OrganisationalUnitsService, ModalService, AccountService, JsaService) {
        var vm = this;

        redirectIfFromDirectUrl();

        vm.instance = InstanceService.instance().lang;
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.key = $stateParams.key;
        vm.selected = [];
        vm.saveUser = saveUser;
        vm.isSchool = AccountService.clientType.isSchool();
        vm.loggedUserIsOrgUnitAdmin = AccountService.role.isOrgUnitAdmin();
        vm.backToUserList = backToUserList;
        vm.roles = USERROLES.getRolesForCurrentClient(AccountService);
        vm.userQueryCounterTypes = USER_QUERY_COUNTER_TYPES;
        vm.userQueryCountersKeyPress = userQueryCountersKeyPress;
        vm.userQueryCountersKeyUp = userQueryCountersKeyUp;
        vm.loggedUserIsSuperAdmin = AccountService.role.isSuperAdmin();
        vm.jsaIntegration = AccountService.getClientSettings().jsaIntegration;
        vm.academicTitleVisible = !AccountService.clientType.isPublishingHouse() && !AccountService.clientType.isSchool()  && !AccountService.clientType.isPap();
        vm.testPoolVisible = !AccountService.clientType.isPap();
        vm.USERSCHOOLTYPE = USERSCHOOLTYPE;
        
        findOrganisationalUnits();
        if(vm.jsaIntegration) {
            getPolonUsers();
        }

        function getPolonUsers() {
            JsaService.getPolonEmployeesEditUser(vm.form.id).then(function(response) {
                vm.polonEmployees = response;
            });
        }

		function findOrganisationalUnits() {
			var editedUserId = undefined;
			if(vm.loggedUserIsSuperAdmin && $stateParams.user){
				editedUserId = $stateParams.user.id;
			}
			OrganisationalUnitsService.findAllForClient(undefined, editedUserId).then(function(response) {
				vm.organisationalUnits = response.data;
                vm.selectedOrganization = vm.organisationalUnits.find(function (element) {
                    return element.id === vm.form.organisationalUnitId;
                });
			});
		}

        function backToUserList(){
        	$state.go('users');
        }

        function redirectIfFromDirectUrl(){
        	vm.user = $stateParams.user;
        	vm.canEditMail = $stateParams.canEditMail;
        	if(vm.user) {
            	vm.form = vm.user;
            } else {
            	backToUserList();
            }
        }

        function saveUser(form, ev){
        	UserService.editUser(form, form.id).then(function(){
        		backToUserList();
        		ModalService.showSuccessModal("users.form.edit.user.msg", ev);
        	}, function(errResponse){
        		ModalService.showErrorModal(errResponse);
        	})
        }
        
        function userQueryCountersKeyPress(event){
        	if (event.target.value.length >= event.target.maxLength || !(event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))){
        		event.preventDefault();
        	}
        }
        
        function userQueryCountersKeyUp(event){
        	if (event.target.value.length >= event.target.maxLength){
        		event.preventDefault();
        		event.target.value = event.target.value.slice(0, event.target.maxLength);        		
        		vm.form.userQueryCounter = event.target.value;
        	}
        }

        vm.querySearchOrganizations = function () {
            return vm.searchOrganizationText ? vm.organisationalUnits.filter(function (org) {
                return org.orguniLabel.toLowerCase().includes(vm.searchOrganizationText.toLowerCase());
            }) : vm.organisationalUnits;
        };

        vm.selectedOrganizationChange = function (item) {
            if (item) {
                vm.form.organisationalUnitId = item.id;
            } else {
                vm.form.organisationalUnitId = null;
            }
        };

    }
})();
