(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('AssignmentsAddController', AssignmentsAddController);

    AssignmentsAddController.$inject = ['AssignmentsService', 'ModalService', 'AccountService', 'UserService', 'Utils',
        'PeerReviewFeedbackService', 'PeerReviewLibraryService', '$localStorage', '$state', '$stateParams', '$mdDialog', '$scope'];

    function AssignmentsAddController(AssignmentsService, ModalService, AccountService, UserService, Utils,
                                      PeerReviewFeedbackService, PeerReviewLibraryService, $localStorage, $state, $stateParams, $mdDialog, $scope) {

        var vm = this;
        vm.pending = false;
        vm.editMode = false;
        vm.activeTab = 'assignments';

        vm.obj = {};
        vm.obj.emailAddresses = [];
        vm.obj.peerReview = {};
        vm.obj.peerReview.feedbackUploadedDocCount = 0;
        vm.obj.peerReview.questions = [];
        vm.docuKinds = [];
        vm.emailAddressesInvalid = null;
        vm.emailAddressesNotRegistered = null;
        vm.debounceDelay = 2000;
        vm.clientSettingGrammarCheckEnabled = AccountService.account.clientSettings.grammarCheckEnabled;

        vm.aiDetectionEnabled = AccountService.account.contractAiDetectionEnabled
            && (!AccountService.role.isLecturer() || (AccountService.account.clientSettings.aiDetectionForLecturer && AccountService.account.clientSettings.aiDetectionForLecturer !== 'alwaysDisabled'))
            && (!AccountService.role.isAdmin() || (AccountService.account.clientSettings.aiDetectionForAdmin && AccountService.account.clientSettings.aiDetectionForAdmin !== 'alwaysDisabled'))
        ;
        
        vm.clientSettingPeerReviewEnabled = AccountService.account.clientSettings.peerReviewEnabled;
        vm.obj.peerReviewEnabled = null;

        vm.saveOrUpdate = saveOrUpdate;
        vm.transformEmailAddress = transformEmailAddress;
        vm.generate = generate;
        vm.validateShortcode = validateShortcode;
        vm.addQuestionDialog = addQuestionDialog;
        vm.addQuestion = addQuestion;
        vm.addFromLibraryDialog = addFromLibraryDialog;
        vm.addFromLibrary = addFromLibrary;
        vm.removeQuestion = removeQuestion;
        vm.addToLibrary = addToLibrary;
        vm.moveQuestionUp = moveQuestionUp;
        vm.moveQuestionDown = moveQuestionDown;
        vm.editQuestion = editQuestion;
        vm.onEditQuestion = onEditQuestion;

        vm.selectedLibrary = null;
        vm.loadingLibraries = false;
        vm.libraries = [];

        this.$onInit = function () {
            vm.pending = true;
            AssignmentsService.getAvailableDocuKinds().then(function (ret) {
                vm.docuKinds = ret.data;

                if ($stateParams.id) {
                    vm.editMode = true;
                    if ($state.current.name === 'assignment-duplicate') {
                        AssignmentsService.duplicateAssignment($stateParams.id).then(function (obj) {
                            vm.obj = obj.data;
                            if (vm.obj.peerReview) {
                                vm.obj.peerReview.questions.sort(function (a, b) {
                                    return a.sequence - b.sequence;
                                });
                            }
                            vm.pending = false;
                        });
                    } else {
                        AssignmentsService.getAssignmentForEditing($stateParams.id).then(function (obj) {
                            vm.obj = obj.data;
                            if (vm.obj.startDate) {
                                vm.obj.startDate = new Date(vm.obj.startDate);
                                // vm.obj.startTime = new Date(vm.obj.startDate);
                            }
                            if (vm.obj.endDate) {
                                vm.obj.endDate = new Date(vm.obj.endDate);
                                vm.obj.endTime = new Date(vm.obj.endDate);
                            }
                            if (vm.obj.peerReview) {
                                if (vm.obj.peerReview.studentsCanReviewFrom) {
                                    vm.obj.peerReview.studentsCanReviewFrom = new Date(vm.obj.peerReview.studentsCanReviewFrom);
                                }
                                if (vm.obj.peerReview.studentsCanReviewUntil) {
                                    vm.obj.peerReview.studentsCanReviewUntil = new Date(vm.obj.peerReview.studentsCanReviewUntil);
                                }
                                if (vm.obj.peerReview.feedbackAvailableFrom) {
                                    vm.obj.peerReview.feedbackAvailableFrom = new Date(vm.obj.peerReview.feedbackAvailableFrom);
                                }
                                vm.obj.peerReview.questions.sort(function (a, b) {
                                    return a.sequence - b.sequence;
                                });
                            }
                            vm.pending = false;
                        });
                    }
                } else {
                    vm.obj.startDate = new Date();
                    // vm.obj.startTime = new Date();
                    // vm.obj.startTime.setHours(0, 0, 0, 0);
                    vm.obj.endTime = new Date();
                    vm.obj.endTime.setHours(23, 59, 0, 0);
                    vm.obj.emailAddresses = [];
                    vm.pending = false;
                }
            });
            loadLibraries();
        };

        function setTimeInDates() {
            // if (vm.obj.startDate && vm.obj.startTime) {
            //     vm.obj.startDate.setHours(vm.obj.startTime.getHours(), vm.obj.startTime.getMinutes())
            // }
            if (vm.obj.endDate && vm.obj.endTime) {
                vm.obj.endDate.setHours(vm.obj.endTime.getHours(), vm.obj.endTime.getMinutes())
            }
        }

        function generate() {
            vm.obj.shortcode = getRandomChars(6);
            checkUnique($stateParams.id, vm.obj.shortcode);
        }

        function validateShortcode() {
            checkUnique($stateParams.id, vm.obj.shortcode);
        }

        function checkUnique(id, shortcode) {
            if (!id) {
                id = null;
            }
            AssignmentsService
                .checkUnique(id, shortcode)
                .then(function (resp) {
                    if (!resp.data) {
                        ModalService.showErrorModal({data: {code: 126}});
                    }
                    vm.pending = false;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                    vm.pending = false;
                });
        }

        function getRandomChars(length) {
            var result = '';
            var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }

        function saveOrUpdate(form) {
            if (!Utils.validateForm(form)) {
                return;
            }
            vm.pending = true;
            setTimeInDates();
            AssignmentsService
                .saveOrUpdate(vm.obj)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('assignments');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                    vm.pending = false;
                });
        }

        function transformEmailAddress(chips) {
            if (vm.obj.emailAddresses) {
                vm.obj.emailAddresses = vm.obj.emailAddresses.slice(0); // workaround na bug w md-chips
            }

            vm.emailAddressesInvalidError = false;
            vm.emailAddressesInvalid = null;

            var chipsArray = chips.split(/[\n\s,]+/);
            UserService.getAvailableStudentEmails(chipsArray).then(
                function (result) {
                    const response = result.data;
                    const registeredEmails = [];
                    angular.forEach(response, function (user) {
                        registeredEmails.push(user.userEmail);
                    })
                    angular.forEach(chipsArray, function (chip) {
                        if (validateEmailAddress(chip, registeredEmails)) {
                            if (vm.obj.emailAddresses.indexOf(chip) < 0) {
                                vm.obj.emailAddresses.push(chip);
                                addEmailToPeerReviewFeedbackSettings(response[0].userEmail, response[0].fullName);
                                resetManualReviewers();
                            }
                        }
                    });
                }
            )

            return null;
        }

        function validateEmailAddress(email, registeredEmails) {
            var validationRegexp = /\S+@\S+\.\S+/;
            if (!validationRegexp.test(email)) {
                vm.emailAddressesInvalid = (vm.emailAddressesInvalid ? (vm.emailAddressesInvalid + ', ') : '') + email;
                return null;
            }
            if (registeredEmails.indexOf(email) < 0) {
                vm.emailAddressesNotRegistered = (vm.emailAddressesNotRegistered ? (vm.emailAddressesNotRegistered + ', ') : '') + email;
                return null;
            }
            return true;
        }

        vm.onClickRemoveEmail = function (email) {
            angular.forEach(vm.obj.peerReview.manuallyAssignedReviewers, function (value, i) {
                if (value.email === email) {
                    vm.obj.peerReview.manuallyAssignedReviewers.splice(i, 1);
                }
            })
            resetManualReviewers();
        }

        // PEER REVIEW ////////////////////////////////////////////////////////////

        function addQuestionDialog() {
            $mdDialog.show({
                templateUrl: 'app/account/assignments/peer-review/add-question.template.html',
                controller: AddQuestionController,
                controllerAs: 'vm',
                locals: {
                    items: {
                        onAddQuestion: vm.addQuestion
                    }
                }
            });
        }

        function editQuestion(question, index) {
            $mdDialog.show({
                templateUrl: 'app/account/assignments/peer-review/add-question.template.html',
                controller: AddQuestionController,
                controllerAs: 'vm',
                locals: {
                    items: {
                        index: index,
                        question: question,
                        onEditQuestion: vm.onEditQuestion
                    }
                }
            });
        }

        function addQuestion(question) {
            const questionLength = vm.obj.peerReview.questions.length;
            const sequence = questionLength > 0 ? vm.obj.peerReview.questions[questionLength - 1].sequence + 1 : 0;
            question.sequence = sequence;
            vm.obj.peerReview.questions.push(question);
        }

        function loadLibraries() {
            vm.loadingLibraries = true;
            PeerReviewLibraryService.loadAllWithQuestions().then(function (obj) {
                vm.libraries = obj.data;
                if (vm.libraries.length > 0) {
                    vm.selectedLibrary = vm.libraries[vm.libraries.length - 1];
                }
                vm.loadingLibraries = false;
            }, function (errorResponse) {
                vm.loadingLibraries = false;
                ModalService.showErrorModal(errorResponse);
            });
        }

        function addFromLibrary(questions) {
            vm.obj.peerReview.questions = vm.obj.peerReview.questions.concat(questions);
            vm.obj.peerReview.questions.forEach(function (question, index) {
                question.id = null;
                question.libraryId = null;
                question.sequence = index + 1;
            });
        }

        function addFromLibraryDialog() {
            $mdDialog.show({
                templateUrl: 'app/account/assignments/peer-review/add-from-library.template.html',
                controller: AddFromLibraryController,
                controllerAs: 'vm',
                locals: {
                    items: {
                        library: vm.selectedLibrary,
                        onAddFromLibrary: vm.addFromLibrary
                    }
                }
            });
        }

        function removeQuestion(question, index) {
            if (index > -1 && vm.obj && vm.obj.peerReview.questions) {
                vm.obj.peerReview.questions.splice(index, 1);
            }
        }

        function addToLibrary() {
            $mdDialog.show({
                templateUrl: 'app/account/assignments/peer-review/add-to-library.template.html',
                controller: AddToLibraryController,
                controllerAs: 'vm',
                locals: {
                    items: {
                        library: vm.selectedLibrary,
                        questions: vm.obj.peerReview.questions,
                    }
                }
            }).then(function (selectedQuestions) {
                if (selectedQuestions) {
                    if (!this.selectedLibrary.questions) {
                        this.selectedLibrary.questions = [];
                    }
                    for (var i = selectedQuestions.length - 1; i >= 0; i--) {
                        this.selectedLibrary.questions.push(selectedQuestions[i]);
                    }
                }
            }.bind(this));
        }

        vm.onClickCreateLibrary = function () {
            $mdDialog.show({
                templateUrl: 'app/account/assignments/peer-review/library/create-library.template.html',
                controller: CreateLibraryController,
                controllerAs: 'vm'
            }).then(function (library) {
                if (library) {
                    this.selectedLibrary = library;
                    this.libraries.push(library);
                }
            }.bind(this));
        }

        vm.onClickDeleteLibrary = function () {
            ModalService.showConfirmModal('peer-review.library.delete.question').then(function () {
                vm.loadingLibraries = true;
                PeerReviewLibraryService.delete(vm.selectedLibrary.id).then(function () {
                    vm.selectedLibrary = null;
                    loadLibraries();
                    vm.loadingLibraries = false;
                });
            });
        }

        function moveQuestionUp(question, index) {
            if (index > 0) {
                const sequence1 = vm.obj.peerReview.questions[index].sequence;
                const sequence2 = vm.obj.peerReview.questions[index - 1].sequence;
                // Swap the questions
                vm.obj.peerReview.questions[index] = vm.obj.peerReview.questions[index - 1];
                vm.obj.peerReview.questions[index - 1] = question;

                vm.obj.peerReview.questions[index].sequence = sequence1;
                vm.obj.peerReview.questions[index - 1].sequence = sequence2;
            }
        }

        function moveQuestionDown(question, index) {
            if (index < vm.obj.peerReview.questions.length - 1) {
                const sequence1 = vm.obj.peerReview.questions[index].sequence;
                const sequence2 = vm.obj.peerReview.questions[index + 1].sequence;
                // Swap the questions
                vm.obj.peerReview.questions[index] = vm.obj.peerReview.questions[index + 1];
                vm.obj.peerReview.questions[index + 1] = question;

                vm.obj.peerReview.questions[index].sequence = sequence1;
                vm.obj.peerReview.questions[index + 1].sequence = sequence2;
            }
        }

        function onEditQuestion(index, question) {
            vm.obj.peerReview.questions[index] = question;
        }

        function addEmailToPeerReviewFeedbackSettings(email, fullName) {
            if (!vm.obj.peerReview.manuallyAssignedReviewers) {
                vm.obj.peerReview.manuallyAssignedReviewers = [];
            }
            vm.obj.peerReview.manuallyAssignedReviewers.push({email: email, fullName: fullName, manuallyAssigned: []});
        }

        vm.onClickShowDetailsManualReviewer = function (i) {
            const possibleReviewers = vm.obj.peerReview.manuallyAssignedReviewers.slice(); // .slice() does copy of array
            const docOwnerFullName = vm.obj.peerReview.manuallyAssignedReviewers[i].fullName;
            const docOwnerEmail = vm.obj.peerReview.manuallyAssignedReviewers[i].email;
            possibleReviewers.splice(i, 1);
            const mappedPossibleReviewers = [];
            angular.forEach(possibleReviewers, function (reviewer) {
                mappedPossibleReviewers.push({email: reviewer.email, fullName: reviewer.fullName});
            })
            $mdDialog.show({
                clickOutsideToClose: false,
                templateUrl: 'app/account/assignments/peer-review/manual-assigment/add-reviewer.controller.html',
                controller: AddReviewerManuallyController,
                controllerAs: 'vm',
                locals: {
                    items: {
                        email: docOwnerEmail,
                        fullName: docOwnerFullName,
                        reviewers: mappedPossibleReviewers,
                        manuallyAssigned: vm.obj.peerReview.manuallyAssignedReviewers[i].manuallyAssigned,
                        max: vm.obj.peerReview.pairCount,
                        disabled: vm.obj.peerReview.documentsExist,
                    }
                }
            }).then(function (selectedReviewers) {
                if (selectedReviewers) {
                    vm.obj.peerReview.manuallyAssignedReviewers[i].manuallyAssigned = selectedReviewers;
                }
            }.bind(this));
        }

        vm.onClickRemoveManualReviewer = function (index) {
            ModalService.showConfirmModal('peer-review.reviewer.delete.question').then(function () {
                var email = vm.obj.peerReview.manuallyAssignedReviewers[index].email;
                vm.obj.peerReview.manuallyAssignedReviewers.splice(index, 1);
                resetManualReviewers();
                angular.forEach(vm.obj.emailAddresses, function (email) {
                    if (vm.obj.emailAddresses[i] === email) {
                        vm.obj.emailAddresses.splice(i, 1);
                    }
                })
            });
        }

        function resetManualReviewers() {
            if (vm.obj.peerReview) {
                angular.forEach(vm.obj.peerReview.manuallyAssignedReviewers, function (value) {
                    value.manuallyAssigned = [];
                });
            }
        }
    }
})();
