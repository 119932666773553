(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('CodePaymentsController', CodePaymentsController);

    CodePaymentsController.$inject = [
        '$rootScope',
        '$scope',
        'CodePaymentsService',
        'CodePaymentsRequestModel',
        'PaymentsService',
        'pagingParams',
        'paginationConstants',
        '$stateParams',
        '$mdDialog',
        '$translate',
        '$timeout',
        '$location',
        'JhiLanguageService',
        'InstanceService',
        'DetectInstanceService',
        'CodeService',
        'ContractService'
    ];

    function CodePaymentsController(
        $rootScope,
        $scope,
        CodePaymentsService,
        CodePaymentsRequestModel,
        PaymentsService,
        pagingParams,
        paginationConstants,
        $stateParams,
        $mdDialog,
        $translate,
        $timeout,
        $location,
        JhiLanguageService,
        InstanceService,
        DetectInstanceService,
        CodeService,
        ContractService
    ) {

        var vm = this;

        vm.key = $stateParams.key;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.paymentForm = {priorityCode: $stateParams.priorityCode ? true : null, codes: 1};
        vm.priorityCodeMandatory = $stateParams.priorityCode;
        vm.request = {};
        vm.code = true;
        vm.codeExpiryDate = moment().add(6, 'months').format('YYYY-MM-DD');
        vm.documentId = $stateParams.documentId;
        vm.protocol = $location.protocol();
        vm.host = $location.host();
        vm.port = $location.port();
        vm.domain = vm.protocol + '://' + vm.host;
        vm.instance = DetectInstanceService.instance();
        vm.filterAvailablePayments = filterAvailablePayments;
        vm.paymentMethodChanged = paymentMethodChanged;

        vm.getSingleCodePriceForCurrency = getSingleCodePriceForCurrency;
        vm.getTotalCodePriceForCurrency = getTotalCodePriceForCurrency;
        vm.getCodes = getCodes;
        vm.paymentInit = new CodePaymentsRequestModel();
        vm.buyCode = buyCode;
        vm.setDefaultCurrency = setDefaultCurrency;
        vm.transitionToStep2 = transitionToStep2;

        if (vm.key === 'success') {
            ContractService.broadcast();
            var confirm = $mdDialog.confirm()
                .title('')
                .clickOutsideToClose(true)
                .htmlContent($translate.instant("payments.payment-code-ok"))
                .ariaLabel('')
                .ok('Ok')
                .openFrom('#left');
            $mdDialog.show(confirm).then(function () {
            });

        }

        this.$onInit = function () {
            vm.getCodes().then(function () {
                if (vm.documentId) {
                    CodeService.findIdOfCodeTypeRequiredForDocument(vm.documentId).then(
                        function (requiredCodeTypeIdResponse) {
                            vm.paymentForm.selectedCode = vm.codes.find(function (availableCode) {
                                return availableCode.id === requiredCodeTypeIdResponse.data;
                            });
                            vm.disableChoosingCodeType = vm.paymentForm.selectedCode
                        }
                    );
                }
            });
            vm.phase = 'step-1';
        };

        function transitionToStep2(form) {
            if (form.$invalid || form.$pending) {
                for (var i = 0; i < form.$$controls.length; i++) {
                    form.$$controls[i].$touched = true;
                }
                return;
            }
            if (vm.codeError) {
                return;
            }
            vm.phase = 'step-2'
        }

        $scope.$on("langChanged",function () {
            vm.setDefaultCurrency();
        } );

        function setDefaultCurrency(){
            JhiLanguageService.getCurrent().then(function(response){
                angular.forEach(InstanceService.instance().currencies.langSettings, function(value) {
                    if( response === value.lang ) {
                        vm.paymentForm.payment = vm.availablePayments.find(function(price) {
                            return price.currency === value.currency;
                        });
                        if (!vm.paymentForm.payment && vm.availablePayments) {
                            vm.paymentForm.payment = vm.availablePayments[0];
                        }
                    }
                });
            });
        }

        function getTotalCodePriceForCurrency() {
            vm.codeError = (!(vm.paymentForm.codes > 0 && vm.paymentForm.codes <= 50));

            if (vm.paymentForm.codes) {
                const price = getSingleCodePriceForCurrency();

                return price * vm.paymentForm.codes;
            } else {
                return 0;
            }

        }

        function getSingleCodePriceForCurrency() {
            if( vm.paymentForm.selectedCode && vm.paymentForm.payment) {
                const price = vm.paymentForm.selectedCode.prices.find(function(price) {
                    return price.currency === vm.paymentForm.payment.currency;
                });
                return vm.paymentForm.priorityCode ? price.priorityAmount : price.amount;
            } else {
                return 0;
            }
        }

        function getCodes() {
            return CodePaymentsService.getAvailableCodes().then(
                function (response) {
                    vm.pending = false;
                    vm.availablePayments = response.data.availablePayments;
                    vm.codes = response.data.codes;
                    vm.setDefaultCurrency();
                }, function (errorResponse) {
                    vm.pending = false;
                    ModalService.showErrorModal(errorResponse);
                });
        }

        function buyCode() {
            vm.request.invoiceRequired = vm.paymentForm.invoiceRequired;
            vm.request.userPaymentType = vm.paymentForm.paymentMethod;
            vm.request.currency = vm.paymentForm.payment.currency;
            vm.request.codeId = vm.paymentForm.selectedCode.id;
            vm.request.priorityCode = vm.paymentForm.priorityCode;
            vm.request.productQuantity = vm.paymentForm.codes;
            vm.request.documentId = vm.documentId;
            vm.request.backUrl = vm.domain + (vm.port ? (':' + vm.port) : '') + vm.paymentInit[vm.paymentForm.paymentMethod].backUrl;
            vm.request.browserInfo = getBrowserInfo();

            PaymentsService.pay(vm.request).then(function (responseData) {
                if (responseData.data.paymentRedirectionParams !== null) {
                    paymentRedirect(responseData.data);
                }else {
                    paymentComplete(responseData.data);
                }
            }, function (responseError) {
                if (responseError.status === 400) {
                    $mdDialog.show(
                        $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .textContent(responseError.data.message)
                        .ariaLabel('ok')
                        .ok('OK')
                    );
                }
            });
        }
        
        function getBrowserInfo() {
            const browserInfoModel = {};
            browserInfoModel.colorDepth = window.screen.colorDepth.toString();
            browserInfoModel.screenHeight = window.screen.height.toString();
            browserInfoModel.screenWidth = window.screen.width.toString();
            browserInfoModel.language = window.navigator.language;
            browserInfoModel.javaEnabled = window.navigator.javaEnabled();
            browserInfoModel.timeZone = (new Date()).getTimezoneOffset().toString();
            return browserInfoModel;
        }

        function paymentComplete(response) {
            if (response.userPaymentType === 'TYPE_BLIK') {
                if (response.blikOK === null) {
                    confirm = $mdDialog.confirm()
                        .title('')
                        .textContent($translate.instant("payments.paymentbliknotok"))
                        .ariaLabel('')
                        .ok('Ok')
                        .openFrom('#left');
                    $mdDialog.show(confirm);
                }
            }
        }

        function paymentRedirect(paymentResponse) {
            $mdDialog.show(
                $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(false)
                .title($translate.instant("global.messages.info.redirect"))
                .textContent($translate.instant("global.messages.info.redirect"))
                .ariaLabel($translate.instant("global.messages.info.redirect"))
                .ok($translate.instant("global.button.cancel"))
            );

            if(paymentResponse.userPaymentType==='TYPE_KASSA_24'){
                window.location = paymentResponse.formAction;
                return;
            }

            if(paymentResponse.userPaymentType==='TYPE_KASSA_24_CARD'){
                window.location = paymentResponse.formAction;
                return;
            }

            if (paymentResponse.userPaymentType === 'TYPE_FORTE_KZ') {
                window.location = paymentResponse.formAction;
                return;
            }

            vm.paymentParams = paymentResponse.paymentRedirectionParams;
            var $redirectFormContainer = angular.element('#paymentForm');
            var $redirectForm = $redirectFormContainer.find('form');

            $redirectForm.attr('method', paymentResponse.formMethod);
            $redirectForm.attr('action', paymentResponse.formAction);

            $timeout(function () {
                $redirectForm.submit();
            }, 100);

        }

        function filterAvailablePayments(payment) {
            return vm.paymentInit[payment];
        }

        function paymentMethodChanged(){
            if(!vm.paymentForm.paymentMethod.invoice){
                vm.paymentForm.invoiceRequired = false;
            }
        }
    }
})();
