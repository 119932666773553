(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ClientLogoService', ClientLogoService);

    ClientLogoService.$inject = ['$http', '$q'];

    function ClientLogoService($http, $q) {

        var service = {
            createOrUpdate: createOrUpdate,
            get: get,
            remove: remove
        };

        var requestUrl = '/api/settings/client-logo';
        return service;

        function createOrUpdate(formData) {
            var request = {
                transformRequest: angular.identity,
                transformResponse: angular.identity,
                headers: {
                    'Content-Type': undefined,
                }
            };
            return $http.post(requestUrl, formData, request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function get() {
            return $http.get(requestUrl).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function remove() {
            return $http.delete(requestUrl).then(
                function () {
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
