(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('portal-of-client', {
                parent: 'app',
                url: '/portal-of-client',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.account.portal-of-client'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pap/portal-of-client/portal-of-client.html',
                        controller: 'PortalOfClientController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '0',
                        squash: true
                    },
                    sort: {
                        value: 'userName,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil',
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort)
                            };
                        }],
                    translatePartialLoader: ['$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('reports');
                            return $translate.refresh();
                        }]
                }
            })
            .state('portal-of-client-edit', {
                parent: 'portal-of-client',
                url: '/:id/edit',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.account.portal-of-client'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pap/portal-of-client/portal-of-client-add-edit.html',
                        controller: 'PortalOfClientAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
            .state('portal-of-client-add', {
                parent: 'portal-of-client',
                url: '/add',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.account.portal-of-client'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pap/portal-of-client/portal-of-client-add-edit.html',
                        controller: 'PortalOfClientAddEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }

})();
