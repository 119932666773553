(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ApiKeysAddController', ApiKeysAddController);

    ApiKeysAddController.$inject = ['$state', '$mdDialog', 'ApiKeysService', 'PlagiatValidationService', 'ClientService',
    	'SearchRequest', 'QueryRequest', 'SearchService', 'UserService', 'ClientQueryRequest', '$q', '$timeout', 'SIMPLE_SEARCH_DELAY'];

    function ApiKeysAddController ($state, $mdDialog, ApiKeysService, PlagiatValidationService, ClientService,
    		SearchRequest, QueryRequest, SearchService, UserService, ClientQueryRequest, $q, $timeout, SIMPLE_SEARCH_DELAY) {
        var vm = this;
        
        vm.save = save;
        vm.backToMainList = backToMainList;
        vm.querySearchClients = querySearchClients;
        vm.querySearchUsers = querySearchUsers;
        vm.searchRequest = new SearchRequest();
        vm.queryRequest = new QueryRequest();
        vm.clientQueryRequest = new ClientQueryRequest();
        vm.selectedClientChange = selectedClientChange;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.form = {};
        
        ApiKeysService.getDefaultExpiryDate().then(function(response){
        	vm.form.expiryDate = new Date(response.data);
        });

        function backToMainList(){
        	$state.go('ap-keys');
        }
        
        function save(form, ev){
        	var params = {
        			userId: form.selectedUser.id,
        			clientId: form.selectedClient.id,
        			expiryDate: form.expiryDate
        			}
        	ApiKeysService.add(params).then(function(){
        		backToMainList();

                $mdDialog.show(
                        $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("api.keys.form.new.msg"))
                        .ariaLabel('msgSuccess')
                        .ok('Ok')
                        .targetEvent(ev)
                        );
        	}, function(response){
        		PlagiatValidationService.getModalError(response.data);
        	})
        }
        
        function querySearchClients(query) {
            vm.searchRequest.pageNumber = 0;
            vm.searchRequest.pageSize = 20;
            vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
            vm.searchRequest.search = query;
            vm.searchRequest.simpleSearch = query;
            
            return ClientService.searchClients(vm.searchRequest).then(function(response) {
                return response.content;
            });
        }
        
        function selectedClientChange(item) {
            if(angular.isDefined(item)){
                vm.clientId = item.id;
                vm.form.selectedUser = null;
            } else {
            	vm.clientId = null;
            }
        }

        function querySearchUsers(query) {
            if(query.length > 2){
	        	vm.searchRequest.pageNumber = 0;
	            vm.searchRequest.pageSize = 20;
	            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
	            vm.searchRequest.search = query;
	            vm.searchRequest.simpleSearch = query;
	            vm.searchRequest.clientId =  vm.clientId;
	            
	            return UserService.searchUsersForClient(vm.searchRequest).then(function(responseData) {
	                 return responseData.content;
	            })
            }else {
            	var deferred = $q.defer();
            	deferred.resolve([]);
            	return deferred.promise;
            }
        }

         
    }
})();
