(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ReportsService', ReportsService);
    
    ReportsService.$inject = ['$http', '$q', 'FileSaver', 'PlagiatValidationService', '$mdDialog', '$translate', 'DateUtils'];

    function ReportsService($http, $q, FileSaver, PlagiatValidationService, $mdDialog, $translate, DateUtils) {

        var service = {
            generateReportXls: generateReportXls,
            sendReportXls: sendReportXls,
            getDetails: getDetails,
            search: search
        };
        var requestUrl = 'api/reports';
        return service;

        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function getDetails(id) {
            return $http.get('/api/reports/document/details/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function sendReportXls(response, selected, searchRequest) {
            var confirm = $mdDialog.confirm()
                .clickOutsideToClose(true)
                .escapeToClose(true)
                .textContent($translate.instant("reports.xls.report.generating.info"))
                .ok("OK");
            $mdDialog.show(confirm);

            var url = response.selectedAll ? 'api/suspect-reports/xls/search/xls-send'
                : 'api/suspect-reports/xls/selected/xls-send';
            var data = response.selectedAll
                ? {filter:searchRequest}
                : {ids:{ids:selected}};
            
            return $http.post(url, data).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function generateReportXls(response, selected, searchRequest) {
            var alert = $mdDialog.alert()
                .clickOutsideToClose(false)
                .escapeToClose(false)
                .textContent($translate.instant("global.report.xls.exporting-in-progress"))
                .ok("OK");
            $mdDialog.show(alert);

            var url = response.selectedAll ? 'api/suspect-reports/xls/search/return-xls'
                : 'api/suspect-reports/xls/selected/return-xls';
            var data = response.selectedAll
                ? {filter:searchRequest}
                : {ids:{ids:selected}};

            function jsonBufferToObject (data, headersGetter, status) {
                var type = headersGetter("Content-Type");
                if (!type.startsWith("application/json")) {
                    return data;
                };
                var decoder = new TextDecoder("utf-8");
                var domString = decoder.decode(data);
                var json = JSON.parse(domString);
                return json;
            };
            
            return $http({
                url: url,
                method: "POST",
                data: data,
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/vnd.ms-excel, application/json'
                },
                responseType: 'arraybuffer',
                transformResponse: jsonBufferToObject
            }).then(function (success) {
                $mdDialog.hide();
                var blob = new Blob([success.data], {
                    type: "application/vnd.ms-excel"
                });
                saveAs(blob, 'report_' + DateUtils.getDateTimeForFileName(new Date()) + '.xls');
                return 'OK';
            }, function (errResponse) {
                $mdDialog.hide();
                return $q.reject(errResponse);
            });
        }


    }
})();
