(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .constant('POLON_SYNCHRONIZATION_STATUS', {
            NOT_STARTED: {value: "NOT_STARTED", translationKey: "polon.synchronization.status.not-started"},
            IN_PROGRESS: {value: "IN_PROGRESS", translationKey: "polon.synchronization.status.in-progress"},
            SUCCESS: {value: "SUCCESS", translationKey: "polon.synchronization.status.success"},
            FAILURE: {value: "FAILURE", translationKey: "polon.synchronization.status.failure"}
        })
        .constant('POLON_SYNCHRONIZATION_TYPE', {
            FILE: {value: "FILE", translationKey: "polon.synchronization.type.file"},
            POLON: {value: "POLON", translationKey: "polon.synchronization.status.polon"},
            POLON_UNITS_AND_STUDIES: {value: "POLON_UNITS_AND_STUDIES", translationKey: "polon.synchronization.type.polon-units-and-studies"},
        });
})();
