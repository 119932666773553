(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .filter('numberOfPages', getNumberOfPages);

    getNumberOfPages.$inject = ['AccountService'];

    function getNumberOfPages(AccountService) {
        return getNumberOfPagesFilter;

        function getNumberOfPagesFilter(size) {
            return pagesToString(getRandomPages(size,AccountService.account.numberOfRandomPages,AccountService.account.avgSizeOfLettersPerPage));
        }
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function pagesToString(bucket) {
        var numberOfPagesString = "";
        bucket.sort().forEach(function (pageNumber) {
            numberOfPagesString = numberOfPagesString + pageNumber + ",";
        });
        numberOfPagesString = numberOfPagesString.slice(0, -1);

        return numberOfPagesString + ".";
    }

    function getRandomPages(size, numberOfRandomPages, avgSizeOfLettersPerPage) {
        var numOfpages = Math.ceil(size / avgSizeOfLettersPerPage);
        var bucket = [];

        if (numberOfRandomPages >= numOfpages) {
            for (var i = 1; i <= numOfpages; i++) {
                bucket.push(i);
            }
            return bucket;
        }
        for (var i = 0; i < numberOfRandomPages; i++) {
            var min, max;
            if (numOfpages <= 4 + numberOfRandomPages) {
                min = 1;
                max = numOfpages;
            } else if (numOfpages > 4 + numberOfRandomPages && numOfpages <= 10 + numberOfRandomPages) {
                min = 3;
                max = numOfpages - 2;
            } else if (numOfpages > 10 + numberOfRandomPages) {
                min = 5;
                max = numOfpages - 5;
            }
            var getPage;
            while (getPage = getRandomInt(min, max)) {
                if (!bucket.includes(getPage)) {
                    bucket.push(getPage);
                    break;
                }
            }
        }
        return bucket.sort();
    }

})();
