(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('TranslationsController', TranslationsController);

    TranslationsController.$inject = [
        'JhiLanguageService', 'TranslationsService', 'tableSettingsRequest', 'TableSelectOptions', '$localStorage', 'ModalService',
        '$mdBottomSheet', '$q', '$mdDialog', '$translate', 'PlagiatValidationService', 'QueryRequest', 'SearchRequest', 'TRANSLATION_TYPES', '$scope'
    ];

    function TranslationsController(
        JhiLanguageService, TranslationsService, tableSettingsRequest, TableSelectOptions, $localStorage, ModalService,
        $mdBottomSheet, $q, $mdDialog, $translate, PlagiatValidationService, QueryRequest, SearchRequest, TRANSLATION_TYPES, $scope
    ) {
        var vm = this;

        vm.loadData = loadData;
        vm.loadPageOfTranslations = loadPageOfTranslations;
        vm.onLanguageSelected = onLanguageSelected;
        vm.shouldShowColumnForLanguage = shouldShowColumnForLanguage;
        vm.onMouseEnter = onMouseEnter;
        vm.isMouseOver = isMouseOver;
        vm.editCell = editCell;
        vm.isCellEdited = isCellEdited;
        vm.cancelEdition = cancelEdition;
        vm.saveTranslation = saveTranslation;
        vm.deSelectRow = deSelectRow;
        vm.selectRow = selectRow;
        vm.toggleSelection = toggleSelection;
        vm.selectRows = selectRows;
        vm.openBottomSheet = openBottomSheet;
        vm.closeBottomSheet = closeBottomSheet;
        vm.removeTranslations = removeTranslations;
        vm.generateReportXls = generateReportXls;
        vm.toggleSearch = toggleSearch;
        vm.clear = clear;

        vm.translationTypes = TRANSLATION_TYPES;
        vm.pageOfTranslations = {};
        vm.selected = [];
        vm.tableSelectOptions = new TableSelectOptions();
        vm.tableSettings = new tableSettingsRequest();
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.queryRequest = new QueryRequest();
        vm.searchRequest = new SearchRequest();
        vm.cellWithMouseOver = null;
        vm.editedCell = null;
        vm.editedValue = null;

        vm.codeMouseEnterMap = {};
        vm.simpleSearch = true;
        vm.simpleSearchText;

        this.$onInit = function () {
            JhiLanguageService.getAllWithAdditional().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
            });
            vm.queryRequest.limit = $localStorage.translationsLimit ? $localStorage.translationsLimit : vm.queryRequest.limit;
            vm.searchRequest.search.type = 0;
            loadPageOfTranslations(1);
            vm.languageSelection = $localStorage.translationsPageLanguageSelection ? $localStorage.translationsPageLanguageSelection : {};
        };

        $scope.$on('on-close-modal-translation-edit', function () {
            vm.loadData();
        });

        vm.onSimpleSearchTextChange = function () {
            vm.loadPageOfTranslations(1);
        };

        function toggleSearch() {
            vm.simpleSearchText = '';
            vm.simpleSearch = !vm.simpleSearch;
            $localStorage.translationSimpleSearchEnabled = vm.simpleSearch;
        }

        function clear() {
            vm.searchRequest = new SearchRequest();
            loadData();
        }

        function shouldShowColumnForLanguage(languageCode) {
            return vm.languageSelection[languageCode];
        }

        function onLanguageSelected() {
            $localStorage.translationsPageLanguageSelection = vm.languageSelection;
        }

        function loadPageOfTranslations(pageNumber) {
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.simpleSearch = vm.simpleSearchText;
            $localStorage.translationsLimit = vm.queryRequest.limit;
            vm.pending = true;
            TranslationsService.search(vm.searchRequest).then(function (translationsPage) {
                vm.pending = false;
                vm.pageOfTranslations = translationsPage;
                vm.totalTranslations = translationsPage.totalElements;
                vm.translations = translationsPage.content;
                vm.pageOfTranslations.number++;
            });
        }

        function loadData() {
            if( vm.selected.length > 0) {
                vm.tableSelectOptions = new TableSelectOptions();
                closeBottomSheet();
            }

            if (vm.pageOfTranslations.number) {
                vm.loadPageOfTranslations(vm.pageOfTranslations.number);
            } else {
                vm.loadPageOfTranslations(1);
            }
        }

        function onMouseEnter(translationRow, lang) {
            vm.cellWithMouseOver = new TranslationCellIndex(translationRow.code, lang);
        }

        function isMouseOver(translationRow, lang) {
            return vm.cellWithMouseOver && vm.cellWithMouseOver.code === translationRow.code && vm.cellWithMouseOver.lang === lang;
        }

        function isCellEdited(translationRow, lang) {
            return vm.editedCell && vm.editedCell.code === translationRow.code && vm.editedCell.lang === lang;
        }

        function editCell(translationRow, lang) {
            vm.editedCell = new TranslationCellIndex(translationRow.code, lang);
            vm.editedValue = translationRow[lang];
        }

        function cancelEdition() {
            vm.editedCell = null;
        }

        function saveTranslation(translationRow, lang) {
            TranslationsService.save(translationRow.code, lang, vm.editedValue).then(function () {
                vm.editedCell = null;
                translationRow[lang] = vm.editedValue;
            }, function (errResponse) {
                PlagiatValidationService.getModalError(errResponse);
            });
        }

        function TranslationCellIndex(code, lang) {
            return {
                code: code,
                lang: lang
            };
        }

        function toggleSelection(total) {
            if(!vm.tableSelectOptions.selectedAll) {
                selectRows(vm.translations);
            }
            vm.tableSelectOptions.selectedAll = !vm.tableSelectOptions.selectedAll;
            vm.tableSelectOptions.selectedNo = total;
            if( !vm.tableSelectOptions.selectedAll ) {
                closeBottomSheet();
            }
        }

        function deSelectRow() {
            if( vm.selected.length === 0) {
                closeBottomSheet();
            }
        }

        function selectRow(pickedId) {
            if( vm.selected.length === 1) {
                openBottomSheet();
            }
            angular.forEach(vm.translations, function (value) {
                if (value.id === pickedId) {
                    if (vm.tableSelectOptions.selectedHelper.indexOf(pickedId) === -1) {
                        vm.tableSelectOptions.selectedHelper.push(value);
                    }
                }
            });
        }

        function selectRows(obj) {
            angular.forEach(obj, function (value) {
                if (vm.selected.indexOf(value.id) === -1) {
                    vm.selected.push(value.id);
                }
            })
        }

        function closeBottomSheet() {
            vm.selected.length = 0;
            vm.tableSelectOptions = new TableSelectOptions();
            $mdBottomSheet.hide();
        }

        function openBottomSheet() {
            $mdBottomSheet.show({
                templateUrl: 'app/account/translations/translations-bottom-sheet.html',
                controllerAs: 'bottomSheet',
                parent: angular.element(document.getElementById('translations')),
                controller: function GridBottomSheetCtrl(){
                    var bottomSheet = this;
                    bottomSheet.checked = true;
                    bottomSheet.closeBottomSheet = vm.closeBottomSheet;
                    bottomSheet.selected = vm.selected;
                    bottomSheet.tableSelectOptions = vm.tableSelectOptions;
                    bottomSheet.total = vm.totalTranslations;
                    bottomSheet.toggleSelection = vm.toggleSelection;
                    bottomSheet.remove = vm.removeTranslations;
                    bottomSheet.generateReportXls = vm.generateReportXls;
                },
                isLockedOpen: true,
                disableParentScroll: false,
                disableBackdrop: true
            });
        }

        function prepareMassActions() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                vm.tableSelectOptions.ids.push(value.id);
            });

            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

        function removeTranslations(){
            prepareMassActions().then(function(){
                var TRANSLATION_REMOVE_PREFIX = 'translations.remove';
                ModalService.showConfirmModal(TRANSLATION_REMOVE_PREFIX).then( function (){
                    vm.isLoaderActive = true;
                    TranslationsService.removeTranslations(vm.selected).then(function () {
                        vm.isLoaderActive = false;
                        closeBottomSheet();
                        loadData();
                        ModalService.showSuccessModal();
                    }).catch(function (response) {
                        PlagiatValidationService.getModalError(response.data);
                    });
                });
            });
        }

        function generateReportXls(){
            prepareMassActions().then(function(response){
                TranslationsService.generateReportXls(response, vm.selected, vm.searchRequest, vm.languageSelection);
            });
        }
    }

})();
