(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('PlagiatThemeService', PlagiatThemeService);

    PlagiatThemeService.$inject = ['$q', '$translate', 'THEMES'];

    function PlagiatThemeService($q, $translate, THEMES) {
        var service = {
            getAll: getAll
        };

        return service;

        function getAll() {
            var deferred = $q.defer();
            deferred.resolve(THEMES);
            return deferred.promise;
        }
    
        // function getCurrent() {
        //     var deferred = $q.defer();
        //     var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

        //     deferred.resolve(language);

        //     return deferred.promise;
        // }
    
    }
})();
