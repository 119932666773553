(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('DocumentImportController', DocumentImportController);

    DocumentImportController.$inject = ['$q', 'DocumentImportService', 'DocumentKindService', 'OrganisationalUnitsService',
        'AccountService', 'DocumentService', 'ModalService', '$translate', 'PlagiatValidationService', 'LandingService'];

    function DocumentImportController($q, DocumentImportService, DocumentKindService, OrganisationalUnitsService,
                                      AccountService, DocumentService, ModalService, $translate, PlagiatValidationService, LandingService) {
        var vm = this;
        vm.downloadMetadataTemplate = DocumentImportService.downloadMetadataTemplate;
        vm.oldPhdYearColumn = AccountService.account.clientSettings.oldPhdCheckingYearField;
        vm.shouldShowImportedDocumentsList = shouldShowImportedDocumentsList;
        vm.uploadMetadataFiles = uploadMetadataFiles;
        vm.uploadDocumentFiles = uploadDocumentFiles;
        vm.deleteFromImport = deleteFromImport;
        vm.addDocuments = addDocuments;
        vm.getImportedDocumentStatusTooltip = getImportedDocumentStatusTooltip;
        vm.getImportedDocumentState = getImportedDocumentState;
        vm.canAddDocuments = canAddDocuments;

        vm.availableMetadataFileExtensions = '.xls,.xlsx';
        vm.availableDocumentFileExtensions = null;
        vm.documentImportState = {importId: null, importedDocuments: []};
        vm.addWithoutChecking = false;
        vm.documentKindById = {};
        vm.organisationalUnitById = {};
        vm.importedDocumentState = {
            VALIDATION_ERRORS: 'validation-errors',
            READY: 'ready',
            ADDING_ERROR: 'document-adding-error',
            ADDED: 'added'
        };

        this.$onInit = function () {
            DocumentKindService.findAll().then(function (response) {
                    var documentKindList = response.data;
                    for (var documentKindIdx in documentKindList) {
                        var documentKind = documentKindList[documentKindIdx];
                        vm.documentKindById[documentKind.id] = documentKind;
                    }
                }
            );
            OrganisationalUnitsService.getSimpleForCurrentClient().then(function (organisationalUnitList) {
                for (var organisationalUnitIdx in organisationalUnitList) {
                    var organisationalUnit = organisationalUnitList[organisationalUnitIdx];
                    vm.organisationalUnitById[organisationalUnit.id] = organisationalUnit;
                }
            });
            DocumentService.getDocumentAvailableExtension().then(function (result) {
                vm.availableDocumentFileExtensions = '.zip';
                if (result.data.length === 0) {
                    LandingService.getAvailableFileExtensions().then(function (extensions) {
                        vm.availableDocumentFileExtensions += "," + extensions.data;
                    });
                } else {
                    angular.forEach(result.data, function (value) {
                        vm.availableDocumentFileExtensions += ',.' + value;
                    });
                }
            });
        };

        function uploadMetadataFiles(files) {
            if (files.length === 0) {
                return;
            }
            vm.isLoading = true;
            DocumentImportService.uploadMetadataFiles(files, vm.documentImportState.importId)
                .then(function (documentImportState) {
                        vm.documentImportState = documentImportState;
                        vm.isLoading = false;
                    },
                    function (error) {
                        vm.isLoading = false;
                        ModalService.showErrorModal(error);
                    })
        }

        function uploadDocumentFiles(files) {
            if (files.length === 0) {
                return;
            }
            vm.isLoading = true;
            ModalService.showDialogModal('document-import.files.upload.be-patient');
            DocumentImportService.uploadDocumentFiles(files, vm.documentImportState.importId)
                .then(function (documentImportState) {
                        vm.documentImportState = documentImportState;
                        vm.isLoading = false;
                        ModalService.showSuccessModal('document-import.files.upload.complete')
                    },
                    function (error) {
                        vm.isLoading = false;
                        ModalService.showErrorModal(error);
                    });

        }

        function deleteFromImport(filename) {
            ModalService.showConfirmModal('document-import.files.delete.confirm').then(
                function () {
                    vm.isLoading = true;
                    DocumentImportService.deleteFromImport(vm.documentImportState.importId, filename)
                        .then(function (documentImportState) {
                            vm.documentImportState = documentImportState;
                            vm.isLoading = false;
                        });
                },
                function (error) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(error);
                }
            );
        }

        function addDocuments() {
            if (!vm.canAddDocuments()) {
                ModalService.showDialogModal('document-import.add-documents.no-documents-ready-to-be-added');
                return;
            }
            ModalService.showConfirmModal('document-import.add-documents.confirm').then(
                function () {
                    vm.isLoading = true;
                    DocumentImportService.addDocuments(vm.documentImportState.importId, vm.addWithoutChecking)
                        .then(function (documentImportState) {
                            vm.documentImportState = documentImportState;
                            vm.isLoading = false;
                            ModalService.showDialogModal('document-import.add-documents.finished')
                        });
                },
                function (error) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(error);
                }
            );
        }

        function shouldShowImportedDocumentsList() {
            return vm.documentImportState.importedDocuments.length !== 0;
        }

        function getImportedDocumentStatusTooltip(importedDocument) {
            if (getImportedDocumentState(importedDocument) === vm.importedDocumentState.VALIDATION_ERRORS) {
                var result = '';
                for (var validationErrorCodeIndex in importedDocument.validationErrorCodes) {
                    var errorCode = importedDocument.validationErrorCodes[validationErrorCodeIndex];
                    result += $translate.instant(PlagiatValidationService.getTranslationKeyError(errorCode)) + '<br/>';
                }
                return result;
            } else if (getImportedDocumentState(importedDocument) === vm.importedDocumentState.READY) {
                return $translate.instant('document-import.imported-document.ready-to-be-added');
            } else if (getImportedDocumentState(importedDocument) === vm.importedDocumentState.ADDING_ERROR) {
                return $translate.instant(PlagiatValidationService.getTranslationKeyError(importedDocument.addingErrorCode));
            } else {
                return $translate.instant('document-import.imported-document.added');
            }
        }

        function getImportedDocumentState(importedDocument) {
            if (importedDocument.validationErrorCodes.length > 0) {
                return vm.importedDocumentState.VALIDATION_ERRORS;
            } else if (importedDocument.addingErrorCode) {
                return vm.importedDocumentState.ADDING_ERROR;
            } else if (importedDocument.added) {
                return vm.importedDocumentState.ADDED;
            } else
                return vm.importedDocumentState.READY;
        }

        function canAddDocuments() {
            return vm.documentImportState.importedDocuments.findIndex(function (importedDocument) {
                return importedDocument.validationErrorCodes.length > 0 || importedDocument.added;
            }) === -1;
        }
    }

})();
