(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('FullStatsService', FullStatsService);
    
    FullStatsService.$inject = ['$http', '$q', '$mdDialog', '$translate', 'DateUtils'];

    function FullStatsService($http, $q, $mdDialog, $translate, DateUtils) {

        var service = {
            getAvailable: getAvailable,
            executeFullStats: executeFullStats,
            generateFullStatsXls: generateFullStatsXls,
        };
        var requestUrl = 'api/full-stats';
        return service;

        function getAvailable() {
            return $http.get(requestUrl + '/available').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function executeFullStats(obj) {
            return $http.post(requestUrl + '/execute-full-stats', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
        
        function generateFullStatsXls(obj) {
            var alert = $mdDialog.alert()
                .clickOutsideToClose(false)
                .escapeToClose(false)
                .textContent($translate.instant("global.report.xls.exporting-in-progress"))
                .ok("OK");
            $mdDialog.show(alert);
    
            $http({
                url: requestUrl + '/generate-xls-report',
                method: "POST",
                data: obj,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).then(function (success) {
                $mdDialog.hide();
                var blob = new Blob([success.data], {
                    type: "application/vnd.ms-excel"
                });
                saveAs(blob, 'statistics_' + DateUtils.getDateTimeForFileName(new Date()) + '.xls');
            }, function (error) {
                $mdDialog.hide();
                PlagiatValidationService.getModalError(error);
            });
        }

    }
})();
