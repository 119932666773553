(function() {
	'use strict';

	function ClientDetailsController(
	    UserService, ClientService, UserSearchRequest, $state, USERSCHOOLTYPE
    ) {
		var vm = this;

		vm.showAdmins = showAdmins;

        /**
         * Fill user search object and redirect to user page
         */
		function showAdmins(){
            //for user-search client autocomplete preselection
			ClientService.client = vm.client;

			UserService.searchObject = new UserSearchRequest();
            UserService.searchObject.clientId = vm.client.id;
            UserService.searchObject.role = USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.id;
			$state.go('users');
		}
	}

	angular
        .module('plagiat2017App')
        .component(
            'clientDetails',
            {
                templateUrl : 'app/client/client-detail/client-detail.template.html',
                controller : ClientDetailsController,
                bindings: {
                    client: '<'
                }
            }
        );

})();
