(function () {
    'use strict';

angular
    .module('plagiat2017App')
    .config(html5ModeConfig)
    .config(stateConfig)
    .config(mdDateLocaleProvider)
    .config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }])
    .constant('_', window._);

    html5ModeConfig.$inject = ['$locationProvider'];
    stateConfig.$inject = ['$stateProvider'];
    beforeUnload.$inject = ['$rootScope', '$window'];

    function beforeUnload($rootScope, $window) {
        $window.onbeforeunload = function (e) {
            var confirmation = {};
            var event = $rootScope.$broadcast('onBeforeUnload', confirmation);
            if (event.defaultPrevented) {
                return confirmation.message;
            }
        };

        $window.onunload = function () {
            $rootScope.$broadcast('onUnload');
        };
        return {};
    }


    function html5ModeConfig($locationProvider) {
        $locationProvider.hashPrefix('');
        $locationProvider.html5Mode({enabled: false, requireBase: false});
    }


    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
    }


    function mdDateLocaleProvider($mdDateLocaleProvider){
        $mdDateLocaleProvider.formatDate = function(date) {
            return date ? moment(date).format('YYYY-MM-DD') : '';
        };
        $mdDateLocaleProvider.parseDate = function(dateString) {
          var m = moment(dateString, 'YYYY-MM-DD', true);
          return m.isValid() ? m.toDate() : new Date(NaN);
        };
    } 

})();
