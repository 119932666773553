(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PortalOfClientController', PortalOfClientController);

    PortalOfClientController.$inject = [
        'PortalOfClientService', 'ModalService', 'PlagiatValidationService',
        '$q', '$mdDialog', '$translate', '$scope', '$localStorage',
        'QueryRequest', 'SearchRequest', 'PORTAL_OF_CLIENT_TYPES', 'SIMPLE_SEARCH_DELAY',
    ];

    function PortalOfClientController(
        PortalOfClientService, ModalService, PlagiatValidationService,
        $q, $mdDialog, $translate, $scope, $localStorage,
        QueryRequest, SearchRequest, PORTAL_OF_CLIENT_TYPES, SIMPLE_SEARCH_DELAY
    ) {
        var vm = this;

        vm.loadData = loadData;
        vm.loadPageOfData = loadPageOfData;
        vm.remove = remove;
        vm.clear = clear;

        vm.portalOfClientTypes = PORTAL_OF_CLIENT_TYPES;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.pageOfData = {};
        vm.queryRequest = new QueryRequest();
        vm.searchRequest = new SearchRequest();

        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.portalOfClientLimit ? $localStorage.portalOfClientLimit : vm.queryRequest.limit;
            if ($localStorage.portalOfClientSearch) {
                vm.searchRequest.search = $localStorage.portalOfClientSearch;
            }
            loadPageOfData(1);
        };

        function clear() {
            vm.searchRequest = new SearchRequest();
            loadData();
        }

        function loadPageOfData(pageNumber) {
            vm.pending = true;
            
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            $localStorage.portalOfClientLimit = vm.queryRequest.limit;
            $localStorage.portalOfClientSearch = vm.searchRequest.search;
            PortalOfClientService.search(vm.searchRequest).then(function (page) {
                vm.pending = false;
                vm.pageOfData = page;
                vm.totalTranslations = page.totalElements;
                vm.pageOfData.number++;
            });
        }

        function loadData() {
            vm.loadPageOfData(1);
        }

        function remove(id) {
            var REMOVE_PREFIX = 'portal-of-client.remove';
            ModalService.showConfirmModal(REMOVE_PREFIX).then( function (){
                vm.isLoaderActive = true;
                PortalOfClientService.remove(id).then(function () {
                    vm.isLoaderActive = false;
                    loadData();
                    ModalService.showSuccessModal();
                }).catch(function (response) {
                    PlagiatValidationService.getModalError(response.data);
                });
            });

        }

    }

})();
