(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('JsaController', JsaController);

    JsaController.$inject = ['JsaService', 'ModalService', 'PlagiatValidationService', '$translate', 'POLON_SYNCHRONIZATION_STATUS',
        'POLON_SYNCHRONIZATION_TYPE', 'OrppdService', 'AccountService', '$q'];

    function JsaController(JsaService, ModalService, PlagiatValidationService, $translate, POLON_SYNCHRONIZATION_STATUS,
                           POLON_SYNCHRONIZATION_TYPE, OrppdService, AccountService, $q) {
        var vm = this;
        vm.importFile = importFile;
        vm.keyReturn = KeyCode.KEY_RETURN;
        vm.synchronize = synchronize;
        vm.saveAccessData = saveAccessData;
        vm.jsaTokenModel = saveAccessData;
        vm.isSynchronizationEnabled = isSynchronizationEnabled;
        vm.toggleShouldUpdateJsaToken = toggleShouldUpdateJsaToken;
        vm.shouldShowJsaTokenInput = shouldShowJsaTokenInput;
        vm.synchronizeOnlyUnitsAndStudies = false;
        vm.addPolonEmployee = addPolonEmployee;
        vm.searchPolonInstitutions = searchPolonInstitutions;
        vm.searchPolonEmployees = searchPolonEmployees;
        vm.selectedPolonInstitutionChange = selectedPolonInstitutionChange;
        vm.selectedPolonEmployeeChange = selectedPolonEmployeeChange;
        vm.getPolonEmployeeSummaryFormatted = getPolonEmployeeSummaryFormatted;
        vm.addPolonEmployeeFromOtherInstitution = addPolonEmployeeFromOtherInstitution;
        vm.sendOpiEmail = sendOpiEmail;
        vm.availableExtensions = '.xml,.xls,.xlsx';

        vm.orppdIntegration = AccountService.getClientSettings().orppdIntegration;

        vm.activeTab = 'jsaSynchronization';
        vm.activeSubTabPolonEmployee = 'search';
        vm.polonSynchronizationStatus = POLON_SYNCHRONIZATION_STATUS;
        vm.polonSynchronizationType = POLON_SYNCHRONIZATION_TYPE;
        vm.files = [];

        vm.jsaAccessData = {};
        vm.polonEmployee = {};
        vm.selectedPolonInstitution = null;
        vm.selectedEmployeeCustomTitle = null;
        vm.polonInstitutions = [];
        vm.polonEmployeesInInstitution = [];
        vm.showJsaToken = true;
        vm.showOrppdPassword = true;
        vm.degreesAndTitles = {};
        vm.changeTab = function(){};


        onInit();

        function onInit() {
            vm.isLoading = true;
            JsaService.getSynchronizationInfo().then(
                function (responseData) {
                    vm.isLoading = false;
                    vm.synchronizationStatus = responseData.synchronizationStatus;
                    vm.synchronizationType = responseData.synchronizationType;
                    vm.activeSubTab = responseData.synchronizationType;
                    vm.synchronizationStartDate = !!responseData.synchronizationStartDate ?
                        moment(responseData.synchronizationStartDate).format("DD-MM-YYYY HH:mm") : null;
                },
                function (response) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(response);
                }).catch(function (response) {
                vm.isLoading = false;
                ModalService.showErrorModal(response);
            });
            JsaService.getAccessData().then(function (jsaAccessData) {
                vm.jsaAccessData = jsaAccessData;
                vm.showJsaToken = !jsaAccessData.jsaToken;
                vm.jsaTokenLastSaveDateFormatted = !!jsaAccessData.tokenLastSaveDate
                    ? moment(jsaAccessData.tokenLastSaveDate).format("DD-MM-YYYY HH:mm") : null;
            });
            JsaService.getDegreesAndTitles().then(function (responseData) {
                vm.degreesAndTitles = responseData;
            });
            OrppdService.getPassword().then(function (orppdPassword) {
                vm.orppdPassword = orppdPassword.password;
                vm.showOrppdPassword = !vm.orppdPassword;
                vm.passwordLastSaveDateFormatted = !!orppdPassword.passwordLastSaveDate
                    ? moment(orppdPassword.passwordLastSaveDate).format("DD-MM-YYYY HH:mm") : null;
            });
        }

        function searchPolonInstitutions(searchText) {
           if (!vm.polonInstitutions || (angular.isArray(vm.polonInstitutions) && vm.polonInstitutions.length === 0)) {
               return JsaService.getEmployingPolonInstitutions().then(
                   function (polonInstitutions) {
                       vm.polonInstitutions = polonInstitutions;
                       return filterPolonInstitutionsByName(searchText);
                   }
               )
           } else {
               return filterPolonInstitutionsByName(searchText);
           }
        }

        function filterPolonInstitutionsByName(name) {
            return vm.polonInstitutions.filter(
                function (polonInstitution) {
                    return polonInstitution.name.toLowerCase().indexOf(name.toLowerCase()) >= 0;
                }
            )
        }

        function selectedPolonInstitutionChange() {
            if (vm.selectedPolonInstitution) {
                vm.showLoader = true;
                JsaService.getEmployeeSummaryByInstitution(vm.selectedPolonInstitution.uuid).then(
                    function (employeeSummaryForInstitution) {
                        vm.showLoader = false;
                        vm.polonEmployeesInInstitution = employeeSummaryForInstitution;
                    }, function (response) {
                        vm.showLoader = false;
                        PlagiatValidationService.getModalError(response.data);
                        vm.selectedPolonInstitution = vm.polonInstitutionsSearchText = null;
                        vm.polonEmployeesInInstitution = [];
                    }
                )
            } else {
                vm.polonEmployeesInInstitution = [];
            }
            vm.selectedPolonEmployee = vm.polonEmployeeSearchText = null;
        }

        function selectedPolonEmployeeChange() {
            vm.selectedEmployeeCustomTitle = null;
        }

        function searchPolonEmployees(searchText) {
            var tokens = searchText.split(/\s/);
            return vm.polonEmployeesInInstitution.filter(
                function (polonEmployee) {
                    for (var tokenIdx in tokens) {
                        var token = tokens[tokenIdx];
                        var isMatching = (polonEmployee.name && polonEmployee.name.toLowerCase().indexOf(token.toLowerCase()) >= 0) ||
                          (polonEmployee.surname && polonEmployee.surname.toLowerCase().indexOf(token.toLowerCase()) >= 0) ||
                            polonEmployee.uid.indexOf(token) >= 0;
                        if (!isMatching) {
                            return false;
                        }
                    }
                    return true;
                });
        }

        function getPolonEmployeeSummaryFormatted(polonEmployeeSummary) {
            return (polonEmployeeSummary.title == null ? '' :  polonEmployeeSummary.title)
                + ' ' + polonEmployeeSummary.name + ' ' + polonEmployeeSummary.surname + ' ' + polonEmployeeSummary.uid;
        }

        function importFile($files, $invalidFiles) {
            var formData;
            if ($files === null) {
                vm.files = null;
                vm.fileName = null;
            } else {
                if ($invalidFiles.length === 0) {
                    formData = new FormData();
                    vm.files = $files;
                    angular.forEach(vm.files, function (value) {
                        formData.append("file", value);
                        vm.fileName = value.name;
                    });
                }
                if (formData) {
                    JsaService.upload(formData).then(
                        function () {
                            vm.isLoading = false;
                            ModalService.showSuccessModal(vm.fileName + $translate.instant("jsa.file.import.success"))
                                .then(function () {
                                    refreshStateAfterTriggeringSynchronization(POLON_SYNCHRONIZATION_TYPE.FILE.value);
                                });
                        },
                        function (response) {
                            vm.isLoading = false;
                            ModalService.showErrorModal(response);
                        }).catch(function (response) {
                        vm.isLoading = false;
                        ModalService.showErrorModal(response);
                    });
                }
            }
        }

        function refreshStateAfterTriggeringSynchronization(synchronizationType) {
            vm.synchronizationStatus = POLON_SYNCHRONIZATION_STATUS.IN_PROGRESS.value;
            vm.synchronizationStartDate = moment(new Date()).format("DD-MM-YYYY HH:mm");
            vm.synchronizationType = synchronizationType;
        }

        function toggleShouldUpdateJsaToken() {
            vm.jsaAccessData.jsaToken = null;
        }

        function synchronize() {
            if (vm.synchronizeOnlyUnitsAndStudies) {
                synchronizeUnitsAndStudiesFromPolon();
            } else {
                synchronizeAllDataFromPolon();
            }
        }

        function synchronizeAllDataFromPolon() {
            vm.isLoading = true;
            JsaService.synchronize(vm.login, vm.password, vm.jsaAccessData.polonCode).then(
                function () {
                    vm.isLoading = false;
                    ModalService.showSuccessModal("polon.synchronization.success").then(function () {
                        refreshStateAfterTriggeringSynchronization(POLON_SYNCHRONIZATION_TYPE.POLON.value);
                    });
                },
                function (response) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(response);
                }).catch(function (response) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(response);
                });
        }

        function synchronizeUnitsAndStudiesFromPolon() {
            vm.isLoading = true;
            JsaService.synchronizeUnitsAndStudies().then(
                function () {
                    vm.isLoading = false;
                    ModalService.showSuccessModal("polon.synchronization.units-and-studies.success").then(
                        function () {
                            refreshStateAfterTriggeringSynchronization(POLON_SYNCHRONIZATION_TYPE.POLON_UNITS_AND_STUDIES.value);
                        });
                },
                function (response) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(response);
                }).catch(function (response) {
                    vm.isLoading = false;
                    ModalService.showErrorModal(response);
                });
        }

        function isSynchronizationEnabled() {
            return POLON_SYNCHRONIZATION_STATUS.IN_PROGRESS.value !== vm.synchronizationStatus;
        }

        function shouldShowJsaTokenInput() {
            return !vm.jsaAccessData.tokenLastSaveDate || vm.shouldUpdateJsaToken;
        }

        function addPolonEmployee() {
            vm.isLoading = true;
            JsaService.addPolonEmployee(vm.polonEmployee).then(function () {
                vm.polonEmployee = {};
                vm.isLoading = false;
                successSaveDataModalClosure("jsa.add-polon-employee.success")();
            },function (response) {
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
                return $q.reject()
            });
        }

        function addPolonEmployeeFromOtherInstitution() {
            vm.isLoading = true;
            vm.selectedPolonEmployee.mainInstitutionUid = vm.selectedPolonInstitution.uuid;
            if (!vm.selectedPolonEmployee.title) {
                vm.selectedPolonEmployee.title = vm.selectedEmployeeCustomTitle;
            }
            JsaService.addPolonEmployee(vm.selectedPolonEmployee).then(function () {
                vm.polonEmployee = {};
                vm.isLoading = false;
                successSaveDataModalClosure("jsa.search-polon-employee.add.success")();
            }, function (response) {
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
                return $q.reject()
            });
        }

        function sendOpiEmail() {
            vm.isLoading = true;
            JsaService.sendOpiEmail(vm.polonEmployee).then(function () {
                vm.polonEmployee = {};
                successSaveDataModalClosure("jsa.send-opi-mail.success")();
            },function (response) {
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
                return $q.reject()
            });
        }

        function saveAccessData() {
            vm.isLoading = true;
            var savingDataPromise = JsaService.saveAccessData(vm.jsaAccessData).then(function () {
                if (!vm.orppdIntegration) {
                    successSaveDataModalClosure("jsa.save-access-data.success")();
                }
            },function (response) {
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
                return $q.reject()
            });
            if(vm.orppdIntegration) {
                savingDataPromise.then(saveOrppdAccessData);
            }
        }

        function saveOrppdAccessData() {
            OrppdService.savePassword(vm.orppdPassword).then(successSaveDataModalClosure("orppd.password.save.success"),
                function (response) {
                    vm.isLoading = false;
                    PlagiatValidationService.getModalError(response.data);
                });
        }

        function successSaveDataModalClosure(successMessage) {
            return function() {
                vm.isLoading = false;
                ModalService.showSuccessModal(successMessage)
                    .then(function () {
                        onInit();
                    });
            };
        }

    }
})();
