(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('InfoController', InfoController);

    InfoController.$inject = ['$rootScope', 'AccountService', '_', '$translate', 'tmhDynamicLocale', 'ContactFormModelRequest',
        'ContactAddresatsModelRequest', 'emailFilter', 'InfoService', '$mdDialog', '$document', 'InstanceService', '$log',
        'DetectInstanceService', 'ClientService', 'USERSCHOOLTYPE', '$sce'];

    function InfoController($rootScope, AccountService, _, $translate, tmhDynamicLocale, ContactFormModelRequest,
                            ContactAddresatsModelRequest, emailFilter, InfoService, $mdDialog, $document, InstanceService, $log,
                            DetectInstanceService, ClientService, USERSCHOOLTYPE, $sce) {
        var vm = this;

        vm.isIndividual = AccountService.role.isIndividual();
        vm.isOperator = AccountService.role.isOperator();
        vm.isLecturer = AccountService.role.isLecturer();
        vm.isAdmin = AccountService.role.isAdmin();
        vm.isReseller = AccountService.role.isReseller();
        vm.clientType = AccountService.getClientType();
        vm.isAcceptRegulationsEnabled = AccountService.account.clientSettings.acceptRegulationsEnabled;
        vm.regulationsUrl = AccountService.account.clientSettings.regulationsUrl;

        vm.showProcedures = (AccountService.account.clientRegionId == 8) && AccountService.role.isAdmin();

        vm.instance = DetectInstanceService.instance();

        vm.numbers = [1, 2, 3, 4, 5, 6];

        if(vm.instance === 'ro') {
            vm.instanceStr = '_instance_ro';
        } else if (vm.instance === 'int') {
            vm.instanceStr = '_instance_int';
        } else {
            vm.instanceStr = '';
        }

        vm.accountType = AccountService.account.accountType;
        vm.accountTypeSuffix = '';
        vm.userRole = getAccountTypeSuffix(vm.accountType);
        vm.userRoleYoutube = getAccountTypeYoutubeSuffix(vm.accountType);
        vm.trustSrc = trustSrc;
        vm.files = [];
        vm.translateStr = translateStr;

        switch(vm.instance) {
            case 'int':
                vm.instanceType = '_int';
                break;
            case 'ro':
                vm.instanceType = '_ro';
                break;
            case 'pl':
                vm.instanceType = '_pl';
                break;
            case 'aka':
                vm.instanceType = '_aka';
                break;
            default:
                vm.instanceType = '_pl';
        }

        switch(vm.clientType) {
	        case 'CLIENT_TYPE_STANDARD':
	        case 'CLIENT_TYPE_ASAP':
	        	vm.clientTypeAdmin = ClientService.clientTypes.other.suffix;
	        	vm.clientTypeSuffix = ClientService.clientTypes.notIndividual.suffix;
	          break;
            case 'CODE_ACCOUNT':
                vm.clientTypeAdmin = ClientService.clientTypes.codeAccount.suffix;
                vm.clientTypeSuffix = ClientService.clientTypes.codeAccount.suffix;
                break;
            case 'PUBLISHING_HOUSE':
	        	vm.clientTypeAdmin = ClientService.clientTypes.publisher.suffix;
	        	vm.clientTypeSuffix = ClientService.clientTypes.publisher.suffix;
	          break;
	        case 'SCHOOL':
	        	vm.clientTypeAdmin = ClientService.clientTypes.school.suffix;
	        	vm.clientTypeSuffix = ClientService.clientTypes.school.suffix;
	        	vm.accountTypeSuffix = getAccountTypeSuffix(vm.accountType);
	          break;
	        default:
                vm.clientTypeAdmin = ClientService.clientTypes.other.suffix;
                vm.clientTypeSuffix = ClientService.clientTypes.notIndividual.suffix;
        }

        function getAccountTypeSuffix(accountType){
        	switch(accountType) {
        		case USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.value:
                    return '.admin';
        		case USERSCHOOLTYPE.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN.value:
        			return '.unitadmin';
        		case USERSCHOOLTYPE.USER_SCHOOL_TYPE_TEACHER.value:
        			return '.teacher';
        		case USERSCHOOLTYPE.USER_SCHOOL_TYPE_STUDENT.value:
        			return '.student';
        	}
        }

        function getAccountTypeYoutubeSuffix(accountType){
            switch(accountType) {
                case USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.value:
                    return '.admin';
                case USERSCHOOLTYPE.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN.value:
                    return '.unitadmin';
                case USERSCHOOLTYPE.USER_SCHOOL_TYPE_DEANERY.value:
                    return '.deanery';
                case USERSCHOOLTYPE.USER_SCHOOL_TYPE_TEACHER.value:
                    return '.teacher';
                case USERSCHOOLTYPE.USER_SCHOOL_TYPE_STUDENT.value:
                    return '.student';
            }
        }

        vm.contactFormModel = new ContactFormModelRequest();
        vm.adresatsRequest = [];
        vm.contactForm = vm.contactFormModel.contactForm;
        vm.clearForm = clearForm;
        vm.getTheFiles = getTheFiles;
        vm.messages = vm.contactFormModel.messages;
        var formData = new FormData();
        vm.sendForm = sendForm;
        vm.isSending = false;

        vm.ul = $document[0].getElementById("faq");
        vm.ul.addEventListener('click', handleClick);

        vm.adresatsArr = [];

        getAdressats();

        function getAdressats(){
            if (vm.isIndividual) {
                return;
            }
            vm.adresats = [];

            var helpEmail = AccountService.getClientRegionOfficeEmail();

            vm.adresats.push({
                name: $translate.instant("info.contact-form.helpdesk"),
                email: helpEmail
            });

            vm.adresats.push({
                name: $translate.instant("info.contact-form.account-manager"),
                email: helpEmail
            });

            InfoService.getAdmins().then(function (response) {
                angular.forEach(response, function (key) {
                    if (emailFilter(key.clieAdminEmail)) {
                        vm.adresats.push({name: $translate.instant("info.contact-form.administrator") + ' - ' + key.clieAdminName + ' ' + key.clieAdminSurname, email: key.clieAdminEmail});
                    }
                });
            });

            vm.contactForm.adresat = null;
        }

        function getTheFiles($files, $invalidFiles) {
            formData = new FormData();
            resetMessages();
            if ($invalidFiles.length === 0 && !!$files && $files.length > 0) {
                vm.files = vm.files.concat($files);
                vm.fileError = false;
            } else if ($invalidFiles.length > 0) {
                vm.fileError = true;
            }
        }

        function handleClick(event) {
            vm.header = event.target;
            vm.next = vm.header.nextElementSibling;
            if( vm.next !== null ){
                if (vm.next.style.display === 'block') {
                    vm.header.style.border = '1px solid #ddd';
                    vm.next.style.display = 'none';
                } else {
                    vm.header.style.border = 'none';
                    vm.next.style.display = 'block';
                }
            }

        }

        function resetMessages() {
            vm.contactFormModel = new ContactFormModelRequest();
            vm.messages = vm.contactFormModel.messages;
        }

      function trustSrc(src) {
        return $sce.trustAsResourceUrl(src);
      }

      function translateStr(val) {
            return $translate.instant(val);
      }

        function clearForm() {
            resetMessages();
            vm.adresat = vm.contactFormModel.adresat;
            vm.contactForm = vm.contactFormModel.contactForm;
            formData = new FormData();
            vm.fileError = false;
            vm.files = [];
            vm.isSending = false;
        }

        function sendForm() {
            vm.isSending = true;
            angular.forEach(vm.contactForm, function (element, key) {
                formData.append(key, element);
            });

            if (!vm.files || vm.files.length === 0) {
                var confirm = $mdDialog.confirm()
                .title()
                .textContent($translate.instant("info.contact-form.sendwithoutattachment"))
                .ariaLabel($translate.instant("info.contact-form.sendwithoutattachment"))
                .ok($translate.instant("global.button.send"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');
                $mdDialog.show(confirm).then(function (result) {
                    vm.sendFn = vm.isIndividual ? InfoService.sendForm(formData) : InfoService.operator.sendForm(formData);
                    vm.sendFn.then(function (response) {
                        clearForm();
                        vm.isSending = false;
                        vm.messages.success = true;
                        vm.fileName = false;
                    }).catch(function (error) {
                        vm.messages.error = true;
                    });
                }, function (result) {
                    vm.isSending = false;
                });
            } else {
                for (var fileIdx in vm.files) {
                    formData.append("files", vm.files[fileIdx]);
                }
                vm.sendFn = vm.isIndividual ? InfoService.sendForm(formData) : InfoService.operator.sendForm(formData);
                vm.sendFn.then(function (response) {
                    clearForm();
                    vm.messages.success = true;
                    vm.fileName = false;
                }).catch(function (error) {
                    vm.messages.error = true;
                });
            }

        }
    }
})();
