(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('SendToJSAController', SendToJSAController);

    function SendToJSAController(
        $scope, $mdDialog, $translate, $localStorage, $log,
        docId,
        TRANSLATION_KEYS, DOCUMENT_KINDS,
        AccountService, SearchService, DocumentService, JsaService, OrganisationalUnitsService, DictionaryService,
        DocumentKindService, DocumentParameterDefinitionService, PlagiatValidationService,
        JsaDocumentFormService, $rootScope
    ) {
        var vm = this;
        vm.idDocument = docId;
        vm.isLoading = true;
        vm.sendWithoutUid = false;
        vm.searchValuePromoter = null;
        vm.onUploadObj = {};
        vm.selectedItem = [];
        vm.querySearchForLanguage;
        vm.querySearchForOrganisationalUnit;
        vm.formatAuthor = JsaDocumentFormService.formatAuthor;
        vm.searchLanguages = DictionaryService.getLanguagesJsaAutocompleteItems;
        vm.typeOfDocuments = DOCUMENT_KINDS.jsaDocumentKinds;
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();

        this.$onInit = function () {
            DocumentService.getMetadataForm(vm.idDocument).then(function (responseData) {
                vm.document = responseData.data;
                vm.clientId = vm.document.clientId;
                vm.userId = vm.document.userId;
                DocumentService.operator.getConfiguration().then(function (response) {
                    vm.onUploadObj = Object.assign(response.data, vm.onUploadObj);
                    vm.onUploadObj.title = vm.document.docuTitle;
                    vm.onUploadObj.documentKindId = vm.document.documentKindId;
                    vm.isLoading = false;
                });
            });
        };

        vm.searchAuthors = function (searchText) {
            return JsaService.getAuthorsAutocompleteItems(searchText, vm.onUploadObj.authors, vm.document.clientId)
        };

        vm.searchPromoters = function(searchText) {
            return JsaService.getPromotersAutocompleteItems(searchText, vm.document.clientId);
        };

        vm.selectPromoter = function (promoter) {
            vm.onUploadObj.promoter = (promoter && promoter.name) ? promoter : null;
        };

        vm.selectedOrganisationalUnitChange = function (organisationalUnit) {
            vm.onUploadObj.organisationalUnit = (organisationalUnit && organisationalUnit.id) ? organisationalUnit : null;
        };

        vm.selectLanguage = function (language) {
            vm.onUploadObj.language = (language && language.code) ? language : null;
        };

        vm.getLanguageItemText = function (item) {
            return $translate.instant(TRANSLATION_KEYS.dictionaryLanguage + item.code);
        };

        vm.selectedAuthorChanged = function (item, index) {
            JsaDocumentFormService.handleSelectedAuthorChange(item, index, vm);
        };

        vm.selectedStudyChanged = function selectedStudyChanged() {
            JsaDocumentFormService.refreshAvailableUnits(vm);
        };

        vm.addAuthor = function () {
            if (!vm.onUploadObj.authors) {
                vm.onUploadObj.authors = [];
            }
            vm.onUploadObj.authors.push({});
        };

        vm.removeAuthor = function (event, index) {
            vm.onUploadObj.authors.splice(index, 1);
        };

        vm.closeDialog = function () {
            $mdDialog.hide();
        };

        vm.saveData = function () {
            vm.isLoading = true;
            JsaDocumentFormService.inferOrganisationalUnitId(vm).then(
                function (organisationalUnitId) {
                    vm.onUploadObj.organisationalUnitId = organisationalUnitId;
                }
            ).then(
                function () {
                    JsaService.send(vm.idDocument, vm.onUploadObj, vm.sendWithoutUid).then(function () {
                        $mdDialog.hide();
                        $rootScope.$broadcast('on-get-documents');
                        $state.go("documents");
                    }, function (response) {
                        $rootScope.$broadcast('on-get-documents');
                        $mdDialog.hide();
                        PlagiatValidationService.getModalError(response.data, null, "documents");
                    });
                }
            );
        }
    }
})();
