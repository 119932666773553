(function() {
    'use strict';
    
    angular
        .module('plagiat2017App')
        .controller('ClientProtocolAddController', ClientProtocolAddController);
    
    function ClientProtocolAddController(
        ProtocolsService, ClientService, ModalService, JhiLanguageService, PlagiatValidationService,
        QueryRequest, SearchRequest,
        $q, $stateParams, $localStorage, $state
    ) {
        var vm = this;
        vm.pending = false;
        vm.protocols = [];
        vm.clientId = $stateParams.clientId;
        vm.addToClient = addToClient;
        vm.loadData = loadData;
        vm.loadPage = loadPage;
        
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.order = 'id';
        vm.searchRequest = new SearchRequest();
        vm.protocols = [];
        vm.protocolPage = {};
    
        this.$onInit = function () {
            loadData();
        };
        
        function loadData() {
            if (vm.protocolPage.number) {
                vm.loadPage(vm.protocolPage.number);
            } else {
                vm.loadPage(1);
            }
        }
    
        function loadPage(pageNumber) {
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            $localStorage.protocolLimit = vm.queryRequest.limit;
            vm.pending = true;
            ProtocolsService.search(vm.searchRequest).then(function (protocolPage) {
                vm.pending = false;
                vm.protocolPage = protocolPage;
                vm.protocols = protocolPage.content;
                vm.protocolPage.number++;
                vm.queryRequest.page = vm.protocolPage.number;
            });
        }
    
        function addToClient(obj) {
            vm.pending = true;
            ClientService.addClientProtocol(vm.clientId, obj.id).then(function () {
                vm.pending = false;
                ModalService.showSuccessModal().then(
                    function () {
                        $state.go("client-protocols");
                    }, function () {
                        $state.go("client-protocols");
                    });
            }, function (errResponse) {
                PlagiatValidationService.getModalError(errResponse);
            });
        }
        
    }
    
})();
