(function () {
    'use strict';

    function OrganisationalUnitsSearchController($scope, $rootScope, $state, $translate, $mdDialog, $log,
    		OrganisationalUnitsService, organisationalUnitsQueryRequest, organisationalUnitsSearchRequest, $localStorage) {
        var vm = this;
        vm.simpleSearch = true;
        vm.getData = getData;
        vm.clear = clear;
        vm.goToAddPage = goToAddPage;
        
        function goToAddPage(){
        	$state.go('organisationalUnitsAddEdit');
        }
        
        function getData(){
            $localStorage.organisationalListLimit = vm.onQueryRequest.limit;
        	vm.onGetData(vm.organisationalUnitsSearchRequest);
		}
        
        function clear() {
            vm.organisationalUnitsSearchRequest = new organisationalUnitsSearchRequest();
            getData();
        }

    }

    angular.module('plagiat2017App')
            .component('organisationalUnitsSearch', {
                templateUrl: 'app/account/settings/organisational-units/organisational-units-search/organisational-units-search.template.html',
                controller: OrganisationalUnitsSearchController,
                bindings: {
                    onGetData: '<',
                    onQueryRequest: '=',
                    onSearchData: '='
                }
            });

})();
