(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DocumentImportService', DocumentImportService);

    DocumentImportService.$inject = ['$http', 'ModalService', '$translate'];

    function DocumentImportService($http, ModalService, $translate) {

        var service = {
            downloadMetadataTemplate: downloadMetadataTemplate,
            uploadMetadataFiles: uploadMetadataFiles,
            deleteFromImport: deleteFromImport,
            uploadDocumentFiles: uploadDocumentFiles,
            addDocuments: addDocuments
		};
        var requestUrl = '/api/document-import';
        return service;

        function uploadMetadataFiles(metadataFiles, importId) {
            var formData = new FormData();
            for (var metadataFileIdx in metadataFiles) {
                formData.append("files", metadataFiles[metadataFileIdx]);
            }
            if (importId) {
                formData.append("importId", importId);
            }
            var request = {
                transformRequest: angular.identity,
                transformResponse: angular.identity,
                headers: {'Content-Type': undefined, 'Accept': 'application/json'}
            };
            return $http.post(requestUrl + '/upload-metadata', formData, request).then(
                function (response) {
                    return JSON.parse(response.data).data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function uploadDocumentFiles(documentFiles, importId) {
            var formData = new FormData();
            for (var documentFileIdx in documentFiles) {
                formData.append("files", documentFiles[documentFileIdx]);
            }
            if (importId) {
                formData.append("importId", importId);
            }
            var request = {
                transformRequest: angular.identity,
                transformResponse: angular.identity,
                headers: {'Content-Type': undefined, 'Accept': 'application/json'}
            };
            return $http.post(requestUrl + '/upload-document-files', formData, request).then(
                function (response) {
                    return JSON.parse(response.data).data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function deleteFromImport(importId, fileName) {
            return $http.post(requestUrl + '/delete-from-import', {importId: importId, fileName: fileName}).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function addDocuments(importId, addWithoutChecking) {
            return $http.post(requestUrl + '/add-documents', {importId: importId, addWithoutChecking: addWithoutChecking}).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function downloadMetadataTemplate() {
            var templateName = $translate.instant('document.import.metadata.file.template.name');
            $http({
                url: requestUrl + '/metadata/template',
                method: "GET",
                responseType: 'arraybuffer'
            }).then(function (success) {
                var blob = new Blob([success.data], {type: "application/vnd.ms-excel"});
                saveAs(blob, templateName + '.xls');
            }, function (errorResponse) {
                ModalService.showErrorModal(errorResponse);
            });
        }
    }

})();
