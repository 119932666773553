(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('TranslationEditController', TranslationEditController);

    function TranslationEditController(
        $mdDialog, JhiLanguageService, DocumentKindService, $q,
        $stateParams, OrppdService, code, $rootScope, ModalService,
        TranslationModel, TranslationsService, TRANSLATION_TYPES, TRANSLATION_TYPES_ADD
    ) {
        var vm = this;

        vm.code = code;
        vm.translationTypes = TRANSLATION_TYPES;
        vm.obj;
        vm.availableLanguages;

        this.$onInit = function () {
            if (code) {
                find();
            } else {
                vm.obj = new TranslationModel();
                vm.obj.type = 0;
                vm.translationTypes = TRANSLATION_TYPES_ADD;
            }
            initAvailableLanguages();
        };

        function find() {
            vm.pending = true;
            TranslationsService.findOne(code).then(
                function (response) {
                    vm.pending = false;
                    vm.obj = response.data;
                    vm.initialCode = vm.obj.code;
                    vm.isAtLeastOneTranslationFilledEngine();
                }, function (errorResponse) {
                    vm.pending = false;
                    $mdDialog.cancel();
                    ModalService.showErrorModal(errorResponse);
                }
            );
        }

        function initAvailableLanguages() {
            JhiLanguageService.getAll().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
            });
        }

        vm.closeDialog = function () {
            $mdDialog.cancel();
            $rootScope.$broadcast('on-close-modal-translation-edit');
        };

        /**
         * calculates if at least one translation is filled
         */
        vm.isAtLeastOneTranslationFilledEngine = function () {
            for (var i = 0; i < vm.availableLanguages.length; i++) {
                var lang = vm.availableLanguages[i];
                if (vm.obj[lang]) {
                    vm.isAtLeastOneTranslationFilled = true;
                    return;
                }
            }
            vm.isAtLeastOneTranslationFilled = false;
        };

        vm.saveOrUpdate = function (form) {
            if (form.$invalid || form.$pending) {
                for (var i = 0; i < form.$$controls.length; i++) {
                    form.$$controls[i].$touched = true;
                }
                return;
            }
            vm.pending = true;
            TranslationsService
                .saveOrUpdate(vm.obj)
                .then(function () {
                        vm.pending = false;
                        $mdDialog.hide();
                        ModalService.showSuccessModal("global.messages.success.label");
                        $rootScope.$broadcast('on-close-modal-translation-edit');
                    }, function (errorResponse) {
                        vm.pending = false;
                        ModalService.showErrorModal(errorResponse);
                    }
                );
        };
    }

})();
