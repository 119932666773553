(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ApiKeysService', ApiKeysService);

    ApiKeysService.$inject = ['$http', '$q'];

    function ApiKeysService($http, $q) {

        var service = {
        	add: add,
            getApiKeys: getApiKeys,
            invalidate: invalidate,
            getDefaultExpiryDate: getDefaultExpiryDate,
            detailsObj: {}
        };

        return service;

        function getApiKeys(params) {
            return $http.post('/api/api-keys', params).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function invalidate(ids) {
            return $http.post('/api/api-keys/delete-multiple', ids).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function add(obj) {
            return $http.put('api/api-keys', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function getDefaultExpiryDate(params) {
            return $http.get('/api/api-keys/default-expiry-date', params).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
    }

})();
