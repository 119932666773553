(function() {
	'use strict';

	function SetCountersController($scope, $mdDialog) {
		var vm = this;
		vm.cancelDialog = cancelDialog;

		vm.value = 0;

		function cancelDialog() {
			$mdDialog.cancel();
		}

	}

	angular
		.module('plagiat2017App')
		.component(
			'setCounters',
			{
				templateUrl : 'app/account/users/set-counters/set-counters.template.html',
				controllerAs: 'vm',
				bindings: {
					setCountersCallback: "="
				},
				controller : SetCountersController
			});

})();
