(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .constant('USERSTATUSES', [
       {'id': 0, 'status': 'users.model.status.active' },
       {'id': -1, 'status': 'users.model.status.hibernated' }  ])
    .constant('USERSTATUSES_SUPERADMIN', [
       {'id': 0, 'status': 'users.model.status.active' },
       {'id': -2, 'status': 'users.model.status.blocked' },
       {'id': -3, 'status': 'users.model.status.inactive' },
       {'id': -1, 'status': 'users.model.status.hibernated' }  ])
    ;
})();
