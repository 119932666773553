(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('CodeSearchModel', function () {
            function model() {
                this.organizationUnitId; //Integer
                this.userEmailAddress; //String
                this.userFirstName; //String
                this.userLastName; //String
                this.userRole; //Integer
                this.control; //String
                this.codeTypeId; //Integer
                this.status; //Integer
            }

            return model;
        });
})();
