(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('JsaService', JsaService);

    JsaService.$inject = ['$http', '$q'];

    function JsaService($http, $q) {

        var service = {
            upload: upload,
            getSynchronizationInfo: getSynchronizationInfo,
            synchronize: synchronize,
            synchronizeUnitsAndStudies: synchronizeUnitsAndStudies,
            synchronizeInstitutions: synchronizeInstitutions,
            saveAccessData: saveAccessData,
            getAccessData: getAccessData,
            getEmployingPolonInstitutions: getEmployingPolonInstitutions,
            getEmployeeSummaryByInstitution: getEmployeeSummaryByInstitution,
            getPolonEmployeesEditUser: getPolonEmployeesEditUser,
            getPolonEmployeesAddUser: getPolonEmployeesAddUser,
            getAuthorsAutocompleteItems: getAuthorsAutocompleteItems,
            getPromoterByUserId: getPromoterByUserId,
            getPromotersAutocompleteItems: getPromotersAutocompleteItems,
            saveDocumentJSA: saveDocumentJSA,
            getJsaMetadata: getJsaMetadata,
            editJsaMetadata: editJsaMetadata,
            send: send,
            addPolonEmployee: addPolonEmployee,
            getDegreesAndTitles: getDegreesAndTitles,
            sendOpiEmail: sendOpiEmail
        };

        var requestUrl = 'api/jsa';
        return service;

        function upload(file) {
            var request = {
                transformRequest: angular.identity,
                transformResponse: angular.identity,
                headers: {
                    'Content-Type': undefined,
                }
            };
            return $http.post(requestUrl + '/import-file', file, request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function synchronize(login, password, polonCode) {
            var synchronizationData = {
                login: login,
                password: password,
                polonCode: polonCode
            };
            return $http.post(requestUrl + '/synchronize', synchronizationData).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function synchronizeUnitsAndStudies() {
            return $http.post(requestUrl + '/synchronize-units-and-studies').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function synchronizeInstitutions() {
            return $http.post(requestUrl + '/synchronize-institutions').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getSynchronizationInfo() {
            return $http.get(requestUrl + '/synchronization-info').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveAccessData(accessData) {
            return $http.post(requestUrl + '/access-data', accessData).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDegreesAndTitles() {
            return $http.get(requestUrl + '/degrees-and-titles').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function addPolonEmployee(polonEmployee) {
            return $http.post(requestUrl + '/add-polon-employee', polonEmployee).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function sendOpiEmail(polonEmployee) {
            return $http.post(requestUrl + '/send-email-opi', polonEmployee).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAccessData() {
            return $http.get(requestUrl + '/access-data').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getEmployingPolonInstitutions() {
            return $http.get('api/polon/institution/employing').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getEmployeeSummaryByInstitution(institutionUid) {
            return $http.get('api/polon/employees/summary/institution/' + institutionUid).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getPolonEmployeesEditUser(uid) {
            return $http.get('api/polon/employees/edit-user/' + uid).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getPolonEmployeesAddUser() {
            return $http.get('api/polon/employees/add-user/').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAuthorsAutocompleteItems(autocompleteQuery, authors, clientId) {
            var params = {params: {searchValue: autocompleteQuery, 'clientId': clientId}};
            if (authors && authors[0]) {
                if (authors[0].uid) {
                    params.params.includePhdStudents = false;
                } else {
                    params.params.includeStudents = false;
                }
            }
            return $http.get(requestUrl + '/authors',  params).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getPromoterByUserId(userId) {
            return $http.get(requestUrl + '/promoter?userId=' + userId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getPromotersAutocompleteItems(searchValue, clientId) {
            return $http.get(requestUrl + '/promoters', {params: {searchValue: searchValue, 'clientId': clientId}}).then(
                function (response) {
                    return response.data.data.content;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveDocumentJSA(obj) {
            return $http.post(requestUrl + '/add-document', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function editJsaMetadata(documentId, jsaDocument) {
            return $http.post(requestUrl + '/edit-metadata?documentId=' + documentId, jsaDocument).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        /**
         * Send already created document to JSA
         */
        function send(documentId, jsaDocument, sendWithoutUid) {
            return $http.post(requestUrl + '/send?documentId=' + documentId + '&sendWithoutUid=' + sendWithoutUid, jsaDocument).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getJsaMetadata(documentId) {
            return $http.get(requestUrl + '/get-metadata?documentId=' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

    }

})();
