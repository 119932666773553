(function () {
    'use strict';

    angular.module('plagiat2017App').component('plagiatNgMessages', {
        templateUrl: 'app/components/plagiat-ng-messages/plagiat-ng-messages.template.html',
        controllerAs: 'vm',
        bindings: {
            formElement: '=',
            translateValues: '='
        }
    });

})();
