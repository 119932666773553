(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('DocumentClientModel', function () {

        function DocumentClientModelRequest() {
            this.title = '';
            this.authors = [
                { firstName: '', lastName: '', index: ''  }
                ];
            this.promoter = {
                title: 'Tytuł promotora',
                firstname: 'Imię promotora',
                surname: 'Nazwisko promotora'
            };
            this.documentKindId = 'Rodzaj dokumentu';
            this.orgUnitId = 'Jednostka organizacyjna';
            this.documentParameters = [
                { name: 'Kierunek', value: ['Zarządzanie', 'Ekonomia', 'Prawo'] },
                { name: 'Pole do wpisania', value: 'Wpisz coś' }
                ];
        }

        return DocumentClientModelRequest;
    });
})();