(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ViewFeedbackController', ViewFeedbackController);
    
    ViewFeedbackController.$inject = ['PeerReviewFeedbackService', 'AccountService', '$stateParams', '$scope', '$window'];

    function ViewFeedbackController(PeerReviewFeedbackService, AccountService, $stateParams, $scope, $window) {

        const vm = this;
        vm.loadingValues = true;
        vm.pending = false;
        vm.isAdmin = false;
        vm.isLecturer = false;

        vm.obj = {};

        this.$onInit = function () {
            if ($stateParams.id) {
                PeerReviewFeedbackService.findOne($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                    vm.loadingValues = false;
                });
            }
            vm.isAdmin = AccountService.role.isAdmin();
            vm.isLecturer = AccountService.role.isLecturer();
        };

        $scope.generateRange = function(start, end) {
            var arr = [];
            for (var i = start; i <= end; i++) {
                arr.push(i);
            }
            return arr;
        };

        $scope.goBack = function () {
            $window.history.back();
        }

        $scope.saveGrade = function () {
            PeerReviewFeedbackService.saveGrade(vm.obj).then(function (obj) {
                $window.history.back();
            })
        }
    }
})();
