(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
          .state('assignments', {
            parent: 'app',
            url: '/assignments',
            data: {
                pageTitle: 'global.menu.account.assignments'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/assignments/assignments.html',
                    controller: 'AssignmentsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'shortcode,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil',
                    function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate(
                                $stateParams.sort),
                            ascending: PaginationUtil.parseAscending(
                                $stateParams.sort)
                        };
                    }],
                translatePartialLoader: ['$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('assignment');
                        $translatePartialLoader.addPart('document');
                        return $translate.refresh();
                    }]
            }
        })
          .state("assignment-add", {
            parent: "assignments",
            url: "/add-edit/:id",
            data: {
                pageTitle: 'assignment.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/assignments/assignments-add.template.html',
                    controller: 'AssignmentsAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('assignment');
                    $translatePartialLoader.addPart('peer-review');
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }]
            }
        })
        .state("assignment-submit", {
            parent: "assignments",
            url: "/submit/:id",
            data: {
                pageTitle: 'assignment.submit.page.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/assignments/assignments-submit.html',
                    controller: 'AssignmentsSubmitController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('assignment');
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }]
            }
        })
        .state("assignment-duplicate", {
            parent: "assignments",
            url: "/duplicate/:id",
            data: {
                pageTitle: 'assignment.edit.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/assignments/assignments-add.template.html',
                    controller: 'AssignmentsAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('assignment');
                    $translatePartialLoader.addPart('peer-review');
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }]
            }
        })
    }

})();
