(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('PaymentsService', PaymentsService);

    PaymentsService.$inject = ['$http', '$q'];

    function PaymentsService($http, $q) {

        var service = {
            save: save,
            get: get,
            getTest: getTest,
            getPaymentsForSuperadmin: getPaymentsForSuperadmin,
            getPrice: getPrice,
            useTokens: useTokens,
            pay: pay,
            getLastInvoiceInfo: getLastInvoiceInfo,
            downloadInvoice: downloadInvoice,
            markAsCanceled: markAsCanceled,
            getPaymentMethods: getPaymentMethods,
            getEasyPayAvailableForUsers: getEasyPayAvailableForUsers,
            getKassa24AvailableForUsers: getKassa24AvailableForUsers,
            getStatusForLastPayment: getStatusForLastPayment
        };

        return service;

        function getPaymentMethods(obj) {
            return $http.get('api/payments/methods', obj).then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }

        function useTokens(obj) {
            return $http.post('api/payments/use-tokens', obj).then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }

        function getLastInvoiceInfo() {
            return $http.get('api/invoice/data').then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }

        function getPrice(obj) {
            return $http.post('api/payments/token-price', obj).then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }


        function save(obj) {

            return $http.post('api/payments/save', obj).then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }

        function get(obj) {
            return $http.post('/api/payments', obj).then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }

        function getTest(obj) {
            return $http.get('/api/paymentsTest').then(
        function (response) {
            return response.data;
        },
        function (errResponse) {
            return $q.reject(errResponse);
        });
        }

        function getPaymentsForSuperadmin(obj) {
	        return $http.post('/api/payments/list-for-superadmin', obj).then(
	        function (response) {
	            return response.data;
	        },
	        function (errResponse) {
	            return $q.reject(errResponse);
	        });
        }

        function pay(obj) {
            return $http.post('/api/payments/pay', obj).then(
            function (response) {
                return response.data;
            },
            function (errResponse) {
                return $q.reject(errResponse);
            });
        }

        function getEasyPayAvailableForUsers() {
            return $http.get('api/payments/easypay-available-for-users').then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }

        function getKassa24AvailableForUsers() {
            return $http.get('api/payments/kassa24-available-for-users').then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }

        function getStatusForLastPayment() {
            return $http.get('api/payments/status-for-last-payment').then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }
    
        function downloadInvoice(invoiceNumber) {
            return $http({
                url : '/api/invoice/download',
                method: "POST",
                data: invoiceNumber,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).then(
                function(success) {
                    var blob = new Blob(
                        [ success.data ],
                        {
                            type: "text/pdf; encoding=UTF-8"
                        });
                    var fileName = 'faktura-' + invoiceNumber.replace( /[<>:"\/\\|?*]+/g, '-' ) + ".pdf";
                    saveAs(blob, fileName);
                }, function(errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
        function markAsCanceled(id) {
            return $http.get('/api/payments/mark-as-canceled/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
    }



})();
