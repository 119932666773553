(function () {
    'use strict';

    function PaymentsSuperadminSearchController($scope, $localStorage, SearchService, DateService,
        PaymentsRequest, PaymentsQueryRequest, $q, _, $timeout, PaymentsService, 
                                                SIMPLE_SEARCH_DELAY, PAYMENT_TYPES, PAYMENT_STATUSES) {

        var vm = this;
        vm.getPayments = getPayments;
        vm.fixDate = DateService.fixDate;
        vm.toggleSearch = toggleSearch;
        vm.clear = clear;
        vm.searchRequest = new PaymentsRequest();
        vm.queryRequest = new PaymentsQueryRequest();
        vm.querySearch = querySearch;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.paymentTypes = PAYMENT_TYPES;
        vm.paymentStatuses = PAYMENT_STATUSES;

        function toggleSearch() {
            vm.onSearchRequest.simpleSearch = '';
            vm.simpleSearch = !vm.simpleSearch;
            $localStorage.paymentsSimpleSearchEnabled = vm.simpleSearch;
        }

        function clear() {
            vm.onClear();
        }

        function getPayments() {
            $localStorage.paymentsListlimit = vm.onQueryRequest.limit;
            vm.onGetPayments();
        }

        function querySearch(query) {
            if (query.length > 2) {
                vm.searchRequest.pageNumber = 0;
                vm.searchRequest.pageSize = 20;
                vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
                vm.searchRequest.search = query;
                vm.searchRequest.simpleSearch = query;

                return PaymentsService.searchPayments(vm.searchRequest).then(function (response) {
                    return response.content;
                })
            } else {
                var deferred = $q.defer();
                $timeout(function () {
                    deferred.resolve([]);
                }, 1200, false);
                return deferred.promise;
            }
        }

    }

    angular.module('plagiat2017App')
        .component('paymentsSuperadminSearch', {
            templateUrl: 'app/account/payments/payments-superadmin-search/payments-superadmin-search.template.html',
            controller: PaymentsSuperadminSearchController,
            bindings: {
                onGetPayments: '<',
                onQueryRequest: '<',
                onSearchRequest: '<',
                onClear: '<',
                simpleSearch: '<',
            }
        });

})();
