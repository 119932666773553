(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PopupAdministrationController', PopupAdministrationController);

    PopupAdministrationController.$inject = ['PopupAdministrationService', 'ModalService', 'SearchRequest', 'QueryRequest',
        '$localStorage'];

    function PopupAdministrationController(PopupAdministrationService, ModalService, SearchRequest, QueryRequest, $localStorage) {
        var vm = this;
        vm.activeTab = 'popupAdministration';
        vm.remove = remove;
        vm.switchOff = switchOff;
        vm.copy = copy;
        vm.getDetails = getDetails;
    
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.queryRequest = new QueryRequest();
        vm.searchRequest = new SearchRequest();
        vm.pending = false;
        vm.popups = [];
        vm.popupPage = {};
        vm.expandedRow = null;
    
        vm.loadData = loadData;
        vm.loadPage = loadPage;
    
        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.popupLimit ? $localStorage.popupLimit : vm.queryRequest.limit;
            vm.searchRequest.sortTab = ['id,desc'];
            vm.loadData();
        };

        function loadData() {
            if (vm.popupPage.number) {
                vm.loadPage(vm.popupPage.number);
            } else {
                vm.loadPage(1);
            }
        }

        function loadPage(pageNumber) {
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            $localStorage.popupLimit = vm.queryRequest.limit;
            vm.pending = true;
            PopupAdministrationService.search(vm.searchRequest).then(function (popupPage) {
                vm.pending = false;
                vm.expandedRow = null;
                vm.popupPage = popupPage;
                vm.popups = popupPage.content;
                vm.popupPage.number++;
            });
        }
    
        function getDetails(popup) {
            vm.expandedRow = null;
            PopupAdministrationService.getPopupForEditing(popup.id).then(function (result) {
                vm.details = result.data;
                vm.expandedRow = popup.id;
            });
        }

        function remove(obj) {
            PopupAdministrationService
                .remove(obj.id)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    loadData();
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
        function switchOff(obj) {
            PopupAdministrationService
                .switchOff(obj.id)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    loadData();
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
        function copy(obj) {
            PopupAdministrationService
                .copy(obj.id)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    loadData();
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
    }
})();
