(function () {
    'use strict';

    angular.module('UserRegisterRequestModel', [])
    .factory('UserRegisterRequest', function () {

        function UserRegisterRequest() {
            this.name = '';
            this.surname = '';
            this.email = '';
            this.password = '';
            this.confirmedPassword = '';
            this.acceptRegulations = false;
        
        }

        return UserRegisterRequest;
    });
})();
