(function() {
	'use strict';

	function JsaDocumentEditControler($scope, $mdDialog, AccountService, $translate, TRANSLATION_KEYS,
		DocumentService, JsaService, $localStorage, $log, OrganisationalUnitsService, DictionaryService,
		DocumentKindService, DocumentParameterDefinitionService, DOCUMENT_KINDS, PlagiatValidationService,
                                      JsaDocumentFormService) {
        var vm = this;
        vm.searchValuePromoter = null;
        vm.closeDialog = closeDialog;
        vm.saveData = saveData;
        vm.selectedItem = [];
        vm.addAuthor = addAuthor;
        vm.removeAuthor = removeAuthor;
        vm.formatAuthor = JsaDocumentFormService.formatAuthor;
        vm.searchAuthors = function(searchText) {return JsaService.getAuthorsAutocompleteItems(searchText, vm.onUploadObj.authors, vm.clientId)};
        vm.selectedAuthorChanged = selectedAuthorChanged;
        vm.selectedStudyChanged = selectedStudyChanged;
        vm.searchPromoters = function(searchText) {return JsaService.getPromotersAutocompleteItems(searchText, vm.clientId)};
        vm.selectPromoter = selectPromoter;
        vm.querySearchForOrganisationalUnit = null;
        vm.selectedOrganisationalUnitChange = selectedOrganisationalUnitChange;
        vm.querySearchForLanguage = null;
        vm.searchLanguages = DictionaryService.getLanguagesJsaAutocompleteItems;
        vm.selectLanguage = selectLanguage;
        vm.getLanguageItemText = getLanguageItemText;
        vm.typeOfDocuments = DOCUMENT_KINDS.jsaDocumentKinds;
        vm.idDocument = DocumentService.document.id;
        vm.onUploadObj = {};
        vm.isLoading = true;
        vm.isSaving = false;

        DocumentService.operator.getConfiguration().then(function (response) {
            vm.onUploadObj = Object.assign(response.data, vm.onUploadObj);
            JsaService.getJsaMetadata(vm.idDocument).then(function (response) {
                vm.onUploadObj = Object.assign(response.data, vm.onUploadObj);
                vm.clientId = vm.onUploadObj.clientId;
                vm.userId = vm.onUploadObj.uploadingUserId;
                vm.jsaExaminationDeprecated = vm.onUploadObj.jsaExaminationDeprecated;
                vm.selectedItem = vm.onUploadObj.authors;
                vm.selectedPromoter = vm.onUploadObj.promoter;
                if (AccountService.account.id === vm.onUploadObj.promoter.userId) {
                    vm.promoterNotEditable = true;
                }
                vm.selectedLanguage = vm.onUploadObj.language;
                vm.selectedOrganisationalUnit = vm.onUploadObj.organisationalUnit;
                vm.selectedDocumentKindId = vm.onUploadObj.documentKindId;
                vm.isLoading = false;
            })
        });

        function selectPromoter(promoter) {
            vm.onUploadObj.promoter = (promoter && promoter.name) ? promoter : null;
        }

        function selectedOrganisationalUnitChange(organisationalUnit) {
            vm.onUploadObj.organisationalUnit = (organisationalUnit && organisationalUnit.id) ? organisationalUnit : null;
        }

        function selectLanguage(language) {
            vm.onUploadObj.language = (language && language.code) ? language : null;
        }

        function getLanguageItemText(item) {
            return $translate.instant(TRANSLATION_KEYS.dictionaryLanguage + item.code);
        }

        function selectedAuthorChanged(item, index) {
            JsaDocumentFormService.handleSelectedAuthorChange(item, index, vm);
        }

        function selectedStudyChanged() {
            JsaDocumentFormService.refreshAvailableUnits(vm);
        }

        function addAuthor() {
            if (!vm.onUploadObj.authors) {
                vm.onUploadObj.authors = [];
            }
            vm.onUploadObj.authors.push({});
        }

        function removeAuthor(event, item) {
            vm.onUploadObj.authors.splice(item, 1);
        }

        function closeDialog() {
            $mdDialog.hide();
        }

        function saveData() {
            vm.isSaving = true;
            JsaDocumentFormService.inferOrganisationalUnitId(vm).then(
                function (organisationalUnitId) {
                    vm.onUploadObj.organisationalUnitId = organisationalUnitId;
                }
            ).then(
                function () {
                    JsaService.editJsaMetadata(vm.idDocument, vm.onUploadObj).then(function () {
                        $mdDialog.hide();
                    }, function (response) {
                        vm.isSaving = false;
                        PlagiatValidationService.getModalError(response.data, undefined, undefined, true)
                    });
                }
            );
        }

    }

    angular
        .module('plagiat2017App')
        .component(
            'jsaDocumentEditForm',
            {
                templateUrl: 'app/components/document/jsa-edit-form/jsa-document-edit-form.template.html',
                controller: JsaDocumentEditControler,
                controllerAs: 'vm'
            });

})();
