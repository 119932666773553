(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ProtocolsService', ProtocolsService);
    
    ProtocolsService.$inject = ['$http', '$q'];

    function ProtocolsService($http, $q) {

        var service = {
            search: search,
            getProtocolForEditing: getProtocolForEditing,
            getProtocolParameters: getProtocolParameters,
            remove: remove,
            duplicate: duplicate,
            saveOrUpdate: saveOrUpdate
        };
        var requestUrl = 'api/protocols';
        return service;

        function getProtocolForEditing(id) {
            return $http.get(requestUrl + '/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function getProtocolParameters() {
            return $http.get(requestUrl + '/parameters').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveOrUpdate(obj) {
            return $http.post(requestUrl + '/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function remove(id) {
            return $http.get(requestUrl + '/remove?id=' + id).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function duplicate(id) {
            return $http.get(requestUrl + '/duplicate?id=' + id).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
    }
})();
