angular.module('plagiat2017App')
  .component('selfEnrollmentModal', {
    templateUrl: 'app/account/assignments/peer-review/add-to-library.template.html',
    controller: AddToLibraryController,
    controllerAs: 'vm'
  });

AddToLibraryController.$inject = ['ModalService', 'PeerReviewLibraryService', '$mdDialog', 'items'];

function AddToLibraryController(ModalService, PeerReviewLibraryService, $mdDialog, items) {
  var vm = this;

  vm.cancel = cancel;
  vm.addToLibrary = addToLibrary;
  vm.isLoading = false;
  vm.questions = [];
  vm.library = items.library;
  vm.checkedQuestions = {};

  this.$onInit = function () {
    vm.questions = items.questions;
    vm.selectAllQuestions();
  };

  vm.selectAllQuestions = function () {
    for (var i = 0; i < items.questions.length; i++) {
      vm.checkedQuestions[i] = true; // Pre-select the checkbox
    }
  };

  vm.deselectAllQuestions = function () {
    for (var i = 0; i < items.questions.length; i++) {
      vm.checkedQuestions[i] = false; // Pre-select the checkbox
    }
  };


  function addToLibrary(library) {
    vm.isLoading = true;
    const selectedQuestions = vm.getSelectedQuestions();
    PeerReviewLibraryService.addQuestions({id: library.id, name: library.name, questions: selectedQuestions}).then(function () {
      vm.isLoading = false;
      $mdDialog.hide(selectedQuestions);
    }, function (errorResponse) {
      vm.isLoading = false;
      ModalService.showErrorModal(errorResponse);
    });
  }

  vm.getSelectedQuestions = function() {
    var selectedQuestions = [];
    for (var i = 0; i < vm.questions.length; i++) {
      if (vm.checkedQuestions[i]) {
        selectedQuestions.push(vm.questions[i]);
      }
    }
    return selectedQuestions;
  };

  function cancel() {
    return $mdDialog.hide();
  }

}
