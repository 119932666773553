(function () {
    'use strict';

    function CodeInfoController($scope, CodeService) {
        var vm = this;
        vm.lessCharacter = '<';
        vm.availableCodesInfo = {};
        $scope.$on("refreshContract", function () {
            getAvailableCodesInfo();
        });

        function getAvailableCodesInfo() {
            CodeService.getAvailableCodeInfo().then(function (availableCodesResponse) {
                vm.availableCodesInfo = availableCodesResponse.data.availableCodes;
            });
        }

        getAvailableCodesInfo();
    }

    angular.module('plagiat2017App')
        .component('codeInfo', {
            templateUrl: 'app/components/code-info/code-info.template.html',
            controller: CodeInfoController,
            controllerAs: 'vm',
            bindings: {
                onIsCollapsed: '<'
            }
        });

})();
