(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['ClientService', 'ClientQueryRequest', 'SearchRequest', 'ClientSearchRequest',
    'SearchService', '$mdBottomSheet', 'TableSelectOptions', '$localStorage', 'tableSettingsRequest', 'ModalService',
    'ClientReportXlsService', '$q', 'AccountService', 'CLIENTTYPECONSTANT', 'CLIENT_STATUS', '$translate'];

    function ClientController(
        ClientService, ClientQueryRequest, SearchRequest, ClientSearchRequest,
        SearchService, $mdBottomSheet, TableSelectOptions, $localStorage, tableSettingsRequest, ModalService,
        ClientReportXlsService, $q, AccountService, CLIENTTYPECONSTANT, CLIENT_STATUS, $translate
    ) {
        var vm = this;

        vm.queryRequest = new ClientQueryRequest();
        vm.queryRequest.limit = $localStorage.clientListlimit ? $localStorage.clientListlimit : vm.queryRequest.limit;
        vm.searchRequest = new SearchRequest();
        vm.clientSearchRequest = new ClientSearchRequest();
        vm.options = new tableSettingsRequest();
        vm.selected = [];
        vm.pagingAction = pagingAction;
        vm.getClients = getClients;
        vm.getDetails = getDetails;
        vm.pickedClient = false;
        vm.showDetailsBlock = false;
        vm.clear = clear;
        vm.searchClients = searchClients;
        vm.tableSelectOptions = new TableSelectOptions();
        vm.selected = [];
        vm.deSelectRow = deSelectRow;
        vm.selectRow = selectRow;
        vm.toggleSelection = toggleSelection;
        vm.selectRows = selectRows;
        vm.openBottomSheet = openBottomSheet;
        vm.closeBottomSheet = closeBottomSheet;
        vm.generateReportXls = generateReportXls;
        vm.unfreezeClient = unfreezeClient;
        vm.freezeClient = freezeClient;
        vm.removeClient = removeClient;
        vm.getStatusDescription = getStatusDescription;
        vm.clientSettings = AccountService.getClientSettings();
        vm.clientTypeConstant = CLIENTTYPECONSTANT;
        vm.clientStatus = CLIENT_STATUS;
        vm.simpleSearch = true;
        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();

        this.$onInit = function () {
            if ($localStorage.clientSimpleSearchEnabled == true && $localStorage.clientSimpleSearchValue && $localStorage.clientSimpleSearchValue.length > 0) {
                vm.searchRequest.simpleSearch = $localStorage.clientSimpleSearchValue;
            }
            if ($localStorage.clientSearchRequest && $localStorage.clientSimpleSearchEnabled != true) {
                vm.simpleSearch = false;
                vm.clientSearchRequest = $localStorage.clientSearchRequest;
            }
            if ($localStorage.queryRequest) {
                vm.queryRequest = $localStorage.queryRequest;
            }

            getClients();
        };

        function prepareMassActions() {
            vm.deferred = $q.defer();
            vm.tableSelectOptions.ids.length = 0;
            vm.tableSelectOptions.selectedNo = vm.tableSelectOptions.selectedAll ? vm.tableSelectOptions.selectedNo : vm.selected.length;
            angular.forEach(vm.tableSelectOptions.selectedHelper, function (value) {
                vm.tableSelectOptions.ids.push(value.id);
            });
            vm.deferred.resolve(vm.tableSelectOptions);
            return vm.deferred.promise;
        }

        function toggleSelection(total) {
            if(!vm.tableSelectOptions.selectedAll) {
                selectRows(vm.users);
            }
            vm.tableSelectOptions.selectedAll = !vm.tableSelectOptions.selectedAll;
            vm.tableSelectOptions.selectedNo = total;
            if( !vm.tableSelectOptions.selectedAll ) {
                closeBottomSheet();
            }
        }

        function deSelectRow() {
            if( vm.selected.length === 0) {
                closeBottomSheet();
            }
        }

        function selectRow(pickedId) {
            if( vm.selected.length === 1) {
                openBottomSheet();
            }
            angular.forEach(vm.users, function (value) {
                if (value.id === pickedId) {
                    if (vm.tableSelectOptions.selectedHelper.indexOf(pickedId) === -1) {
                        vm.tableSelectOptions.selectedHelper.push(value);
                    }
                }
            });
        }

        function selectRows(obj) {
            angular.forEach(obj, function (value) {
                if (vm.selected.indexOf(value.id) === -1) {
                    vm.selected.push(value.id);
                }
            })
        }

        function resetSelection() {
            if(vm.tableSelectOptions.selectedAll) {
                vm.selected.length = 0;
                vm.tableSelectOptions = new TableSelectOptions();
                $mdBottomSheet.hide();
            }
        }

        function closeBottomSheet() {
                vm.selected.length = 0;
                vm.tableSelectOptions = new TableSelectOptions();
                $mdBottomSheet.hide();
        }

        function openBottomSheet() {
            $mdBottomSheet.show({
                templateUrl: 'app/client/bottom-sheet-grid-template.html',
                controllerAs: 'bootomSheet',
                parent: angular.element(document.getElementById('clients')),
                controller: function GridBottomSheetCtrl(){
                    var bootomSheet = this;
                    bootomSheet.checked = true;
                    bootomSheet.closeBottomSheet = vm.closeBottomSheet;
                    bootomSheet.selected = vm.selected;
                    bootomSheet.tableSelectOptions = vm.tableSelectOptions;
                    bootomSheet.total = vm.totalClients;
                    bootomSheet.toggleSelection = vm.toggleSelection;
                    bootomSheet.generateReportXls = vm.generateReportXls;
            },
            isLockedOpen: true,
            disableParentScroll: false,
            disableBackdrop: true
          });
        }

        function getClients(){
            vm.showDetailsBlock = false;
            vm.searchRequest.pageNumber = vm.queryRequest.page-1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            vm.searchRequest.search = vm.clientSearchRequest;

            $localStorage.clientSearchRequest = vm.clientSearchRequest;
            $localStorage.queryRequest = vm.queryRequest;

            vm.getOrSearch = vm.searchRequest.simpleSearch.length > 0 ? function (s) {
                return ClientService.searchClients(s);
            } : function (s) {
                return ClientService.getClients(s);
            };

            vm.isLoaderActive = true;
            vm.getOrSearch(vm.searchRequest).then(function (responseData) {
                vm.responseObj = responseData.content;
                vm.clients = vm.responseObj;
                vm.totalClients = responseData.totalElements;
                vm.isLoaderActive = false;
                vm.queryRequest.page = vm.searchRequest.pageNumber + 1;
            });
        }

        function searchClients() {
            vm.queryRequest.page = 1;
            getClients();
        }

        function pagingAction(page) {
            resetSelection();
        	vm.isLoaderActive = true;
            vm.queryRequest.page = page;
            getClients();
        }

        function getDetails(client) {
            vm.showDetailsBlock = false;
            vm.isLoaderActive = true;
            if (client.id !== ClientService.clientDetailsObj.id) {
                vm.pickedClient = client.id;
                ClientService.clientDetailsObj = client;
                ClientService.getClient(client.id).then(function (result) {
                    vm.showDetailsBlock = true;
                    vm.isLoaderActive = false;
                    vm.details = result.data;
                });
            } else {
                vm.isLoaderActive = false;
                ClientService.clientDetailsObj = {};
            }
        }

        function clear() {
            vm.clientSearchRequest = new ClientSearchRequest();
            getClients();
        }

        function generateReportXls(){
        	prepareMassActions().then(function(response){
        		ClientReportXlsService.generateReportXls(response, vm.selected, vm.searchRequest);
        	});
        }
    
        function removeClient(id) {
            var TRANSLATION_REMOVE_PREFIX = 'client.remove';
            ModalService.showConfirmModal(TRANSLATION_REMOVE_PREFIX).then( function (){
                ClientService.remove(id).then(function() {
                    getClients();
                    ModalService.showSuccessModal();
                });
            });
        }
    
        function freezeClient(id) {
            ModalService.showConfirmModal().then(function (){
                ClientService.freeze(id).then(function() {
                    getClients();
                    ModalService.showSuccessModal();
                });
            });
        }
    
        function unfreezeClient(id) {
            ModalService.showConfirmModal().then(function (){
                ClientService.unfreeze(id).then(function() {
                    getClients();
                    ModalService.showSuccessModal();
                });
            });
        }

        function getStatusDescription(client) {
            switch (client.clieStatus) {
                case vm.clientStatus.FROZEN.id:
                    return $translate.instant('client.status.frozen');
                case vm.clientStatus.REMOVAL_IN_PROGRESS.id:
                    return $translate.instant('client.status.removal-in-progress');
                case vm.clientStatus.REMOVAL_ERROR.id:
                    return $translate.instant('client.status.removal-error');
                default:
                    return '';
            }
        }

    }

})();
