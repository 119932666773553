(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('documentParameterDefinitionsAdd', {
            parent: 'account',
            url: '/document-parameter-definitions-add',
            data: {
            	authorities: ['PERM_USER_ADMIN', 'PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.document.parameter.definitions'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/document-parameter-definitions/document-parameter-definitions-form/document-parameter-definitions-form.html',
                    controller: 'DocumentParameterDefinitionsAddController',
                    controllerAs: 'vm'
                }
            }
        });
    }

})();
