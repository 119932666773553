angular.module('plagiat2017App').directive('translationCodeValidator', function ($http, $q, $timeout, TranslationsService) {
    return {
        restrict: "A",
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$asyncValidators.translationCodeExist = function (modelValue, viewValue) {
                var initialValue = scope.$eval(attrs.translationCodeValidator);
                if (viewValue == initialValue) {
                    return $q.resolve();
                }

                return TranslationsService.findOne(viewValue).then(
                    function (response) {
                        if (response.data && response.data.id) {
                            return $q.reject();
                        } else {
                            return $q.resolve();
                        }
                    }
                );
            };
            ngModel.$asyncValidators.conflictingExternalTranslationCode = function (modelValue, viewValue) {
                if (viewValue === scope.$eval(attrs.translationCodeValidator)) {
                    return $q.resolve();
                }
                return TranslationsService.hasConflictingExternalTranslationCodes(viewValue).then(
                    function (response) {
                        if (response.data) {
                            return $q.reject();
                        } else {
                            return $q.resolve();
                        }
                    }
                );
            };
        }
    };
});
