(function () {
    angular
        .module('plagiat2017App')
        .directive('autoFocus', function () {
            return {
                link: function (scope, elem) {
                    elem.focus();
                }
            }
        })
})();
