(function () {
    'use strict';

    function DocumentProtocolController($translate, DocumentProtocolService) {
        var vm = this;
        vm.getProtocolForDocument = getProtocolForDocument;

        function getProtocolForDocument(protocolForDocument) {
            var fileName = protocolForDocument.fileName + "_" + vm.document.docuTitle;
            DocumentProtocolService.generateProtocolForDocuments(
                vm.document.documentId,
                protocolForDocument.id,
                fileName
            );
        }
    }

    angular.module('documentProtocol', [])
    .component('documentProtocol', {
        templateUrl: 'app/account/documents/documents-list/document-protocol/document-protocol.template.html',
        controller: DocumentProtocolController,
        bindings: {
            document: '<',
            protocolForDocuments: '<'
        }
    });

})();
