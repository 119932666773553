(function () {
    'use strict';
    function ApiKeysButtonsController($translate, ApiKeysService, PlagiatValidationService, ModalService) {
        var vm = this;
        vm.arrIdis = [];
        vm.invalidate = invalidate;

        function invalidate(ev, id) {
        	var ids = [];
        	ids.push(id);
        	
            ModalService.showConfirmModal('global.confirmremove').then(function(){
                ApiKeysService.invalidate(ids).then(function () {
                    vm.onGetData();
                    
                }, function (response) {
                	PlagiatValidationService.getModalError(response.data, $translate.instant("global.messages.warning.label"));
                });
            });
        }
    }

    angular.module('apKeysButtons', [])
    .component('apKeysButtons', {
        templateUrl: 'app/account/settings/ap-keys/ap-keys-buttons/ap-keys-buttons.template.html',
        controller: ApiKeysButtonsController,
        controllerAs: 'vm',
        bindings: {
        	onGetData: '<',
        	onId: '<'
        }
    });

})();
