(function () {
    'use strict';
    angular.module('plagiat2017App').factory('UserReportXlsService',
        UserReportXlsService);

    UserReportXlsService.$inject = ['$http', '$q', 'FileSaver', 'PlagiatValidationService', '$mdDialog', '$translate'];

    function UserReportXlsService($http, $q, FileSaver, PlagiatValidationService, $mdDialog, $translate) {
        var service = {
            generateReportXls: generateReportXls,
            generateReportSendXls: generateReportSendXls
        };
        return service;

        function generateReportXls(response, selected, searchRequest){
            var sendingEmailInfo = $translate.instant("users.report.xls.sending.email.info");

            var confirm = $mdDialog.confirm()
                .title($translate.instant("global.confirm.action"))
                .htmlContent(sendingEmailInfo)
                .ariaLabel($translate.instant("client.areyousure"))
                .ok($translate.instant("global.report.export.xls"))
                .cancel($translate.instant("global.button.cancel"))
                .openFrom('#left');

            $mdDialog.show(confirm).then(function () {
                service.generateReportSendXls({ids: selected}, searchRequest, response.selectedAll).then(function() {
                    var generatingXlsMsg = $translate.instant("users.report.xls.report.generating.with.email.info1");
                    generatingXlsMsg += '<br/>';
                    generatingXlsMsg += $translate.instant("users.report.xls.report.generating.with.email.info2");
                    $mdDialog.show(
                        $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .title('')
                            .htmlContent(generatingXlsMsg)
                            .ariaLabel('ok')
                            .ok('OK')
                    );

                }, function(response) {
                    PlagiatValidationService.getModalError(response.data);
                });
            });
        }

        function generateReportSendXls(ids, searchRequest, selectedAll) {
            var url = selectedAll ? 'api/user/report/xls/search/send-xls': 'api/user/report/xls/selected/send-xls';
            var data = selectedAll ? searchRequest:ids;

            return $http({
                url : url,
                method : "POST",
                data : data,
                headers : {
                    'Content-type' : 'application/json'
                }
            })
        }

    }

})();
