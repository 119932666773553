(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .constant('SSO_LOGIN_DATA', function () {
            var constant = {
                other: {id: 0, title: 'Plagiat.pl ADFS'},
                valencia: {id: 1, title: 'Universitat de València'},
                sdu: {id: 2, title: 'University of Southern Denmark, SDU'},
                tartu: {id: 3, title: 'University of Tartu'},
                tallinn: {id: 4, title: 'Tallinn University'},
                tusofia: {id: 5, title: 'TU Sofia'},
                taltech: {id: 6, title: 'Tallinna Tehnikaülikool'},
                unibit: {id: 7, title: 'unibit.bg'},
            };

            return constant;
        }())
})();
