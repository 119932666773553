(function () {
  'use strict';

  function SelectLanguageController($scope, $rootScope, $mdDialog,
                                    JhiLanguageService, InstanceService, AccountService, 
                                    findLanguageFromKeyFilter, tmhDynamicLocale, USERSCHOOLTYPE) {
    var vm = this;
    vm.isIndividual = AccountService.role.isIndividual();
    vm.account = AccountService.account;
    
    function showSelectLanguageOnStart(){
    	$mdDialog.show({
	        templateUrl: 'app/components/select-language/select-language.template.html',
	        clickOutsideToClose: false,
	        escapeToClose: false,
            multiple: true,
	        controller: DialogController
    	});
    }

    if (vm.account.userFirstLoginDate 
            && vm.account.userLastLoginDate 
            && vm.account.userFirstLoginDate == vm.account.userLastLoginDate 
            && vm.account.accountType == USERSCHOOLTYPE.USER_SCHOOL_TYPE_TEACHER.value){
        showSelectLanguageOnStart();
    }
    
    function DialogController($scope, $mdDialog) {
        $scope.closeDialog = function() {
          $mdDialog.hide();
        };
        
        JhiLanguageService.getAll().then(function (allLanguages) {
            $scope.availableLanguages =  allLanguages.data.availableLanguages;
        });

        $scope.changeLanguage = function(languageKey) {
            vm.pickedLanguage = findLanguageFromKeyFilter(languageKey);
            tmhDynamicLocale.set(languageKey).then(function(){
                $translate.use(languageKey).then(function(){
                    $translate.fallbackLanguage('en');
                    $translate.preferredLanguage(languageKey);
                    $rootScope.$broadcast("langChanged");
                })
            });
        }

        $scope.setLang = function(languageKey){
            AccountService.setLang(languageKey);
        }
        
        $scope.langChangedOnStartBroadcast = function(){
        	$rootScope.$broadcast("langChangedOnStart");
        }
    }

  }

  angular.module('plagiat2017App')
  .component('selectLanguage', {
    templateUrl: 'app/components/select-language/select-language.template.html',
    controller: SelectLanguageController
  });

})();
