(function () {
    'use strict';

    function ViewTextController($rootScope, $mdDialog, DocumentService) {
        var vm = this;
        vm.closeDialog = closeDialog;
        vm.textToUpload = '';
        vm.uploadTextFlag = false;
        vm.documentId = DocumentService.document.id;

        function closeDialog() {
            $mdDialog.hide();
        }

        if( vm.documentId !== null ){
            getText(vm.documentId);
        }

        function getText(id) {
            DocumentService.getDocument(id).then(function (responseData) {
                vm.textToUpload = responseData.data.text;
                angular.element(function () {
                    vm.rendered = true;
                });
            });
        }

    }

    angular.module('plagiat2017App')
    .component('viewText', {
        templateUrl: 'app/account/documents/document-text/view-text/view-text.template.html',
        controller: ViewTextController,
        bindings: {
            onUploadIsActive: '=',
            onUploadObj: '='
        }
    });

})();
