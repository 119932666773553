(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('DocumentParameterDefinitionsAddController', DocumentParameterDefinitionsAddController);

    DocumentParameterDefinitionsAddController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'DocumentParameterDefinitionsService',
    	'InstanceService', '$state', '$cookies', '$stateParams', '$mdDialog', 'PlagiatValidationService'];

    function DocumentParameterDefinitionsAddController (Principal, Auth, JhiLanguageService, $translate, DocumentParameterDefinitionsService,
    		InstanceService, $state, $cookies, $stateParams,  $mdDialog, PlagiatValidationService) {
        var vm = this;
        
        vm.instance = InstanceService.instance().lang;
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.key = $stateParams.key;
        vm.selected = [];
        vm.save = save;
        vm.backToMainList = backToMainList;
        vm.dopadeTypeListener = dopadeTypeListener;
        vm.form = {
        		dopadeType: 0,
        		dopadeIsMandatoryBoolean: false,
        		comboBoxValues: []
        }
        
        vm.dopadeTypes = DocumentParameterDefinitionsService.getDopadeTypes();
        
        function backToMainList(){
        	$state.go('document-parameter-definitions');
        }
        
        function save(form, ev){
        	DocumentParameterDefinitionsService.add(form).then(function(){
        		backToMainList();
        		
        		$mdDialog.show(
                        $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("document.parameter.definition.form.new.msg"))
                        .ariaLabel('msgSuccess')
                        .ok('Ok')
                        .targetEvent(ev)
                        );
        	}, function(response){
        		PlagiatValidationService.getModalError(response.data);
        	})
        }

		function dopadeTypeListener(){
			if(vm.form){
				vm.form.dopadeDefault = undefined; 
			}
		}

    }
})();
