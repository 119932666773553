(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .factory('PeerReviewLibraryService', PeerReviewLibraryService);

  PeerReviewLibraryService.$inject = ['$http', '$q'];

  function PeerReviewLibraryService($http, $q) {

    var requestUrl = 'api/peer/libraries';

    var service = {
      create: create,
      update: update,
      delete: deleteLibrary,
      deleteLibraryQuestion: deleteLibraryQuestion,
      loadAll: loadAll,
      addQuestions: addQuestions,
      loadAllWithQuestions: loadAllWithQuestions,
    };
    return service;

    function addQuestions(obj) {
      return $http.post(requestUrl + '/add-questions', obj).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function loadAllWithQuestions() {
      return $http.get(requestUrl + '/with-questions').then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function loadAll() {
      return $http.get(requestUrl).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function create(obj) {
      return $http.post(requestUrl, obj).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function update(obj) {
      return $http.put(requestUrl, obj).then(
        function () {
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function deleteLibrary(id) {
      return $http.delete(requestUrl + '/' + id).then(
        function () {
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function deleteLibraryQuestion(id) {
      return $http.delete(requestUrl + '/questions/' + id).then(
        function () {
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

  }
})();
