(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ComparisionController', ComparisionController);

    ComparisionController.$inject = ['ComparisionService', 'ModalService', '$mdDialog'];

    function ComparisionController(ComparisionService, ModalService, $mdDialog) {
        var vm = this;
        vm.obj = {};
        vm.pending = false;

        vm.downloadComparisionReport = function () {
            vm.pending = true;
            ModalService.showPleaseWaitDialog(false);
            ComparisionService.downloadComparisionReport(vm.obj, 'report').then(
                function () {
                    vm.pending = false;
                }, function () {
                    $mdDialog.hide();
                    vm.pending = false;
                });
        }
    }
})();
