(function () {
    'use strict';
    
    PaymentsMenuController.$inject = ['AccountService'];

    function PaymentsMenuController(AccountService) {
        var vm = this;
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
    }

    angular.module('plagiat2017App')
    .component('paymentsMenu', {
        templateUrl: 'app/account/payments/payments-menu/payments-menu.template.html',
        controller: PaymentsMenuController,
        bindings: {
        	activeTab: '<'
        }
    });

})();
