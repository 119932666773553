(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .directive('jhSocial', jhSocial);

    jhSocial.$inject = ['$translatePartialLoader', '$translate', '$filter', 'SocialService', '$timeout', 'SSO_LOGIN_DATA'];

    function jhSocial($translatePartialLoader, $translate, $filter, SocialService, $timeout, SSO_LOGIN_DATA) {
        var directive = {
            restrict: 'E',
            scope: {
                provider: '@ngProvider',
                params: '@ngParams',
                disabled: '=ngDisabled',
                redirect: "@ngRedirect"
            },
            templateUrl: 'app/account/social/directive/social.html',
            link: linkFunc
        };

        return directive;

        /* private helper methods */

        function linkFunc(scope) {

            $translate.refresh();

            // scope.label = $filter('capitalize')(scope.provider);
            scope.titles = SSO_LOGIN_DATA;
            scope.providerSetting = SocialService.getProviderSetting(scope.provider);
            scope.providerURL = SocialService.getProviderURL(scope.provider, scope.params);
            scope.csrf = SocialService.getCSRF();
            if (scope.redirect) {
                $timeout(function () {
                    var $redirectFormContainer = angular.element('.login');
                    var $redirectForm = $redirectFormContainer.find('form');
                    $redirectForm.submit();
                });
            }

        }

    }
})();
