(function() {
	'use strict';

	angular.module('plagiat2017App').controller('PaymentsSuperadminController',
			PaymentsSuperadminController);

	PaymentsSuperadminController.$inject = [ '$localStorage', 'PaymentsQueryRequest', 'tableSettingsRequest',
		'PaymentsRequest', 'SearchService', 'PaymentsService', 'PlagiatValidationService', 'ModalService'];

	function PaymentsSuperadminController($localStorage, PaymentsQueryRequest, tableSettingsRequest,
			PaymentsRequest, SearchService, PaymentsService, PlagiatValidationService, ModalService) {
		var vm = this;
        vm.activeTab = 'paymentsSuperadmin';

		vm.queryRequest = new PaymentsQueryRequest();
        vm.queryRequest.limit = $localStorage.paymentsListlimit ? $localStorage.paymentsListlimit : vm.queryRequest.limit;
        vm.searchRequest = new PaymentsRequest();
        vm.options = new tableSettingsRequest();
        vm.downloadInvoice = downloadInvoice;
        vm.markAsCanceled = markAsCanceled;
		vm.getPayments = getPayments;
		vm.searchPayments = searchPayments;
		vm.pagingAction = pagingAction;
		vm.clear = clear;
		vm.simpleSearch = false;

        this.$onInit = function () {
            if ($localStorage.paymentsSuperadminSearch) {
                vm.searchRequest.search = $localStorage.paymentsSuperadminSearch;
            }
            if ($localStorage.paymentsSuperadminSimpleSearch) {
                vm.simpleSearch = $localStorage.paymentsSuperadminSimpleSearch;
            }

        }

        function searchPayments(){
            vm.queryRequest.page = 1;
            getPayments(); 
        }

        function getPayments(){
            vm.showDetailsBlock = false;
            vm.searchRequest.pageNumber = vm.queryRequest.page-1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            vm.isLoaderActive = true;

            $localStorage.paymentsSuperadminSimpleSearch = vm.simpleSearch;
            $localStorage.paymentsSuperadminSearch = vm.searchRequest.search;

            PaymentsService.getPaymentsForSuperadmin(vm.searchRequest).then(function (responseData) {
                vm.responseObj = responseData.content;
                vm.payments = vm.responseObj;
                vm.totalPayments = responseData.totalElements;
                vm.isLoaderActive = false;
            });
        }

        function pagingAction(page) {
        	vm.isLoaderActive = true;
            vm.queryRequest.page = page;
            vm.getPayments();
        }
		
        function searchPayments() {
            vm.queryRequest.page = 1;
            vm.getPayments();
        }

        function clear() {
        	vm.searchRequest = new PaymentsRequest();
            vm.getPayments();
        }
        
        function downloadInvoice(invoiceNumber) {
            PaymentsService.downloadInvoice(invoiceNumber).then(
                function () {},
                function (response) {
                    PlagiatValidationService.getModalError(response.data);
                });
        }
        
        function markAsCanceled(payment) {
            ModalService.showConfirmModal().then(function () {
                vm.isLoaderActive = true;
                PaymentsService.markAsCanceled(payment.providerTransactionId).then(
                    function () {
                        vm.getPayments();
                        ModalService.showSuccessModal();
                    },
                    function (response) {
                        PlagiatValidationService.getModalError(response.data);
                        vm.isLoaderActive = false;
                    });
            });
            
        }
        
        vm.getPayments();

	}
})();
