(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ClientGroupListController', ClientGroupListController);
    
    ClientGroupListController.$inject = ['ClientGroupsService', 'ModalService', 'SearchRequest', 'QueryRequest',
        'SIMPLE_SEARCH_DELAY', '$localStorage'];

    function ClientGroupListController(ClientGroupsService, ModalService, SearchRequest, QueryRequest,
                                    SIMPLE_SEARCH_DELAY, $localStorage) {
        var vm = this;
        vm.activeTab = 'clientGroups';
        vm.pending = false;
    
        vm.loadData = loadData;
        vm.loadPage = loadPage;
        
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.queryRequest = new QueryRequest();
        vm.searchRequest = new SearchRequest();
        vm.clientGroups = [];
        vm.clientGroupPage = {};
    
        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.clientGroupLimit ? $localStorage.clientGroupLimit : vm.queryRequest.limit;
            vm.searchRequest.sortTab = ['id,desc'];
            vm.loadData();
        };

        function loadData() {
            if (vm.clientGroupPage.number) {
                vm.loadPage(vm.clientGroupPage.number);
            } else {
                vm.loadPage(1);
            }
        }

        function loadPage(pageNumber) {
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            $localStorage.clientGroupLimit = vm.queryRequest.limit;
            vm.pending = true;
            ClientGroupsService.search(vm.searchRequest).then(function (clientGroupPage) {
                vm.pending = false;
                vm.clientGroupPage = clientGroupPage;
                vm.clientGroups = clientGroupPage.content;
                vm.clientGroupPage.number++;
                vm.queryRequest.page = vm.clientGroupPage.number;
            });
        }
    
    }
})();
