(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('InfoService', InfoService);

    InfoService.$inject = ['$http', '$q', '$log'];

    function InfoService($http, $q, $log) {


        var service = {
            sendForm: sendForm,
            getAdmins: getAdmins,
            dontAccept: dontAcceptIodoDataProcessing,
            getIodoCompanies:getIodoCompanies,
            operator: {
                sendForm: operatorSendForm
            }
        };

        return service;

        function dontAcceptIodoDataProcessing(obj){
            var request = {
                method: 'POST',
                url: 'api/iodo/dont-accept',
                data: obj,
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http(request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAdmins(){
            return $http.get('/api/account-admins').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getIodoCompanies(lang){
            return $http.get('/api/iodo/companies/' + lang).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function operatorSendForm(obj){
            var request = {
                method: 'POST',
                url: 'api/operator/contact-form',
                data: obj,
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http(request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function sendForm(obj) {
            var request = {
                method: 'POST',
                url: 'api/contact-form',
                data: obj,
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };
            return $http(request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }



})();
