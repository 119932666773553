(function () {
    'use strict';

    function DocumentButtonsReportController($rootScope, $state, $translate, PaymentsService,
        ReportDocumentService, $mdDialog, $window, $mdToast, TokenService, $log, DocumentService, 
        ContractService, PlagiatValidationService, JsaReportService, JSA_ORDER_STATUS, AccountService, ModalService, DOCUMENT_STATUS) {
        var vm = this;
        vm.getDocumentReport = getDocumentReport;
        vm.getDocumentReport2018 = getDocumentReport2018;
        vm.checkTokens = checkTokens;
        vm.checkTokensWithExtraOptions = checkTokensWithExtraOptions;
        vm.canAddPriority = canAddPriority;
        vm.showAddPriorityDialog = showAddPriorityDialog;
        vm.canAddPriorityFromContract = canAddPriorityFromContract;
        vm.canSubmitAssignment = canSubmitAssignment;
        vm.canSubmitAssignmentCorrection = canSubmitAssignmentCorrection;
        vm.canSubmitAssignmentPaidCorrection = canSubmitAssignmentPaidCorrection;
        vm.addPriorityFromContract = addPriorityFromContract;
        vm.checkDocument = checkDocument;
        vm.checkDocumentCodeAccount = checkDocumentCodeAccount;
        vm.getFullJsaReport = getFullJsaReport;
        vm.jsaOrderStatus = JSA_ORDER_STATUS;
        vm.checkDisabled = false;
        vm.DOCUMENT_STATUS = DOCUMENT_STATUS;


        vm.isCodeAccount = AccountService.clientType.isCodeAccount();
        vm.assignmentsPaidCorrectionsEnabled = AccountService.getClientSettings().assignmentsPaidCorrectionsEnabled;
        vm.shouldShowCodeAccountCheckModal = AccountService.clientType.isCodeAccount() && !AccountService.role.isAdmin();
        vm.maxAssignmentCorrections = AccountService.getClientSettings().maxAssignmentCorrections;
        vm.report2020Disabled = AccountService.getClientSettings().report2020Disabled;
        vm.isIndividual = AccountService.role.isIndividual();
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.userId = AccountService.account.id;

        function checkDocument(docId, codeId) {
            $mdDialog.show($mdDialog.confirm()
                .title($translate.instant("document.messages.checknow"))
                .textContent(vm.checkInfo)
                .ariaLabel(vm.checkInfo)
                .ok('Ok')
                .cancel($translate.instant("global.button.cancel"))
            ).then(function () {
                vm.checkDisabled = true;
                ModalService.showPleaseWaitDialog();
                DocumentService.operator.checkDocument(docId, codeId)
                    .then(function () {
                        ModalService.showSuccessModal("global.messages.info.actionsuccess");
                        vm.onReloadDocuments();
                        ContractService.broadcast();
                    }).catch(function (response) {
                        vm.checkDisabled = false;
                        PlagiatValidationService.getModalError(response.data);
                    });
            });
        }

        function checkTokens(docId, ev) {
            vm.checkDisabled = true;
    
            DocumentService.isWaitingPayment(docId).then(function (responseData) {
                vm.checkDisabled = false;
                var onDocumentWaitingPayment = responseData.data;
                
                if (onDocumentWaitingPayment) {
                    $mdDialog.show($mdDialog.confirm()
                        .title($translate.instant('document.messages.waiting-payment.title'))
                        .textContent($translate.instant('document.messages.waiting-payment'))
                        .ariaLabel('checkInfo')
                        .targetEvent(ev)
                        .ok($translate.instant('document.messages.waiting-payment.continue'))
                        .cancel($translate.instant('document.messages.waiting-payment.cancel'))
                    ).then(function () {
                        showExtraOptionsDialog();
                    });
                } else {
                    showExtraOptionsDialog();
                }
        
            });
        }
        
        function showExtraOptionsDialog() {   
            $mdDialog.show({
                controller: 'ExtraOptionsController',
                templateUrl: 'app/components/document/extra-options/extra-options.dialog.html',
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                locals: {
                    onSuccess: vm.checkTokensWithExtraOptions,
                    documentId: vm.onDocumentId,
                    documentSize: vm.onDocumentSize
                }
            });
        }

        function showAddPriorityDialog() {
            $mdDialog.show({
                controller: 'AddPriorityController',
                templateUrl: 'app/components/document/extra-options/add-priority.dialog.html',
                clickOutsideToClose: false,
                controllerAs: 'vm',
                multiple: true,
                locals: {
                    documentId: vm.onDocumentId,
                    documentSize: vm.onDocumentSize,
                    onReloadDocuments:  vm.onReloadDocuments
                }
            });
        }

        function canAddPriority() {
            var notTooLate = vm.onDocumentReleaseDate && (vm.onDocumentReleaseDate > new Date().getTime());
            return vm.onDocumentStatus == 0 && vm.isIndividual && notTooLate;
        }


        function addPriorityFromContract() {
            ModalService.showConfirmModal("document.add-priority-from-contract.dialog").then(
              function () {
                  DocumentService.addPriorityFromContract(vm.onDocumentId)
                    .then(function () {
                        ContractService.broadcast();
                        vm.onReloadDocuments();
                    })
                    .catch(function (response) {
                        PlagiatValidationService.getModalError(response.data);
                    });
              }
            )
        }

        function canAddPriorityFromContract() {
            return vm.onDocumentStatus === 0 && !vm.onDocumentPriorityAddedFromContract &&
              AccountService.role.isAdmin() &&
              AccountService.getClientSettings().addPriorityFromContractEnabled;
        }
    
        function checkTokensWithExtraOptions(newExtraOptions, totalPrice, availableTokens) {
            ModalService.showPleaseWaitDialog(true);
        
            var useTokenRequestDto = {
                documentId: vm.onDocumentId,
                documentExtraOptions: newExtraOptions
            };
            PaymentsService.useTokens(useTokenRequestDto).then(function (responseData) {
                TokenService.broadcastTokens();
                if (responseData.data.used === false) {
                    $mdDialog.hide(); //close please wait
                
                    var confirm = $mdDialog.confirm()
                        .title($translate.instant('global.confirm'))
                        .textContent($translate.instant('global.messages.error.notenough'))
                        .ariaLabel('global.messages.error.notenough')
                        .ok($translate.instant('document.buytokens'))
                        .multiple(true)
                        .cancel($translate.instant('document.metric.check-later'));
                    $mdDialog.show(confirm).then(function (result) {
                        buyTokens(totalPrice - availableTokens);
                    });
                    vm.pending = false;
                } else {
                    ModalService.showSuccessModal("global.messages.info.systemstart").then(function () {
                        vm.onReloadDocuments();
                    });
                }
            
            });
        }
    
        function buyTokens(tokensNeeded) {
            TokenService.buyTokens = true;
            TokenService.tokensNedded = tokensNeeded;
            $mdDialog.hide();
            $state.go('payments', {documentId: vm.onDocumentId});
        }
    
        function checkDocumentCodeAccount(documentId, size, isAssignment) {
            var checkDocumentCallback = function(codeDialogParameters) {
                var codeId = codeDialogParameters.codeId;
                return vm.checkDocument(documentId, codeId);
            };
            $mdDialog.show({
                templateUrl: 'app/account/documents/document-code-check/document-check-code.dialog.html',
                clickOutsideToClose: false,
                locals: {
                    items: {
                        documentId: documentId,
                        checkDocumentCallback: checkDocumentCallback,
                        isAssignment: vm.onDocument.assignmentId,
                        size: size
                    }
                },
                controllerAs: 'vm',
                controller: DialogController
            });
            function DialogController($scope, $mdDialog, items) {
                $scope.items = items;
            }
        }

        function getDocumentReport() {
            if (vm.isSuperAdmin) {
                if (!vm.onReport2020Disabled) {
                    openDocumentReport2020();
                } else {
                    getDocumentReport2018();
                }
            } else if (!vm.report2020Disabled) {
                openDocumentReport2020();
            } else {
                getDocumentReport2018();
            }
        }

        function openDocumentReport2020() {
            vm.onIsLoaderActive = true;
            ReportDocumentService.getReport2020RedirectionUrl(vm.onDocumentId).then(function(redirectionUrl){
                window.open(redirectionUrl, "_blank");
                vm.onIsLoaderActive = false;
            });
        }

        function getDocumentReport2018() {
            vm.onIsLoaderActive = true;
            ReportDocumentService.getDocumentReport2018(vm.onDocumentId, 'full', vm.onDocuAuthor).then(function(){
                vm.onIsLoaderActive = false;
            });
        }

        function getFullJsaReport() {
            vm.onIsLoaderActive = true;
            JsaReportService.getReport(vm.onDocumentId, true, vm.onDocuAuthor).then(function(){
                vm.onIsLoaderActive = false;
            }).catch(function(){
                vm.onIsLoaderActive = false;
            });
        }

        function canSubmitAssignment() {
            return vm.onDocument.assignmentId 
                && vm.onDocument.userId === vm.userId 
                && (vm.onDocumentStatus === DOCUMENT_STATUS.DRAFT.id);
        }

        // pokazujemy gdy w statusie DO POPRAWY i nie zostal jeszcze zaladowany poprawiony dokument, 
        // ale mozna jeszcze dodac bezplatny dokument
        function canSubmitAssignmentCorrection() {
            return vm.onDocument.assignmentId 
                && (vm.onDocument.userId === vm.userId)
                && (vm.onDocumentStatus === DOCUMENT_STATUS.EDITION_REQUIRED.id)
                && !(vm.onDocument.docuNextDocumentID)
                && (vm.onDocument.docuVersion && (vm.onDocument.docuVersion < vm.maxAssignmentCorrections) || (!vm.onDocument.docuVersion && (vm.maxAssignmentCorrections > 1)));
        }

        // pokazujemy gdy w statusie DO POPRAWY i nie zostal jeszcze zaladowany poprawiony dokument
        // nie mozna juz dodac bezplatnego dokumentu, ale mozna dodac platny
        function canSubmitAssignmentPaidCorrection() { //pokazujemy gdy w statusie DO POPRAWY i nie zostal jeszcze zaladowany poprawiony dokument
            return vm.onDocument.assignmentId 
                && (vm.onDocument.userId === vm.userId)
                && (vm.onDocumentStatus === DOCUMENT_STATUS.EDITION_REQUIRED.id)
                && !(vm.onDocument.docuNextDocumentID)
                && (vm.onDocument.docuVersion && (vm.onDocument.docuVersion >= vm.maxAssignmentCorrections) || (!vm.onDocument.docuVersion && (vm.maxAssignmentCorrections <= 1)))
                && vm.isCodeAccount
                && vm.assignmentsPaidCorrectionsEnabled;
        }

    }

    angular.module('documentButtonsReport', [])
        .component('documentButtonsReport', {
            templateUrl: 'app/account/documents/documents-list/document-buttons-report/document-buttons-report.template.html',
            controller: DocumentButtonsReportController,
            bindings: {
                onDocumentId: '<',
                onDocumentSize: '<',
                onDocumentReleaseDate: '<',
                onDocumentStatus: '=',
                onDocumentHasReport: '=',
                onReloadDocuments: '<',
                onIsLoaderActive: '=',
                onHasAlert: '<',
                onReport2020Disabled: '=',
                onDocuAuthor: '=',
                onDocumentHasJsaReport: "<",
                onDocumentJsaStatus: "<",
                onDocumentPriorityAddedFromContract: "<",
                onDocument: "<",
                onAvailableExtensions: "<",
                onSubmitAssignment: "<",
                onSubmitAssignmentCorrection: "<",
                onSubmitAssignmentPaidCorrection: "<",
            }
        });

})();
