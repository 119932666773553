(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('AuthServerProvider', AuthServerProvider);

    AuthServerProvider.$inject = ['$http', '$q', '$localStorage', '$sessionStorage'];

    function AuthServerProvider ($http, $q, $localStorage, $sessionStorage) {
        var service = {
            getToken: getToken,
            hasValidToken: hasValidToken,
            login: login,
            loginWithToken: loginWithToken,
            logout: logout,
            redirect: redirect
        };

        return service;

        function getToken () {
            var token = $localStorage.authenticationToken;
            return token;
        }

        function hasValidToken () {
            var token = this.getToken();
            return !!token;
        }

        function loginWithToken(jwt) {
            var deferred = $q.defer();

            if (angular.isDefined(jwt)) {
                this.storeAuthenticationToken(jwt);
                deferred.resolve(jwt);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function storeAuthenticationToken(jwt) {
                $sessionStorage.authenticationToken = jwt;
        }

        function redirect (credentials, url) {
             $.redirect('https://' + url + '/api/redirect', {
                 j_username: credentials.username,
                 j_password: credentials.password,
                 'remember-me': credentials.rememberMe
             }, "POST");
        }

        function login (credentials) {
            var data = 'j_username=' + encodeURIComponent(credentials.username) +
            '&j_password=' + encodeURIComponent(credentials.password) +
            '&remember-me=' + credentials.rememberMe + '&submit=Login';

            return $http.post('api/authentication', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function (response) {
                return response;
            });
        }

        function logout () {
            // logout from the server
            $http.post('api/saml/logout').then(function (response) {
                delete $localStorage.authenticationToken;
                delete $sessionStorage.role;
                // to get a new csrf token call the api
                $http.get('api/account');
                return {message:'Success'};
            },
            function (errResponse) {
                return $q.reject(errResponse);
            });
            
        }
    }
})();
