(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('SocialAuthController', SocialAuthController);

    SocialAuthController.$inject = ['$state', '$cookies', 'Auth', '$log'];

    function SocialAuthController($state, $cookies, Auth, $log) {
        var token = $cookies.get('social-authentication');
        Auth.loginWithToken(token).then(
            function () {
                $cookies.remove('social-authentication');
            }, function () {
                $state.go('social-register', {'success': 'false'});
            }
        );
    }
})();
