(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('DocumentKindsListController', DocumentKindsListController);
    
    DocumentKindsListController.$inject = ['DocumentKindsService', 'AccountService', 'ModalService', '$state'];

    function DocumentKindsListController(DocumentKindsService, AccountService, ModalService, $state) {
        var vm = this;
        vm.activeTab = 'documentKinds';
        vm.pending = false;
        vm.clientId = AccountService.account.clientId;
        vm.warningThresholdsEnabled = AccountService.account.clientSettings.warningThresholds;
        vm.dueDateEnabled = AccountService.account.clientSettings.documentKindDueDate;
        vm.warningThreshold1 = AccountService.account.clientSettings.warningThreshold1;
        vm.warningThreshold2 = AccountService.account.clientSettings.warningThreshold2;
    
        vm.loadData = loadData;
        vm.save = save;
        vm.editThresholds = editThresholds;

        vm.warningsKeyPress = warningsKeyPress;
        vm.warningsKeyUp = warningsKeyUp;
        vm.editWarnings = editWarnings;
        vm.cancelEditWarnings = cancelEditWarnings;
        vm.saveWarnings = saveWarnings;

        vm.editDueDates = editDueDates;
        vm.cancelEditDueDates = cancelEditDueDates;
        vm.saveDueDates = saveDueDates;

        vm.kinds = [];
        vm.warnings1 = [];
        vm.warnings2 = [];
        vm.warningId = undefined;

        vm.dueDates = [];
        vm.dueDateId = undefined;

        this.$onInit = function () {
            vm.loadData();
        };

        function loadData() {
            vm.pending = true;
            DocumentKindsService.load(vm.searchRequest).then(function (data) {
                vm.pending = false;
                for (var i = 0; i < data.length; i++) {
                    var dueDate = data[i].dueDate;
                    if (dueDate) {
                        data[i].dueDate = new Date(dueDate);
                    }
                    vm.dueDates[i] = data[i].dueDate;
                }
                vm.kinds = data;
            });
        }
    
        function save() {
            vm.isLoaderActive = true;
        
            var rows = [];
            angular.forEach(vm.kinds, function(row) {
                var item = {};
                item.documentKindId = row.documentKindId;
                item.enabled = row.enabled;
                item.label = row.label;
                rows.push(item);
            });
    
            DocumentKindsService.updateDocumentKinds({'clientId':vm.clientId, 'dtos': rows}).then(function () {
                vm.isLoaderActive = false;
                ModalService.showSuccessModal().then(
                    function () {
                        vm.loadData();
                    }, function () {
                        vm.loadData();
                    });
            }, function (errResponse) {
                PlagiatValidationService.getModalError(errResponse);
            });
        }
        
        function editThresholds(row) {
            $state.go('edit-thresholds', {documentKindId : row.documentKindId});
        }
        
        function warningsKeyPress(event){
            if (event.target.value.length >= event.target.maxLength || !(event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))){
                event.preventDefault();
            }
        }

        function warningsKeyUp(event){
            if (event.target.value.length >= event.target.maxLength){
                event.target.value = event.target.value.slice(0, event.target.maxLength);
            }
        }

        function resetWarnings() {
            if (vm.kinds){
                angular.forEach(vm.kinds, function(kind, index) {
                    vm.warnings1[index] = kind.warning1;
                    vm.warnings2[index] = kind.warning2;
                });
            }
        }

        function editWarnings(row, index, event) {
            vm.clickedElement = event.target;
            if (!vm.clickedElement) return;
            var elementClasses = vm.clickedElement.classList;
            vm.clickedCancelOrSave = elementClasses.contains('icon-save') || elementClasses.contains('icon-cancel');
            if (vm.clickedCancelOrSave){
                return;
            }
            vm.warningId = row.documentKindId;
        }

        function cancelEditWarnings(){
            vm.warningId = undefined;
            resetWarnings();
        }

        function saveWarnings(user, index){
            vm.warningsSaving = true;
            vm.form = {
                documentKindId: vm.warningId,
                warning1: vm.warnings1[index],
                warning2: vm.warnings2[index]
            };
            DocumentKindsService.saveThresholds(vm.form).then(function() {
                vm.warningId = undefined;
                vm.warningsSaving = false;
                vm.kinds[index].warning1 = vm.warnings1[index];
                vm.kinds[index].warning2 = vm.warnings2[index];
                vm.kinds[index].enabled = true;
            }, function(response){
                vm.warningId = undefined;
                vm.warningsSaving = false;
                resetWarnings();
                PlagiatValidationService.getModalError(response.data);
            })
        }

        function resetDueDates() {
            if (vm.kinds){
                angular.forEach(vm.kinds, function(kind, index) {
                    vm.dueDates[index] = kind.dueDate;
                });
            }
        }

        function editDueDates(row, index, event) {
            vm.clickedElement = event.target;
            if (!vm.clickedElement) return;
            var elementClasses = vm.clickedElement.classList;
            vm.clickedCancelOrSave = elementClasses.contains('icon-save') || elementClasses.contains('icon-cancel');
            if (vm.clickedCancelOrSave){
                return;
            }
            vm.dueDateId = row.documentKindId;
        }

        function cancelEditDueDates(){
            vm.dueDateId = undefined;
            resetDueDates();
        }

        function saveDueDates(user, index){
            vm.dueDatesSaving = true;
            var form = {
                documentKindId: vm.dueDateId,
                dueDate: vm.dueDates[index]
            };
            DocumentKindsService.saveDueDate(form).then(function() {
                vm.dueDateId = undefined;
                vm.dueDatesSaving = false;
                vm.kinds[index].dueDate = vm.dueDates[index];
                vm.kinds[index].enabled = true;
            }, function(response){
                vm.dueDateId = undefined;
                vm.dueDatesSaving = false;
                resetDueDates();
                PlagiatValidationService.getModalError(response.data);
            })
        }


    }
})();
