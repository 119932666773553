(function () {
    'use strict';

    IdentificationDataController.$inject = ['OrppdService'];

    function IdentificationDataController(OrppdService) {

        var vm = this;
        vm.peselDisabled = peselDisabled;
        vm.documentDataDisabled = documentDataDisabled;
        vm.isPesel = false;
        vm.countriesDictionary = [];
        vm.documentTypesDictionary = [];

        OrppdService.getCountriesDictionary().then(
            function (countriesDictionaryResult) {
                vm.countriesDictionary = countriesDictionaryResult.filter(function (dictValue) {
                    return dictValue.code !== '00';
                });
            }
        )

        OrppdService.getDocumentTypeDictionary().then(
            function (documentTypesDictionaryResult) {
                vm.documentTypesDictionary = documentTypesDictionaryResult.filter(function (dictValue) {
                    return dictValue.code !== 'PESEL';
                });
            }
        )

        function peselDisabled() {
            return vm.identificationDataForm.documentCountry || vm.identificationDataForm.documentType || vm.identificationDataForm.documentNumber;
        }

        function documentDataDisabled() {
            return vm.identificationDataForm.pesel;
        }
    }

    angular.module('plagiat2017App')
        .component('identificationData', {
            templateUrl: 'app/components/document/send-to-orppd/identification-data.component.html',
            controller: IdentificationDataController,
            controllerAs: 'vm',
            bindings: {
                identificationDataForm: '<',
            }
        });

})();
