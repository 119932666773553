(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DocumentParameterDefinitionsService', DocumentParameterDefinitionsService);

    DocumentParameterDefinitionsService.$inject = ['$http', '$q', '$translate'];

    function DocumentParameterDefinitionsService($http, $q, $translate) {

        var service = {
        	getDocumentParameterDefinitions: getDocumentParameterDefinitions,
            getAllDocumentParameterDefinitionsForClient: getAllDocumentParameterDefinitionsForClient,
        	getDopadeTypes: getDopadeTypes,
        	add: add,
        	edit: edit,
        	getForm: getForm,
        	remove: remove,
        	removeMultiple: removeMultiple
		}

        return service;

        function getDocumentParameterDefinitions(obj) {
        	return $http.post('/api/document-parameter-definitions/list', obj).then(
        			function (response) {
        				return response.data;
        			},
        			function (errResponse) {
        				return $q.reject(errResponse);
        			});
        }

        function getAllDocumentParameterDefinitionsForClient(clientId) {
        	return $http.get('/api/document-parameter-definitions/client/' + clientId).then(
        			function (response) {
        				return response.data;
        			},
        			function (errResponse) {
        				return $q.reject(errResponse);
        			});
        }

        function getDopadeTypes(){
            return [
            	{id:0, label:$translate.instant("document.parameter.definitions.dopade-type.text")},
            	{id:1, label:$translate.instant("document.parameter.definitions.dopade-type.enum")}
            ]
        }

        function add(obj) {
            return $http.put('api/document-parameter-definitions', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function edit(obj) {
            return $http.post('api/document-parameter-definitions', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getForm(parameterId) {
            return $http.get('api/document-parameter-definitions/' + parameterId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function remove(parameterId) {
            return $http.delete('api/document-parameter-definitions/' + parameterId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function removeMultiple(obj) {
            return $http.post('/api/document-parameter-definitions/remove-multiple', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
