(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('TableSelectOptions', function () {

        function TableSelectOptions() {
            this.selectedNo = 0;
            this.selectedHelper = [];
            this.selectedAll = false;
            this.ids = [];
        }

        return TableSelectOptions;
    });
})();