(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .factory('PeerReviewFeedbackService', PeerReviewFeedbackService);

  PeerReviewFeedbackService.$inject = ['$http', '$q'];

  function PeerReviewFeedbackService($http, $q) {

    var requestUrl = 'api/feedbacks';

    var service = {
      getList: getList,
      findOne: findOne,
      update: update,
      submit: submit,
      saveGrade: saveGrade,
      saveGrades: saveGrades,
      createBySelfSelect: createBySelfSelect,
    };
    return service;

    function getList(pageableFilter) {
      return $http.post(requestUrl + '/list', pageableFilter).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function findOne(id) {
      return $http.get(requestUrl + '/' + id).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function update(obj) {
      return $http.put(requestUrl, obj).then(
        function () {},
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function submit(obj) {
      return $http.put(requestUrl + '/submit', obj).then(
        function () {},
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function saveGrade(obj) {
      return $http.put(requestUrl + '/grade', obj).then(
        function () {},
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function saveGrades(reviewer) {
      return $http.put(requestUrl + '/grades', reviewer).then(
        function () {},
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function createBySelfSelect(feedback) {
      return $http.post(requestUrl + '/self-select', feedback).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

  }
})();
