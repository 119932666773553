(function () {
    'use strict';

    function ChangePasswordController($scope, $mdDialog, $translate, 
                                      AccountService, ModalService, DocumentErrors, PlagiatValidationService, Auth) {
        var vm = this;
        vm.closeDialog = closeDialog;
        vm.user = {};
        vm.saveData = saveData;
        vm.doNotMatch = false;
        vm.enforcePasswordChange = AccountService.forceChangePassword();
        vm.tooglePasswordVisibility = tooglePasswordVisibility;
        vm.user.password = '';
        vm.showPassword = false;
        vm.typeInput = 'password';
        vm.passwordPattern = Auth.getPasswordPattern();

        function tooglePasswordVisibility(){
            vm.typeInput = vm.showPassword ? 'text' : 'password';
        }

        function closeDialog() {
            $mdDialog.hide();
        }
        
        function saveData() {
            if (vm.user.newPassword !== vm.user.confirmedPassword) {
                vm.doNotMatch = true;
            } else {
                
                if (vm.user.newPassword == vm.user.password) {
                    ModalService.showErrorModal({data: {code: 111}}, true);
                } else {
                    AccountService.setNewPassword(vm.user)
                        .then(function () {
                            closeDialog();
                            vm.doNotMatch = false;
                            ModalService.showSuccessModal("global.messages.info.passwordchanged");
                        }, function (errorResponse) {
                            ModalService.showErrorModal(errorResponse, true);
                        });
                }
            }
        }

    }

    angular.module('plagiat2017App')
    .component('changePassword', {
        templateUrl: 'app/components/profile/change-password/change-password.template.html',
        controller: ChangePasswordController
    });

})();
