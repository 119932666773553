(function() {
    'use strict';

    function LangSelectController (AccountService, InstanceService, findLanguageFromKeyFilter, $scope, $rootScope, tmhDynamicLocale, 
        $stateParams, $translate, JhiLanguageService) {
    	var vm = this;

    	vm.isIndividual = AccountService.role.isIndividual();
        vm.isOperator = AccountService.role.isOperator();
        vm.isLecturer = AccountService.role.isLecturer();

        vm.changeLanguage = changeLanguage;
        vm.instanceLang = InstanceService.instance().lang;
        vm.setLang = setLang;
        vm.availableLanguages = null;
        vm.pickedLanguage = '';

        JhiLanguageService.getAll().then(function (allLanguages) {
            vm.availableLanguages = allLanguages.data.availableLanguages;
        });

        function changeLanguage (languageKey) {
            vm.pickedLanguage = findLanguageFromKeyFilter(languageKey);
            tmhDynamicLocale.set(languageKey).then(function(){
                $translate.use(languageKey).then(function(){
                    $translate.fallbackLanguage(languageKey);
                    $translate.preferredLanguage(languageKey);
                    $rootScope.$broadcast("langChanged");
                })
            });
        }

        function setLang() {
            if ($stateParams.lang) {
                $translate.use($stateParams.lang);
                tmhDynamicLocale.set($stateParams.lang);
            }
        }

        setLang();

    }

    angular.module('plagiat2017App')
    .component('langSelect', {
        templateUrl: 'app/components/lang-select/lang-select.template.html',
        controller: LangSelectController
    });

})();
