(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ActivateSetPasswordController', ActivateSetPasswordController);

    ActivateSetPasswordController.$inject = ['$stateParams', 'Auth', '$cookies', 'DetectInstanceService', 'AccountService'];

    function ActivateSetPasswordController ($stateParams, Auth, $cookies, DetectInstanceService, AccountService) {
        var vm = this;
        vm.conversionDomain = DetectInstanceService.getConversionAllowance();
        vm.theme = $cookies.get('plagiat-theme');
        vm.setPassword = setPassword;
        vm.toggleShowPassword = toggleShowPassword;
        vm.showPassword = false;
        vm.uniqueKeyValid = true;
        vm.passwordPattern = Auth.getPasswordPattern();
        
        Auth.activateAccountForCreatedUser({key: $stateParams.key}).then(function () {
            vm.error = null;
            vm.success = 'OK';
        }).catch(function (response) {
            if(response.data.code == 58){
            	vm.alreadyActivated = true;
            	vm.error = null;
                vm.success = 'OK';
                vm.passwordSetSuccess = false;
            }else if(response.data.code == 57){
            	vm.uniqueKeyValid = false;
            }else{
            	vm.success = null;
            	vm.error = 'ERROR';
            }
        });
        
        function setPassword() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.resetAccount.confirmedPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, password: vm.resetAccount.password, newPassword: vm.resetAccount.confirmedPassword}).then(function () {
                    vm.success = 'OK';
                    vm.passwordSetError=false;
                    vm.passwordSetSuccess=true;
                }).catch(function (response) {
                    vm.success = null;
                    vm.passwordSetSuccess=false;
                    vm.passwordSetError=true;
                    vm.error = 'ERROR';
                });
            }
        }

        function toggleShowPassword() {
            vm.showPassword = !vm.showPassword;
        }

    }
})();
