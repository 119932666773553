(function () {
    angular
        .module('plagiat2017App')
        .directive('selectPageSize', function () {
            return {
                restrict: 'E',
                scope: {
                    queryRequest: '=',
                    onValueChosen: '&'
                },
                templateUrl: 'app/components/select-page-size/select-page-size.template.html'
            }
        })
})();
