(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('comparision', {
            parent: 'app',
            url: '/comparision',
            data: {
                pageTitle: 'global.menu.account.comparision'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/comparision/comparision.html',
                    controller: 'ComparisionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                }
            },
            resolve: {
                translatePartialLoader: ['$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('comparision');
                        $translatePartialLoader.addPart('document');
                        return $translate.refresh();
                    }]
            }
        });
    }

})();
