(function () {
    'use strict';
    
    angular.module('plagiat2017App')
        .factory('FeedbackRequest', function () {
    
            function FeedbackRequest() {
                this.pageNumber = 0;
                this.pageSize = 5;
                this.sortTab = [];
                this.simpleSearch = '';
                this.search = {};
            }
    
            return FeedbackRequest;
        });
})();
