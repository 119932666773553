(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('DatabaseExchangeProgramMembersSearchRequest', function () {

        function DatabaseExchangeProgramMembersSearchRequest() {
            this.clieName = '';
            this.id = '';
            this.type = '';
            this.status = '';
        }

        return DatabaseExchangeProgramMembersSearchRequest;
    });
})();