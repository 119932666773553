(function() {
    'use strict';

    function ClientContractController(CONTRACTTYPECONSTANT) {
        var vm = this;

        vm.contractType = CONTRACTTYPECONSTANT;
        vm.toggleEdit = false;
        vm.createForthcomingContract = createForthcomingContract;
        vm.countPercentOfPlagReportsUsed = countPercentOfPlagReportsUsed;
        vm.countPercentOfAiDetectionUsed = countPercentOfAiDetectionUsed;
        vm.forthcomingContractFromDateChange = forthcomingContractFromDateChange;
        vm.contractToDateChange = contractToDateChange;
        vm.today = new Date();
        vm.disableForthcoming = disableForthcoming;
        this.$onInit = onInit;

        function onInit() {
            contractToDateChange();
            if(vm.client.forthcomingContract) {
                forthcomingContractFromDateChange();
            }
        }

        function disableForthcoming() {
            vm.client.forthcomingContract = undefined;
            vm.contractMaxDate = null;
            onInit();
        }

        function createForthcomingContract(){
            vm.client.forthcomingContract = {};
            if(vm.client.contract) {
                vm.client.forthcomingContract.contDateFrom = new Date(
                    addDays(vm.client.contract.contDateTo, 1));
                vm.client.forthcomingContract.contDateTo = new Date(
                    addDays(vm.client.forthcomingContract.contDateFrom, 365));
                vm.client.forthcomingContract.contNumOfPlagReports = vm.client.contract.contNumOfPlagReports;
                vm.client.forthcomingContract.contMaxDocPlagSize = vm.client.contract.contMaxDocPlagSize;
                vm.client.forthcomingContract.contType = vm.client.contract.contType;
                vm.contractToDateChange();
                vm.forthcomingContractFromDateChange();
            }
        }

        function contractToDateChange() {
            if (vm.client.contract) {
                vm.forthcomingContractFromMinDate = new Date(addDays(vm.client.contract.contDateTo, 1));
            } else {
                vm.forthcomingContractFromMinDate = null;
            }
        }

        function forthcomingContractFromDateChange() {
            vm.contractMaxDate = new Date(addDays(vm.client.forthcomingContract.contDateFrom, -1));
        }

        function addDays(date, days){
            var dateToChange = new Date(date);
            return dateToChange.setDate(dateToChange.getDate() + days);
        }

        function countPercentOfPlagReportsUsed(contract) {
            return (contract.contNumOfPlagReportsLeft && contract.contNumOfPlagReports) ? getFlooredFixed((((contract.contNumOfPlagReports - contract.contNumOfPlagReportsLeft) / contract.contNumOfPlagReports)) * 100, 2) + ' %' : '';
        }

        function countPercentOfAiDetectionUsed(contract) {
            return (contract.contNumOfAiDetectionLeft && contract.contNumOfAiDetection) ? getFlooredFixed((((contract.contNumOfAiDetection - contract.contNumOfAiDetectionLeft) / contract.contNumOfAiDetection)) * 100, 2) + ' %' : '';
        }

        function getFlooredFixed(v, d) {
            return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
        }

    }

    angular
    .module('plagiat2017App')
    .component(
        'clientContract',
        {
            templateUrl : 'app/client/client-add/steps/components/contract/contract.template.html',
            controller : ClientContractController,
            controllerAs: 'vm',
            bindings: {
                client: '=',
                onIsEdit: '='
            }
        }
    );
})();
