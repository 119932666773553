(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ClientGroupAddController', ClientGroupAddController);
    
    ClientGroupAddController.$inject = ['ClientGroupsService', 'ModalService',
        '$localStorage', '$state', '$stateParams'];

    function ClientGroupAddController(ClientGroupsService, ModalService,
        $localStorage, $state, $stateParams) {
        var vm = this;
        vm.pending = false;
        vm.activeTab = 'clientGroupList';
        
        vm.obj = {};
        vm.saveOrUpdate = saveOrUpdate;

        this.$onInit = function () {
            vm.pending = true;

            if ($stateParams.id) {
                ClientGroupsService.getClientGroupForEditing($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                });
            }
            vm.pending = false;
        };
    
        function saveOrUpdate() {
            ClientGroupsService
                .saveOrUpdate(vm.obj)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('client-group-list');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }

    }
})();
