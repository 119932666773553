(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('SearchService', SearchService);

    SearchService.$inject = ['$http', '$q'];

    function SearchService($http, $q) {


        var service = {
        	sort: sort,
        	getSomething: 7
        };

        return service;

        function sort(queryRequest) {
            var reverse = queryRequest.order.slice(0, 1);
            var _length = queryRequest.order.length;
            var predicate;
            if (reverse === '-') {
                predicate = queryRequest.order.slice(1, _length);
                reverse = false;
            } else {
                predicate = queryRequest.order;
                reverse = true;
            }
            var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
            if (predicate !== 'id') {
                result.push('id');
            }
            return result;
         }

    }


})();
