(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('suspect-reports', {
            parent: 'app',
            url: '/suspect-reports/d/:documentId',
            data: {
                authorities: [],
                pageTitle: 'global.menu.account.reports'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/pap/suspect-reports/suspect-reports.html',
                    controller: 'SuspectReportsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'docuSubmitDate,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil',
                    function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate(
                                $stateParams.sort),
                            ascending: PaginationUtil.parseAscending(
                                $stateParams.sort)
                        };
                    }],
                translatePartialLoader: ['$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reports');
                        $translatePartialLoader.addPart('document');
                        return $translate.refresh();
                    }]
            }
        })
            .state('suspect-reports-s', {
                parent: 'app',
                url: '/suspect-reports/s/:suspectId',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.account.reports'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pap/suspect-reports/suspect-reports.html',
                        controller: 'SuspectReportsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '0',
                        squash: true
                    },
                    sort: {
                        value: 'docuSubmitDate,asc',
                        squash: true
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil',
                        function ($stateParams, PaginationUtil) {
                            return {
                                page: PaginationUtil.parsePage($stateParams.page),
                                sort: $stateParams.sort,
                                predicate: PaginationUtil.parsePredicate(
                                    $stateParams.sort),
                                ascending: PaginationUtil.parseAscending(
                                    $stateParams.sort)
                            };
                        }],
                    translatePartialLoader: ['$translate',
                        '$translatePartialLoader',
                        function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('reports');
                            $translatePartialLoader.addPart('document');
                            return $translate.refresh();
                        }]
                }
            })
        ;
    }

})();
