(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('UserLoginHistoryService', UserLoginHistoryService);

    UserLoginHistoryService.$inject = ['$http', '$q'];

    function UserLoginHistoryService($http, $q) {

        var requestUrl = 'api/user-login-history';

        var service = {
            findAllByUser: findAllByUser,
        };
        return service;

        function findAllByUser(searchRequest) {
            return $http.post(requestUrl, searchRequest).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }
})();
