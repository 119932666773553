(function() {
	'use strict';

	function ProfileFormOperatorController($scope, $mdDialog, AccountService, $log, OrganisationalUnitsService) {
		var vm = this;
		vm.closeDialog = closeDialog;
		vm.user = {};
		vm.saveDataDetails = saveDataDetails;
		
		function initForm() {
			AccountService.getLoggedUserDetails().then(function(success) {
				vm.user = success.data;
			})
		}

		initForm();

		function closeDialog() {
			$mdDialog.hide();
		}
		
		OrganisationalUnitsService.findAllForClient().then(function(response) {
			vm.organisationalUnits = response.data;
		});

		function saveDataDetails() {
			AccountService
					.setDataDetails(vm.user)
					.then(
							function(responseData) {

								$mdDialog.hide();
								AccountService.account.firstName = vm.user.userName;
								AccountService.account.lastName = vm.user.userSurname;
								AccountService.account.userWantsNotifications = vm.user.userWantsNotifications;
							});
		}

	}

	angular
			.module('plagiat2017App')
			.component(
					'profileFormOperator',
					{
						templateUrl : 'app/components/profile/profile-form-operator/profile-form-operator.template.html',
						controller : ProfileFormOperatorController
					});

})();
