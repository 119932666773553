(function() {
	'use strict';

	function DocumentDetailsReportsController($scope, $log, DOCUMENT_STATUS) {
		var vm = this;
		vm.DOCUMENT_STATUS = DOCUMENT_STATUS;
	}

	angular
	.module('plagiat2017App')
	.component(
		'documentDetailsReports',
		{
			templateUrl : 'app/account/pap/reports/document-details-reports/document-details-reports.template.html',
			controller : DocumentDetailsReportsController,
			bindings: {
				onDocument: '<',
				onDocumentDetails: '<',
			}
		});

})();
