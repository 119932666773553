(function() {
	'use strict';
	angular.module('plagiat2017App').factory('DocumentKindService',
			DocumentKindService);

	DocumentKindService.$inject = [ '$http', '$q' ];

	function DocumentKindService($http, $q) {
		var service = {
			findAll : findAll
		};
		return service;

		function findAll(editedDocumentId) {
			var url = 'api/document-kinds';
			if(editedDocumentId){
				url += '?editedDocumentId=' + editedDocumentId;
			}
			return $http.get(url).then(
					function(response) {
						return response.data;
					}, function(errResponse) {
						return $q.reject(errResponse);
					});
		}

	}

})();
