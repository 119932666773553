(function () {
    'use strict';

    function DocumentsUniversalRoleController(DocumentSearchRequestModel, AccountService, $log, $q, _, $localStorage, $timeout,
        UserService, QueryRequest, DocumentService, DateService, SearchService, SearchRequest, $document, ClientService,
                                              ClientQueryRequest, SIMPLE_SEARCH_DELAY, JSA_ORDER_STATUS, ORPPD_STATUS) {
        var vm = this;
        vm.documentsSearchRequest = new DocumentSearchRequestModel();
        if (DocumentService.searchObject) {
            vm.documentsSearchRequest = DocumentService.searchObject;
            DocumentService.searchObject = null;
        }
        vm.getDocuments = getDocuments;
        vm.fixDateDcouments = DateService.fixDateDcouments;
        vm.simpleSearch = true;
        vm.toogleSearch = toogleSearch;
        vm.userCantSeeTheDocumentsOfOthers = AccountService.getPermision('PERM_USER_CANT_SEE_THE_DOCUMENTS_OF_OTHERS');
        setShowOnlyForUser(angular.isUndefined($localStorage.showOnlyForUser) ? true : $localStorage.showOnlyForUser);
        vm.setUserSearchSetting = setUserSearchSetting;
        vm.searchRequest = new SearchRequest();
        vm.queryRequest = new QueryRequest();
        vm.clientQueryRequest = new ClientQueryRequest();
        vm.queryRequest.limit = $localStorage.documentsListLimit ? $localStorage.documentsListLimit : vm.queryRequest.limit;
        vm.clear = clear;
        vm.searchTextChange = searchTextChange;
        vm.selectedUserChange = selectedUserChange;
        vm.selectedClientChange = selectedClientChange;
        vm.querySearch = querySearch;
        vm.jsa = AccountService.getClientSettings().jsaIntegration;
        vm.orppdIntegration = AccountService.getClientSettings().orppdIntegration;
        vm.clientSettings = AccountService.getClientSettings();
        vm.ORPPD_STATUS = ORPPD_STATUS;
        vm.clientTypePublishingHouse = AccountService.clientType.isPublishingHouse();
        vm.clientTypeCodeAccount = AccountService.clientType.isCodeAccount();
        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();
        vm.isCoordinatorVisible = isCoordinatorVisible;
        vm.canUserSearchCoordinator = canUserSearchCoordinator;
        vm.querySearchClients = querySearchClients;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.shouldShowAuthor = !vm.onIsStudent;
        vm.docuCoordinatorTranslationKey = AccountService.clientType.isSchool() ? 'document.metric.client-type.school.teacher' : 'document.model.docu-coordinator';
        vm.isLoggedUserOrgUnitAdmin = AccountService.role.isOrgUnitAdmin();
        vm.JSA_ORDER_STATUS = JSA_ORDER_STATUS;
        vm.clientRegions = [];
        
        this.$onInit = function () {
            if (vm.onIsSuperAdmin) {
                ClientService.getClientRegions().then(
                    function (success) {
                        vm.clientRegions = success.data;
                    });

                ClientService.getClientCountries().then(
                    function (success) {
                        vm.countries = success.data;
                    });
            }
        };

        function querySearchClients(query) {
                vm.searchRequest.pageNumber = 0;
                vm.searchRequest.pageSize = 20;
                vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
                vm.searchRequest.search = query;
                vm.searchRequest.simpleSearch = query;

                return ClientService.searchClients(vm.searchRequest).then(function(response) {
                    return response.content;
                });
        }

        function prepareGetDocumentsMapping(){
            vm.onGetDocuments(vm.documentsSearchRequest);
        }

        function simpleSearch(){
            vm.onGetDocumentsSimpleSearch(vm.documentsSearchRequest);
        }

        function verifyOrganisationaUnitId(){
            setShowOnlyForUser((AccountService.account.organisationalUnitId === 0) ? true : vm.documentsSearchRequest.showOnlyForUser);
        }

        function getDocuments(){
            $localStorage.documentsListLimit = vm.onQueryRequest.limit;
            if( DocumentService.searchUser ) {
              vm.simpleSearch = false;
              vm.selectedUser = DocumentService.searchUser;
            }
            if( DocumentService.searchClient ) {
                vm.simpleSearch = false;
                vm.selectedClient = DocumentService.searchClient;
            }
            vm.documentsSearchRequest.simpleSearch.length === 0 ? prepareGetDocumentsMapping() : '';
            vm.documentsSearchRequest.simpleSearch.length > 2 ? simpleSearch() : '';
        }

        function setUserSearchSetting(){
            vm.onResetSelection();
            $localStorage.showOnlyForUser = vm.documentsSearchRequest.showOnlyForUser;
            if( vm.documentsSearchRequest.showOnlyForUser ){
                vm.documentsSearchRequest.searchUser = null;
            }
            verifyOrganisationaUnitId();
            getDocuments();
        }

        function toogleSearch() {
            vm.onResetSelection();
            vm.simpleSearch ? vm.documentsSearchRequest.simpleSearch = '' : '';
            vm.simpleSearch = !vm.simpleSearch;
        }

        function clear() {
            vm.documentsSearchRequest = new DocumentSearchRequestModel();
            vm.selectedClient = undefined;
            vm.selectedUser = undefined;
            getDocuments();
        }

        function searchTextChange(query){

        }

        function selectedClientChange(item) {
            if (item) {
                setShowOnlyForUser(false);
                vm.documentsSearchRequest.searchClient = item.id;
            } else {
                vm.documentsSearchRequest.searchClient = null;
            }
            vm.getDocuments();
        }

        vm.querySearchOrganizations = function () {
            return vm.searchOrganizationText ? vm.onSearchData.orgUnitInfo.filter(function (org) {
                return org.orguniLabel.toLowerCase().includes(vm.searchOrganizationText.toLowerCase());
            }) : vm.onSearchData.orgUnitInfo;
        };

        vm.selectedOrganizationChange = function (item) {
            if (item) {
                vm.documentsSearchRequest.organisationalUnit = item.id;
            } else {
                vm.documentsSearchRequest.organisationalUnit = null;
            }
            vm.getDocuments();
        };

        function selectedUserChange(item) {
            if( angular.isDefined(item) ){
                setShowOnlyForUser(false);
                vm.documentsSearchRequest.searchUser = item.id;
            }
            else{
                vm.documentsSearchRequest.searchUser = null;
            }
            vm.getDocuments();
        }

        function lowercase(string) {
            return angular.isString(string) ? string.toLowerCase() : string;
        }

        function querySearch(query) {
            var results = {};

            if(query.length > 2){
	        	vm.searchRequest.pageNumber = 0;
	            vm.searchRequest.pageSize = 20;
	            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
	            vm.searchRequest.search = query;
	            vm.searchRequest.simpleSearch = query;

	            return UserService.searchUsers(vm.searchRequest).then(function(responseData) {
	            	vm.users = responseData.content;
	            	console.log('users=' + vm.users);
	            	results = _.filter(vm.users, function(o) { return (lowercase(o.userName).indexOf(lowercase(query)) && lowercase(o.userSurname).indexOf(lowercase(query)) && lowercase(o.userEmail).indexOf(lowercase(query)) ) !== -1 ; });
	            	console.log(
	            	    'results=' + results
                    );
	                return results;
	            })
            }else {
            	var deferred = $q.defer();
            	$timeout(function(){
            		deferred.resolve([]);
            	}, 1200, false);
            	return deferred.promise;
            }
        }

        $timeout(function() {
            getDocuments();
            verifyOrganisationaUnitId();
        }, 1);

        function setShowOnlyForUser(value){
            if(vm.userCantSeeTheDocumentsOfOthers && vm.onIsOperator){
            	vm.documentsSearchRequest.showOnlyForUser = true;
            	$localStorage.showOnlyForUser = true;
            	vm.hideShowOnlyForUserOption = true;
            }else if(vm.onIsSuperAdmin){
            	vm.documentsSearchRequest.showOnlyForUser = false;
            	$localStorage.showOnlyForUser = false;
            	vm.hideShowOnlyForUserOption = false;
            }else{
            	vm.documentsSearchRequest.showOnlyForUser = value;
            	$localStorage.showOnlyForUser = value;
            }
        }

        function disableInputNumberDefaultBehavior() {
        	var inputs = Array.from(document.querySelectorAll("#wp1FromInput, #wp1ToInput, #wp2FromInput, #wp2ToInput"));
        	inputs.forEach(function(input) {
        		input.addEventListener("wheel", function (event) {
        			event.preventDefault();
        		});
        	});
        }
        disableInputNumberDefaultBehavior();

        function isCoordinatorVisible() {
            return !vm.clientTypePublishingHouse;
        }

        function canUserSearchCoordinator() {
            if(vm.onIsLecturer) {
                return AccountService.getClientSettings().userTeacherCanChangePromoterDuringAddDocument;
            } else {
                return true;
            }
        }

    }

    angular.module('plagiat2017App')
    .component('documentsSearchUniversal', {
        templateUrl: 'app/account/documents/documents-search/documents-search.template.html',
        controller: DocumentsUniversalRoleController,
        bindings: {
            onGetDocuments: '<',
            onGetDocumentsSimpleSearch: '<',
            onQueryRequest: '=',
            onIsStudent: '<',
            onIsLecturer: '<',
            onIsOperator: '<',
            onIsIndividual: '<',
            onIsAdmin: '<',
            onIsSuperAdmin: '<',
            onSearchData: '=',
            onGetUserDocuments: '<',
            onResetSelection: '<'
        }
    });
})();
