(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('translations', {
            parent: 'app',
            url: '/translations',
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.account.translations'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/translations/translations.html',
                    controller: 'TranslationsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'userName,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil',
                    function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate(
                                $stateParams.sort),
                            ascending: PaginationUtil.parseAscending(
                                $stateParams.sort)
                        };
                    }],
                translatePartialLoader: ['$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('translations');
                        $translatePartialLoader.addPart('document');
                        return $translate.refresh();
                    }]
            }
        })
        .state("translations-edit", {
            url: "/:code/edit",
            parent: "translations",
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'translations.edit.update-title'
            },
            onEnter: [
                "$mdDialog", "$state", '$stateParams',
                function ($mdDialog, $state, $stateParams) {
                    $mdDialog.show({
                        controller: 'TranslationEditController',
                        controllerAs: 'vm',
                        templateUrl: 'app/account/translations/translation-edit.html',
                        clickOutsideToClose: false,
                        locals: {
                            code: $stateParams.code
                        },
                    }).then(function () {
                        $state.go("translations");
                    }, function () {
                        $state.go("translations");
                    });
                }
            ]
        })
        .state("translationHistory", {
            url: "/history?code&lang",
            parent: "translations",
            data: {
                pageTitle: 'translations.history.title'
            },
            onEnter: [
                "$mdDialog", "$state", '$stateParams',
                function ($mdDialog, $state, $stateParams) {
                    $mdDialog.show({
                        controller: 'TranslationHistoryController',
                        controllerAs: 'vm',
                        templateUrl: 'app/account/translations/translation.history.template.html',
                        clickOutsideToClose: true,
                        locals: {
                            code: $stateParams.code,
                            lang: $stateParams.lang
                        }
                    }).then(function () {
                        $state.go("translations");
                    }, function () {
                        $state.go("translations");
                    });
                }
            ]
        })
        .state("translations-create", {
            url: "/create",
            parent: "translations",
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'translations.edit.create-title'
            },
            onEnter: [
                "$mdDialog", "$state",
                function ($mdDialog, $state) {
                    $mdDialog.show({
                        controller: 'TranslationEditController',
                        controllerAs: 'vm',
                        templateUrl: 'app/account/translations/translation-edit.html',
                        clickOutsideToClose: false,
                        locals: {
                            code: null
                        },
                    }).then(function () {
                        $state.go("translations");
                    }, function () {
                        $state.go("translations");
                    });
                }
            ]
        })
        .state("translations-import", {
            url: "/import",
            parent: "translations",
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'translations.import.import-title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/translations/translation-import.html',
                    controller: 'TranslationImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate',
                    '$translatePartialLoader',
                    function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('translations');
                        $translatePartialLoader.addPart('document');
                        return $translate.refresh();
                    }]
            }
        });
    }

})();
