(function() {
	'use strict';
	function UsersButtonsController($state, $translate, $log, $mdDialog, $window, $localStorage,
									UserService, ModalService, Auth, PlagiatValidationService, AccountService) {

		var vm = this;
		vm.arrIdis = [];
		vm.hibernateUser = hibernateUser;
		vm.activateUser = activateUser;
		vm.removeUser = removeUser;
		vm.remindPassword = remindPassword;
		vm.grantMainAdminRights = grantMainAdminRights;
		vm.revokeMainAdminRights = revokeMainAdminRights;
		vm.loginAs = loginAs;
        vm.showHistory = showHistory;
        vm.showAddFreeTokensForm = showAddFreeTokensForm;
        vm.onUserAdmin = onUserAdmin;
        vm.canEditRegions = canEditRegions;
		vm.isReseller = AccountService.role.isReseller();
		vm.isMinistry = AccountService.role.isMinistry();

        vm.editUser = function() {
			$state.go('userEdit', {
				user : {
					id: vm.onUser.id,
					userEmail: vm.onUser.userEmail,
					userName: vm.onUser.userName,
					role: vm.onUser.userSchoolType,
					userSurname: vm.onUser.userSurname,
					academicTitle: vm.onUser.academicTitle,
					organisationalUnitId: vm.onUser.organisationalUnitId,
                    userQueryCounter: vm.onUser.userQueryCounter,
                    userQueryCounterType: vm.onUser.userQueryCounterType,
					userPhone: vm.onUser.userPhone,
                    polonEmployeeId: vm.onUser.polonEmployeeId,
                    userClientType: vm.onUser.userClientType
				},
				canEditMail: !vm.onUser.mainAdmin || vm.onLoggedUserIsSuperAdmin
			});
		};
        
        function canEditRegions() {
        	return vm.onUser.userSchoolTypeEnum == 'USER_SCHOOL_TYPE_SUPERADMIN'
				|| vm.onUser.userSchoolTypeEnum == 'USER_SCHOOL_TYPE_RESELLER'
				|| vm.onUser.userSchoolTypeEnum == 'USER_SCHOOL_TYPE_MINISTRY';
		}

		function hibernateUser(ev){
			$mdDialog.show($mdDialog.confirm()
				.title($translate.instant("global.confirm.action"))
				.targetEvent(ev)
				.ok($translate.instant("global.button.confirm"))
				.cancel($translate.instant("global.button.cancel"))
				).then(function () {
					UserService.verifyHibernateUsers({ids: [vm.onUser.id]}, {}, false).then(function () {
						vm.onGetUsers();
					});
				});
		}

		function activateUser(ev){
			$mdDialog.show($mdDialog.confirm()
				.title($translate.instant("global.confirm.action"))
				.targetEvent(ev)
				.ok($translate.instant("global.button.confirm"))
				.cancel($translate.instant("global.button.cancel"))
				).then(function () {
					UserService.activateUsers({ids: [vm.onUser.id]}).then(function () {
						vm.onGetUsers();
					});
				});
		}

		function removeUser(ev){
			$mdDialog.show($mdDialog.confirm()
				.title($translate.instant("global.confirm.action"))
				.targetEvent(ev)
				.ok($translate.instant("global.button.remove"))
				.cancel($translate.instant("global.button.cancel"))
				).then(function () {
					UserService.verifyRemoveUsers({ids: [vm.onUser.id]}, {}, false).then(function(){
						ModalService.showSuccessModal("users.message.removed", ev);
						vm.onGetUsers();
					});
				});

		}

		function onUserAdmin(){
			return AccountService.userRole.isUserAnAdmin(vm.onUser);
		}

		function remindPassword(ev){
            Auth.resetPasswordInit({email:vm.onUser.userEmail}).then(
            		function () {
            			ModalService.showSuccessModal("users.reset.request.success", ev);
            		},
            		function (response) {
            			PlagiatValidationService.getModalError(response.data);
            		}
            );
		}
		
        function grantMainAdminRights(userId, ev){
        	UserService.grantMainAdminRights(userId).then(
            		function () {
            			vm.onGetUsers();
            			ModalService.showSuccessModal("users.grant.main.admin.rights.success", ev);
            		},
            		function (response) {
            			PlagiatValidationService.getModalError(response.data);
            		}
            );
        }
        
        function revokeMainAdminRights(userId, ev){
        	UserService.revokeMainAdminRights(userId).then(
            		function () {
            			vm.onGetUsers();
                        $mdDialog.show(
                                $mdDialog.confirm()
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent($translate.instant("users.revoke.main.admin.rights.success"))
                                .ariaLabel('msgSuccess')
                                .ok('Ok')
                                .targetEvent(ev)
                                );
            		},
            		function (response) {
            			PlagiatValidationService.getModalError(response.data);
            		}
            );
        }

        function loginAs(username, ev){
        	UserService.loginAs(username).then(function(){
        		loginCallback();
        	},
        	function(){
                ModalService.showSuccessModal("global.operation.failed", ev);
        	});
        }
        
        var loginCallback = function(){
    		$localStorage.showLogoutToRootBtn = true;
    		$localStorage.logoutToRootInfo = AccountService.account.login;
    		$localStorage.backUrlSuperAdmin = $state.href();
            $localStorage.userSearchRequest = angular.copy(vm.onSearchRequest);
            window.location.replace("/");
        };
        
        function showHistory(ev) {
            UserService.user.id = vm.onUser.id;
            $mdDialog.show({
                templateUrl: 'app/account/users/user-history/user-history.dialog.html',
                targetEvent: ev,
                clickOutsideToClose: false
            }).then(function () {
                vm.onGetUsers();
            });
        }
        
        function showAddFreeTokensForm(ev) {
            UserService.user.id = vm.onUser.id;
            $mdDialog.show({
                templateUrl: 'app/account/users/add-free-tokens/add-free-tokens.dialog.html',
                targetEvent: ev,
                clickOutsideToClose: false
            }).then(function () {
            	ModalService.showSuccessModal("users.add.free.tokens.success", ev);
                vm.onGetUsers();
            });
        }

	}

    angular.module('usersButtons', [])
    .component('usersButtons', {
        templateUrl: 'app/account/users/users-list/users-buttons/users-buttons.template.html',
        controller: UsersButtonsController,
        bindings: {
        	onUser: '=',
        	onGetUsers: '<',
        	onLoggedUserIsSuperAdmin: '<',
        	onLoggedUserIsMainSuperAdmin: '<',
        	onLoggedUserIsMainAdmin: '<',
        	onLoggedUserIsOrgUnitAdmin: '<',
        	onLoggedUserIsAdmin: '<',
            onSearchRequest: '<'
        }
    });

})();
