(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('CodeModel', function () {
            function model() {
                this.transactionNumber; // String
                this.userId; // Integer
                this.organizationId; // Integer
                this.clientId; // Integer
                this.userFirstName; // String
                this.userLastName; // String
                this.userRole; // Integer
                this.userEmailAddress; // String
                this.codeType; // String
                this.paymentSystem; // String
                this.paymentDate; // Date
                this.dateOfUse; // Date
                this.status; // Integer
            }

            return model;
        });
})();
