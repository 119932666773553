(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('AssignmentsSubmitController', AssignmentsSubmitController);

    AssignmentsSubmitController.$inject = ['PlagiatValidationService', 'ModalService', 'DocumentService', 'AssignmentsService', 'LandingService', 'Utils',
                                           '$localStorage', '$state', '$stateParams', '$mdDialog', '$translate'];

    function AssignmentsSubmitController(PlagiatValidationService, ModalService, DocumentService, AssignmentsService, LandingService, Utils,
                                         $localStorage, $state, $stateParams, $mdDialog, $translate) {
        var vm = this;
        vm.submits = [];
        vm.pending = false;
        vm.activeTab = 'assignments';
        vm.getTheFiles = getTheFiles;
        vm.uploadFile = uploadFile;
        vm.backToList = backToList;
        vm.save = save;
        vm.remove = remove;
        vm.assignment = {};
        vm.errorFilenames = '';

        this.$onInit = function () {
            vm.pending = true;
            if ($stateParams.id) {
                AssignmentsService.getAssignmentForEditing($stateParams.id).then(function (obj) {
                    vm.assignment = obj.data;
                });
            } else {
                $state.go('assignments');
            }
            DocumentService.getDocumentAvailableExtension().then(function (result) {
                vm.availableExtension = '.zip,';

                if (result.data.length === 0) {
                    LandingService.getAvailableFileExtensions().then(function (extensions) {
                        vm.availableExtension += extensions.data;
                        vm.pending = false;
                    });
                } else {
                    angular.forEach(result.data, function (value) {
                        vm.availableExtension += '.' + value + ',';
                    });
                    vm.pending = false;
                }
            });
        }

        function getTheFiles($files, $invalidFiles) {
            preprocessFile($files, $invalidFiles).then(function (formData) {
                //TODO refactor
            }, function (error) {
                console.error('getTheFiles error ' + error);
            })
        }

        function preprocessFile($files, $invalidFiles) {
            if ($files === null) {
                vm.files = null;
                return Promise.reject();
            } else {
                if ($invalidFiles.length === 0) {
                    var i = 0;
                    var formData = null;
                    vm.files = $files;
                    angular.forEach(vm.files, function (value, key) {
                        formData = new FormData();
                        formData.append(key, value);
                        vm.fileName = Utils.removeSpecialCharsForFileName(value.name);
                        vm.uploadFile(formData, vm.fileName);
                        i++;
                    });
                    if (i > 0) {
                        return Promise.resolve(formData);
                    }
                    return Promise.reject();
                }
            }
        }

        function uploadFile(obj, name) {
            vm.pending = true;
            DocumentService.operator.uploadMultipleFiles(obj, name).then(function (responseData) {
                var arr = responseData.data;
                var errorFiles = false;
                angular.forEach(arr, function (d) {
                    if (!d.errorCode) {
                        vm.submits.push({
                            fileId: d.fileId,
                            filename: d.docuTitle,
                            size: d.size,
                            title: vm.assignment.title
                        });
                    } else {
                        errorFiles = true;
                        if (vm.errorFilenames.length == 0) {
                            vm.errorFilenames = d.docuTitle;
                        } else if (vm.errorFilenames.length < 200) {
                            vm.errorFilenames += '<br/>' + d.docuTitle;
                        } else if (!vm.errorFilenames.endsWith('(...)')) {
                            vm.errorFilenames += '<br/>(...)';
                        }
                    }
                });
                vm.pending = false;
                if (errorFiles) { //jesli byly jakies nieobslugiwane pliki, to poakzuje komunikat
                    $mdDialog.show(
                        $mdDialog.confirm()
                            .multiple(false)
                            .clickOutsideToClose(true)
                            .title($translate.instant('global.messages.warning.label'))
                            .htmlContent($translate.instant('global.messages.error.unexpected-zip-content') + '<br /><br />' + vm.errorFilenames)
                            .ariaLabel('ok')
                            .ok('OK')
                    ).then(function () {
                        vm.errorFilenames = '';
                    }, function () {
                        //do nothing
                    });
                }
            }).catch(function (response) {
                vm.pending = false;
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function backToList() {
            $state.go('assignments');
        }
        
        function remove(sub) {
            var filteredAry = vm.submits.filter(function (e) {
                return e !== sub
            });
            vm.submits = filteredAry;
        }

        function save() {
            vm.pending = true;
            AssignmentsService.submitMultipleAssignments(vm.submits, vm.assignment.id).then(function (responseData) {
                ModalService.showSuccessModal("assignments.upload.success").then(function () {
                    $state.go('assignments');
                });
                
            }, function (errorResponse) {
                vm.pending = false;
                ModalService.showErrorModal(errorResponse);
            });
        }

    }
})();