(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ClientLogoController', ClientLogoController);

    ClientLogoController.$inject = ['ClientLogoService', 'ModalService'];

    function ClientLogoController(ClientLogoService, ModalService) {
        var vm = this;
        vm.activeTab = 'clientLogo';

        vm.clientLogo;

        this.$onInit = function () {
            vm.getClientLogo();
        };
        vm.addFile = function (files) {
            vm.isLoading = true;
            var formData = new FormData();
            formData.append("file", files[0]);
            formData.append("fileName", files[0].name);

            ClientLogoService.createOrUpdate(formData).then(function () {
                vm.isLoading = false;
                vm.getClientLogo();
            }).catch(function (response) {
                vm.isLoading = false;
                ModalService.showErrorModal(response);
            });
        };

        vm.getClientLogo = function () {
            vm.isLoading = true;
            ClientLogoService.get().then(function (response) {
                vm.clientLogo = response;
                vm.isLoading = false;
            });
        };

        vm.removeClientLogo = function () {
            vm.isLoading = true;
            ClientLogoService.remove().then(function () {
                vm.clientLogo = null;
                vm.isLoading = false;
            }, function (response) {
                ModalService.showErrorModal(response);
                vm.isLoading = false;
            });
        };
    }
})();
