(function () {
    'use strict';

    function DocumentParameterDefinitionsSearchController($scope, $rootScope, $state, $translate, $mdDialog, $log,
    		DocumentParameterDefinitionsService, documentParameterDefinitionsQueryRequest, documentParameterDefinitionsSearchRequest) {
        var vm = this;
        vm.simpleSearch = true;
        vm.getData = getData;
        vm.clear = clear;
        vm.goToAddPage = goToAddPage;
        
        function goToAddPage(){
        	$state.go('documentParameterDefinitionsAdd');
        }
        
        function getData(){
            $localStorage.documentParameterDefinitionListLimit = vm.onQueryRequest.limit;
        	vm.onGetData(vm.documentParameterDefinitionsSearchRequest);
		}
        
        function clear() {
            vm.documentParameterDefinitionsSearchRequest = new documentParameterDefinitionsSearchRequest();
            getData();
        }

    }

    angular.module('plagiat2017App')
            .component('documentParameterDefinitionsSearch', {
                templateUrl: 'app/account/settings/document-parameter-definitions/document-parameter-definitions-search/document-parameter-definitions-search.template.html',
                controller: DocumentParameterDefinitionsSearchController,
                bindings: {
                    onGetData: '<',
                    onQueryRequest: '=',
                    onSearchData: '='
                }
            });

})();
