(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('UserImportController', UserImportController);

    UserImportController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate',
    	'InstanceService', '$state', '$cookies', '$stateParams', '$mdDialog', '$http', 'UserImportService', 'Utils',
        'AccountService', '$sce', 'DetectInstanceService'];

    function UserImportController (Principal, Auth, JhiLanguageService, $translate,
    		InstanceService, $state, $cookies, $stateParams,  $mdDialog, $http, UserImportService, Utils,
                                   AccountService, $sce, DetectInstanceService) {
        var vm = this;
        vm.activeTab='userImport';
        vm.instance = InstanceService.instance().lang;
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.importUsersFromXls = UserImportService.importUsersFromXls;
        vm.downloadTemplate = downloadTemplate;
        vm.uploadFile = uploadFile;
        vm.getTheFiles = getTheFiles;
        vm.availableExtension = UserImportService.getAvailableExtensions();
        vm.isLoaderActive = false;
        vm.fileReportName = $translate.instant("user.import.file-report-name");
        vm.userImportSuccess = false;
        vm.userImportError = false;
        vm.importSuccessMsg = $translate.instant("user.import.success.info1") + '<br/>' + $translate.instant("user.import.success.info2");
        vm.importErrorMsg = $translate.instant("user.import.error.info1");
        vm.clientTypePublishingHouse = AccountService.clientType.isPublishingHouse();
        vm.trustSrc = trustSrc;
        vm.instance = DetectInstanceService.instance();

        function downloadTemplate(){
        	UserImportService.downloadTemplate(vm.fileReportName);
        }

        function trustSrc(src) {
            return $sce.trustAsResourceUrl(src);
        }

        switch(vm.instance) {
            case 'int':
              vm.instanceType = '_int';
              break;
            case 'ro':
              vm.instanceType = '_ro';
              break;
            case 'pl':
              vm.instanceType = '_pl';
              break;
            case 'aka':
              vm.instanceType = '_aka';
              break;
            default:
              vm.instanceType = '_pl';
        }

        function getTheFiles($files, $invalidFiles) {
            if ($files === null) {
                vm.files = null;
            } else {
                if ($invalidFiles.length === 0) {
                    var formData = new FormData();
                    vm.files = $files;
                    var i = 0;
                    angular.forEach(vm.files, function (value, key) {
                        vm.fileName = Utils.removeSpecialCharsForFileName(value.name);
                        formData.append(key, value);
                        i++;
                    });
                    if (i > 0) {
                        vm.uploadFile(formData, vm.fileName);
                    }
                }
            }
        }

		function uploadFile(obj, filename) {
			var request = {
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                },
                responseType : 'arraybuffer'
            };
			vm.isLoaderActive = true;
        	vm.userImportSuccess = false;
			vm.userImportError = false;

			 return $http.post('api/user/upload-file?filename=' + filename, obj, request).then(
                function (response) {
					var blob = new Blob(
							[ response.data ],
							{
								type : "application/vnd.ms-excel"
							});
					saveAs(blob, vm.fileReportName + getExtension(filename));
					vm.isLoaderActive = false;
					vm.userImportError = false;
					vm.userImportSuccess = true;

		            $mdDialog.show(
		                    $mdDialog.confirm()
		                    .clickOutsideToClose(true)
		                    .title('')
		                    .htmlContent(vm.importSuccessMsg)
		                    .ariaLabel('ok')
		                    .ok('OK')
		                    );
                },
                function (errResponse) {
                	vm.isLoaderActive = false;
                	vm.userImportSuccess = false;
					vm.userImportError = true;

		            $mdDialog.show(
		                    $mdDialog.confirm()
		                    .clickOutsideToClose(true)
		                    .title('')
		                    .textContent(vm.importErrorMsg)
		                    .ariaLabel('ok')
		                    .ok('OK')
		                    );

                    return $q.reject(errResponse);
                });
        }
        
        function getExtension(filename) {
            if (filename) {
                var a = filename.lastIndexOf('.xlsx');
                if (a > 0) {
                    return '.xlsx';
                }
                var b = filename.lastIndexOf('.xls');
                if (b > 0) {
                    return '.xls';
                }
            }
            return '.xlsx';
        }

    }
})();
