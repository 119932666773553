(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('PaymentInitRequest', function () {

        function PaymentInit() {
            this.productQuantity = '';

            this.price = {
                userPaymentType: 'TYPE_TRANSFER',
                quantity: 0,
                currency: "EUR"
            };

            this.TYPE_TRANSFER = {
                name: 'Przelewy24',
                img: 'pay-przelewy24.png',
                userPaymentType: 'TYPE_TRANSFER',
                maxTokens: 250,
                invoice: true,
                backUrl: '/api/payments/return-from-dotpay'
            };
            this.TYPE_PAY_PAL = {
                name: 'Paypal',
                img: 'pay-paypal.png',
                userPaymentType: 'TYPE_PAY_PAL',
                maxTokens: 250,
                invoice: true,
                backUrl: '/api/payments/return-from-paypal'
            };
            this.TYPE_PAY_PAL_RO = {
                name: 'Paypal.',
                img: 'pay-paypal.png',
                userPaymentType: 'TYPE_PAY_PAL_RO',
                maxTokens: 250,
                invoice: true,
                backUrl: '/api/payments/return-from-paypal'
            };
            this.TYPE_EASY_PAY = {
                name: 'EasyPay',
                img: 'pay-easypay.png',
                userPaymentType: 'TYPE_EASY_PAY',
                maxTokens: 250,
                invoice: true,
                backUrl: '/api/payments/return-from-easypay'
            };
            this.TYPE_KASSA_24 = {
                name: 'Kassa24',
                img: 'pay-kassa24.png',
                userPaymentType: 'TYPE_KASSA_24',
                maxTokens: 50,
                invoice: false,
                backUrl: '/api/payments/return-from-kassa24'
            };
            this.TYPE_KASSA_24_CARD = {
                name: 'Kassa24 Business',
                img: 'pay-kassa24card.png',
                userPaymentType: 'TYPE_KASSA_24_CARD',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-kassa24card'
            };
            this.TYPE_SMS = {
                name: 'SMS',
                img: 'pay-smartpay.png',
                userPaymentType: 'TYPE_SMS',
                maxTokens: 20,
                invoice: false,
                backUrl: '/api/payments/return-from-sms',
                smsCode: []
            };
            this.TYPE_MOBILPAY_CARD = {
                name: " Card",
                img: 'pay-mobilpay-card.png',
                userPaymentType: 'TYPE_MOBILPAY_CARD',
                maxTokens: 250,
                invoice: true,
                backUrl: '/api/payments/return-from-mobilpay'
            };
            this.TYPE_MOBILPAY_SMS = {
                name: "SMS",
                img: 'pay-mobilpay.png',
                userPaymentType: 'TYPE_MOBILPAY_SMS',
                maxTokens: 4,
                invoice: false,
                backUrl: '/api/payments/return-from-mobilpay'
            };
            this.TYPE_MILLIKART_CARD = {
                name: 'Millikart',
                img: 'pay-millikart.png',
                userPaymentType: 'TYPE_MILLIKART_CARD',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-millikart'
            };
            this.TYPE_YIGIM_AZ = {
                name: 'Yigim.az',
                img: 'pay-yigim.png',
                userPaymentType: 'TYPE_YIGIM_AZ',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-provider'
            };
            this.TYPE_EPAY_BG = {
                name: 'ePay.bg',
                img: 'pay-epay_bg.png',
                userPaymentType: 'TYPE_EPAY_BG',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-provider'
            };
            this.TYPE_BLIK = {
                name: 'Blik',
                img: 'pay-blik.png',
                userPaymentType: 'TYPE_BLIK',
                maxTokens: 250,
                invoice: true,
                backUrl: '/api/payments/return-from-blik'
            };
            this.TYPE_MOBILTEK = {
                name: 'SMS',
                img: 'pay-mobiltek.png',
                userPaymentType: 'TYPE_MOBILTEK',
                maxTokens: 20,
                invoice: false,
                backUrl: '/api/payments/return-from-mobiltek'
            };
            this.TYPE_PAYME = {
                name: 'Payme',
                img: 'pay-payme.png',
                userPaymentType: 'TYPE_PAYME',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-payme'
            };
            this.TYPE_FORTE_KZ = {
                name: 'Forte.kz',
                img: 'pay-forte-kz.png',
                userPaymentType: 'TYPE_FORTE_KZ',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-provider'
            };
            this.TYPE_KASPI_BANK = {
                name: 'Kaspi Bank',
                img: 'pay-kaspi-bank.png',
                userPaymentType: 'TYPE_KASPI_BANK',
                maxTokens: 250,
                invoice: false,
                backUrl: '/api/payments/return-from-provider'
            };

        }

        return PaymentInit;
    });
})();
