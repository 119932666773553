(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('ModalService', ModalService);

    ModalService.$inject = ['$translate', '$mdDialog', 'PlagiatValidationService'];

    function ModalService($translate, $mdDialog, PlagiatValidationService) {
        var service = {
            showConfirmModal: showConfirmModal,
            showSuccessModal: showSuccessModal,
            showErrorModal: showErrorModal,
            showDialogModal: showDialogModal,
            showPleaseWaitDialog: showPleaseWaitDialog
        };

        function showConfirmModal(translationKey, params) {
            var key = translationKey ? translationKey : 'global';
            var customCancelButton = params && params.customCancelButton;
            var customTitle = params && params.customTitle;

            return $mdDialog.show(
                $mdDialog.confirm()
                    .title($translate.instant(key + '.confirm'))
                    .textContent($translate.instant(customTitle ? customTitle : (key + '.areyousure')))
                    .ariaLabel($translate.instant(key + '.areyousure'))
                    .ok($translate.instant(key + '.button.confirm'))
                    .cancel($translate.instant(customCancelButton ? key + '.button.cancel' : 'global.button.cancel'))
            );
        }

        function showSuccessModal(textConent) {
            var key = textConent ? textConent : 'global.messages.info.actionsuccess';
        	return $mdDialog.show(
                    $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant(key))
                    .ariaLabel('msgSuccess')
                    .ok('Ok')
                    );
        }

        function showPleaseWaitDialog(isMultiple) {
            var dialog = $mdDialog.alert()
                .clickOutsideToClose(false)
                .title('')
                .multiple(isMultiple === true ? true : false)
                .textContent($translate.instant('global.please-wait'))
                .ariaLabel('please-wait')
                .ok('');
            $mdDialog.show(dialog);
            return dialog;
        }

        function showDialogModal(textContent, additionalContent) {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant(textContent, additionalContent || {}))
                    .ariaLabel('ok')
                    .ok('Ok')
            );
        }

        function showErrorModal(error, multiple) {
        	PlagiatValidationService.getModalError(error.data, null, null, multiple);
        }

        return service;
    }

})();
