(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('TranslationHistoryController', TranslationHistoryController);

    function TranslationHistoryController(
        code, lang, $mdDialog, JhiLanguageService, DocumentKindService, $q,
        $stateParams, OrppdService, $rootScope, ModalService,
        TranslationModel, TranslationsService
    ) {
        var vm = this;

        vm.code = code;
        vm.lang = lang;
        vm.obj;
        vm.selectedStepIndex;
        vm.availableLanguages;
        vm.order = {};

        this.$onInit = function () {
            find();
            initAvailableLanguages();
        };

        function find() {
            vm.pending = true;
            TranslationsService.findHistory(code).then(
                function (response) {
                    vm.pending = false;
                    vm.obj = response.data;
                }, function (errorResponse) {
                    vm.pending = false;
                    $mdDialog.cancel();
                    ModalService.showErrorModal(errorResponse);
                }
            );
        }

        function initAvailableLanguages() {
            JhiLanguageService.getAll().then(function (allLanguages) {
                vm.availableLanguages = allLanguages.data.availableLanguages;
                vm.selectedStepIndex = vm.availableLanguages.indexOf(vm.lang);
                for (var i = 0; i < vm.availableLanguages.length; i++) {
                    vm.order[vm.availableLanguages[i]] = '-createDate';
                }
            });
        }

        vm.closeDialog = function () {
            $mdDialog.cancel();
        };

    }

})();
