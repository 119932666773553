(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .constant('CODE_STATUS', function () {
                var statuses = {
                    STATUS_SENT_TO_ALLPAY: {id: -1, key: 'codes.model.status.sent-to-allpay'},
                    CODE_STATUS_AVAILABLE: {id: 0, key: 'codes.model.status.available'},
                    CODE_STATUS_USED: {id: 1, key: 'codes.model.status.used'}
                };

                var keysById = {};
                var keys = Object.keys(statuses);
                for (var key in keys) {
                    var codeStatus = statuses[keys[key]];
                    keysById[codeStatus.id] = codeStatus.key;
                }
                statuses.keysById = keysById;

                return statuses;
            }()
        );
})();
