(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('FullStatsController', FullStatsController);

    function FullStatsController(FullStatsService, ModalService, PlagiatValidationService) {
        var vm = this;
        vm.activeTab = 'fullStats';

        vm.loadData = loadData;
        vm.displayField = displayField;
        vm.executeFullStats = executeFullStats;
        vm.generateFullStatsXls = generateFullStatsXls;
    
        vm.pending = false;
        vm.availableStats = [];
        vm.obj = {};
        vm.obj.paymentType = '0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,99';
        vm.result = null;
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        
        this.$onInit = function () {
            vm.loadData();
            vm.obj.dateTo = new Date();
            var dateToChange = new Date();
            dateToChange.setMonth(dateToChange.getMonth() - 1);
            vm.obj.dateFrom = dateToChange;
        };

        function loadData() {
            vm.pending = true;
            FullStatsService.getAvailable().then(function (response) {
                vm.pending = false;
                vm.availableStats = response;
                vm.obj.option = vm.availableStats[0];
            });
        }
        
        function displayField(fieldName) {
            return vm.obj.option && (vm.obj.option.parameters.indexOf(fieldName) > -1);
        }
    
        function checkIfParamsValid() {
            if (vm.obj.dateTo && vm.obj.dateFrom && (vm.obj.dateTo < vm.obj.dateFrom)) {
                ModalService.showErrorModal({data: {code: 110}});
                return false;
            }
            return true;
        }
        
        function executeFullStats() {
            if (!checkIfParamsValid()) {
                return;
            }
            vm.result = null;
            vm.pending = true;
            FullStatsService.executeFullStats(vm.obj).then(function (response) {
                vm.result = response.data;
                vm.pending = false;
            }, function (errResponse) {
                vm.pending = false;
                PlagiatValidationService.getModalError(errResponse);
            });
        }
    
        function generateFullStatsXls(){
            FullStatsService.generateFullStatsXls(vm.obj);
        }
    
    }
})();
