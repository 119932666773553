(function() {
	'use strict';
	angular.module('plagiat2017App').directive('stopccp', function() {
		return {
			scope : {},
			link : function(scope, element) {
				element.on('cut copy paste', function(event) {
					event.preventDefault();
				});
			}
		};
	});

})();
