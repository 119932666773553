(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PeerReviewScoringController', PeerReviewScoringController);
    
    PeerReviewScoringController.$inject = ['PeerReviewService', 'PeerReviewFeedbackService', '$state', '$stateParams'];

    function PeerReviewScoringController(PeerReviewService, PeerReviewFeedbackService, $state, $stateParams) {

        const vm = this;
        vm.loadingValues = true;
        vm.pending = false;
        vm.obj = {};
        vm.expandedReviewers = {};
        vm.editScoreMode = {};

        this.$onInit = function () {
            if ($stateParams.id) {
                PeerReviewService.findOne($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                    vm.loadingValues = false;
                });
            }
        };
        vm.expandReviews = function(reviewerId) {
            vm.expandedReviewers[reviewerId] = !vm.expandedReviewers[reviewerId];
        };

        vm.editScores = function(reviewerId) {
            vm.editScoreMode[reviewerId] = !vm.editScoreMode[reviewerId];
        };

        vm.cancelEditScores = function(reviewerId) {
            vm.editScoreMode[reviewerId] = !vm.editScoreMode[reviewerId];
        };

        vm.saveScores = function(reviewer) {
            PeerReviewFeedbackService.saveGrades(reviewer).then(function () {
                vm.editScoreMode[reviewer.id] = !vm.editScoreMode[reviewer.id];
            })
        };

        vm.onChangeScore = function (reviewer) {
            reviewer.sumScore = calcSumScores(reviewer);
            reviewer.averageScore = Math.round(reviewer.sumScore / reviewer.feedbacks.length);
            reviewer.scorePercentage = Math.round(((reviewer.sumScore / reviewer.feedbacks.length) / reviewer.maxScore) * 100);
        }

        var calcSumScores = function (reviewer) {
            var sum = 0;
            for (var i = 0; i < reviewer.feedbacks.length; i++) {
                if (reviewer.feedbacks[i].score) {
                    sum += reviewer.feedbacks[i].score;
                }
            }
            return sum;
        };

        vm.readReview = function(feedbackId) {
            $state.go('view-feedback', {id: feedbackId});
        };
    }
})();
