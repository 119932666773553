(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .constant('INSTANCES', [
                {
                    instance: 'pl', settings: {
                        lang: 'pl',
                        domain: 'plagiat.pl',
                        landingLink: 'https://plagiat.pl',
                        domainGTM: 'GTM-MQMNQ4VK',
                        domainUa: 'G-Z7RZ9JE8CD',
                        domainAw: 'AW-861372621',
                        domainAwCode: 'AW-861372621/4aAECJH_vW8QzYHemgM',
                        helpSettings: [
                            {
                                lang: 'pl',
                                emails: [{name: 'pomoc@antyplagiat.pl', email: 'pomoc@antyplagiat.pl'}]},
                            {
                                lang: 'ro',
                                emails: [{name: 'contact@sistemantiplagiat.ro', email: 'contact@sistemantiplagiat.ro'}]
                            },
                            {
                                lang: 'default',
                                emails: [{name: 'help@strikeplagiarism.com', email: 'help@strikeplagiarism.com'}]
                            }
                        ],
                        currencies: {
                            default: 'PLN',
                            list: ['PLN', 'EUR', 'USD', 'UAH', 'KZT'],
                            settings: [
                                {name: 'PLN', methodsOfPayment: ["TYPE_TRANSFER", "TYPE_BLIK", "TYPE_PAY_PAL"], invoice: true},
                                {name: 'EUR', methodsOfPayment: ["TYPE_TRANSFER", "TYPE_PAY_PAL"], invoice: true},
                                {name: 'USD', methodsOfPayment: ["TYPE_TRANSFER", "TYPE_PAY_PAL"], invoice: true},
                                {name: 'UAH', methodsOfPayment: ["TYPE_EASY_PAY"], invoice: false},
                                {name: 'KZT', methodsOfPayment: ["TYPE_KASSA_24_CARD"], invoice: false}
                            ],
                            langSettings: [
                                {lang: 'pl', currency: 'PLN'},
                                {lang: 'en', currency: 'EUR'},
                                {lang: 'de', currency: 'EUR'},
                                {lang: 'es', currency: 'EUR'},
                                {lang: 'ro', currency: 'EUR'},
                                {lang: 'ru', currency: 'USD'},
                                {lang: 'az', currency: 'USD'},
                                {lang: 'ka', currency: 'USD'},
                                {lang: 'uk', currency: 'UAH'},
                                {lang: 'kk', currency: 'USD'},
                                {lang: 'tr', currency: 'USD'}
                            ],
                            landingCurrencies: [
                                {lang: 'pl', defaultCurrency: 'PLN', currencyList: ['PLN', 'EUR', 'USD']},
                                {lang: 'en', defaultCurrency: 'PLN', currencyList: ['PLN', 'EUR', 'USD']}
                            ]
                        },
                        timezone: '',
                        timezoneDocuments: '',
                        timezonePayments: '',
                        timezones: [
                            {
                                subdomain: 'st',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'test',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'sprawdz-prace',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'preprod',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'panel',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            }
                        ],
                        timeFormat: 'yyyy-MM-dd HH:mm'
                    }
                },
                {
                    instance: 'aka', settings: {
                        lang: 'pl',
                        domain: 'akambiko.pl',
                        landingLink: 'https://akambiko.pl',
                        domainGTM: 'GTM-MQMNQ4VK',
                        domainUa: 'G-Z7RZ9JE8CD',
                        domainAw: 'AW-861372621',
                        domainAwCode: 'AW-861372621/4aAECJH_vW8QzYHemgM',
                        helpSettings: [
                            {
                                lang: 'pl',
                                emails: [{name: 'pomoc@antyplagiat.pl', email: 'pomoc@antyplagiat.pl'}]},
                            {
                                lang: 'ro',
                                emails: [{name: 'contact@sistemantiplagiat.ro', email: 'contact@sistemantiplagiat.ro'}]
                            },
                            {
                                lang: 'default',
                                emails: [{name: 'help@strikeplagiarism.com', email: 'help@strikeplagiarism.com'}]
                            }
                        ],
                        currencies: {
                            default: 'PLN',
                            list: ['PLN', 'EUR', 'USD', 'UAH', 'KZT', 'AZN'],
                            settings: [
                                {name: 'PLN', methodsOfPayment: ["TYPE_TRANSFER", "TYPE_PAY_PAL", "TYPE_BLIK"]},
                                {name: 'EUR', methodsOfPayment: ["TYPE_TRANSFER", "TYPE_PAY_PAL"]}
                            ],
                            langSettings: [
                                {lang: 'pl', currency: 'PLN'},
                                {lang: 'en', currency: 'EUR'}
                            ],
                            landingCurrencies: [
                                {lang: 'pl', defaultCurrency: 'PLN'}
                            ]
                        },
                        timezone: '',
                        timezoneDocuments: '',
                        timezonePayments: '',
                        timezones: [
                            {
                                subdomain: 'st',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'sprawdz-prace',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'preprod',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'panel',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            }
                        ],
                        timeFormat: 'yyyy-MM-dd HH:mm'
                    }
                },
                {
                    instance: 'int', settings: {
                        lang: 'en',
                        domain: 'strikeplagiarism.com',
                        landingLink: 'https://strikeplagiarism.com',
                        domainGTM: 'GTM-0000000000',
                        domainUa: 'G-D23R1YN241',
                        domainAw: 'AW-11429142773',
                        domainAwCode: 'AW-11429142773/_KQcCLKX0_sYEPXB68kq',
                        helpSettings: [
                            {
                                lang: 'pl',
                                emails: [{name: 'pomoc@antyplagiat.pl', email: 'pomoc@antyplagiat.pl'}]},
                            {
                                lang: 'ro',
                                emails: [{name: 'contact@sistemantiplagiat.ro', email: 'contact@sistemantiplagiat.ro'}]
                            },
                            {
                                lang: 'default',
                                emails: [{name: 'help@strikeplagiarism.com', email: 'help@strikeplagiarism.com'}]
                            },
                            {
                                lang: 'uk',
                                emails: [{name: 'ukraine@strikeplagiarism.com', email: 'ukraine@strikeplagiarism.com'}]
                            }
                        ],
                        currencies: {
                            default: 'EUR',
                            list: ['EUR', 'PLN', 'USD', 'UAH', 'KZT', 'BGN', 'AZN', 'UZS'],
                            settings: [
                                {name: 'PLN', methodsOfPayment: ["TYPE_PAY_PAL"], invoice: true},
                                {name: 'EUR', methodsOfPayment: ["TYPE_PAY_PAL"], invoice: true},
                                {name: 'USD', methodsOfPayment: ["TYPE_PAY_PAL"], invoice: true},
                                {name: 'UAH', methodsOfPayment: ["TYPE_EASY_PAY"], invoice: false},
                                {name: 'KZT', methodsOfPayment: ["TYPE_KASPI_BANK", "TYPE_FORTE_KZ", "TYPE_KASSA_24_CARD"], invoice: false},
                                {name: 'AZN', methodsOfPayment: ["TYPE_YIGIM_AZ", "TYPE_MILLIKART_CARD"], invoice: false},
                                {name: 'UZS', methodsOfPayment: ["TYPE_PAYME"], invoice: false},
                                {name: 'BGN', methodsOfPayment: ["TYPE_EPAY_BG"], invoice: false}
                            ],
                            langSettings: [
                                {lang: 'pl', currency: 'PLN'},
                                {lang: 'en', currency: 'EUR'},
                                {lang: 'de', currency: 'EUR'},
                                {lang: 'es', currency: 'EUR'},
                                {lang: 'ro', currency: 'EUR'},
                                {lang: 'ru', currency: 'KZT'}, //tenge takze dla rosyjskiego
                                {lang: 'az', currency: 'AZN'}, //manat dla azerbejdzanu
                                {lang: 'ka', currency: 'USD'},
                                {lang: 'uk', currency: 'UAH'}, // grywny dla ukraincow
                                {lang: 'kk', currency: 'KZT'}, //tenge dla kazachow
                                {lang: 'bg', currency: 'BGN'}
                            ],
                            landingCurrencies: [
                                {lang: 'pl', defaultCurrency: 'PLN'},
                                {lang: 'en', defaultCurrency: 'EUR', currencyList: ['EUR', 'USD', 'AZN', 'KZT', 'UAH', 'UZS']},
                                {lang: 'ru', defaultCurrency: 'USD', currencyList: ['EUR', 'USD', 'AZN', 'KZT', 'UAH', 'UZS']},
                                {lang: 'az', defaultCurrency: 'AZN'},
                                {lang: 'kk', defaultCurrency: 'KZT'}, //kazach
                                {lang: 'uk', defaultCurrency: 'UAH'},
                                {lang: 'ka', defaultCurrency: 'USD'}, //georgian
                                {lang: 'de', defaultCurrency: 'EUR'},
                                {lang: 'es', defaultCurrency: 'EUR'},
                                {lang: 'bg', defaultCurrency: 'EUR'},
                                {lang: 'tr', defaultCurrency: 'EUR', currencyList: ['EUR', 'USD']},
                                {lang: 'uz', defaultCurrency: 'UZS', currencyList: ['UZS', 'USD']},
                            ]
                        },
                        timezone: '',
                        timezoneDocuments: '',
                        timezonePayments: '',
                        timezones: [
                            {
                                subdomain: 'st',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'check-paper',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'check-paper-for-plagiarism',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'plagiat-tekseru-antiplagiat',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'prover-rabotu-antiplagiat',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'antiplagiat',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'perevirka-na-plagiat-antiplagiat',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'plagiata-yoxlamaq-antiplagiat',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'plagiatni-tekshiring-antiplagiat',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'dokument-auf-plagiat-prufen',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'sheamotsmet-dokumenti-plagiatis-shesakheb',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'proverete-dokumenta-za-plagiat-stvo',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'intihal-ile-ilgili-belgeyi-kontrol-et',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'comprobar-documento-sobre-plagio',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'preprod',
                                timezone: 'Europe/Warsaw', documents: 'Europe/Warsaw', payments: 'Europe/Warsaw'
                            },
                            {
                                subdomain: 'panel',
                                timezone: 'Europe/Warsaw',
                                documents: 'Europe/Warsaw',
                                payments: 'Europe/Warsaw'
                            }
                        ],
                        timeFormat: 'yyyy-MM-dd HH:mm'
                    }
                },
                {
                    instance: 'ro', settings: {
                        lang: 'ro',
                        domain: 'sistemantiplagiat.ro',
                        landingLink: 'https://sistemantiplagiat.ro',
                        domainGTM: 'GTM-0000000000',
                        domainUa: 'G-H13XM1CF6S',
                        domainAw: 'AW-874812094',
                        domainAwCode: 'AW-874812094/Jk2hCN7Ho4oBEL6lkqED',
                        helpSettings: [
                            {
                                lang: 'default',
                                emails: [{name: 'contact@sistemantiplagiat.ro', email: 'contact@sistemantiplagiat.ro'}]
                            }
                        ],
                        currencies: {
                            default: 'RON',
                            list: ['RON', 'EUR', 'USD'],
                            settings: [
                                {name: 'RON', methodsOfPayment: ["TYPE_MOBILPAY_CARD"], invoice: true},
                                {name: 'EUR', methodsOfPayment: ["TYPE_PAY_PAL_RO", "TYPE_MOBILPAY_SMS"], invoice: true},
                                {name: 'USD', methodsOfPayment: ["TYPE_PAY_PAL_RO"], invoice: true}
                            ],
                            langSettings: [
                                {lang: 'pl', currency: 'EUR'},
                                {lang: 'en', currency: 'EUR'},
                                {lang: 'de', currency: 'EUR'},
                                {lang: 'es', currency: 'EUR'},
                                {lang: 'ro', currency: 'RON'},
                                {lang: 'ru', currency: 'EUR'},
                                {lang: 'az', currency: 'EUR'},
                                {lang: 'ka', currency: 'EUR'},
                                {lang: 'uk', currency: 'EUR'},
                                {lang: 'kk', currency: 'EUR'},
                                {lang: 'tr', currency: 'EUR'}
                            ],
                            landingCurrencies: [
                                {lang: 'ro', defaultCurrency: 'RON'}
                            ]
                        },
                        timezone: '',
                        timezoneDocuments: '',
                        timezonePayments: '',
                        timezones: [
                            {
                                subdomain: 'st',
                                timezone: 'Europe/Bucharest',
                                documents: 'Europe/Bucharest',
                                payments: 'Europe/Bucharest'
                            },
                            {
                                subdomain: 'test',
                                timezone: 'Europe/Bucharest',
                                documents: 'Europe/Bucharest',
                                payments: 'Europe/Bucharest'
                            },
                            {
                                subdomain: 'verifica-textul',
                                timezone: 'Europe/Bucharest',
                                documents: 'Europe/Bucharest',
                                payments: 'Europe/Bucharest'
                            },
                            {
                                subdomain: 'preprod',
                                timezone: 'Europe/Bucharest',
                                documents: 'Europe/Bucharest',
                                payments: 'Europe/Bucharest'
                            },
                            {
                                subdomain: 'panel',
                                timezone: 'Europe/Bucharest',
                                documents: 'Europe/Bucharest',
                                payments: 'Europe/Warsaw'
                            }
                        ],
                        timeFormat: 'yyyy-MM-dd HH:mm'
                    }
                }
            ]
        );
})();
