(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ShowPopupService', ShowPopupService);

    ShowPopupService.$inject = [
        '$http', '$q', '$mdDialog', '$localStorage', '$translatePartialLoader', '$translate',
        'AccountService', 'DetectInstanceService', 'PopupAdministrationService'
    ];

    function ShowPopupService(
        $http, $q, $mdDialog, $localStorage, $translatePartialLoader, $translate,
        AccountService, DetectInstanceService, PopupAdministrationService
    ) {
        var openPopups = [];

        var service = {
            action: action
        };
        return service;

        function action() {
            $translatePartialLoader.addPart('popup');
            $translate.refresh();

            PopupAdministrationService.findAllToShowForCurrentUser().then(function (res) {

                var popups = res;
                openPopups = [];
                for (var i = 0; i < popups.length; i++) {
                    if (shouldShowPopup(popups[i].id)) {
                        openPopups.push({ modal: showModal(popups[i]), popupId: popups[i].id });
                    }
                }
            });
        }

        function close(popupId) {
            var popupIdToRemove = -1;
            for ( var i=0; i< openPopups.length; i++) {
                if (openPopups[i].popupId === popupId) {
                    $mdDialog.cancel(openPopups[i].modal);
                    popupIdToRemove = i;
                    break;
                }
            }
            if (popupIdToRemove >= 0) {
                openPopups.slice(popupIdToRemove, 1);
            }
        }

        function showModal(popup) {
            return $mdDialog.show({
                controller: 'PopupController',
                controllerAs: 'vm',
                templateUrl: 'app/account/popup/popup.template.html',
                clickOutsideToClose: true,
                locals: {
                    popup: popup,
                    closeCallback: close
                },
                multiple: true
            });
        }

        function shouldShowPopup(popupId) {
            var hidePopup = checkLocalStorage(popupId);
            return !hidePopup;
        }

        function checkLocalStorage(popupId) {
            if (!$localStorage.dontShowPopupMap) {
                $localStorage.dontShowPopupMap = {};
            }
            return $localStorage.dontShowPopupMap[popupId];
        }

    }
})();
