(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('PortalsService', PortalsService);

    PortalsService.$inject = ['$http', '$q', 'FileSaver', 'PlagiatValidationService', '$mdDialog', '$translate', 'DateUtils'];

    function PortalsService($http, $q, FileSaver, PlagiatValidationService, $mdDialog, $translate, DateUtils) {

        var service = {
            generateReportXls: generateReportXls,
            downloadZip: downloadZip,
            search: search,
        };
        var requestUrl = 'api/portals';
        return service;

        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function generateReportXls(response, selected, searchRequest) {
            var alert = $mdDialog.alert()
                .clickOutsideToClose(false)
                .escapeToClose(false)
                .textContent($translate.instant("global.report.xls.exporting-in-progress"))
                .ok("OK");
            $mdDialog.show(alert);

            var url = 'api/portals/xls/return-xls';
            var data = response.selectedAll
                ? {filter:searchRequest}
                : {filter:searchRequest, ids:{ids:selected}};

            return $http({
                url: url,
                method: "POST",
                data: data,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).then(function (success) {
                $mdDialog.hide();
                var blob = new Blob([success.data], {
                    type: "application/vnd.ms-excel"
                });
                saveAs(blob, 'portals_' + DateUtils.getDateTimeForFileName(new Date()) + '.xls');
                return 'OK';
            }, function (errResponse) {
                $mdDialog.hide();
                PlagiatValidationService.getModalError(error);
                return $q.reject(errResponse);
            });
        }
        
        function downloadZip(response, selected, searchRequest) {
            var alert = $mdDialog.alert()
                .clickOutsideToClose(false)
                .escapeToClose(false)
                .textContent($translate.instant("global.report.xls.exporting-in-progress"))
                .ok("OK");
            $mdDialog.show(alert);

            var url = 'api/portals/reports-zip';
            var data = response.selectedAll
                ? {filter:searchRequest}
                : {filter:searchRequest, ids:{ids:selected}};

            function jsonBufferToObject (data, headersGetter, status) {
                var type = headersGetter("Content-Type");
                if (!type.startsWith("application/json")) {
                    return data;
                };
                var decoder = new TextDecoder("utf-8");
                var domString = decoder.decode(data);
                var json = JSON.parse(domString);
                return json;
            };
            
            return $http({
                url: url,
                method: "POST",
                data: data,
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/pdf, application/json'
                },
                responseType: 'arraybuffer',
                transformResponse: jsonBufferToObject
            }).then(function (success) {
                $mdDialog.hide();
                var blob = new Blob([success.data], {
                    type: "application/zip"
                });
                saveAs(blob, 'reports_' + DateUtils.getDateTimeForFileName(new Date()) + '.zip');
                return 'OK';
            }, function (errResponse) {
                $mdDialog.hide();
                return $q.reject(errResponse);
            });
        }
        
    }
})();
