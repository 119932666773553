(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PlagiatThemeController', PlagiatThemeController);

    PlagiatThemeController.$inject = ['PlagiatThemeService', '$location', '$cookies', '$window', '$translate', 
    'tmhDynamicLocale', '$timeout', 'DetectInstanceService', '$log', 'BrowserService'];

    function PlagiatThemeController (PlagiatThemeService, $location, $cookies, $window, $translate, 
        tmhDynamicLocale, $timeout, DetectInstanceService, $log, BrowserService) {
        var vm = this;
        vm.changeTheme = changeTheme;
        vm.host = $location.host();
        BrowserService.isIeLowerThen12()
        vm.inProduction = true;
        if( vm.host === 'localhost' || vm.host === 'st.plagiat.pl' ){
          vm.inProduction = false;
        }
        
        PlagiatThemeService.getAll().then(function (themes) {
            vm.themes = themes;
        });

        vm.isIeLowerThen12 = BrowserService.isIeLowerThen12() ? true : false;

        getTheme();
                
        function changeTheme(theme) {
            setTheme(theme);
            $translate.use(theme);
            tmhDynamicLocale.set(theme);
            $timeout(function () {
                $window.location.reload();
            }, 300);
        }
        
        function getTheme(){
            vm.theme = $cookies.get('plagiat-theme');
            if(vm.theme === 'en'){
                vm.theme = undefined;
            }
           if( angular.isUndefined(vm.theme) ){
            setTheme(DetectInstanceService.instance())
           }
           vm.ico = 'content/favicon/favicon_'+vm.theme+'.ico';
           vm.domain = DetectInstanceService.getUAConfig();
           vm.pixel = DetectInstanceService.getPixelEnabled();
           vm.conversionDomain = DetectInstanceService.getConversionAllowance();
        }

        function setTheme(theme){
            $cookies.put('plagiat-theme', theme);
            vm.theme = theme;
            vm.ico = 'content/favicon/favicon_'+theme+'.ico';
        }

    }
})();


