(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('jsa', {
            parent: 'account',
            url: '/jsa',
            data: {
                // authorities: ['PERM_USER_ADMIN', 'PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.settings.jsa.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/jsa/jsa.html',
                    controller: 'JsaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('jsa');
                    $translatePartialLoader.addPart('polon');
                    $translatePartialLoader.addPart('orppd');
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
