(function() {
	'use strict';

	function DocumentHistoryController($scope, $mdDialog, DocumentService, $log) {
		var vm = this;
		vm.closeDialog = closeDialog;
		vm.idDocument = DocumentService.document.id;
		vm.getDocumentHistory = getDocumentHistory;

		function closeDialog() {
			$mdDialog.hide();
		}

		function getDocumentHistory() {
			DocumentService.getDocumentHistory(vm.idDocument).then(function(response) {
				vm.documentHistoryList = response.data;
			});
		}

		if (vm.idDocument !== null) {
			getDocumentHistory();
		}

	}

	angular
			.module('plagiat2017App')
			.component(
					'documentHistory',
					{
						templateUrl : 'app/components/document/document-history/document-history.template.html',
						controller : DocumentHistoryController
					});

})();
