(function () {
    'use strict';

    const clientStatus = {
      ACTIVE: {'id': 0, 'label': 'global.status.active' },
      FORTHCOMING: {'id': 1, 'label': 'global.status.forthcoming' },
      REMOVAL_IN_PROGRESS: {'id': 252, 'label': 'client.status.removal-in-progress' },
      REMOVAL_ERROR: {'id': 253, 'label': 'client.status.removal-error' },
      FROZEN: {'id': 254, 'label': 'client.status.frozen' },
      INACTIVE: {'id': 255, 'label': 'global.status.inactive' },
    }
    angular
    .module('plagiat2017App')
    .constant('CLIENTSTATUSES', [
       clientStatus.ACTIVE,
       clientStatus.FORTHCOMING,
       clientStatus.INACTIVE
       ])
    .constant('CLIENT_STATUS', clientStatus);
})();
