(function () {
	'use strict';

	function DetectBrowserController() {
		var vm = this;

	}

	angular.module('plagiat2017App')
	.component('detectBrowser', {
		templateUrl: 'app/components/detect-browser/detect-browser.template.html',
		controller: DetectBrowserController
	});

})();
