(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('OrppdService', OrppdService);

    OrppdService.$inject = ['$http', '$q'];

    function OrppdService($http, $q) {

        var service = {
            sendDocument: sendDocument,
            getOrppdForm: getOrppdForm,
            getAvailablePromotersAndReviewers: getAvailablePromotersAndReviewers,
            savePassword: savePassword,
            getPassword: getPassword,
            getCountriesDictionary: getCountriesDictionary,
            getDocumentTypeDictionary: getDocumentTypeDictionary
        };
        return service;

        function sendDocument(form) {
            return $http.post('api/send-to-orppd/', form).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getOrppdForm(docId) {
            return $http.get('api/orppd/form/' + docId).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAvailablePromotersAndReviewers(docId) {
            return $http.get('api/orppd/form/' + docId + '/promoters-and-reviewers').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function savePassword(password) {
            return $http.post('api/orppd/password?password=' + password).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getPassword() {
            return $http.get('api/orppd/password').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getCountriesDictionary() {
            return $http.get('api/orppd/dictionary/countries').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDocumentTypeDictionary() {
            return $http.get('api/orppd/dictionary/document-types').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    }

})();
