(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('Auth', Auth);

    Auth.$inject = ['$rootScope', '$state', '$sessionStorage', '$q', '$translate', 'Principal', 'AuthServerProvider',
        'Account', 'Register', 'Activate', 'Password', 'PasswordResetInit', 'PasswordResetFinish', 'AccountService', '$log',
        'ActivateUserCreated', '$localStorage'];

    function Auth ($rootScope, $state, $sessionStorage, $q, $translate, Principal, AuthServerProvider, Account, Register,
                   Activate, Password, PasswordResetInit, PasswordResetFinish, AccountService, $log, ActivateUserCreated,
                   $localStorage) {
        var service = {
            activateAccount: activateAccount,
            activateAccountForCreatedUser: activateAccountForCreatedUser,
            authorize: authorize,
            changePassword: changePassword,
            createAccount: createAccount,
            getPreviousState: getPreviousState,
            login: login,
            loginWithToken:loginWithToken,
            logout: logout,
            resetPasswordFinish: resetPasswordFinish,
            resetPasswordInit: resetPasswordInit,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState,
            updateAccount: updateAccount,
            getPasswordPattern: getPasswordPattern,
            redirect: redirect
        };

        return service;

        function activateAccount (key, callback) {
            var cb = callback || angular.noop;

            return Activate.post(key,
                function (response) {
                    return cb(response);
                }).$promise;
        }

        function activateAccountForCreatedUser(key, callback) {
            var cb = callback || angular.noop;

            return ActivateUserCreated.post(key,
                function (response) {
                    return cb(response);
                }).$promise;
        }

        function authorize (force) {
            var authReturn = Principal.identity(force).then(authThen);

            return authReturn;

            function authThen () {
                if ($rootScope.toState.parent === 'landing') {
                    if ($rootScope.toStateParams.key === 'facebook' && $sessionStorage.landingPageFileId) {
                        return;
                    }
                }

                var isAuthenticated = Principal.isAuthenticated();

                // an authenticated user can't access to login and register pages
                if (isAuthenticated && ($rootScope.toState.parent === 'landing' || $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'activate' || $rootScope.toState.name === 'activate' || $rootScope.toState.name === 'login' || $rootScope.toState.name === 'register' || $rootScope.toState.name === 'requestReset' || $rootScope.toState.name === 'finishReset' || $rootScope.toState.name === 'social-auth' || $rootScope.toState.name === 'social-register'))) {
                    var ii = Principal.identity();
                    
                    if (AccountService.clientType && AccountService.clientType.isPap()) {
                        $state.go('portals');
                    } else {
                        $state.go('documents');
                    }
                }

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0
                    && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)
                    && $rootScope.toState.data.authorities[0] !== 'open' && $rootScope.toState.data.authorities[0] !== 'ANY') {
                    if (isAuthenticated) {
                        if(!$localStorage.returnFromUser) {
                            // user is signed in but not authorized for desired state
                            $state.go('accessdenied');
                        } else {
                            $localStorage.returnFromUser = null;
                        }
                    }
                    else {
                        // user is not authenticated. stow the state they wanted before you
                        // send them to the login service, so you can return them when you're done
                        storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
                        // now, send them to the signin state so they can log in
                        $state.go('login');
                    }
                }
                if ($rootScope.toState.data.authorities
                    && $rootScope.toState.data.authorities.length > 0
                    && $rootScope.toState.data.authorities[0] === 'ANY'
                    && !isAuthenticated) {
                    storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
                    $state.go('login');
                }
            }
        }

        function changePassword (newPassword, callback) {
            var cb = callback || angular.noop;

            return Password.save(newPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function createAccount (account, callback) {
            var cb = callback || angular.noop;

            return Register.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    this.logout();
                    return cb(err);
                }.bind(this)).$promise;
        }

        function loginWithToken(jwt) {
            return AuthServerProvider.loginWithToken(jwt);
        }

        function login (credentials, callback) {
            var cb = callback || angular.noop;
            var deferred = $q.defer();

            AuthServerProvider.login(credentials)
                .then(loginThen)
                .catch(function (err) {
                    this.logout();
                    deferred.reject(err);
                    return cb(err);
                }.bind(this));

            function loginThen (data) {
                Principal.identity(true).then(function(account) {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    if (account!== null) {
                        $translate.use(account.langKey).then(function () {
                            $translate.refresh();
                        });
                    }
                    deferred.resolve(data);
                });
                return cb();
            }

            return deferred.promise;
        }


        function logout () {
            AuthServerProvider.logout();
            Principal.authenticate(null);
            AccountService.account.userAcceptsRegulations = true;
        }

        function resetPasswordFinish (keyAndPassword, callback) {
            var cb = callback || angular.noop;

            return PasswordResetFinish.save(keyAndPassword, function () {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function resetPasswordInit (mail, callback) {
            var cb = callback || angular.noop;

            return PasswordResetInit.save(mail, function() {
                return cb();
            }, function (err) {
                return cb(err);
            }).$promise;
        }

        function updateAccount (account, callback) {
            var cb = callback || angular.noop;

            return Account.save(account,
                function () {
                    return cb(account);
                },
                function (err) {
                    return cb(err);
                }.bind(this)).$promise;
        }

        function getPreviousState() {
            var previousState = $sessionStorage.previousState;
            return previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            var previousState = { "name": previousStateName, "params": previousStateParams };
            $sessionStorage.previousState = previousState;
        }

        function getPasswordPattern(){
        	return new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/);
        }

        function redirect(credentials, url){
            AuthServerProvider.redirect(credentials, url);
        }

    }
})();
