(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PopupCallMeWithTelController', PopupCallMeWithTelController);

    function PopupCallMeWithTelController($mdDialog, ModalService, PopupAdministrationService, popup, closeCallback) {
        var vm = this;
        vm.popup = popup;
        vm.tel = '';
        vm.gdpr = false;

        this.callMeWithTelEnd = function() {
            close();
            closeCallback(vm.tel);
        }

        this.close = function() {
            $mdDialog.hide();
        }

        this.buttonDisabled = function () {
            return (vm.tel === null) || (vm.tel === undefined) || (vm.tel === '') || (!vm.gdpr);
        }

    }

})();
