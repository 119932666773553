(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('organisationalUnitsAddEdit', {
            parent: 'account',
            url: '/organisational-units-add-edit/:organisationalUnitId',
            data: {
            	authorities: ['PERM_USER_ADMIN', 'PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.organisational.units'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/organisational-units/organisational-units-form/organisational-units-form.html',
                    controller: 'OrganisationalUnitsAddEditController',
                    controllerAs: 'vm'
                }
            }
        });
    }

})();
