(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('SuspectReportsService', SuspectReportsService);
    
    SuspectReportsService.$inject = ['$http', '$q'];

    function SuspectReportsService($http, $q) {

        var service = {
            downloadReport: downloadReport,
            search: search
        };
        var requestUrl = 'api/suspect-reports';
        return service;

        function downloadReport(inforSuspectId) {
            // const id = 1;
            return $http({
                url : '/api/suspect-reports/download',
                method: "POST",
                data: inforSuspectId,
                headers: {
                    'Content-type': 'application/json',
                    'Accept': 'application/pdf, application/json'
                },
                responseType: 'arraybuffer'
            }).then(
                function(success) {
                    var blob = new Blob(
                        [ success.data ],
                        {
                            type: "text/pdf; encoding=UTF-8"
                        });
                    
                    // var fileName = 'rep-' + id.replace( /[<>:"\/\\|?*]+/g, '-' ) + ".pdf";
                    var fileName = 'report' + ".pdf"; // TODO
                    saveAs(blob, fileName);
                }, function(errResponse) {
                    return $q.reject(errResponse);
                }
            );
            
        }
        
        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }
})();
