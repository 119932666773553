(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .factory('PolonStudyService', PolonStudyService);

    PolonStudyService.$inject = ['$http', '$q'];

    function PolonStudyService($http, $q) {
        var service = {
            getOrganisationalUnitId: getOrganisationalUnitId,
            getAllNotAssignedToOrganisationalUnit: getAllNotAssignedToOrganisationalUnit
        };
        return service;
        function getOrganisationalUnitId(studyId) {
            return $http({
                url: 'api/polon-study/' + studyId + '/organisational-unit-id', method: 'GET'
            }).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function getAllNotAssignedToOrganisationalUnit() {
            return $http({
                url: 'api/polon-study/not-assigned-to-organisational-unit', method: 'GET'
            }).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    }

})();
