(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PopupAdministrationAddController', PopupAdministrationAddController);

    PopupAdministrationAddController.$inject = ['PopupAdministrationService', 'ModalService', 'SearchRequest', 'QueryRequest',
        'USERROLES', 'POPUP_OPTIONS', '$localStorage', '$state', '$stateParams'];

    function PopupAdministrationAddController(PopupAdministrationService, ModalService, SearchRequest, QueryRequest, 
        USERROLES, POPUP_OPTIONS, $localStorage, $state, $stateParams) {
        var vm = this;
        vm.activeTab = 'popupAdministration';
        
        vm.obj = {};
        vm.roles = USERROLES.SUPERADMIN;
        vm.options = POPUP_OPTIONS;
        vm.saveOrUpdate = saveOrUpdate;
        
        this.$onInit = function () {
            if ($stateParams.id) {
                PopupAdministrationService.getPopupForEditing($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                    if (vm.obj.dateFrom) {
                        vm.obj.dateFrom = new Date(vm.obj.dateFrom);
                    }
                    if (vm.obj.dateTo) {
                        vm.obj.dateTo = new Date(vm.obj.dateTo);
                    }
                });
            } else {
                vm.obj.dateFrom = new Date();
            }
        };

        function saveOrUpdate() {
            PopupAdministrationService
                .saveOrUpdate(vm.obj)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('popup-administration');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
    }
})();
