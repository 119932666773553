
(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('translationStorageProvider', translationStorageProvider);

    translationStorageProvider.$inject = ['$q','$cookies', '$log', 'LANGUAGES', '$location', 'DOMAINS'];

    function translationStorageProvider($q, $cookies, $log, LANGUAGES, $location,  DOMAINS) {

        var host = $location.host();
        var instanceLang;
        
        return {
            get: get,
            put: put
        };

        function getInstance(){
            var deferred = $q.defer();
            var lang;
            angular.forEach(DOMAINS, function(value, key) {
                if( host === value.domain ) {
                    lang = value.lang;
                }
            });

            deferred.resolve(lang);
            return lang;
        }

        function get(name) {
            var retObj = getInstance();

            if (LANGUAGES.indexOf($cookies.getObject(name)) === -1) {
                $log.info('Resetting invalid cookie language "' + $cookies.getObject(name) + '" to preferred language ');
                $cookies.putObject(name, retObj);
            }
            return $cookies.getObject(name);
        }

        function put(name, value) {
            $cookies.putObject(name, value);
        }
    }
})();
