(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('ClientQueryRequest', function () {

        function ClientQueryRequest() {
            this.order = 'clieName';
            this.page = 1;
            this.limit = 5;
        }

        return ClientQueryRequest;
    });
})();
