(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('UserLoginHistoryController', UserLoginHistoryController);

    UserLoginHistoryController.$inject = [
        '$mdDialog', '$q',
        'userId', '$rootScope', 'ModalService',
        'SearchRequest', 'UserLoginHistoryService'
    ];

    function UserLoginHistoryController(
        $mdDialog, $q, userId, $rootScope, ModalService,
        SearchRequest, UserLoginHistoryService
    ) {
        var vm = this;

        vm.page;
        vm.searchRequest;
        vm.pageOfTranslations = {};

        this.$onInit = function () {
            vm.find(1);
        };

        vm.find = function (pageNumber) {
            vm.pending = true;
            vm.searchRequest = new SearchRequest();
            vm.searchRequest.userId = userId;
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = 20;
            vm.searchRequest.sortTab = ['loginTimestamp,desc'];

            UserLoginHistoryService.findAllByUser(vm.searchRequest).then(
                function (response) {
                    vm.pending = false;
                    vm.page = response.data;
                    vm.page.number++;
                }, function (errorResponse) {
                    vm.pending = false;
                    $mdDialog.cancel();
                    ModalService.showErrorModal(errorResponse);
                }
            );
        };

        vm.closeDialog = function () {
            $mdDialog.cancel();
            $rootScope.$broadcast('on-close-modal-translation-edit');
        };
    }

})();
