(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .constant('PAYMENT_TYPES', [
        	{name: 'TYPE_SMS', id: '0', langKey : 'payments.payment.type.sms'},
        	{name: 'TYPE_TRANSFER', id: '1', langKey : 'payments.payment.type.transfer'},
        	{name: 'TYPE_CREDIT_CARD', id: '2', langKey : 'payments.payment.type.credit.card'},
        	{name: 'TYPE_PAY_PAL', id: '3', langKey : 'payments.payment.type.pay.pal'},
        	{name: 'TYPE_MOBILPAY_SMS', id: '4', langKey : 'payments.payment.type.mobilpay.sms'},
        	{name: 'TYPE_MOBILPAY_CARD', id: '5', langKey : 'payments.payment.type.mobilpay.card'},
        	{name: 'TYPE_MILLIKART_CARD', id: '6', langKey : 'payments.payment.type.millikart.card'},
        	{name: 'TYPE_EASY_PAY', id: '7', langKey : 'payments.payment.type.easy.pay'},
        	{name: 'TYPE_KASSA_24', id: '8', langKey : 'payments.payment.type.kassa.24'},
        	{name: 'TYPE_BLIK', id: '9', langKey : 'payments.payment.type.blik'},
        	{name: 'TYPE_CODE', id: '10', langKey : 'payments.payment.type.code'},
            {name: 'TYPE_EASY_PAY_TERMINAL', id: '11', langKey : 'payments.payment.type.easy.pay.terminal'},
            {name: 'TYPE_KASSA_24_TERMINAL', id: '12', langKey : 'payments.payment.type.kassa.24.pay.terminal'},
            {name: 'TYPE_MOBILTEK', id: '13', langKey : 'payments.payment.type.mobiltek'},
            {name: 'TYPE_PAYME', id: '14', langKey : 'payments.payment.type.payme'},
            {name: 'TYPE_KASSA_24_CARD', id: '15', langKey : 'payments.payment.type.kassa24card'},
            {name: 'TYPE_YIGIM_AZ', id: '16', langKey : 'payments.payment.type.yigim'},
            {name: 'TYPE_EPAY_BG', id: '17', langKey : 'payments.payment.type.epay.bg'},
            {name: 'TYPE_PAY_PAL_RO', id: '18', langKey : 'payments.payment.type.pay.pal_ro'},
            {name: 'TYPE_KASPI_BANK', id: '19', langKey : 'payments.payment.type.kaspi_bank'},
            {name: 'TYPE_FORTE_KZ', id: '20', langKey : 'payments.payment.type.forte_kz'},

        	{name: 'TYPE_FREE', id: '99', langKey : 'payments.payment.type.free'}
        ])
        .constant('PAYMENT_STATUSES', [
          {name: 'SENT_TO_ALLPAY', id: '-1', langKey : 'payments.payment.status.sent.to.allpay'},
          {name: 'SUSPENDED', id: '0', langKey : 'payments.payment.status.suspended'},
          {name: 'NEW', id: '1', langKey : 'payments.payment.status.new'},
          {name: 'COMPLETED', id: '2', langKey : 'payments.payment.status.success'},
          {name: 'REJECTED', id: '3', langKey : 'payments.payment.status.rejected'},
          {name: 'CANCELLED', id: '4', langKey : 'payments.payment.status.cancelled'},
          {name: 'RECLAMATION', id: '5', langKey : 'payments.payment.status.reclamation'},
        ])
        .constant('INVOICE_STATUSES', [
            {name: 'NEW', id: '0', langKey : 'payments.invoice.status.0'},
            {name: 'PAID', id: '1', langKey : 'payments.invoice.status.1'},
            {name: 'ISSUED', id: '2', langKey : 'payments.invoice.status.2'},
            {name: 'WAITING', id: '3', langKey : 'payments.invoice.status.3'},
            ]
        )
    ;
})();