(function () {
    'use strict';

    function ApiKeysSearchController($scope, $rootScope, $state, $translate, $mdDialog, $log,
    		apiKeysQueryRequest, apiKeysSearchRequest, $localStorage, SIMPLE_SEARCH_DELAY,
    		ClientService, SearchRequest, QueryRequest, SearchService, ClientQueryRequest, UserService, $q, $timeout,
    		CLIENTSTATUSES) {
        var vm = this;
        vm.simpleSearch = true;
        vm.getData = getData;
        vm.clear = clear;
        vm.goToAddPage = goToAddPage;
        vm.toggleSearch = toggleSearch;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.querySearchClients = querySearchClients;
        vm.selectedClientChange = selectedClientChange;
        vm.searchRequest = new apiKeysSearchRequest();
        vm.queryRequest = new apiKeysQueryRequest();
        vm.clientQueryRequest = new ClientQueryRequest();
        vm.userQueryRequest = new QueryRequest();
        vm.selectedUserChange = selectedUserChange;
        vm.querySearchUsers = querySearchUsers;
        vm.statuses = CLIENTSTATUSES;
        
        function goToAddPage() {
            $state.go('apiKeysAdd');
        }
        
        function getData() {
            $localStorage.apiKeysListLimit = vm.onQueryRequest.limit;
            vm.onGetData(vm.onSearchRequest);
        }
        
        function clear() {
            vm.selectedClient = undefined;
            vm.selectedUser = undefined;
            vm.onClear();
        }
        
        function toggleSearch() {
            vm.onSearchRequest.simpleSearch = '';
            vm.simpleSearch = !vm.simpleSearch;
            $localStorage.apiKeysSimpleSearchEnabled = vm.simpleSearch;
        }

        function selectedClientChange(item) {
            if(angular.isDefined(item)) {
                vm.onSearchRequest.search.clientId = item.id;
            } else {
                vm.onSearchRequest.search.clientId = null;
            }
            vm.getData();
        }
        
        function querySearchClients(query) {
            vm.searchRequest.pageNumber = 0;
            vm.searchRequest.pageSize = 20;
            vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
            vm.searchRequest.search = query;
            vm.searchRequest.simpleSearch = query;
            
            return ClientService.searchClients(vm.searchRequest).then(function(response) {
                return response.content;
            });
        }
        
        function selectedUserChange(item) {
            if(angular.isDefined(item)) {
                vm.onSearchRequest.search.userId = item.id;
            } else {
                vm.onSearchRequest.search.userId = null;
            }
            vm.getData();
        }
        
        function querySearchUsers(query) {
            if(query.length > 2) {
	            vm.searchRequest.pageNumber = 0;
	            vm.searchRequest.pageSize = 20;
	            vm.searchRequest.sortTab = SearchService.sort(vm.userQueryRequest);
	            vm.searchRequest.search = query;
	            vm.searchRequest.simpleSearch = query;
	            
	            return UserService.searchUsers(vm.searchRequest).then(function(responseData) {
	                 return responseData.content;
	            })
            } else {
            	var deferred = $q.defer();
            	deferred.resolve([]);
            	return deferred.promise;
            }
        }

        
    }

    angular.module('plagiat2017App')
            .component('apKeysSearch', {
                templateUrl: 'app/account/settings/ap-keys/ap-keys-search/ap-keys-search.template.html',
                controller: ApiKeysSearchController,
                bindings: {
                    onGetData: '<',
                    onQueryRequest: '<',
                    onSearchRequest: '<',
                    onClear: '<',
                    simpleSearch: '<',
                }
            });

})();
