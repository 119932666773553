(function () {
    'use strict';

    function ClientCreateStepCodeAccountController(InstanceService, PaymentInitRequest) {
        var vm = this;
        vm.isEverythingValidEngine = isEverythingValidEngine;

        this.$onInit = function () {
            vm.isEverythingValid = true;
            vm.paymentInit = new PaymentInitRequest();
            vm.instance = vm.client.basicData.clientInstance;
            if (!vm.client.codeAccount.codeTypePrices || vm.client.codeAccount.codeTypePrices.length === 0) {
                vm.addCodeTypePrices();
            }
            vm.availableCurrencies = InstanceService.findInstanceByBrand(vm.instance).currencies.list;
            vm.availablePayments = {};
            InstanceService.findInstanceByBrand(vm.instance).currencies.settings.forEach(function (currencySetting) {
                vm.availablePayments[currencySetting.name] = currencySetting.methodsOfPayment;
            });
            isEverythingValidEngine();
        };

        function isEverythingValidEngine() {
            var currencyValid = vm.client.codeAccount.currencies.length > 0;
            var codeTypesValid = vm.client.codeAccount.codeTypePrices && vm.client.codeAccount.codeTypePrices.length > 0;
            var paymentsValid = true;
            for (var currency in vm.client.codeAccount.payments) {
                if (vm.client.codeAccount.payments[currency].length === 0) {
                    paymentsValid = false;
                    break;
                }
            }
            vm.isEverythingValid = paymentsValid && currencyValid && codeTypesValid;
        }

        vm.onClickNext = function () {
            isEverythingValidEngine();
            if (vm.isEverythingValid) {
                vm.stepCodeAccountCompleted();
            }
        }

        vm.onClickCurrency = function (currency) {
            var index = vm.client.codeAccount.currencies.indexOf(currency);
            if (index > -1) {
                vm.client.codeAccount.currencies.splice(index, 1);
                delete vm.client.codeAccount.payments[currency];
            } else {
                vm.client.codeAccount.currencies.push(currency);
            }
            isEverythingValidEngine();
        };

        vm.onClickPayment = function (currency, paymentMethod) {
            if (!vm.client.codeAccount.payments[currency]) {
                vm.client.codeAccount.payments[currency] = [];
            }
            var index = vm.client.codeAccount.payments[currency].indexOf(paymentMethod);
            if (index > -1) {
                vm.client.codeAccount.payments[currency].splice(index, 1);
            } else {
                vm.client.codeAccount.payments[currency].push(paymentMethod);
            }
            isEverythingValidEngine();
        };

        vm.addCodeTypePrices = function () {
            vm.client.codeAccount.codeTypePrices.push({name: "", size: 0, currencies: {}});
            isEverythingValidEngine();
        };

        vm.deleteCodeTypePrices = function (index) {
            vm.client.codeAccount.codeTypePrices.splice(index, 1);
            isEverythingValidEngine();
        };
    }

    angular
        .module('plagiat2017App')
        .component('clientCreateStepCodeAccount', {
            templateUrl: 'app/client/client-add/steps/client-create-step-code-account.html',
            controller: ClientCreateStepCodeAccountController,
            controllerAs: 'vm',
            bindings: {
                client: '=',
                availableCurrencies: '=',
                availablePayments: '=',
                isSaving: '=',
                isEdit: '=',
                previousStep: '&',
                saveOrUpdate: '&',
                stepCodeAccountCompleted: '&'
            }
        });
})();
