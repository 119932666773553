(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('DocumentFormService', DocumentFormService);

    DocumentFormService.$inject = [];

    function DocumentFormService() {

        var service = {
            documentSkipAddress: documentSkipAddress
        }

        return service;

        function documentSkipAddress(chips, vm) {

            if (vm.onUploadObj.documentSkipAddresses) {
                vm.onUploadObj.documentSkipAddresses = vm.onUploadObj.documentSkipAddresses.slice(0); // workaround na bug w md-chips
            }

            vm.skipAddressesInvalidError = false;
            vm.skipAddressesInvalidUrl = null;

            var chipsArray = chips.split(/[\n\s,]+/);
            angular.forEach(chipsArray, function(chip) {
                if (chip.startsWith("http")) {
                    if (!isValidUrl(chip)) {
                        vm.skipAddressesInvalidError = true;
                        vm.skipAddressesInvalidUrl = chip;
                    } else {
                        const chipObj = {address: chip};
                        if (vm.onUploadObj.documentSkipAddresses.indexOf(chipObj) < 0) {
                            vm.onUploadObj.documentSkipAddresses.push(chipObj);
                        }
                    }
                }
            });

            return null;    
        }
        
        function isValidUrl(string) {
            try {
                new URL(string);
            } catch (_) {
                return false;
            }
            return true;
        }

    }

})();
