(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .constant('PORTAL_OF_CLIENT_TYPES', [
        {'id': 1, 'key': 'reports.portal-of-client.type.native' },
        {'id': 2, 'key': 'reports.portal-of-client.type.client' }
        ])
    .constant('SUSPECT_TYPES', [
        {'id': 1, 'key': 'reports.portal-of-client.type.client' },
        {'id': 2, 'key': 'reports.portal-of-client.type.illegal' },
        {'id': 3, 'key': 'reports.portal-of-client.type.native' }
        ])
    ;
})();
