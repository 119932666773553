(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('IodoController', IodoController);

    IodoController.$inject = ['AccountService', 'InstanceService', '$mdDialog', '$translate', 'InfoService', 
    'ModalService', 'PlagiatValidationService', '$cookies', '$scope'];

    function IodoController (AccountService, InstanceService, $mdDialog, $translate, InfoService, 
        ModalService, PlagiatValidationService, $cookies, $scope) {

        var vm = this;
        vm.activeTab = 'iodo';
        vm.acceptRodo = angular.isUndefined(AccountService.account.acceptRodo) || AccountService.account.acceptRodo == null ? true : AccountService.account.acceptRodo;
        vm.accept = vm.acceptRodo;
        vm.verifyAcceptTerms = verifyAcceptTerms;

        if (AccountService.account.clientRegionId == 8) {
            // dla klientów z bulgarskiego ministerstwa wyświetlamy rumuńskie napisy
            vm.instance = '_ro';
        } else {
            vm.instance = InstanceService.instance().lang === 'en' ? '_int' : '_' + InstanceService.instance().lang;
        }

        vm.acceptTitleHtml = '<h2 data-translate="global.iodo.confirm.title">Potwierdź brak akcpetacji podpowierzania danych</h2>';
        vm.acceptInfoHtml = '<span class="dialog-info">' + $translate.instant("global.iodo.confirm.info" + vm.instance)  + '</span>';

        $scope.$on('langChanged', function () {
          getIodoCompanies();
        });

        function getIodoCompanies(){
          InfoService.getIodoCompanies($cookies.getObject('NG_TRANSLATE_LANG_KEY')).then(function(response){
            vm.companies = response['iodo.companies.content' + vm.instance];
          });
        }


        function verifyAcceptTerms() {

            if (!vm.accept) {
                $mdDialog.show({
                  template:
                  '<md-dialog aria-label="List dialog">' +
                  '  <md-toolbar>' +
                  '  <div class="md-toolbar-tools">' +
                      vm.acceptTitleHtml +
                  '  </div>' +
                  '  </md-toolbar>' +
                  '  <form name="form" ng-cloak>' +
                  '  <md-dialog-content>' +
                  '   <div class="md-dialog-content rodo-md-dialog-content">' +
                        vm.acceptInfoHtml +
                  '    <md-input-container class="md-block">' +
                  '     <input placeholder="{{\'global.iodo.confirm.client-id\' | translate}}" ng-model="_dialog.account.clientId" disabled>' +
                  '    </md-input-container>' +
                  '    <md-input-container class="md-block">' +
                  '     <input placeholder="{{\'global.iodo.confirm.reporting-person\' | translate}}" ng-model="_dialog.person" disabled>' +
                  '    </md-input-container>' +
                  '    </md-input-container>' +
                  '    <md-input-container class="md-block">' +
                  '     <textarea placeholder="{{\'global.iodo.confirm.reason\' | translate}}" ng-model="_dialog.reason" rows="4" md-autofocus></textarea>' +
                  '    </md-input-container>' +
                  '   </div>' +
                  '  </md-dialog-content>' +
                  '  <md-dialog-actions>' +
                  '    <md-button ng-click="_dialog.closeDialog()">' + $translate.instant("global.button.cancel") +
                  '    </md-button>' +
                  '    <md-button ng-click="_dialog.dontAccept()" ng-disabled="form.$invalid" class="md-primary">' + $translate.instant("global.button.confirm") +
                  '    </md-button>' +
                  '  </md-dialog-actions>' +
                  '  </form>' +
                  '</md-dialog>',
                  clickOutsideToClose: false,
                  escapeToClose: false,
                  parent: angular.element(document.getElementById('mainContent')),
                  controllerAs: '_dialog',
                  controller: function DialogController($scope, $mdDialog) {
                    var _dialog = this;
                    _dialog.closeDialog = closeDialog;
                    _dialog.dontAccept = dontAccept;
                    _dialog.formData = new FormData();
                    _dialog.account = AccountService.account;
                    _dialog.person = _dialog.account.firstName + ' ' + _dialog.account.lastName;
                    function closeDialog() {
                      $mdDialog.hide();
                      vm.accept = true;
                    }
                    function dontAccept() {
                        _dialog.formData.append('message', _dialog.reason);
                        InfoService.dontAccept(_dialog.formData).then(function() {
                            $mdDialog.hide();
                            ModalService.showSuccessModal($translate.instant("global.iodo.confirm.success"));
                        }).catch(function (response) {
                            PlagiatValidationService.getModalError(response.data);
                        });
                    }
                  }
                });
            } 
        }

        getIodoCompanies();

   }

})();
