(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('UsersAddController', UsersAddController);

    UsersAddController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'USERROLES', 'USER_QUERY_COUNTER_TYPES', 'UserService',
    	'InstanceService', '$state', '$cookies', '$stateParams', 'OrganisationalUnitsService', '$mdDialog', 'PlagiatValidationService', 'JsaService', 'AccountService'];

    function UsersAddController (Principal, Auth, JhiLanguageService, $translate, USERROLES, USER_QUERY_COUNTER_TYPES, UserService,
    		InstanceService, $state, $cookies, $stateParams, OrganisationalUnitsService, $mdDialog, PlagiatValidationService, JsaService, AccountService) {
        var vm = this;

        vm.instance = InstanceService.instance().lang;
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.key = $stateParams.key;
        vm.selected = [];
        vm.saveUser = saveUser;
        vm.isSchool = AccountService.clientType.isSchool();
        vm.backToUserList = backToUserList;
        vm.jsaIntegration = AccountService.getClientSettings().jsaIntegration;
        vm.loggedUserIsOrgUnitAdmin = AccountService.role.isOrgUnitAdmin();
        vm.academicTitleVisible = !AccountService.clientType.isPublishingHouse() && !AccountService.clientType.isSchool()  && !AccountService.clientType.isPap();
        vm.testPoolVisible = !AccountService.clientType.isPap();
        vm.roles = USERROLES.getRolesForCurrentClient(AccountService);
        vm.userQueryCounterTypes = USER_QUERY_COUNTER_TYPES;
        vm.userQueryCountersKeyPress = userQueryCountersKeyPress;
        vm.userQueryCountersKeyUp = userQueryCountersKeyUp;
		vm.form = {
			userQueryCounterType: USER_QUERY_COUNTER_TYPES[0].id,
      userQueryCounter: 1000
		};
		vm.canEditMail = true;
        findOrganisationalUnits();
        if(vm.jsaIntegration) {
            getPolonUsers();
        }

        function getPolonUsers() {
            JsaService.getPolonEmployeesAddUser().then(function(response) {
                vm.polonEmployees = response;
            });
        }

		function findOrganisationalUnits() {
			if (vm.loggedUserIsOrgUnitAdmin) {
                vm.form.organisationalUnitId = AccountService.account.organisationalUnitId;
            }
            OrganisationalUnitsService.findAllForClient().then(function(response) {
                vm.organisationalUnits = response.data;
                vm.selectedOrganization = vm.organisationalUnits.find(function (element) {
                    return element.id === vm.form.organisationalUnitId;
                });
            });
		}

        function backToUserList(){
        	$state.go('users');
        }

        function saveUser(form, ev){
        	UserService.addUser(form).then(function(){
        		backToUserList();

                $mdDialog.show(
                        $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("users.form.new.user.msg"))
                        .ariaLabel('msgSuccess')
                        .ok('Ok')
                        .targetEvent(ev)
                        );
        	}, function(response){
        		PlagiatValidationService.getModalError(response.data);
        	})
        }
        
        function userQueryCountersKeyPress(event){
        	if (event.target.value.length >= event.target.maxLength || !(event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))){
        		event.preventDefault();
        	}
        }
        
        function userQueryCountersKeyUp(event){
        	if (event.target.value.length >= event.target.maxLength){
        		event.preventDefault();
        		event.target.value = event.target.value.slice(0, event.target.maxLength);        		
        		vm.form.userQueryCounter = event.target.value;
        	}
        }

        vm.querySearchOrganizations = function () {
            return vm.searchOrganizationText ? vm.organisationalUnits.filter(function (org) {
                return org.orguniLabel.toLowerCase().includes(vm.searchOrganizationText.toLowerCase());
            }) : vm.organisationalUnits;
        };

        vm.selectedOrganizationChange = function (item) {
            if (item) {
                vm.form.organisationalUnitId = item.id;
            } else {
                vm.form.organisationalUnitId = null;
            }
        };

    }
})();
