(function () {
    'use strict';

    function DatabaseExchangeProgramMembersSearchController($localStorage, SIMPLE_SEARCH_DELAY) {

        var vm = this;
        vm.simpleSearch = true;
        vm.getClients = getClients;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;

        function getClients() {
            $localStorage.clientListlimit = vm.onQueryRequest.limit;
            vm.onSearchRequest.simpleSearch.length === 0 ? vm.onGetClients() : '';
            vm.onSearchRequest.simpleSearch.length > 2 ? vm.onGetClients() : '';
        }

    }

    angular.module('plagiat2017App')
        .component('databaseExchangeProgramMembersSearch', {
            templateUrl: 'app/account/settings/database-exchange-program-members/database-exchange-program-members-search/database-exchange-program-members-search.template.html',
            controller: DatabaseExchangeProgramMembersSearchController,
            bindings: {
                onGetClients: '<',
                onQueryRequest: '<',
                onSearchRequest: '<',
                onClear: '<'
            }
        });

})();
