(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DetectInstanceService', DetectInstanceService);

    DetectInstanceService.$inject = ['$http', '$q', '$location', '$log', 'DOMAINS', 'INSTANCES'];

    function DetectInstanceService($http, $q, $location, $log, DOMAINS, INSTANCES) {
        var instanceLang, landingLang, uaConfig = {}, conversion, fb, register, domain;
        var host = $location.host();

        var service = {
            instance : getInstance,
            getLandingLang : getLandingLang,
            getAll: getAll,
            getUAConfig: getUAConfig,
            getPixelEnabled: getPixelEnabled,
            getConversionAllowance: getConversionAllowance,
            samlLoginButtons: samlLoginButtons,
            oauthLoginButtons: oauthLoginButtons,
            getFbLoginForm: getFbLoginForm,
            getAllowRegister: getAllowRegister,
            getInstanceDomain: getInstanceDomain,
            isRedirectEnabled: isRedirectEnabled
        };

        return service;

    function getInstance() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                instanceLang = value.instance;
            }
        });
        return instanceLang;
    }

    function getLandingLang() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                landingLang = value.landingLang;
            }
        });
        return landingLang;
    }

    function getInstanceDomain(instance) {

        if(host === 'localhost'){
            return 'localhost';
        }

        var subDomain = host.split('.')[0] + '.';

        angular.forEach(DOMAINS, function(value) {
            if( instance === value.instance && value.domain.startsWith(subDomain) ) {
                domain = value.domain;
            }
        });
        return domain;
    }

    function getUAConfig() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                uaConfig = value.ua;
            }
        });
        return uaConfig;
    }

    function getPixelEnabled() {
        var ret = false;
        angular.forEach(DOMAINS, function(value) {
            if (host === value.domain) {
                ret = value.pixel;
            }
        });
        return ret;
    }

    function getConversionAllowance() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                conversion = value.conversion;
            }
        });
        return conversion;
    }

    function getFbLoginForm() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                fb = value.fb;
            }
        });
        return fb;
    }

    function getAllowRegister() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                register = !value.disableRegister;
            }
        });
        return register;
    }

    function samlLoginButtons() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                register = value.saml;
            }
        });
        return register;
    }

    function oauthLoginButtons() {
        angular.forEach(DOMAINS, function(value) {
            if( host === value.domain ) {
                register = value.oauth;
            }
        });
        return register;
    }

        function isRedirectEnabled() {
            angular.forEach(DOMAINS, function(value) {
                if( host === value.domain ) {
                    register = value.redirect;
                }
            });
            return register;
        }

    function getAll() {
        var deferred = $q.defer();
        deferred.resolve(INSTANCES);
        return deferred.promise;
    }
}
})();
