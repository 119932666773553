(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('OrganisationalUnitsService', OrganisationalUnitsService);

    OrganisationalUnitsService.$inject = ['$http', '$q'];

    function OrganisationalUnitsService($http, $q) {

        var url = 'api/organisational-units';

        var service = {
            getList: getList,
            getAll: getAll,
            add: add,
            edit: edit,
            getForm: getForm,
            getSimpleById: getSimpleById,
            findByUserId: findByUserId,
            getSimpleForCurrentClient: getSimpleForCurrentClient,
            remove: remove,
            findAllForClient: findAllForClient,
            findAllByClient: findAllByClient
        };

        return service;

        function getList(obj) {
            return $http.post(url + '/list', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAll(obj) {
            return $http.get(url + '/all', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function add(obj) {
            return $http.put('api/organisational-units', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function edit(obj) {
            return $http.post('api/organisational-units', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getForm(id) {
            return $http.get('api/organisational-units/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getSimpleById(id) {
            return $http.get('api/organisational-units/simple/' + id).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findByUserId(id) {
            return $http.get('api/organisational-units/by-user-id/' + id).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getSimpleForCurrentClient() {
            return $http.get('api/organisational-units/simple/').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function remove(obj) {
            return $http.post(url + '/delete', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findAllForClient(editedDocumentId, editedUserId) {
            var url2 = url;
            if (editedDocumentId) {
                url2 += '?editedDocumentId=' + editedDocumentId;
            } else if (editedUserId) {
                url2 += '?editedUserId=' + editedUserId;
            }

            return $http.get(url2).then(
                function (response) {
                    return response.data;
                }, function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findAllByClient(clientId) {
            return $http.get(url + "/all-for-client/" + clientId).then(
                function (response) {
                    return response.data;
                }, function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    }

})();
