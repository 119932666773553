(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DuplicatesDialogService', DuplicatesDialogService);

    DuplicatesDialogService.$inject = ['DocumentService', 'JsaService', 'ContractService', 'PlagiatValidationService', '$translate', '$mdDialog', '$mdToast', '$rootScope'];


    function DuplicatesDialogService(DocumentService, JsaService, ContractService, PlagiatValidationService, $translate, $mdDialog, $mdToast, $rootScope) {
        var vm = this;
        var service = {
            saveDocumentWithPopUp: saveDocumentWithPopUp,
            saveJSADocumentWithPopUp: saveJSADocumentWithPopUp,
        };

        return service;

        function saveDocumentWithPopUp(obj,ev) {
            return DocumentService.operator.save(obj).then(function (responseData) {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("global.messages.info.actionsuccess"))
                        .ariaLabel('')
                        .ok('Ok')
                        .targetEvent(ev)
                );
                ContractService.broadcast();
                $rootScope.$broadcast('on-get-documents');
                return responseData.data;
            }).catch(function (response) {
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function saveJSADocumentWithPopUp(obj, alert) {
            JsaService.saveDocumentJSA(obj).then(function () {
                $mdDialog.hide(alert);
                $mdDialog.show(
                    $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("global.messages.info.actionsuccess"))
                        .ok('Ok')
                );
                ContractService.broadcast();
                $rootScope.$broadcast('on-get-documents');
            }).catch(function (response) {
                $rootScope.$broadcast('on-get-documents');
                $mdDialog.hide(alert);
                vm.isLoading = false;
                PlagiatValidationService.getModalError(response.data);
            });
        }
    }

})();
