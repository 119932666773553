(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state("peer-review-scoring", {
        parent: 'app',
        url: "/peer-review/:id/scoring",
        data: {
          pageTitle: "peer-review.scoring.title"
        },
        views: {
          'content@': {
            templateUrl: 'app/account/assignments/peer-review/scoring.component.html',
            controller: 'PeerReviewScoringController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('peer-review');
            $translatePartialLoader.addPart('feedback');
            return $translate.refresh();
          }]
        }
      })
  }

})();
