(function () {
    'use strict';

    angular
    .module('sessionService', [])
    .factory('sessionService',
      function () {

          var service = {};

          service.subscriptions = {};

          return service;

      });
})();
