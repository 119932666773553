(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('tableSettingsRequest', function () {

        function tableSettingsRequest() {
            this.rowSelection = true;
            this.multiSelect = true;
            this.autoSelect = true;
            this.decapitate = false;
            this.largeEditDialog = false;
            this.boundaryLinks = false;
            this.limitSelect = false;
            this.pageSelect = true;
        }

        return tableSettingsRequest;
    });
})();