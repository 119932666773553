(function() {
    'use strict';

    function TokenController($scope, TokenService, $mdToast, $state, $log) {
        var vm = this;
        vm.getTokensInfo = getTokensInfo;
        vm.buyTokens = buyTokens;

        getTokensInfo();
        $scope.$on("availableTokens",function () {
            getTokensInfo();
        } );

        function getTokensInfo() {
            TokenService.getInfo().then(function(responseData) {
                vm.tokens = responseData.data;
            });
        }

        function buyTokens(){
            TokenService.buyTokens = true;
            $state.go('payments');
        }

    }

    angular.module('token', [])
  .component('token', {
      templateUrl: 'app/components/token/token.template.html',
      controller: TokenController,
      bindings: {}
  });

})();
