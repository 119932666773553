(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ActivationController', ActivationController);

    ActivationController.$inject = ['$stateParams', 'Auth', '$cookies', 'DetectInstanceService'];

    function ActivationController ($stateParams, Auth, $cookies, DetectInstanceService) {
        var vm = this;
        vm.conversionDomain = DetectInstanceService.getConversionAllowance();
        vm.theme = $cookies.get('plagiat-theme');

        Auth.activateAccount({key: $stateParams.key}).then(function () {
            vm.error = null;
            vm.success = 'OK';
        }).catch(function () {
            vm.success = null;
            vm.error = 'ERROR';
        });

    }
})();
