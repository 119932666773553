(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .directive('formDisabled', formDisabled);

    function formDisabled() {
        return {
            restrict: 'A',
            require: 'form',
            link: linkFunc,
            scope: {
                formDisabled: '<'
            }
        };

        function linkFunc(scope, element) {
            scope.$watch('formDisabled', function (newValue) {
                element.find(":input").attr('disabled', newValue);
            });
        }
    }
})();
