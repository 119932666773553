(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('organisationalUnitsQueryRequest', function () {

        function queryRequest() {
            this.order = '-id';
            this.page = 1;
            this.limit = 5;
            this.search = {};
        }

        return queryRequest;
    });
})();
