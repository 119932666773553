(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('AccountService', AccountService);

    AccountService.$inject = ['$http', '$q', '$log', '$sessionStorage', '$rootScope', '$localStorage', 
        'USERSCHOOLTYPE'];
    
    function AccountService($http, $q, $log, $sessionStorage, $rootScope, $localStorage, 
                            USERSCHOOLTYPE) {

        var service = {
            profile: {},
            setLang: setLang,
            setData: setData,
            setDataDetails: setDataDetails,
            getLoggedUserDetails: getLoggedUserDetails,
            setNewPassword: setNewPassword,
            acceptTerms: acceptTerms,
            forceChangePassword: forceChangePassword,
            account: {
                userAcceptsRegulations: null,
                pickedLang: null
            },
            getPermision: getPermision,
            getClientSettings: getClientSettings,
            getClientType: getClientType,
            getClientCountryId: getClientCountryId,
            getClientRegionOfficeEmail: getClientRegionOfficeEmail,
            role:{
                isIndividual: isIndividual,
                isOperator: isOperator,
                isStudent: isStudent,
                isLecturer: isLecturer,
                isAdmin: isAdmin,
                isUserAnAdmin: isUserAnAdmin,
                isSuperAdmin: isSuperAdmin,
                isMainSuperAdmin: isMainSuperAdmin,
                isReseller: isReseller,
                isMinistry: isMinistry,
                isOrgUnitAdmin: isOrgUnitAdmin,
                isMainAdmin: isMainAdmin
            },
            userRole: {
                isUserAnAdmin: isUserAnAdmin
            },
            broadcastRole: broadcastRole,
            setRole: setRole,
            clientType: {
                isClientTypeUnivStandard: isClientTypeUnivStandard,
                isClientTypeUnivAsap: isClientTypeUnivAsap,
                isPublishingHouse: isPublishingHouse,
                isSchool: isSchool,
                isPap: isPap,
                isCodeAccount: isClientTypeCodeAccount,
            },
            confirmEmail: confirmEmail,
            sendAccountDeletionConfirmationEmail: sendAccountDeletionConfirmationEmail,
            confirmAccountDeletion: confirmAccountDeletion
        };

        return service;

        function setRole(role){
            $sessionStorage.role.data.accountType = role;
            this.broadcastRole();
        }

        function getRole(){
            return $sessionStorage.role.data.accountType;
        }

        function getClientCountryId(){
            return $sessionStorage.role.data.clientCountryId;
        }

        function getClientRegionOfficeEmail() {
            return $sessionStorage.role.data.clientRegionOfficeEmail;
        }

        function getClientType(){
            return $sessionStorage.role.data.clientType;
        }

        function broadcastRole() {
            $rootScope.$broadcast("switchRole");
        }

        function isOperator(){
            return (!angular.isUndefined($sessionStorage.role) && ($sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_DEANERY.value
                || $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.value));
        }
        function isStudent(){
            return (!angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_STUDENT.value);
        }
        function isLecturer(){
            return (!angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_TEACHER.value);
        }
        function isIndividual(){
            return (!angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_INDIVIDUAL.value);
        }
        function isAdmin(){
            return (!angular.isUndefined($sessionStorage.role) && ($sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.value
                || $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_SUPERADMIN.value));
        }
        function isOrgUnitAdmin(){
            return (!angular.isUndefined($sessionStorage.role) && ($sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN.value));
        }
        function isUserAnAdmin(user){
            return user && user.userSchoolTypeEnum === USERSCHOOLTYPE.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR.value;
        }
        function isSuperAdmin(){
            return (!angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_SUPERADMIN.value);
        }
        function isReseller(){
            return (!angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_RESELLER.value);
        }
        function isMinistry(){
            return (!angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_MINISTRY.value);
        }
        function isMainAdmin(){
            return !angular.isUndefined($sessionStorage.role) && $sessionStorage.role.data.mainAdmin;
        }
        function isMainSuperAdmin() {
            return !angular.isUndefined($sessionStorage.role) 
                && $sessionStorage.role.data.accountType === USERSCHOOLTYPE.USER_SCHOOL_TYPE_SUPERADMIN.value
                && service.account.clientRegionAccess
                && service.account.clientRegionAccess.allRegions;
        }

        function isPublishingHouse() {
            return $sessionStorage.role && $sessionStorage.role.data.clientType === "PUBLISHING_HOUSE";
        }

        function isPap() {
            return $sessionStorage.role && $sessionStorage.role.data.clientType === "CLIENT_TYPE_PORTAL";
        }

        function isSchool() {
            return $sessionStorage.role && $sessionStorage.role.data.clientType === "SCHOOL";
        }

        function isClientTypeCodeAccount() {
            return $sessionStorage.role 
                && ($sessionStorage.role.data.clientType === "CODE_ACCOUNT" 
                    || $sessionStorage.role.data.clientType === "COLLEGE"
                );
        }

        function isClientTypeUnivStandard() {
            return $sessionStorage.role && $sessionStorage.role.data.clientType === "CLIENT_TYPE_STANDARD";
        }

        function isClientTypeUnivAsap() {
            return $sessionStorage.role && $sessionStorage.role.data.clientType === "CLIENT_TYPE_ASAP";
        }

        function forceChangePassword() {
            if ($localStorage.logoutToRootInfo || isIndividual()) { //nie pokazujemy dla przelogowanych i dla indywidualsów
                return false;
            }
            
            var passwordChangePeriod = getClientSettings().passwordChange;
            if (passwordChangePeriod) {
                var userLastPasswordChangeDate = new Date(service.account.userLastPasswordChangeDate);
                var forcePasswordChangeDate = new Date(userLastPasswordChangeDate.setDate(userLastPasswordChangeDate.getDate() + passwordChangePeriod));
                if (new Date() > forcePasswordChangeDate) {
                    return true;
                }
            }
            return false;
        }
        
        function getPermision(permissionKey){
        	var found = false;
            angular.forEach(service.account.authorities, function(value, key) {
                if( value === permissionKey ){
                	found = true;
                }
            });
            return found;
        }

        function getClientSettings(){
        	return service.account.clientSettings;
        }

        function setLang(languageKey) {
            return $http.post('api/internationalization/?lang=' + languageKey).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function setData(obj) {
            return $http.put('api/user/update', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function setNewPassword(obj) {
            return $http.put('api/user/password', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function acceptTerms(obj) {
            return $http.put('api/user/accept-terms', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getLoggedUserDetails(){
            return $http.get('api/user/logged-user-details').then(
                 function (response) {
                    return response.data;
                 },
                 function (errResponse) {
                    return $q.reject(errResponse);
                 });
        }

        function setDataDetails(obj) {
            return $http.put('api/user/update-details', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function confirmEmail(obj) {
            return $http.put('api/user/confirm-email', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function sendAccountDeletionConfirmationEmail() {
            return $http.post('api/user/delete-account/send-confirmation-email').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function confirmAccountDeletion(accountDeletionConfirmationCode) {
            return $http.post('api/user/delete-account/confirm', {key: accountDeletionConfirmationCode}).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
