(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .directive('samlLogin', samlLogin);

    samlLogin.$inject = ['$translatePartialLoader', '$translate', 'SSO_LOGIN_DATA'];

    function samlLogin($translatePartialLoader, $translate, SSO_LOGIN_DATA) {
        var directive = {
            restrict: 'E',
            scope: {
                partner: '@ngPartner',
                disabled: '=ngDisabled',
                onButtonClick: '&'
            },
            templateUrl: 'app/account/login/saml/saml-login.html',
            link: linkFunc
        };

        return directive;

        /* private helper methods */

        function linkFunc(scope) {

            $translate.refresh();

            scope.click = function() {
                scope.onButtonClick()(scope.partner);
            };

            scope.titles = SSO_LOGIN_DATA;

        }

    }
})();
