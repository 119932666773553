(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .factory('PeerReviewService', PeerReviewService);

  PeerReviewService.$inject = ['$http', '$q'];

  function PeerReviewService($http, $q) {

    var requestUrl = 'api/peer-review';

    var service = {
      getList: getList,
      findOne: findOne,
    };
    return service;

    function getList(pageableFilter) {
      return $http.post(requestUrl, pageableFilter).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

    function findOne(id) {
      return $http.get(requestUrl + '/' + id).then(
        function (response) {
          return response.data;
        },
        function (errResponse) {
          return $q.reject(errResponse);
        });
    }

  }
})();
