(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('PolonUnitService', PolonUnitService);

    PolonUnitService.$inject = ['$http', '$q'];

    function PolonUnitService($http, $q) {

        var service = {
            getAllForCurrentClient: getAllForCurrentClient,
            getAllForClient: getAllForClient,
            getAllByStudyId: getAllByStudyId
        };

        return service;

        function getAllForCurrentClient() {
            return $http({
                url: 'api/polon-unit', method: 'GET'
            }).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function getAllForClient(clientId) {
            return $http({
                url: 'api/polon-unit/client/' + clientId, method: 'GET'
            }).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function getAllByStudyId(studyId) {
            return $http({
                url: 'api/polon-unit/study/' + studyId , method: 'GET'
            }).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    }

})();
