(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('GoogleAdWordsService', GoogleAdWordsService);

    GoogleAdWordsService.$inject = ['$http', '$q', '$log'];

    function GoogleAdWordsService($http, $q, $log) {

        return {
            sendRegisterCustomerConversion: function () {
                // Trigger register-customer conversion 
                googleTrackConversion('register-customer');
            },
            sendBookOrderConversion: function () {
                  // Trigger book-order conversion 
                googleTrackConversion('book-order');
            },
            loadJavaScript: loadJavaScript
        };

        // Conversion labels 
        var google_conversion_label = {
            'register-customer': "12abCDef3gH5Klm6789",
            'book-order': "9876mlK5Hg3feDCba21"
        };
       // Basic settings for AdWords Conversion
        var googleTrackConversion = function (conversion_label) {
            $window.google_trackConversion({

            });
        };

        function loadJavaScript(filePath)
        {
            var headElement = document.getElementsByTagName("head")[0];
            var newScriptElement = document.createElement("script");
            newScriptElement.type = "text/javascript";
            newScriptElement.src = filePath;
            headElement.appendChild(newScriptElement);
        }
        
        


    }



})();
