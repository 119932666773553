(function() {
    'use strict';
    angular.module('plagiat2017App').factory('Utils', Utils);
    Utils.$inject = ['CharCode', '$translate'];

    function Utils(CharCode) {
		var service = {
				sortAutoTranslationsLanguages : sortAutoTranslationsLanguages,
				removeDiacritics : removeDiacritics,
				removeSpecialChars: removeSpecialChars,
				removeSpaces: removeSpaces,
				removeSpecialCharsForFileName : removeSpecialCharsForFileName,
				isAllowedCharForDigit: isAllowedCharForDigit,
				isAllowedCharForDate: isAllowedCharForDate,
				isAllowedCharForIntegerPositive: isAllowedCharForIntegerPositive,
				validateForm: validateForm
		};
		return service;

        function removeDiacritics(str) {
        	if (typeof str === 'string') {
                return str.replace(/ą/g, 'a').replace(/Ą/g, 'A')
                .replace(/ć/g, 'c').replace(/Ć/g, 'C')
                .replace(/ę/g, 'e').replace(/Ę/g, 'E')
                .replace(/ł/g, 'l').replace(/Ł/g, 'L')
                .replace(/ń/g, 'n').replace(/Ń/g, 'N')
                .replace(/ó/g, 'o').replace(/Ó/g, 'O')
                .replace(/ś/g, 's').replace(/Ś/g, 'S')
                .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
                .replace(/ź/g, 'z').replace(/Ź/g, 'Z');
        	} else {
        		return '';
        	}
        }

        function removeSpaces(str) {
        	if (typeof str === 'string') {
                return str.replace(/ +(?= )/g,'');
        	} else {
        		return '';
        	}
        }

        function removeSpecialChars(str) {
        	if (typeof str === 'string') {
        		return str.replace(/(?:[\u0022\u0023\u0025\u0026\u002a\u002f\u003a\u003b\u003c\u003e\u003f\u007c\u00b2\u00b3\u2211\u222b\u221e\u03c0])+/g, '');
        	} else {
        		return '';
        	}
        }

        function removeSpecialCharsForFileName(str) {
        	return service.removeSpaces(service.removeSpecialChars(service.removeDiacritics(str)));
        }
        
        function isAllowedCharForDigit(e) {
        	return (e.charCode == CharCode.NULL || e.charCode == CharCode.COMMA || e.charCode == CharCode.DOT || e.charCode == CharCode.ENTER || (e.charCode >= CharCode.CHAR_0 && e.charCode <= CharCode.CHAR_9));
        }
        
        function isAllowedCharForIntegerPositive(e) {
        	return (e.charCode == CharCode.NULL || e.charCode == CharCode.ENTER || (e.charCode >= CharCode.CHAR_0 && e.charCode <= CharCode.CHAR_9));
        }
        
        function isAllowedCharForDate(e) {
        	return (e.charCode == CharCode.NULL || e.charCode == CharCode.DASH || e.charCode == CharCode.ENTER || (e.charCode >= CharCode.CHAR_0 && e.charCode <= CharCode.CHAR_9));
        }

		/**
		 * Checks if given form is valid. Also it triggers error messages on fields that are invalid.
		 */
		function validateForm(form) {
			for (var i = 0; i < form.$$controls.length; i++) {
				form.$$controls[i].$touched = true;
			}
			return !form.$invalid;
		}
		
		function sortAutoTranslationsLanguages(langs, t) {
			var sorted = [];
			angular.forEach(langs, function (value) {
				sorted.push({code: value, translated: t.instant('document.report.similarity.language.' + value.toLowerCase())});
			});
			sorted.sort(function(a, b) {
				return a.translated.localeCompare(b.translated, undefined, {sensitivity: 'base'});
			});
			return sorted;
		}
	}

})();
