angular.module('plagiat2017App')
  .component('selfEnrollmentModal', {
    templateUrl: 'app/account/assignments/peer-review/library/create-library.template.html',
    controller: CreateLibraryController,
    controllerAs: 'vm'
  });

CreateLibraryController.$inject = ['ModalService', 'PeerReviewLibraryService', '$mdDialog'];

function CreateLibraryController(ModalService, PeerReviewLibraryService, $mdDialog) {
  var vm = this;

  vm.cancel = cancel;

  vm.isLoading = false;
  vm.newLibraryName = '';

  vm.addNewLibrary = function() {
    vm.isLoading = true;
    PeerReviewLibraryService.create({name: vm.newLibraryName}).then(function(response) {
      vm.isLoading = false;
      $mdDialog.hide(response.data);
    }, function (errorResponse) {
      vm.isLoading = false;
      ModalService.showErrorModal(errorResponse);
    })
  }

  function cancel() {
    return $mdDialog.hide();
  }

}
