(function () {
    'use strict';
    
    angular
        .module('plagiat2017App')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];
    
    function stateConfig($stateProvider) {
        $stateProvider
            .state('protocol-list', {
                parent: 'account',
                url: '/protocol-list',
                data: {
                    authorities: ['PERM_USER_SUPERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/protocols/protocol-list.template.html',
                        controller: 'ProtocolListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('users');
                        $translatePartialLoader.addPart('protocol');
                        return $translate.refresh();
                    }]
                }
            })
            .state("protocol-add", {
                parent: "protocol-list",
                url: "/add-edit/:id",
                data: {
                    authorities: ['PERM_USER_SUPERADMIN'],
                    pageTitle: 'protocol.edit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/protocols/protocol-add.template.html',
                        controller: 'ProtocolAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('users');
                        $translatePartialLoader.addPart('protocol');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }
})();
