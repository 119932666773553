angular.module('plagiat2017App')
.component('selfEnrollmentModal', {
    templateUrl: 'app/account/assignments/peer-review/add-question.template.html',
    controller: AddQuestionController,
    controllerAs: 'vm'
});

AddQuestionController.$inject = ['$mdDialog', 'items'];

function AddQuestionController($mdDialog, items) {
    var vm = this;

    vm.addQuestionOk = addQuestionOk;
    vm.cancel = cancel;
    
    vm.title = null;
    vm.type = 'TEXT';
    vm.isLoading = false;
    vm.editMode = false;

    this.$onInit = function () {
        vm.isLoading = true;
        vm.isLoading = false;
        vm.editMode = !!items.question;
        if (vm.editMode) {
            vm.title = items.question.title;
            vm.type = items.question.type;
            vm.textMinWords = items.question.textMinWords;
            vm.scaleSize = items.question.scaleSize;
            vm.scaleLowestValueName = items.question.scaleLowestValueName;
            vm.scaleHighestValueName = items.question.scaleHighestValueName;
        }
    };
    
    function addQuestionOk(form) {
        if (form.$invalid) {
            for (var i = 0; i < form.$$controls.length; i++) {
                form.$$controls[i].$touched = true;
            }
            return;
        }
        
        vm.isLoading = true;
        var question = items.question ? items.question : {};
        question.title = vm.title;
        question.type = vm.type;
        question.textMinWords = vm.textMinWords;
        question.scaleSize = vm.scaleSize;
        question.scaleLowestValueName = vm.scaleLowestValueName;
        question.scaleHighestValueName = vm.scaleHighestValueName;
        if (vm.editMode) {
            items.question = question;
            items.onEditQuestion(items.index, items.question);
        } else {
            items.onAddQuestion(question);
        }
        $mdDialog.hide();
    }

    function cancel() {
        return $mdDialog.hide();
    }

}
