(function () {
    'use strict';

    StatisticsSubmenuController.$inject = ['AccountService'];

    function StatisticsSubmenuController(AccountService) {
        var vm = this;
        vm.isAdmin = AccountService.role.isAdmin();
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.jsa = AccountService.getClientSettings().jsaIntegration;
        vm.orppdIntegration = AccountService.getClientSettings().orppdIntegration;
    }

    angular.module('plagiat2017App')
    .component('statisticsSubmenu', {
        templateUrl: 'app/statistics/statistics-submenu/statistics-submenu.template.html',
        controller: StatisticsSubmenuController,
        bindings: {
        	activeTab: '<'
        }
    });

})();
