(function() {
	'use strict';

	function ConversionTrackingPriceController($stateParams, DetectInstanceService, InstanceService, PaymentsService,
		$log, $cookies, $window, GoogleAdWordsService) {

		var vm = this;
		vm.conversionDomain = DetectInstanceService.getConversionAllowance();
		vm.domainAw = InstanceService.instance().domainAw;
		vm.domainAwCode = InstanceService.instance().domainAwCode;

		vm.success = false;
		if (vm.conversionDomain) {

            PaymentsService.getStatusForLastPayment().then(function (responseData) {
                vm.lastPayment = responseData.data;
                if (vm.lastPayment.providerStatus === 'payment.status.success') {
                    vm.price = vm.lastPayment.price;
                    vm.currency = vm.lastPayment.currency;
                    vm.success = true;
                    trackGoogleAdsConversion();
                    trackFbPixelConversion();
                } else{
                    vm.success = false;
                    $log.info('no-ads-cnv');
                }
            });
		}
        
        function trackFbPixelConversion() {
            if ($window.fbq) {
                $window.fbq('track', 'Purchase', {currency: vm.currency, value: vm.price});
                console.log('Purchase tracked! value=' + vm.price);
            }
        }
        
        function trackGoogleAdsConversion() {
            $log.info('ads conversion OK. price: ', vm.price, 'currency: ', vm.currency);
            /* <![CDATA[ */
            GoogleAdWordsService.loadJavaScript('https://www.googletagmanager.com/gtag/js?id=' + vm.domainAw);
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
    
            gtag('config', vm.domainAw);
            gtag('event', 'conversion', {
                'send_to': vm.domainAwCode,
                'value': vm.price,
                'currency': vm.currency,
                'transaction_id': ''
            });
            /* ]]> */
    
        }
        
    }

	angular.module('plagiat2017App')
	.component('conversionTrackingPrice', {
		templateUrl: 'app/components/conversion-tracking-price/conversion-tracking-price.template.html',
		controller: ConversionTrackingPriceController
	});

})();
