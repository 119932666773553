(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('SystemAdministrationController', SystemAdministrationController);

    SystemAdministrationController.$inject = ['JsaService', 'ModalService', 'DetectInstanceService'];

    function SystemAdministrationController(JsaService, ModalService, DetectInstanceService) {
        var vm = this;

        vm.synchronizePolonUnitsButtonDisabled = false;
        vm.activeTab = 'systemAdministration';
        vm.jsaCardEnabled = DetectInstanceService.instance() === 'pl';

        vm.synchronizePolonUnits = function () {
            ModalService.showConfirmModal('system-administration.jsa.synchronize-polon-institutions.confirm').then(
                function () {
                    JsaService.synchronizeInstitutions().then(function () {
                        ModalService.showSuccessModal('system-administration.jsa.synchronize-polon-institutions.in-progress');
                        vm.synchronizePolonUnitsButtonDisabled = true;
                    });
                }
            )
        }
    }
})();
