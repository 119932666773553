(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('order', {
            parent: 'app',
            url: '/order?plagiatPlMode&charactersQuantity&unit&confirmationCode$training1&training2',
            data: {
                authorities: ['open'],
                pageTitle: 'global.menu.buy'
            },
            views: {
                'content@': {
                    templateUrl: 'app/online-order/online-order.html',
                    controller: 'OnlineOrderController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('online-order');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('register');

                    return $translate.refresh();
                }]
            }
        });
    }

})();
