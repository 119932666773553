(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('ClientSearchRequest', function () {

        function ClientSearchRequest() {
            this.name = '';
            this.id = '';
            this.type = '';
            this.status = '';
        }

        return ClientSearchRequest;
    });
})();
