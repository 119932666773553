(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ProtocolListController', ProtocolListController);
    
    ProtocolListController.$inject = ['ProtocolsService', 'ModalService', 'SearchRequest', 'QueryRequest',
        'SIMPLE_SEARCH_DELAY', '$localStorage'];

    function ProtocolListController(ProtocolsService, ModalService, SearchRequest, QueryRequest,
                                    SIMPLE_SEARCH_DELAY, $localStorage) {
        var vm = this;
        vm.activeTab = 'protocolList';
        vm.pending = false;
    
        vm.loadData = loadData;
        vm.loadPage = loadPage;
        vm.remove = remove;
        vm.duplicate = duplicate;
        
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.queryRequest = new QueryRequest();
        vm.searchRequest = new SearchRequest();
        vm.protocols = [];
        vm.protocolPage = {};
    
        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.protocolLimit ? $localStorage.protocolLimit : vm.queryRequest.limit;
            vm.searchRequest.sortTab = ['id,desc'];
            vm.loadData();
        };

        function loadData() {
            if (vm.protocolPage.number) {
                vm.loadPage(vm.protocolPage.number);
            } else {
                vm.loadPage(1);
            }
        }

        function loadPage(pageNumber) {
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            $localStorage.protocolLimit = vm.queryRequest.limit;
            vm.pending = true;
            ProtocolsService.search(vm.searchRequest).then(function (protocolPage) {
                vm.pending = false;
                vm.protocolPage = protocolPage;
                vm.protocols = protocolPage.content;
                vm.protocolPage.number++;
                vm.queryRequest.page = vm.protocolPage.number;
            });
        }
    
        function remove(obj) {
            ProtocolsService
                .remove(obj.id)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    loadData();
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
        function duplicate(obj) {
            ProtocolsService
                .duplicate(obj.id)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    loadData();
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
    
    }
})();
