(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('AdminConfigurationController', AdminConfigurationController);

    AdminConfigurationController.$inject = [
        'AdminConfigurationService', 'CLIENTTYPECONSTANT', 'COUNTRYCODECONSTANT', 'ClientService',
        'PlagiatValidationService', '$mdDialog', 'AccountService',
        'ModalService', '$translate'];

    function AdminConfigurationController (
        AdminConfigurationService, CLIENTTYPECONSTANT, COUNTRYCODECONSTANT, ClientService,
        PlagiatValidationService, $mdDialog, AccountService,
        ModalService, $translate) {

        var vm = this;
        vm.activeTab = 'adminConfiguration';
        vm.clientTypeConstant = CLIENTTYPECONSTANT;

        this.$onInit = function () {
            onInit();
        };
        vm.model = {};
        vm.save = save;

        vm.configuration = {
            reportSettings: [
                {label: 'ignoring-bibliography', model: 'ignoringBibliography'},
                {label: 'ignoring-citations', model: 'ignoringCitations'},
                {label: 'warning-thresholds', model: 'warningThresholds'},
                {label: 'report-2020-operator-can-edit', model: 'report2020OperatorCanEdit'},
                {label: 'excluding-citations', model: 'excludingCitations'}
            ],
            addToDatabase: [
                {value: 'without'},
                {value: 'manually'},
                {value: 'automatically'},
                {value: 'delayed'}
            ],
            otherSettings: [
                {label: 'perm-user-document-verification', model: 'permUserDocumentVerification'},
                {label: 'perm-user-check-document-metric-similarity-before-add', model: 'permUserCheckDocumentMetricSimilarityBeforeAdd'},
                {label: 'send-alert-to-promoter', model: 'sendAlertToPromoter'},
                {label: 'send-alert-to-student', model: 'sendAlertToStudent'},
                {label: 'perm-user-do-not-use-copy-paste', model: 'permUserDoNotUseCopyPaste'},
                {label: 'perm-user-upload-support', model: 'permUserUploadSupport'},
                {label: 'reserved-emails', model: 'reservedEmails'},
                {label: 'accept-regulations-enabled', model: 'acceptRegulationsEnabled'}//+ edition of the regulations url
            ],
            jsaOrppdSettings: [
                {label: 'jsa-run-ai-detection', model: 'jsaRunAiDetection'},
            ],
            userSettings: [
                {label: 'allow-edit-document', model: 'allowEditDocument'},
                {label: 'perm-user-cant-see-the-documents-of-others', model: 'permUserCantSeeTheDocumentsOfOthers'},
                {label: 'user-teacher-can-change-promoter', model: 'userTeacherCanChangePromoter'}
            ],
            uploadPermissions: [
                {label: 'txt-support', model: 'txtSupport'},
                {label: 'pdf-support', model: 'pdfSupport'},
                {label: 'doc-support', model: 'docSupport'},
                {label: 'docx-support', model: 'docxSupport'},
                {label: 'odt-support', model: 'odtSupport'},
                {label: 'rtf-support', model: 'rtfSupport'},
                {label: 'ppt-support', model: 'pptSupport'},
                {label: 'pptx-support', model: 'pptxSupport'},
                {label: 'html-support', model: 'htmlSupport'}
            ]
        };

        vm.available = function(model) {
            switch (model) {
                case 'userSettingsCard':
                    return isAnyUniversity() || AccountService.clientType.isPublishingHouse();

                    // otherSettings
                case 'permUserDocumentVerification':
                    return isAnyUniversity();
                case 'acceptRegulationsEnabled':
                    return isAnyUniversity();
                case 'permUserCheckDocumentMetricSimilarityBeforeAdd':
                    return isAnyUniversity() || AccountService.clientType.isPublishingHouse() || AccountService.clientType.isSchool();
                case 'reservedEmails':
                    return isAnyUniversity() || AccountService.clientType.isPublishingHouse();
                case 'sendAlertToPromoter':
                    return isAnyUniversity() || AccountService.clientType.isSchool();
                case 'sendAlertToStudent':
                    return AccountService.clientType.isSchool();
                case 'permUserDoNotUseCopyPaste':
                    return isAnyUniversity() || AccountService.clientType.isPublishingHouse() || AccountService.clientType.isSchool();
                case 'permUserUploadSupport':
                    return isAnyUniversity() || AccountService.clientType.isPublishingHouse() || AccountService.clientType.isSchool();
                case 'allowEditDocument':
                    return true;
                case 'permUserCantSeeTheDocumentsOfOthers':
                    return isAnyUniversity() || AccountService.clientType.isPublishingHouse();
                case 'userTeacherCanChangePromoter':
                    return isAnyUniversity();
                case 'jsaOrppdCard':
                    return AccountService.account.clientSettings.jsaIntegration
                        || (AccountService.clientType.isClientTypeUnivAsap() && AccountService.clientCountryId === COUNTRYCODECONSTANT.POLAND_COLUMBIA.id);
                default:
                    return false;
            }
        };

        function isAnyUniversity() {
            return AccountService.clientType.isClientTypeUnivStandard() || AccountService.clientType.isCodeAccount() || AccountService.clientType.isClientTypeUnivAsap() ;
        }

        function onInit() {
            vm.isLoading = true;
            AdminConfigurationService.getAdminConfiguration().then(function (success) {
                vm.model = success.data;
                vm.isLoading = false;
            }, function (response) {
                ModalService.showErrorModal(response);
                vm.isLoading = false;
            });
        }
        function save() {
            vm.isSaving = true;

            AdminConfigurationService.save(vm.model.configuration).then(
                function () {
                    vm.isSaving = false;
                    ModalService.showSuccessModal();
                },
                function (response) {
                    vm.isSaving = false;
                    ModalService.showErrorModal(response);
                    $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
                }
            );
        }

    }
})();
