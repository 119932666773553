(function () {
	'use strict';

	angular
	.module('plagiat2017App')
	.factory('ContractService', ContractService);

	ContractService.$inject = ['$http', '$q', '$rootScope'];

	function ContractService($http, $q, $rootScope) {


		var service = {
			getInfo: getInfo,
			getHistory: getHistory,
			broadcast: broadcast,
			buyTokens: false,
			inactive:false,
			contract: {}
		};

		return service;

		function getInfo() {
			return $http.get('api/operator/contract').then(
				function (response) {
					return response.data;
				},
				function (errResponse) {
					return $q.reject(errResponse);
				});
		}

		function getHistory(clientId) {
			return $http.get('api/contract/history?clientId='+clientId).then(
				function (response) {
					return response.data;
				},
				function (errResponse) {
					return $q.reject(errResponse);
				});
		}

		function broadcast() {
			$rootScope.$broadcast("refreshContract");
		}

	}



})();
