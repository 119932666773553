
(function() {
    'use strict';

    function detectTabsController(Auth, $state, $scope) {


            // window.onbeforeunload = function (e) {
            //    //Auth.logout();
            //    // Firefox || IE
            //     e = e || window.event;

            //     var y = e.pageY || e.clientY;

            //     if(y < 0)  {
            //         
            //         //return "fdgfhgjkghfg";
            //     }
            //     else {
            //         
            //         return "fdgfhgjkghfg";
            //     }



            //     return "fdgfhgjkghfg";
            // }


            // var myEvent = window.attachEvent || window.addEventListener;
            // var chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compitable

            // myEvent(chkevent, function(e) { // For >=IE7, Chrome, Firefox
            //     var confirmationMessage = 'Are you sure to leave the page?';  // a space
            //     (e || window.event).returnValue = confirmationMessage;
            //     return confirmationMessage;
            // });


            // window.addEventListener('onbeforeunload',function(e) {
            //   var dialogText = 'onbeforeunload';
            //   e.returnValue = dialogText;
            //   return dialogText;
            // })
            // window.addEventListener('beforeunload',function(e) {
            //   var dialogText = 'beforeunload';
            //   e.returnValue = dialogText;
            //   return dialogText;
            // })


    }

    angular.module('detectTabs', [])
        .component('detectTabs', {
            templateUrl: 'app/components/detect-tabs/detect-tabs.template.html',
            controller: detectTabsController
        });

})();
