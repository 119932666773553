(function() {
	'use strict';

	angular
		.module('plagiat2017App')
		.controller('UserRegionsController', UserRegionsController);

	function UserRegionsController($scope, $mdDialog, UserService, ModalService, ClientService, userId) {
		var vm = this;
		vm.cancelDialog = cancelDialog;
		vm.saveUserRegions = saveUserRegions;

		vm.clientRegions = [];
		vm.allRegions = false;

		this.$onInit = function () {
			UserService.getUserRegions(userId).then(
				function (success) {
					vm.clientRegions = success.data.userRegions;
					vm.allRegions = success.data.allRegions;
				});
		};
		
		function cancelDialog() {
			$mdDialog.cancel();
		}

		function saveUserRegions() {
	        UserService.saveUserRegions({userId: userId, allRegions: vm.allRegions, userRegions: vm.clientRegions}).then(
	        	function() {
	        		$mdDialog.cancel();
	        	}, function(response) {
	        		$mdDialog.cancel().then(function() {
	        			ModalService.showErrorModal(response);
	        		});
	        	});
		}

	}

})();
