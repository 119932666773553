(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ClientProtocolsController', ClientProtocolsController);
    
    function ClientProtocolsController(
        ClientService, ModalService, JhiLanguageService, PlagiatValidationService,
        $q, $stateParams
    ) {
        var vm = this;
        vm.pending = false;
        vm.protocols = [];
        vm.clientId = $stateParams.clientId;
        
        vm.remove = remove;
        vm.loadData = loadData;

        this.$onInit = function () {
            loadData();
        };
        
        function loadData() {
            vm.pending = true;
            ClientService.getClientProtocols(vm.clientId).then(function (result) {
                vm.pending = false;
                vm.protocols = result.data;
            });
        }
    
        function remove(obj) {
            ClientService
                .removeClientProtocol(vm.clientId, obj.id)
                .then(function () {
                    ModalService.showSuccessModal("global.messages.success.label");
                    loadData();
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    }

})();
