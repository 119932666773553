(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ToastService', ToastService);

    ToastService.$inject = ['PLAGIAT_VALIDATIONS_CODES', '$translate', '_', '$mdToast'];

    function ToastService(PLAGIAT_VALIDATIONS_CODES, $translate, _, $mdToast) {
        var service = {
            showSuccessToast: showSuccessToast,
            showErrorToast: showErrorToast
        };

        function showSuccessToast(textContent) {
            var key = textContent ? textContent : 'global.messages.info.actionsuccess';
            var toast = $mdToast.simple()
                .textContent($translate.instant(key))
                .position("top right")
                .hideDelay(5000);
            $mdToast.show(toast);
        }

        function lowercase(string) {
            return angular.isString(string) ? string.toLowerCase() : string;
        }

        function showErrorToast(response) {
            vm.translationKey = 'global.messages.error.error';
            if( response.code !== '' ) {
                vm.code = _.filter(PLAGIAT_VALIDATIONS_CODES, function(o) { return lowercase(o.code).indexOf(lowercase(response.code)) !== -1; });
                vm.translationKey = ( vm.code.length > 0 && angular.isDefined(vm.code[0].translationCode) && vm.code[0].translationCode !== '' ) ? vm.code[0].translationCode : 'global.messages.error.error';
            }
            vm.data = '';
            if (response.data){
                vm.data = '</br>' + response.data;
            }

            var toast = $mdToast.simple()
                .textContent($translate.instant(vm.translationKey) + vm.data)
                .position("top right")
                .hideDelay(5000);

            $mdToast.show(toast);
        }

        return service;
    }

})();
