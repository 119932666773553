(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('DatabaseExchangeProgramMembersListController', DatabaseExchangeProgramMembersListController);

    DatabaseExchangeProgramMembersListController.$inject = ['ClientService', 'AccountService', 'SearchRequest', 'DatabaseExchangeProgramMembersSearchRequest', 'DatabaseExchangeProgramMembersQueryRequest', 'tableSettingsRequest', '$localStorage', 'SearchService', 'TableSelectOptions'];

    function DatabaseExchangeProgramMembersListController (ClientService, AccountService, SearchRequest, DatabaseExchangeProgramMembersSearchRequest, DatabaseExchangeProgramMembersQueryRequest, tableSettingsRequest, $localStorage, SearchService, TableSelectOptions) {
        var vm = this;
        vm.activeTab = 'databaseExchangeProgramMembers';
        vm.queryRequest = new DatabaseExchangeProgramMembersQueryRequest();
        vm.queryRequest.limit = $localStorage.clientListlimit ? $localStorage.clientListlimit : vm.queryRequest.limit;
        vm.searchRequest = new SearchRequest();
        vm.DatabaseExchangeProgramMembersSearchRequest = new DatabaseExchangeProgramMembersSearchRequest();
        vm.options = new tableSettingsRequest();
        vm.tableSelectOptions = new TableSelectOptions();
        vm.pagingAction = pagingAction;
        vm.getClients = getClients;
        vm.isDatabaseExchangeProgramMember = undefined;
        vm.showDbExchangeProgramList = !AccountService.getClientSettings().dbExchangeProgramListHidden;
        
        function getClients(){
            vm.searchRequest.pageNumber = vm.queryRequest.page-1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);
            vm.searchRequest.search = vm.DatabaseExchangeProgramMembersSearchRequest;

            vm.isLoadingData = false;
            vm.isLoaderActive = true;
            ClientService.listDatabaseExchangeProgramMembers(vm.searchRequest).then(function (responseData) {
                vm.responseObj = responseData.content;
                vm.clients = vm.responseObj;
                vm.totalClients = responseData.totalElements;
                vm.isLoaderActive = false;
                vm.isLoadingData = false;
            });
        }
        
        function pagingAction(page) {
            vm.queryRequest.page = page;
            vm.getClients();
        }
        
        if (vm.showDbExchangeProgramList) {
            vm.getClients();
        }
        
        function isDatabaseExchangeProgramMember(){
            ClientService.isDatabaseExchangeProgramMember().then(function (responseData) {
                vm.isDatabaseExchangeProgramMember = responseData.data;
            });
        }
        
        isDatabaseExchangeProgramMember();
        
    }
})();
