(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('EditThresholdsController', EditThresholdsController);

    EditThresholdsController.$inject = ['DocumentKindsService', 'PlagiatValidationService', 'ModalService',
    	'$state', '$stateParams'];

    function EditThresholdsController (DocumentKindsService, PlagiatValidationService, ModalService,
    		$state, $stateParams) {
        var vm = this;
        vm.key = $stateParams.key;
        vm.save = save;
        vm.backToMainList = backToMainList;

        if ($stateParams.documentKindId) {
            vm.pending = true;
            getForm($stateParams.documentKindId).then(function () {
                vm.pending = false;
            });
        }

        function backToMainList() {
        	$state.go('document-kinds');
        }
        
        function getForm(id) {
        	return DocumentKindsService.getThresholdsForEdit(id).then(function(response) {
        		vm.form = response;
        	});
        }
        
        function save(form){
            DocumentKindsService.saveThresholds(form).then(function(){
        		backToMainList();

                ModalService.showSuccessModal();
        	}, function(response){
        		PlagiatValidationService.getModalError(response.data);
        	})
        }
         
    }
})();
