(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('SuspectReportsController', SuspectReportsController);

    SuspectReportsController.$inject = [
        'SuspectReportsService', 'ModalService', 'PlagiatValidationService', 'DocumentService', 'DateService', 'ReportsService',
        '$q', '$mdDialog', '$translate', '$scope', '$localStorage', '$stateParams', '$state',
        'QueryRequest', 'SearchRequest', 'tableSettingsRequest', 'SIMPLE_SEARCH_DELAY'
    ];

    function SuspectReportsController(
        SuspectReportsService, ModalService, PlagiatValidationService, DocumentService, DateService, ReportsService,
        $q, $mdDialog, $translate, $scope, $localStorage, $stateParams, $state,
        QueryRequest, SearchRequest, tableSettingsRequest, SIMPLE_SEARCH_DELAY
    ) {
        var vm = this;

        vm.fixDate = DateService.fixDate;
        vm.loadData = loadData;
        vm.loadPageOfData = loadPageOfData;
        vm.clear = clear;
        vm.showBothReports = showBothReports;
        vm.goBack = goBack;

        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.keyCodeEnter = KeyCode.KEY_RETURN;
        vm.pageOfData = {};
        vm.queryRequest = new QueryRequest();
        vm.queryRequest.order = '-reportCoeReverted';
        vm.searchRequest = new SearchRequest();
        vm.searchRequest.search.status = 1;

        this.$onInit = function () {
            vm.queryRequest.limit = $localStorage.suspectReportsLimit ? $localStorage.suspectReportsLimit : vm.queryRequest.limit;
            
            vm.documentId = $stateParams.documentId;
            vm.suspectId = $stateParams.suspectId;

            if (vm.documentId) {
                vm.searchRequest.search.documentId = vm.documentId;
                vm.searchRequest.search.suspectId = undefined;
            } else if (vm.suspectId) {
                if ($localStorage.suspectReportsSearch) {
                    vm.searchRequest.search = $localStorage.suspectReportsSearch;
                }
                vm.searchRequest.search.documentId = undefined;
                vm.searchRequest.search.suspectId = vm.suspectId;
                if ($localStorage.portalsSearch) {
                    if ($localStorage.portalsSearch.portalDateFrom) {
                        vm.searchRequest.search.dateFrom = $localStorage.portalsSearch.portalDateFrom;
                    }
                    if ($localStorage.portalsSearch.portalDateTo) {
                        vm.searchRequest.search.dateTo = $localStorage.portalsSearch.portalDateTo;
                    }
                }
            } else {
                vm.searchRequest.search.documentId = -1;
                vm.searchRequest.search.suspectId = undefined;
            }
            
            loadPageOfData(1);
            if (vm.documentId) {
                ReportsService.getDetails(vm.documentId).then(function (result) {
                    vm.details = result.data;
                });
            }
        };
        
        function goBack() {
            if (vm.documentId) {
                $state.go("reports");
            } else {
                $state.go("portals");
            }
        }

        function clear() {
            vm.searchRequest = new SearchRequest();
            loadData();
        }

        function loadPageOfData(pageNumber) {
            vm.pending = true;
            
            vm.searchRequest.pageNumber = pageNumber - 1;
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = sort();
            $localStorage.suspectReportsLimit = vm.queryRequest.limit;
            if (vm.suspectId) {
                $localStorage.suspectReportsSearch = vm.searchRequest.search;
            }
            SuspectReportsService.search(vm.searchRequest).then(function (page) {
                vm.pending = false;
                vm.pageOfData = page;
                vm.pageOfData.number++;
                if (vm.suspectId) {
                    vm.suspectDomain = vm.pageOfData.content[0].urlDomain;
                }
            });
        }

        function loadData() {
            vm.loadPageOfData(1);
        }

        function sort() {
            var reverse = vm.queryRequest.order.slice(0, 1);
            var _length = vm.queryRequest.order.length;
            var predicate;
            if (reverse === '-') {
                predicate = vm.queryRequest.order.slice(1, _length);
                reverse = false;
            } else {
                predicate = vm.queryRequest.order;
                reverse = true;
            }
            var result = [predicate + ',' + (reverse ? 'asc' : 'desc')];
            return result;
        }

        vm.convertToDate = function (date){
            return new Date(date);
        }
        
        function showBothReports(row) {
            SuspectReportsService.downloadReport(row.id).then(
                function () {},
                function (response) {
                    PlagiatValidationService.getModalError(response.data);
                });
        }

    }

})();
