(function () {
    'use strict';

    angular
            .module('plagiat2017App')
            .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$scope', '$state', 'DetectInstanceService', '$timeout', 'Auth', '$stateParams',
    'tmhDynamicLocale', '$translate', 'BrowserService', '$localStorage', 'UserService', 'InstanceService', '$window'];

    function LoginController($rootScope, $scope, $state, DetectInstanceService, $timeout, Auth, $stateParams, 
        tmhDynamicLocale, $translate, BrowserService, $localStorage, UserService, InstanceService, $window) {

        var vm = this;

        vm.authenticationError = false;
        vm.credentials = {};
        vm.password = null;
        vm.rememberMe = true;
        vm.username = null;
        vm.pending = false;
        vm.loginPossible = true;
        vm.showPassword = false;
        vm.response = null;
        vm.isCapsLockOn = null;

        vm.login = login;
        vm.register = register;
        vm.loginSaml = loginSaml;
        vm.requestResetPassword = requestResetPassword;
        vm.goToHomePage = goToHomePage;
        vm.toggleShowPassword = toggleShowPassword;
        vm.regulationsAcceptanceInfo = regulationsAcceptanceInfo;
        vm.toggleInstitutions = toggleInstitutions;

        vm.allowFbLogin = DetectInstanceService.getFbLoginForm();
        vm.allowRegister = DetectInstanceService.getAllowRegister();
        vm.samlPartners = DetectInstanceService.samlLoginButtons();
        vm.oauthPartners = DetectInstanceService.oauthLoginButtons();
        vm.showInstitutions = (vm.samlPartners && vm.samlPartners.length > 0) || (vm.oauthPartners && vm.oauthPartners.length > 0);
        vm.institutionsExpanded = false;
        vm.homepage = InstanceService.instance().domain;
        vm.instance = DetectInstanceService.instance();
        vm.lastSsoPartner = null;

        $timeout(function () {
            angular.element('#username').focus();
        });

        $scope.$on('authenticationSuccess', function () {
            vm.loginPossible = false;
        });

        this.$onInit = function () {
            vm.key = $stateParams.error;
            if (vm.key === 'saml') {
                vm.authenticationError = true;
                vm.responseMessage = 'login.messages.saml.error.authentication';
            }
            vm.lastSsoPartner = $localStorage.lastSsoPartner;
        }

        function login(event) {
            event.preventDefault();
            var credentials = {
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            };
            UserService.getUserInstance(vm.username, DetectInstanceService.isRedirectEnabled()).then(function(data) {
                if (data.data === "" || DetectInstanceService.instance() === data.data) {
                    $localStorage.showLogoutToRootBtn = false;
                    $localStorage.logoutToRootInfo = undefined;
                    Auth.login(credentials)
                        .then(function (response) {
                            vm.authenticationError = false;
                            $rootScope.$broadcast('authenticationSuccess');
                            // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                            // since login is successful, go to stored previousState and clear previousState
                            if (Auth.getPreviousState()) {
                                var previousState = Auth.getPreviousState();
                                Auth.resetPreviousState();
                                $state.go(previousState.name, previousState.params);
                            } else if ($state.current.name === 'login') {
                                $state.go('documents');
                            }
                        }).catch(function (response) {
                        vm.authenticationError = true;
                        if (response.status === 401) {
                            vm.authenticationError = true;
                            vm.responseMessage = 'login.messages.error.authentication';
                            //Wyszukanie komunikatu wysłanego z backendu
                            for (var prop in response) {
                                vm.response = response[prop];
                                if (vm.response.message !== 'undefined') {
                                    // Znaleziono komunikat - do przetłumaczenia i18 na stronie
                                    vm.responseMessage = vm.response.message;
                                    break;
                                }
                            }
                        }
                    });
                } else {
                    Auth.redirect(credentials, DetectInstanceService.getInstanceDomain(data.data));
                }
            });
        }

        function register() {
            $state.go('register');
        }

        function requestResetPassword() {
            $state.go('requestReset');
        }

        function goToHomePage(){
            $window.open('https://' + vm.homepage, "_self");
        }

        function toggleShowPassword() {
            vm.showPassword = !vm.showPassword;
        }

        function toggleInstitutions() {
            vm.institutionsExpanded = !vm.institutionsExpanded;
        }

        function regulationsAcceptanceInfo() {
            var regulationsKey = 'info.instructions.instruction3.url_' + vm.instance;
            var regulationsTranslation = $translate.instant(regulationsKey);
            var result = $translate.instant('global.social.regulations-acceptance-info');
            result = result.replace('{regulationsUrl}', regulationsTranslation);
            result =  '<span>' + result + '</span>';
            return result;
        }

        function loginSaml(samlPartner) {
            $localStorage.lastSsoPartner = samlPartner;

            UserService.samlRedirectionUrl(samlPartner).then(function(data) {
                $.redirect(data.data.url, "GET");
            });

        }

        vm.checkCapsLockKey = function (event) {
            vm.isCapsLockOn = event.originalEvent.getModifierState('CapsLock');
        };
    }
})();
