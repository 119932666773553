(function () {
    'use strict';

    angular.module('documentDetailsRequestModel', [])
    .factory('documentDetailsRequest', function () {

        function documentDetailsRequest() {
            this.simpleSearch = '';
            this.docuTitle = '';
            this.docuStatus = '';
        }

        return documentDetailsRequest;
    });
})();
