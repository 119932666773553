(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('AdminConfigurationService', AdminConfigurationService);

    AdminConfigurationService.$inject = ['$http', '$q'];

    function AdminConfigurationService($http, $q) {

        var requestUrl = 'api/client-setting';

        var service = {
            getAdminConfiguration: getAdminConfiguration,
            save: save,
		}

        return service;

        function getAdminConfiguration() {
            return $http.get(requestUrl + '/admin-configuration').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function save(obj) {
            return $http.post(requestUrl + '/save-admin-configuration', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    }

})();
