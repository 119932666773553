(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('ContactAddresatsModelRequest', function () {

        function ContactAddresatsModelRequest() {
            this.email = '';
            this.name = '';
        }

        return ContactAddresatsModelRequest;
    });
})();
