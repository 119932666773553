(function () {
    'use strict';

    function ClientsSearchController(
        $scope, $localStorage, $q, _, $timeout,
        ClientSearchRequest, ClientQueryRequest, ClientService, SearchService, DateService, AccountService,
        CLIENTSTATUSES, INSTANCES, SIMPLE_SEARCH_DELAY, CLIENTTYPECONSTANT) {

        var vm = this;
        vm.getClients = getClients;
        vm.organisationalUnits = [];
        vm.clientGroups = [];
        vm.clientRegions = [];
        vm.countries = [];
        vm.statuses = CLIENTSTATUSES;
        vm.fixDate = DateService.fixDate;
        vm.toggleSearch = toggleSearch;
        vm.clear = clear;
        vm.searchRequest = new ClientSearchRequest();
        vm.clientQueryRequest = new ClientQueryRequest();
        vm.selectedItemChange = selectedItemChange;
        vm.querySearch = querySearch;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.isReseller = AccountService.role.isReseller();
        vm.isMinistry = AccountService.role.isMinistry();
        vm.clientTypesForSearch = (vm.isReseller || vm.isMinistry) ? CLIENTTYPECONSTANT.listForResellers : CLIENTTYPECONSTANT.listForClientSearch;
        vm.clientInstances = INSTANCES.map(function (instanceConstant) {
            return instanceConstant.instance;
        });
    
        this.$onInit = function () {
            if (!vm.isReseller && !vm.isMinistry) {
                ClientService.getClientGroups().then(
                    function (success) {
                        vm.clientGroups = success.data;
                    });

                ClientService.getClientRegions().then(
                    function (success) {
                        vm.clientRegions = success.data;
                    });

                ClientService.getClientCountries().then(
                    function (success) {
                        vm.countries = success.data;
                    });
            }
        };

        function toggleSearch() {
            vm.onSearchRequest.simpleSearch = '';
            vm.simpleSearch = !vm.simpleSearch;
            $localStorage.clientSimpleSearchEnabled = vm.simpleSearch;
        }

        function clear() {
            vm.onClear();
        }

        function getClients() {
            $localStorage.clientListlimit = vm.onQueryRequest.limit;
            $localStorage.clientSimpleSearchValue = vm.onSearchRequest.simpleSearch;
            prepareSearchObject();
            vm.onSearchRequest.simpleSearch.length === 0 ? vm.onGetClients() : '';
            vm.onSearchRequest.simpleSearch.length > 2 ? vm.onGetClients() : '';
        }

        function selectedItemChange(item) {
            if (angular.isDefined(item)) {
                vm.onSearchRequest.search.clientId = item.id;
            } else {
                vm.onSearchRequest.search.clientId = null;
            }
        }

        function querySearch(query) {
            if (query.length > 2) {
                vm.searchRequest.pageNumber = 0;
                vm.searchRequest.pageSize = 20;
                vm.searchRequest.sortTab = SearchService.sort(vm.clientQueryRequest);
                vm.searchRequest.search = query;
                vm.searchRequest.simpleSearch = query;

                return ClientService.searchClients(vm.searchRequest).then(function (response) {
                    return response.content;
                })
            } else {
                var deferred = $q.defer();
                $timeout(function () {
                    deferred.resolve([]);
                }, 1200, false);
                return deferred.promise;
            }
        }

        function prepareSearchObject() {
            if (angular.isDefined(vm.onSearchRequest.search.databaseExchangeProgram)) {
                vm.onSearchRequest.search.contractStatus = 0;
            }
        }
    }

    angular.module('plagiat2017App')
        .component('clientsSearch', {
            templateUrl: 'app/client/client-search/client-search.template.html',
            controller: ClientsSearchController,
            bindings: {
                onGetClients: '<',
                onQueryRequest: '<',
                onSearchRequest: '<',
                onClear: '<',
                simpleSearch: '<',
            }
        });

})();
