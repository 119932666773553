(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('StatisticsService', Statistics);

    Statistics.$inject = ['$http', '$q', '$mdDialog', '$translate', 'PlagiatValidationService', 'DateUtils'];

    function Statistics($http, $q, $mdDialog, $translate, PlagiatValidationService, DateUtils) {

        var service = {
            getStatistics: getStatistics,
            generateStatisticsFile: generateStatisticsFile
        };

        var requestUrl = 'api/statistics/';
        var requestXlsUrl = 'api/statistics/xls/';

        return service;

        function getStatistics(url, search) {
            return $http.post(requestUrl + url, search).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function generateStatisticsFile(url, search) {

            var alert = $mdDialog.alert()
                .clickOutsideToClose(false)
                .escapeToClose(false)
                .textContent($translate.instant("users.report.xls.users.to.export"));

            $mdDialog.show(alert);

            $http({
                url: requestXlsUrl + url,
                method: "POST",
                data: search,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).then(function (success) {
                $mdDialog.hide();
                var blob = new Blob([success.data], {
                    type: "application/vnd.ms-excel"
                });
                saveAs(blob, $translate.instant("statistics.export." + url ) + '_' + DateUtils.getDateTimeForFileName(new Date()) + '.xls');

            }, function (error) {
                $mdDialog.hide();
                PlagiatValidationService.getModalError(error);
            });

        }
    }
})();
