(function () {
    'use strict';

    angular.module('PaymentsRequestModel', [])
    .factory('PaymentsRequest', function () {

        function PaymentsRequest() {
            this.providerTransactionId = '';
            this.paymentDate = '';
            this.providerStatus = '';
            this.userPaymentType = '';
            this.initialAmount = '';
            this.amountLeft = '';
            this.dueDate = '';
        }

        return PaymentsRequest;
    });
})();
