(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .constant('CURRENCY_CONSTANT', function () {
            var constant = {
                EUR: "EUR",
                USD: "USD",
                PLN: "PLN",
                AZN: "AZN",
                KZT: "KZT",
                UAH: "UAH",
                RON: "RON"
            };
            constant.list = Object.keys(constant).map(function (key) {
                return constant[key];
            });

            return constant;
        }());
})();
