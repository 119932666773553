(function() {
	'use strict';

	function DocumentDetailsController($scope, $log) {    
	}

	angular
			.module('plagiat2017App')
			.component(
					'documentDetails',
					{
						templateUrl : 'app/components/document/document-details/document-details.template.html',
						controller : DocumentDetailsController,
						bindings: {
							onDocument: '<',
                    		onDocumentDetails: '<'
                }
					});

})();
