(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('UserSearchRequest', function () {

        function UserSearchRequest() {
            this.userName = '';
            this.userEmail = '';
            this.organisationalUnitId = '';
            this.status = '';
            this.hasntLogged = false;
            this.userLastLoginDateFrom = null;
            this.userLastLoginDateTo = null;
            this.role = '';
            this.registerDateFrom = null;
            this.registerDateTo = null;
        }

        return UserSearchRequest;
    });
})();