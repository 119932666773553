(function() {
    'use strict';
    
    function PopupDetailsController(
        PopupAdministrationService, $state, USERROLES, POPUP_OPTIONS
    ) {
        var vm = this;
        vm.roles = USERROLES.SUPERADMIN;
        vm.options = POPUP_OPTIONS;
    }
    
    angular
        .module('plagiat2017App')
        .component(
            'popupDetails',
            {
                templateUrl : 'app/account/settings/popup/popup-detail/popup-detail.template.html',
                controller : PopupDetailsController,
                bindings: {
                    popup: '<'
                }
            }
        );
    
})();
