(function () {
	'use strict';

	function DetectLandingController($state, $log, $location, $window) {
		var vm = this;
		vm.host = $location.host();
		vm.path = $location.path();

		vm.params = '';
		vm.gclid = location.search.split('gclid=')[1];
		vm.gclidUrl = '';

		if( Object.keys($location.search()).length > 0 ){
			vm.params += '?';
			angular.forEach($location.search(), function (value, key) {
				vm.params += key +'='+value+'&';
			});
		}

		if( angular.isDefined(vm.gclid) ){
			vm.gclidUrl = '/?gclid='+vm.gclid;
		}
		if( vm.gclid === 'undefined'){
			vm.gclidUrl = '';
		}

		if( vm.host === 'sprawdz-prace.plagiat.pl') {
			if( vm.path === '/sprawdz' || vm.path === '/' || vm.path === ''){
				$window.location.href = vm.gclidUrl+'#/sprawdz'+vm.params;
			}
			if( vm.path === '/documents'){
				$window.location.href = vm.gclidUrl+'#/documents'+vm.params;
			}
		}
		if( vm.host === 'check-paper.strikeplagiarism.com'
            || vm.host === 'check-paper-for-plagiarism.strikeplagiarism.com'
            || vm.host === 'plagiat-tekseru-antiplagiat.strikeplagiarism.com'
            || vm.host === 'prover-rabotu-antiplagiat.strikeplagiarism.com'
            || vm.host === 'antiplagiat.strikeplagiarism.com'
            || vm.host === 'perevirka-na-plagiat-antiplagiat.strikeplagiarism.com'
            || vm.host === 'plagiata-yoxlamaq-antiplagiat.strikeplagiarism.com'
            || vm.host === 'plagiatni-tekshiring-antiplagiat.strikeplagiarism.com'
			|| vm.host === 'dokument-auf-plagiat-prufen.strikeplagiarism.com'
			|| vm.host === 'sheamotsmet-dokumenti-plagiatis-shesakheb.strikeplagiarism.com'
			|| vm.host === 'proverete-dokumenta-za-plagiat-stvo.strikeplagiarism.com'
			|| vm.host === 'intihal-ile-ilgili-belgeyi-kontrol-et.strikeplagiarism.com'
			|| vm.host === 'comprobar-documento-sobre-plagio.strikeplagiarism.com'
        ) {
			if( vm.path === '/check' || vm.path === '/' || vm.path === ''){
				$window.location.href = vm.gclidUrl+'#/check'+vm.params;
			}
			if( vm.path === '/documents'){
				$window.location.href = vm.gclidUrl+'#/documents'+vm.params;
			}
		}
		if( vm.host === 'verifica-textul.sistemantiplagiat.ro') {
			if( vm.path === '/verifica' || vm.path === '/' || vm.path === ''){
				$window.location.href = vm.gclidUrl+'#/verifica'+vm.params;
			}
			if( vm.path === '/documents'){
				$window.location.href = vm.gclidUrl+'#/documents'+vm.params;
			}
		}
		// if( vm.host === 'localhost') {
		// 	if( vm.path === '/sprawdz' || vm.path === '/' || vm.path === ''){
		// 		$window.location.href = vm.gclidUrl+'#/sprawdz'+vm.params;
		// 	}
		// 	if( vm.path === '/documents'){
		// 		$window.location.href = vm.gclidUrl+'#/documents'+vm.params;
		// 	}
		// }


	}

	angular.module('plagiat2017App')
	.component('detectLanding', {
		controller: DetectLandingController
	});

})();
