(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('InvoicesService', InvoicesService);
    
    InvoicesService.$inject = ['$http', '$q'];

    function InvoicesService($http, $q) {

        var service = {
            getInvoicesForSuperadmin: getInvoicesForSuperadmin,
            issueInvoice: issueInvoice,
            generateBulkInvoice: generateBulkInvoice,
            markInvoiceAsIssued: markInvoiceAsIssued,
            markInvoiceAsWaiting: markInvoiceAsWaiting
        };

        return service;

        function getInvoicesForSuperadmin(obj) {
	        return $http.post('/api/invoice/list-for-superadmin', obj).then(
	        function (response) {
	            return response.data;
	        },
	        function (errResponse) {
	            return $q.reject(errResponse);
	        });
        }

        function issueInvoice(id) {
            return $http.get('/api/invoice/issue/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function generateBulkInvoice(obj) {
            return $http.post('/api/invoice/issue', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function markInvoiceAsIssued(id) {
            return $http.get('/api/invoice/mark/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function markInvoiceAsWaiting(id) {
            return $http.get('/api/invoice/wait/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
    }
    
})();
