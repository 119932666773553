(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$scope', 'InstanceService', '$rootScope', '$state', '$mdSidenav', '$window', 
                                'Auth', 'Principal', 'ProfileService', 'AccountService', 
                                '$localStorage', '$mdDialog', '$translate', '$log', 'tmhDynamicLocale'];

    function NavbarController($scope, InstanceService, $rootScope, $state, $mdSidenav, $window, 
                              Auth, Principal, ProfileService, AccountService, 
                              $localStorage, $mdDialog, $translate, $log, tmhDynamicLocale) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.show = isNotOrderPage();
        vm.instanceLang = InstanceService.instance().lang;
        vm.account = {};
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.toggleMenu = toggleMenu;

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        function getProfile(){
            ProfileService.getProfileInfo().then(function (response) {
                vm.inProduction = response.inProduction;
                vm.swaggerEnabled = response.swaggerEnabled;
                vm.instance = response.instance;
            });
        }

        function isNotOrderPage() {
            return $state.current.name !== 'order';
        }

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.langKey = (account.langKey).toLowerCase();
                tmhDynamicLocale.set(vm.langKey).then(function(){
                    $translate.use(vm.langKey).then(function(){
                        $translate.fallbackLanguage(vm.langKey);
                        $translate.preferredLanguage(vm.langKey);
                    })
                });
                if (account !== null) {
                    vm.account = account;
                    AccountService.account = account;
                    vm.isAuthenticated = Principal.isAuthenticated;
                    $rootScope.$broadcast('roleIsSet');
                    if(vm.account.login === $localStorage.logoutToRootInfo){
                    	$localStorage.logoutToRootInfo = undefined;
                    	vm.logoutToRootInfo = undefined;
                    } else {
                        vm.logoutToRootInfo = $localStorage.logoutToRootInfo;
                    }
                }
            });
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function toggleMenu(componentId) {
            $localStorage.isClosed = true;
            $mdSidenav(componentId).toggle();
        }

        getProfile();
        getAccount();

    }
})();
