(function() {
    'use strict';

    function UploadFileController ($scope) {
        var vm = this;
        vm.getTheFiles = getTheFiles;

        function getTheFiles($files,$invalidFiles) {
            vm.files = $files;
            vm.errFiles = $invalidFiles;
            vm.onGetTheFiles($files);
        }

    }

    angular.module('fileUpload', ['ngFileUpload'])
    .component('uploadFile', {
        templateUrl: 'app/components/uploadFile/uploadFile.template.html',
        controller: UploadFileController,
        bindings: {
            documentFormFile: '=',
            onGetTheFiles: '='
        }
    });

})();
