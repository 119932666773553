(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('document-parameter-definitions', {
            parent: 'account',
            url: '/document-parameter-definitions',
            data: {
                authorities: ['PERM_USER_ADMIN', 'PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.account.document-parameter-definitions'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/document-parameter-definitions/document-parameter-definitions-list/document-parameter-definitions.html',
                    controller: 'DocumentParameterDefinitionsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('document-parameter-definitions');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
