(function () {
    'use strict';
    
    angular
        .module('plagiat2017App')
        .config(stateConfig);
    
    stateConfig.$inject = ['$stateProvider'];
    
    function stateConfig($stateProvider) {
        $stateProvider
            .state('client-group-list', {
                parent: 'account',
                url: '/client-group-list',
                data: {
                    authorities: ['PERM_USER_SUPERADMIN']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/client-groups/client-group-list.template.html',
                        controller: 'ClientGroupListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('users');
                        $translatePartialLoader.addPart('client');
                        return $translate.refresh();
                    }]
                }
            })
            .state("client-group-add", {
                parent: "client-group-list",
                url: "/add-edit/:id",
                data: {
                    authorities: ['PERM_USER_SUPERADMIN'],
                    pageTitle: 'client-group.edit.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/settings/client-groups/client-group-add.template.html',
                        controller: 'ClientGroupAddController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('users');
                        $translatePartialLoader.addPart('client');
                        return $translate.refresh();
                    }]
                }
            })
        ;
    }
})();
