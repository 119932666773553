(function () {
    'use strict';

    function DocumentButtonsController($rootScope, $state, $translate, DocumentService, PaymentsService,
        ReportDocumentService, $mdDialog, $window, $mdToast, TokenService, $log, JsaReportService, PlagiatValidationService,
        JSA_ORDER_STATUS, DOCUMENT_STATUS, ModalService, OrppdService, ORPPD_STATUS, AccountService, PERMISSIONS, ContractService,
        Principal
    ) {
        var vm = this;
        vm.getDocumentReport = getDocumentReport;
        vm.getShortDocumentReport = getShortDocumentReport;
        vm.onClickReportSimilarity2017Pdf = onClickReportSimilarity2017Pdf;
        vm.getDocumentReport2018 = getDocumentReport2018;
        vm.removeDocument = removeDocument;
        vm.showText = showText;
        vm.useTokens = useTokens;
        vm.getDocuments = vm.onDocuments;
        vm.editDocument = editDocument;
        vm.editDocumentForIndividual = editDocumentForIndividual;
        vm.editDocumentBySuperAdmin = editDocumentBySuperAdmin;
        vm.showHistory = showHistory;
        vm.recheckAiDetection = recheckAiDetection;
        vm.checkDocumentAgain = checkDocumentAgain;
        vm.checkDocumentAgain = checkDocumentAgain;
        vm.sendReport = sendReport;
        vm.addToDatabase = addToDatabase;
        vm.removeFromDatabase = removeFromDatabase;
        vm.withdrawFromDatabase = withdrawFromDatabase;
        vm.rejectFromDatabase = rejectFromDatabase;
        vm.restoreFromDatabase = restoreFromDatabase;
        vm.acceptJsaReport = acceptJsaReport;
        vm.getShortJsaReport = getShortJsaReport;
        vm.canEditDocument = canEditDocument;
        vm.resendJsaDocument = resendJsaDocument;
        vm.canRestoreDocument = canRestoreDocument;
        vm.canRejectDocument = canRejectDocument;
        vm.canAddDocumentToDatabase = canAddDocumentToDatabase;
        vm.canWithdrawDocumentFromDatabase = canWithdrawDocumentFromDatabase;
        vm.canShowDocumentHistory = canShowDocumentHistory;
        vm.canShowRecheckAiDetection = canShowRecheckAiDetection;
        vm.canSendDocumentToOrppd = canSendDocumentToOrppd;
        vm.JSA_ORDER_STATUS = JSA_ORDER_STATUS;
        vm.DOCUMENT_STATUS = DOCUMENT_STATUS;
        vm.arrIdis = [];
        vm.jsa = AccountService.getClientSettings().jsaIntegration;
        vm.teacherCantDeleteFromDatabase = AccountService.getClientSettings().teacherCantDeleteFromDatabase && AccountService.role.isLecturer();
        vm.report2020Disabled = AccountService.getClientSettings().report2020Disabled;
        vm.isSuperAdmin = AccountService.role.isSuperAdmin();
        vm.isMinistry = AccountService.role.isMinistry();
        vm.isJsaDocument = isJsaDocument;
        vm.getDocumentFile = getDocumentFile;
        vm.isReport2020 = isReport2020;
        vm.canDownloadPdfReport = canDownloadPdfReport;

        function resendJsaDocument(ev) {
            editJsaDocument(ev, true);
        }

        function restoreFromDatabase(id){
            vm.onIsLoaderActive = true;
            vm.arrIdis.push(id);
            DocumentService.operator.restoreFromDatabase({ids: vm.arrIdis}).then(function(){
                vm.onDocuments();
                vm.onSelectedRows();
            }).catch(function(){
        		vm.onIsLoaderActive = false;
            });
        }

        function rejectFromDatabase(id){
            vm.onIsLoaderActive = true;
            vm.arrIdis.push(id);
            DocumentService.operator.rejectFromDatabase({ids: vm.arrIdis}).then(function(){
                vm.onDocuments();
                vm.onSelectedRows();
            }).catch(function(){
        		vm.onIsLoaderActive = false;
            });
        }

        function withdrawFromDatabase(id){
            ModalService.showConfirmModal('document.withdraw').then(function(){
                vm.onIsLoaderActive = true;
                vm.arrIdis.push(id);
                DocumentService.operator.withdrawFromDatabase({ids: vm.arrIdis}).then(
                    function() {
                        ModalService.showSuccessModal();
                        vm.onDocuments();
                        vm.onSelectedRows();
                    },
                    function(response) {
                        ModalService.showErrorModal(response);
                        vm.onIsLoaderActive = false;
                    }
                ).catch(function(response){
                    ModalService.showErrorModal(response);
                    vm.onIsLoaderActive = false;
                });
            });
        }

        function addToDatabase(id){
            vm.onIsLoaderActive = true;
            vm.arrIdis.push(''+id+'');
            DocumentService.operator.addToDatabase({ids: vm.arrIdis}).then(function(){
                vm.onDocuments();
                vm.onSelectedRows();
            }).catch(function(){
        		vm.onIsLoaderActive = false;
            });
        }

        function removeFromDatabase(id){
            vm.onIsLoaderActive = true;
            vm.arrIdis.push(''+id+'');
            DocumentService.operator.removeFromDatabase({ids: vm.arrIdis}).then(function(){
                vm.onDocuments();
                vm.onSelectedRows();
            }).catch(function(){
                $log.error('errResponse', errResponse)
            });
        }

        function acceptJsaReport(documentId){
            JsaReportService.acceptReport(documentId).then(function(){
                vm.onDocuments();
            }).catch(function(response){
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function getShortJsaReport() {
            vm.onIsLoaderActive = true;
            JsaReportService.getReport(vm.onDocumentId, false, vm.onDocuAuthor).then(function(){
                vm.onIsLoaderActive = false;
            }).catch(function(){
                vm.onIsLoaderActive = false;
            });
        }

        function getShortDocumentReport() {
            vm.onIsLoaderActive = true;
            ReportDocumentService.plainText(vm.onDocumentId, 'short', vm.onDocuAuthor).then(function(){
                vm.onIsLoaderActive = false;
            }).catch(function(){
            	vm.onIsLoaderActive = false;
            });
        }

        function getDocumentReport(reportType) {
            if (isReport2020()) {
                openDocumentReport2020();
            } else {
                getDocumentReport2018(reportType);
            }
        }

        function isReport2020() {
            return (vm.isSuperAdmin && !vm.onReport2020Disabled) || !vm.report2020Disabled;
        }

        function getDocumentReport2018(reportType) {
            vm.onIsLoaderActive = true;
            ReportDocumentService.getDocumentReport2018(vm.onDocumentId, reportType, vm.onDocuAuthor).then(function(){
                vm.onIsLoaderActive = false;
            });
        }

        function openDocumentReport2020() {
            vm.onIsLoaderActive = true;
            ReportDocumentService.getReport2020RedirectionUrl(vm.onDocumentId).then(function(redirectionUrl){
                window.open(redirectionUrl, "_blank");
                vm.onIsLoaderActive = false;
            });
        }

        function onClickReportSimilarity2017Pdf(type) {
        	vm.onIsLoaderActive = true;
        	ReportDocumentService.getReport2017Pdf(vm.onDocumentId, type, vm.onDocuAuthor).then(function(){
        		vm.onIsLoaderActive = false;
        	}).catch(function(){
        		vm.onIsLoaderActive = false;
            });
        }

        function removeDocument() {
            var confirm = $mdDialog.confirm()
            .title($translate.instant("document.confirmremove"))
            .textContent($translate.instant("document.areyousure"))
            .ariaLabel($translate.instant("document.areyousure"))
            .ok($translate.instant("global.button.remove"))
            .cancel($translate.instant("global.button.cancel"))
            .openFrom('#left');
            $mdDialog.show(confirm).then(function () {
                vm.onIsLoaderActive = true;
                vm.removeService = vm.onIsIndividual ? DocumentService.remove(vm.onDocumentId) : DocumentService.removeMassDocuments({ids: [vm.onDocumentId]});
                vm.removeService.then(function () {
                    TokenService.broadcastTokens();
                    vm.onDocuments();
                    vm.onSelectedRows();
                }, function (error) {
                	vm.onIsLoaderActive = false;
                    $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
                });
            });
        }

        function showText(ev) {
        	DocumentService.document.id = vm.onDocumentId;
        	$mdDialog.show({
        		templateUrl: 'app/account/documents/document-text/view-text.tmpl.html',
        		targetEvent: ev,
        		clickOutsideToClose: true,
                multiple: true
        	});
        }

        function canEditDocument() {
            var docIsEditable = (vm.onDocumentStatus >= -2 && vm.onJsaOrderStatus !== JSA_ORDER_STATUS.ACCEPTED);
            if (vm.onIsAdmin) {
                return docIsEditable;
            } else {
                return docIsEditable && !vm.onIsStudent && !vm.isMinistry && vm.clientHasPermToEditDocument;
            }
        }

        function canRestoreDocument() {
            if (!vm.onDocumentHasReport) {
                return false;
            }
            return ((vm.onDocumentStatus === DOCUMENT_STATUS.DISALLOWED_TO_ADD_TO_DATABASE.id || vm.onDocumentStatus === DOCUMENT_STATUS.EDITION_REQUIRED.id)
                && !vm.onIsIndividual && !vm.onIsStudent && !vm.isMinistry);
        }

        function canRejectDocument() {
            return (vm.onDocumentStatus === DOCUMENT_STATUS.PLAGIARISM_CHECKED.id && !vm.onIsIndividual && !vm.onIsStudent  && !vm.isMinistry && !vm.document.orppdActive);
        }

        function canAddDocumentToDatabase() {
            return (vm.onDocumentStatus === DOCUMENT_STATUS.PLAGIARISM_CHECKED.id && !vm.onIsIndividual && !vm.onIsStudent && !vm.isMinistry && userDatabaseAddingEnabled());
        }

        function canSendDocumentToOrppd() {
            return !vm.onIsIndividual && !vm.onIsStudent && vm.document.orppdActive;
        }

        vm.sendDocumentToOrppd = sendDocumentToOrppd;
        function sendDocumentToOrppd() {
            ModalService.showConfirmModal('document.table.menu.send-document-to-orppd').then(function(){
                vm.onIsLoaderActive = true;
                OrppdService.sendDocument(vm.onDocumentId).then(
                    function(){
                        ModalService.showSuccessModal();
                        vm.onDocuments();
                        vm.onSelectedRows();
                    },
                    function(response){
                        ModalService.showErrorModal(response);
                        vm.onIsLoaderActive = false;
                    }).catch(function(response){
                    ModalService.showErrorModal(response);
                    vm.onIsLoaderActive = false;
                });
            });
        }

        vm.getOrppdActionTranslationKey = getOrppdActionTranslationKey;
        function getOrppdActionTranslationKey() {
            return vm.document.orppdStatus === ORPPD_STATUS.SENT.id ? 'document.table.menu.resend-document-to-orppd' :
                'document.table.menu.send-document-to-orppd';
        }

        function canWithdrawDocumentFromDatabase() {
            return ((vm.onDocumentStatus === DOCUMENT_STATUS.MARKED_FOR_DATABASE.id || vm.onDocumentStatus === DOCUMENT_STATUS.ADDED_TO_DATABASE.id)
                && !vm.onIsIndividual 
                && !vm.onIsStudent
                && !vm.isMinistry
                && !vm.teacherCantDeleteFromDatabase
                && userDatabaseAddingEnabled());
        }

        function userDatabaseAddingEnabled() {
            return vm.onIsSuperAdmin 
                || AccountService.getClientSettings().addingToDatabase === 'automatically'
                || AccountService.getClientSettings().addingToDatabase === 'delayed'
                || AccountService.getClientSettings().addingToDatabase === 'manually';
        }

        function canShowDocumentHistory() {
            return vm.onDocumentStatus >= DOCUMENT_STATUS.WAITING_FOR_PAYMENT.id;
        }

        function canShowRecheckAiDetection() {
            // return vm.document.aiDetection && vm.onIsSuperAdmin;
            return vm.onIsSuperAdmin;
        }

        function editDocument(ev) {
            if (vm.onIsSuperAdmin) {
                editDocumentBySuperAdmin(ev);
            } else if (vm.onIsIndividual) {
                editDocumentForIndividual(ev);
            } else if (isJsaDocument()) {
                editJsaDocument(ev);
            } else {
                editStandardDocument(ev);
            }
        }



        function editStandardDocument(ev) {
            DocumentService.document.id = vm.onDocumentId;
            $mdDialog.show({
                templateUrl: 'app/components/document/document-form/document-form.dialog.html',
                targetEvent: ev,
                clickOutsideToClose: false
            }).then(function () {
                vm.onDocuments();
                vm.onSelectedRows();
            });
        }

        function editJsaDocument(ev, refreshContract) {
            DocumentService.document.id = vm.onDocumentId;
            $mdDialog.show({
                templateUrl: 'app/components/document/jsa-edit-form/jsa-document-form.dialog.html',
                targetEvent: ev,
                clickOutsideToClose: false
            }).then(function () {
                vm.onDocuments();
                vm.onSelectedRows();
                if(refreshContract){
                    ContractService.broadcast();
                }
            });
        }

        function editDocumentForIndividual(ev) {
            DocumentService.document.id = vm.onDocumentId;
            $mdDialog.show({
                templateUrl: 'app/components/document/document-form-individual/document-form-individual.dialog.html',
                targetEvent: ev,
                clickOutsideToClose: false
            }).then(function (result) {
                vm.onDocuments();
                vm.onSelectedRows();
            });
        }

        function editDocumentBySuperAdmin(ev) {
        	DocumentService.checkIfDocumentCreatedByIndividual(vm.onDocumentId).then(function (data) {
                var documentByIndividual = data.data;
                if (documentByIndividual) {
                    editDocumentForIndividual(ev);
                } else if (isJsaDocument()) {
                    editJsaDocument(ev);
                } else {
                    editStandardDocument(ev);
                }
            });
        }

        function isJsaDocument() {
            return angular.isDefined(vm.onJsaOrderStatus) && vm.onJsaOrderStatus != null || vm.onDocumentStatus === DOCUMENT_STATUS.JSA_ERROR.id;
        }

        function showHistory(ev) {
            DocumentService.document.id = vm.onDocumentId;
            $mdDialog.show({
                templateUrl: 'app/components/document/document-history/document-history.dialog.html',
                targetEvent: ev,
                clickOutsideToClose: false
            });
        }

        function recheckAiDetection(docId, ev) {
            $mdDialog.show($mdDialog.confirm()
                .title($translate.instant("document.messages.recheck-ai-detection"))
                .textContent(vm.checkInfo)
                .ariaLabel(vm.checkInfo)
                .targetEvent(ev)
                .ok('Ok')
                .cancel($translate.instant("global.button.cancel"))
            ).then(function () {
                DocumentService.operator.recheckAiDetection(docId)
                    .then(function () {
                        $mdDialog.show(
                            $mdDialog.confirm()
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent($translate.instant("global.messages.info.actionsuccess"))
                                .ariaLabel('')
                                .ok('Ok')
                                .targetEvent(ev)
                        );
                        vm.onDocuments();
                    }).catch(function (response) {
                    PlagiatValidationService.getModalError(response.data);
                });
            });
        }

        function useTokens() {
            vm.onUseTokens;
        }

        function checkDocumentAgain(docId, ev) {
            $mdDialog.show($mdDialog.confirm()
                .title($translate.instant("document.messages.checknow.again"))
                .textContent(vm.checkInfo)
                .ariaLabel(vm.checkInfo)
                .targetEvent(ev)
                .ok('Ok')
                .cancel($translate.instant("global.button.cancel"))
            ).then(function () {
                DocumentService.operator.checkDocumentAgain(docId)
                    .then(function () {
                        $mdDialog.show(
                            $mdDialog.confirm()
                                .clickOutsideToClose(true)
                                .title('')
                                .textContent($translate.instant("global.messages.info.actionsuccess"))
                                .ariaLabel('')
                                .ok('Ok')
                                .targetEvent(ev)
                        );
                        vm.onDocuments();
                    }).catch(function (response) {
                        PlagiatValidationService.getModalError(response.data);
                    });
            });
        }

        function sendReport(docId, ev) {
            DocumentService.operator.sendReport(docId)
            .then(function () {
                $mdDialog.show(
                    $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("global.messages.info.actionsuccess"))
                        .ariaLabel('')
                        .ok('Ok')
                        .targetEvent(ev)
                );
                vm.onDocuments();
            }).catch(function (response) {
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function getDocumentFile() {
            DocumentService.getDocumentFile(vm.onDocumentId).then(function (result) {
                var binary_string = window.atob(result.data.uploadFile);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }

                var blob = new Blob([ bytes.buffer]);
                saveAs(blob, result.data.docuTitle);
            }).catch(function (response) {
                PlagiatValidationService.getModalError(response.data);
            });
        }

        function canDownloadPdfReport() {
            return Principal.hasAnyAuthority(['PERM_DOWNLOAD_PDF_REPORT', 'PERM_USER_SUPERADMIN']) || isReport2020();
        }

    }

    angular.module('documentButtons', [])
    .component('documentButtons', {
        templateUrl: 'app/account/documents/documents-list/document-buttons/document-buttons.template.html',
        controller: DocumentButtonsController,
        controllerAs: 'vm',
        bindings: {
            onTokensNeeded: '<',
            onDocumentId: '<',
            onDocumentSize: '<',
            onUseTokens: '=',
            onCheckTokens: '=',
            onDocumentStatus: '=',
            onDocumentHasReport: '=',
            onReport2020Disabled: '=',
            onDocuments: '<',
            onIsLecturer: '<',
            onIsStudent: '<',
            onIsOperator: '<',
            onIsIndividual: '<',
            onIsAdmin: '<',
            onIsSuperAdmin: '<',
            onClientId: '<',
            onDocuAuthor: '<',
            onIsLoaderActive: '=',
            onSelectedRows: '<',
            onCanAcceptJsaReport: '<',
            onDocumentHasJsaReport: '<',
            onJsaOrderStatus: '<',
            document: '=',
            clientHasPermToEditDocument: '='
        }
    });

})();
