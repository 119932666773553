(function () {
    'use strict';

    function DocumentMetricController($scope, $rootScope, $state, $translate, $mdDialog, AUTO_TRANSLATIONS_LANGS, 
                                      DocumentRequest, DocumentService,
                                      TokenService, DocumentFormService, Utils, ExtraOptionsService, DetectInstanceService) {
        var vm = this;
        vm.getDocuments = getDocuments;
        vm.resetUploadForm = resetUploadForm;
        vm.checkLater = checkLater;
        vm.documentRequest = new DocumentRequest();
        vm.check = check;
        vm.uploadText = uploadText;
        vm.getDocumentSkipAddress = getDocumentSkipAddress;
        vm.goToExtraOptions = goToExtraOptions;
        vm.recalculatePrices = recalculatePrices;
        vm.isAiDetectionEnabled = isAiDetectionEnabled;
        vm.isOptionDisabled = isOptionDisabled;
        vm.step = 1;
        vm.threeStateRadios = DetectInstanceService.instance() === 'int';

        vm.autoTranslationLangs = Utils.sortAutoTranslationsLanguages(AUTO_TRANSLATIONS_LANGS, $translate);
        vm.translation = {};

        function uploadText() {
            vm.onUploadText();
        }

        function broadcastTokensInfo() {
            $rootScope.$broadcast("availableTokens");
        }

        function getDocuments() {
            resetUploadForm();
            vm.onReloadDocuments();
        }

        function resetUploadForm() {
            vm.onUploadIsActive = false;
            vm.onUploadTextFlag = false;
            vm.documentRequest = new DocumentRequest();
            vm.translation = {};
            ExtraOptionsService.resetForm(vm);
            vm.onGetTheFiles(null, {});
            vm.step = 1;
        }

        function checkLater(form, ev) {
            if (!Utils.validateForm(form)) {
                return;
            }
            fillDocumentRequest();
            $mdDialog.show(
                $mdDialog.confirm()
                .title($translate.instant("document.messages.checklater"))
                .textContent($translate.instant("document.messages.clickinfo"))
                .ariaLabel($translate.instant("document.messages.clickinfo"))
                .targetEvent(ev)
                .ok('Ok')
                .cancel($translate.instant("global.button.cancel"))

                ).then(function () {
                    DocumentService.save(vm.documentRequest).then(function (responseData) {
                        vm.onUploadIsActive = false;
                        getDocuments();
                        getTokensInfo();
                        broadcastTokensInfo();
                        $mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .textContent($translate.instant("document.messages.checkconfirm"))
                            .ariaLabel($translate.instant("document.messages.checkconfirm"))
                            .ok('Ok')
                            .targetEvent(ev)
                            );
                    });
                }, function () {

                });
        }

        function fillDocumentRequest() {
            vm.onUploadTextFlag = false;
            vm.documentRequest.fileId = vm.onUploadObj.fileId;
            vm.documentRequest.title = vm.onUploadObj.docuTitle;
            vm.documentRequest.author = vm.onUploadObj.authorFirstName + ' ' + vm.onUploadObj.authorLastName;
            vm.documentRequest.documentSkipAddresses = vm.onUploadObj.documentSkipAddresses;
            vm.documentRequest.paperFormValidityFlag = vm.onUploadObj.paperFormValidityFlag;
            vm.documentRequest.extraOptions = ExtraOptionsService.getCurrentDocumentOptions(vm);
            vm.documentRequest.autoTranslations = vm.translation.chosen;
            vm.documentRequest.autoTranslationsToLang = vm.translation.autoTranslationsToLang;
        }

        function check(form) {
            if (!Utils.validateForm(form)) {
                return;
            }
            fillDocumentRequest();
            vm.isLoading = true;

            DocumentService.check(vm.documentRequest).then(function (responseData) {
                vm.isLoading = false;
                vm.onUploadIsActive = false;
                if (responseData.data.used === false) {
                    var confirm = $mdDialog.confirm()
                    .title('')
                    .textContent($translate.instant("global.messages.error.notenough"))
                    .ariaLabel('notenough')
                    .ok($translate.instant("document.buytokens"))
                    .cancel($translate.instant("document.metric.check-later"))
                    .openFrom('#left');
                    $mdDialog.show(confirm).then(function (result) {
                        TokenService.buyTokens = true;
                        TokenService.tokensNedded = vm.totalPrice - vm.availableTokens;

                        $state.go('payments', {documentId : responseData.data.documentId});
                    },function (result) {
                        getDocuments();
                        broadcastTokensInfo();
                        $mdDialog.hide();
                    });
                } else {
                    $mdDialog.show(
                      $mdDialog.confirm()
                      .clickOutsideToClose(true)
                      .title('')
                      .textContent($translate.instant("global.messages.info.actionsuccess"))
                      .ariaLabel('actionsuccess')
                      .ok('Ok')
                      );
                    getDocuments();
                    broadcastTokensInfo();
                }
            }).catch(function (response) {
                vm.isLoading = false;
                if (response.status === 400) {
                    $mdDialog.show(
                      $mdDialog.confirm()
                      .clickOutsideToClose(true)
                      .title($translate.instant("global.messages.error.unknown"))
                      .textContent($translate.instant("global.messages.error.unknown"))
                      .ariaLabel($translate.instant("global.messages.error.unknown"))
                      .ok('OK')
                      );
                }
            });
                    
        }
    
        function getDocumentSkipAddress(chip) {
            return DocumentFormService.documentSkipAddress(chip, vm);
        }

        function goToExtraOptions(form) {
            if (!Utils.validateForm(form)) {
                return;
            }
            if (!vm.documentExtraOptions) {
                vm.isLoading = true;
                TokenService.getTokenPriceList({
                    documentId: null,
                    documentSize: vm.onUploadObj.size
                }).then(function (responseData) {
                    vm.charactersPerToken = responseData.data.charactersPerToken;
                    vm.documentExtraOptions = responseData.data.extraOptions;
                    if (vm.instance === 'int') {
                        for (var i in vm.documentExtraOptions) {
                            vm.documentExtraOptions[i].chosen = null;
                        }
                    }
                    vm.freeChecksAvailable = responseData.data.freeChecksAvailable;
                    recalculatePrices();
                    TokenService.getInfo().then(function (responseData) {
                        vm.tokenInfo = responseData.data;
                        vm.availableTokens = responseData.data.availableTokens;
                        vm.isLoading = false;
                        vm.step = 2;
                    });
                });
            } else {
                vm.step = 2;
            }

        }

        function recalculatePrices() {
            return ExtraOptionsService.recalculatePrices(vm.onUploadObj.size, vm);
        }

        function isAiDetectionEnabled() {
            return ExtraOptionsService.isAiDetectionEnabled(vm);
        }

        function isOptionDisabled(option) {
            return ExtraOptionsService.isOptionDisabled(vm, vm.onUploadObj.size, option);
        }

    }

    angular.module('documentMetric', [])
            .component('documentMetric', {
                templateUrl: 'app/account/documents/document/document-form/document-form-individual/document-metric-individual.template.html',
                controller: DocumentMetricController,
                bindings: {
                    onUploadIsActive: '=',
                    onUploadTextFlag: '=',
                    onUploadObj: '<',
                    onReloadDocuments: '<',
                    onGetTheFiles: '<',
                    onUploadText: '<',
                    isLoading: '='
                }
            });

})();
