(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('DocumentKindsService', DocumentKindsService);
    
    DocumentKindsService.$inject = ['$http', '$q'];

    function DocumentKindsService($http, $q) {

        var service = {
            load: load,
            updateDocumentKinds: updateDocumentKinds,
            getThresholdsForEdit: getThresholdsForEdit,
            saveThresholds: saveThresholds,
            saveDueDate: saveDueDate,
        };
        return service;

        function load() {
            return $http.get('api/client/admin-document-kinds').then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function updateDocumentKinds(obj) {
            return $http.post('/api/client/admin-update-document-kinds', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
        
        function getThresholdsForEdit(id) {
            return $http.get('/api/client/admin-document-kinds/get-thresholds-for-edit/' + id).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function saveThresholds(obj) {
            return $http.post('/api/client/admin-update-document-kind-thresholds', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function saveDueDate(obj) {
            return $http.put('/api/client/admin-update-document-kind/due-date', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }
    
    }
})();
