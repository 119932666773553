(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('OrganisationalUnitsController', OrganisationalUnitsController);

    OrganisationalUnitsController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate',
    	'organisationalUnitsQueryRequest', 'organisationalUnitsSearchRequest', 'tableSettingsRequest',
    	'OrganisationalUnitsService', 'SearchService', '$mdDialog', 'PlagiatValidationService', '$localStorage',
        'AccountService'];

    function OrganisationalUnitsController (Principal, Auth, JhiLanguageService, $translate,
    		organisationalUnitsQueryRequest, organisationalUnitsSearchRequest, tableSettingsRequest,
    		OrganisationalUnitsService, SearchService, $mdDialog, PlagiatValidationService, $localStorage,
                                            AccountService) {
        var vm = this;
        vm.activeTab='organisationalUnits';
        vm.queryRequest = new organisationalUnitsQueryRequest();
        vm.doesClientHaveJsaIntegration = AccountService.getClientSettings().jsaIntegration;
        vm.queryRequest.limit = $localStorage.organisationalListLimit ? $localStorage.organisationalListLimit : vm.queryRequest.limit;
        vm.searchRequest = new organisationalUnitsSearchRequest();
        vm.options = new tableSettingsRequest();
        vm.selected = [];
        vm.pickedIds = [];
        vm.startPaging = startPaging;
        vm.prepareMapping = prepareMapping;
        vm.getData = getData;
        vm.removeItems = removeItems;
        prepareMapping();

        function prepareMapping(){
        	getData(vm.organisationalUnitsSearchRequest);
        }

        function getData(organisationalUnitsSearchRequest){
            vm.selected.length = 0;
            vm.showDetailsBlock = false;
            vm.organisationalUnitsSearchRequest = organisationalUnitsSearchRequest;
            vm.searchRequest.pageNumber = (vm.queryRequest.page - 1);
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);

            vm.isLoadingData = true;
            OrganisationalUnitsService.getList(vm.searchRequest).then(function (responseData) {

                vm.organisationalUnits = responseData.content;
                vm.totalElements = responseData.totalElements;

                vm.isLoadingData = false;
            });
        }

        function startPaging(page) {
         	vm.isLoadingData = true;
             vm.queryRequest.page = page;
             prepareMapping();
         }

        function transformSelectedObj() {
            vm.pickedIds.length = 0;
            angular.forEach(vm.selected, function (value) {
                vm.pickedIds.push(value.id);
            });
        }

        function removeItems(ev) {
            var confirm = $mdDialog.confirm()
            .title($translate.instant("global.confirmremove"))
            .textContent($translate.instant("global.areyousure"))
            .ariaLabel($translate.instant("global.areyousure"))
            .ok($translate.instant("global.button.remove"))
            .cancel($translate.instant("global.button.cancel"))
            .openFrom('#left');
            $mdDialog.show(confirm).then(function () {
                transformSelectedObj();
                OrganisationalUnitsService.remove(vm.pickedIds).then(function () {
                    vm.selected.length = 0;
                    prepareMapping();

            		$mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .title('')
                            .textContent($translate.instant("global.table.delete-multiple.msg.success"))
                            .ariaLabel('msgSuccess')
                            .ok('Ok')
                            .targetEvent(ev)
                            );
                }, function (response) {
                	PlagiatValidationService.getModalError(response.data, $translate.instant("global.messages.warning.label"));
                });
            });
        }

    }
})();
