(function() {
	'use strict';

	function UserHistoryController($scope, $mdDialog, UserService) {
		var vm = this;
		vm.closeDialog = closeDialog;
		vm.userId = UserService.user.id;
		vm.getUserHistory = getUserHistory;

		function closeDialog() {
			$mdDialog.hide();
		}

		function getUserHistory() {
			UserService.getUserHistory(vm.userId).then(function(response) {
				vm.userHistoryList = response.data;
			});
		}

		if (vm.userId !== null) {
			getUserHistory();
		}

	}

	angular
			.module('plagiat2017App')
			.component(
					'userHistory',
					{
						templateUrl : 'app/account/users/user-history/user-history.template.html',
						controller : UserHistoryController
					});

})();
