(function() {
	'use strict';

	function ApiKeyDetailsController() {

	}

	angular
        .module('plagiat2017App')
        .component(
            'apKeyDetails',
            {
                templateUrl : 'app/account/settings/ap-keys/ap-keys-details/ap-keys-details.template.html',
                controller : ApiKeyDetailsController,
                controllerAs: 'vm',
                bindings: {
                	details: '<'
                }
            }
        );

})();
