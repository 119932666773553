(function () {
    'use strict';

    var countryCodes = {
        INDIVIDUALS: {key: 'country.individuals', id: 1},
        POLAND_COLUMBIA: {key: 'country.poland_columbia', id: 2},
        UKRAINE: {key: 'country.ukraine', id: 4},
        KAZAKHSTAN_KYRGYZSTAN: {key: 'country.kazakhstan_kyrgyzstan', id: 8},
        AZERBAIJAN: {key: 'country.azerbaijan', id: 16},
        ROMANIA: {key: 'country.romania', id: 64},
        GUTONLANDIA: {key: 'country.gutonlandia', id: 128}
    };

    countryCodes.arrayOfValues = [countryCodes.INDIVIDUALS, countryCodes.POLAND_COLUMBIA, countryCodes.UKRAINE, countryCodes.KAZAKHSTAN_KYRGYZSTAN,
        countryCodes.AZERBAIJAN, countryCodes.ROMANIA, countryCodes.GUTONLANDIA
    ];

    angular
        .module('plagiat2017App')
        .constant('COUNTRYCODECONSTANT', countryCodes)
})();
