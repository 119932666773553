(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('user-import', {
            parent: 'account',
            url: '/user-import',
            data: {
            	authorities: ['PERM_USER_ADMIN', 'PERM_USER_ORG_UNIT_ADMIN', 'PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.users'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/users/user-import/user-import.html',
                    controller: 'UserImportController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('user.import');
                    $translatePartialLoader.addPart('document');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
