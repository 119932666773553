(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('ReportDocumentService', ReportDocumentService);

    ReportDocumentService.$inject = ['$http', '$q', '$window', '$translate', '$mdToast'];

    function ReportDocumentService($http, $q, $window, $translate, $mdToast) {

        var service = {
            plainText: plainText,
            getDocumentReport2018: getDocumentReport2018,
            prepareReportName: prepareReportName,
            getReport2017Pdf: getReport2017Pdf,
            getReport2020RedirectionUrl: getReport2020RedirectionUrl
        };

        return service;

        function prepareReportName(docuAuthor, version, reportExtension){
        	var reportPrefix = (version === 'short' ? $translate.instant("document.report.file.name.short") : $translate.instant("document.report.file.name.full"));
        	
        	var reportName = docuAuthor.replace( /[<>:"\/\\|?*]+/g, '' );
        	reportName = reportPrefix + '_' + reportName + reportExtension;
        	
        	return reportName;
        }
        
        function plainText(documentId, version, docuAuthor) {
            return $http({
                url : '/api/document/plainReport/' + documentId + '/' + version,
                method : "GET",
                data : [],
                headers : {
                   'Content-type' : 'application/json'
               },
               responseType : 'arraybuffer'
           })
            .then(
             function(success) {
                var blob = new Blob(
                  [ success.data ],
                  {
                     type: "text/html; encoding=UTF-8"
                 });

                var reportName = prepareReportName(docuAuthor, version, '.html');
                saveAs(blob, reportName);

            }, function(error) {
               $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
           });
        }
        
        function getDocumentReport2018(documentId, reportType, docuAuthor) {
            return $http({
                url : '/api/document/report/html/' + reportType + '/' + documentId,
                method : "GET",
                data : [],
                headers : {
                   'Content-type' : 'application/json'
               },
               responseType : 'arraybuffer'
           })
            .then(
             function(success) {
                var blob = new Blob(
                  [ success.data ],
                  {
                     type: "text/html; encoding=UTF-8"
                 });

                var reportName = prepareReportName(docuAuthor, reportType, '.html');
                saveAs(blob, reportName);

            }, function() {
               $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
           });
        }

        /**
         * Generates 2017 type getReport2017Pdf report
         *
         * @param documentId
         * @param reportType ('full' or 'short')
         * @param docAuthor
         * @returns {*}
         */
        function getReport2017Pdf(documentId, reportType, docAuthor) {
            return $http({
                url : '/api/document/report/pdf/2017/' + reportType + '/' + documentId,
                method : "GET",
                responseType : 'arraybuffer'
            }).then(
                function(success) {
                    onSuccess(success, docAuthor, reportType, '.pdf');
                }, function() {
                    showError();
                }
            );
        }

      function getReport2020RedirectionUrl(documentId) {
        return $http.get('/api/document/report/report-2020/' + documentId + '/redirection-url').then(
          function (response) {
            return response.data.data;
          },
          function (errResponse) {
            return $q.reject(errResponse);
          });
      }

        function onSuccess(success, docAuthor, reportType, reportExtension) {
            var blob = new Blob(
                [ success.data ],
                {
                    type: "text/pdf; encoding=UTF-8"
                });
            var reportName = prepareReportName(docAuthor, reportType, reportExtension);
            saveAs(blob, reportName);
        }

        function showError() {
            $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
        }
    }

})();
