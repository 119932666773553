(function() {
    'use strict';

    function SessionCountdownController ($scope, $log, $http, $interval, $state, Auth, $mdDialog) {
        var vm = this;
        vm.countdownSettings = {
            minutes: 30,
            seconds: 60
        };

        function clearTimer(){
            $interval.cancel(vm.timerInterval);
        }

        function startTimer(duration) {
            var timer = duration, minutes, seconds;
            vm.timerInterval = $interval(function () {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                vm.timer = minutes + ":" + seconds;

                if (--timer < 0) {
                    clearTimer();
                    $mdDialog.hide();
                    Auth.logout();
                    $state.go('login');
                    $mdDialog.hide();
                }
            }, 1000);
        }

        startTimer(vm.countdownSettings.seconds * vm.countdownSettings.minutes);

        $scope.$watch(function() {
            if( $http.pendingRequests.length > 0 ) {
                clearTimer();
                startTimer(vm.countdownSettings.seconds * vm.countdownSettings.minutes);
            }
        });

    }

    angular.module('plagiat2017App')
    .component('sessionCountdown', {
        templateUrl: 'app/components/session-countdown/session-countdown.template.html',
        controller: SessionCountdownController
    });

})();
