(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('DateService', DateService);

    DateService.$inject = [];

    function DateService() {


        var service = {
        	fixDate: fixDate,
            fixDateDcouments: fixDateDcouments
        };

        return service;

        function fixDate(input, fieldName, vm) {
            if( input ){
                vm.onSearchRequest.search[fieldName] = moment(input.setDate(input.getDate())).format('YYYY-MM-DD');
            }
        }

        function fixDateDcouments(input, fieldName, vm) {
            if( input ){
                vm.documentsSearchRequest[fieldName] = moment(input.setDate(input.getDate())).format('YYYY-MM-DD');
            }
        }

    }


})();
