(function () {
    'use strict';


    var statisticOptions = {
        DOCUMENTS: {
            id: 0,
            url: 'documents',
            translationKey: 'statistics.option.documents'
        },
        UNIT_CONTRACT: {
            id: 1,
            url: 'units',
            translationKey: 'statistics.option.units'
        },
        USER_CONTRACT: {
            id: 2,
            url: 'users',
            translationKey: 'statistics.option.users'
        },
        DOCUMENTS_CHECKED: {
            id: 3,
            url: 'document-checks',
            translationKey: 'statistics.option.checks'
        },
        COEFFICIENT_DISTRIBUTION: {
            id: 4,
            url: 'coefficient-distribution',
            translationKey: 'statistics.option.coefficient-distribution'
        },
        NUMBER_OF_USERS: {
            id: 5,
            url: 'number-of-users',
            translationKey: 'statistics.option.number-of-users'
        },
        AI_DETECTION: {
            id: 6,
            url: 'ai-detection',
            translationKey: 'statistics.option.ai-detection'
        }
    };

    angular
        .module('plagiat2017App')
        .constant('STATISTIC_OPTIONS', statisticOptions);

})();
