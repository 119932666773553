(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('SearchRequest', function () {

        function SearchRequest() {
            this.pageNumber = 0;
            this.pageSize = 5;
            this.sortTab = [];
            this.showOnlyForUser = true;
            this.simpleSearch = '';
            this.search = {};
        }

        return SearchRequest;
    });
})();
