(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('InvoicesQueryRequest', function () {

        function InvoicesQueryRequest() {
            this.order = '-id';
            this.page = 1;
            this.limit = 5;
        }

        return InvoicesQueryRequest;
    });
})();
