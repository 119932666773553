(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', '$translate', 'tmhDynamicLocale', 'LANGUAGES'];

    function JhiLanguageService($q, $http, $translate, tmhDynamicLocale, LANGUAGES) {
        var service = {
            getAll: getAll,
            getAllWithAdditional: getAllWithAdditional,
            setLanguage:setLanguage,
            getCurrent: getCurrent
        };

        return service;

        function setLanguage(languageKey){
            $translate.use(languageKey);
            tmhDynamicLocale.set(languageKey);
        }

        function getAll() {
            return $http.get('api/languages/available').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getAllWithAdditional() {
            return $http.get('api/languages/available-with-additional').then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getCurrent() {
            var deferred = $q.defer();
            var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

            deferred.resolve(language);

            return deferred.promise;
        }
    }
})();
