(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .directive('inputNumber', inputNumber);

    function inputNumber(Utils) {
    	return {
    	    restrict: 'A',
    	    link: function ($scope, element, attrs, ngModel) {
    	        element.bind('keypress', function (e, inputs) {
    	            if(!Utils.isAllowedCharForDigit(e)) {
    	            	e.preventDefault();
    	            }
    	        })
    	    }
    	  };
    }
})();
