(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ProtocolForDocumentService', ProtocolForDocumentService);

    ProtocolForDocumentService.$inject = ['$http', '$q'];

    function ProtocolForDocumentService($http, $q) {

        var service = {
            getAllForCurrentClient: getAllForCurrentClient,
        };

        var requestUrl = 'api/protocol-for-document';

        return service;

        function getAllForCurrentClient() {
            return $http({
                url: requestUrl,
                method: 'GET'
            }).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

    }

})();
