(function () {
  'use strict';

  AddPriorityController.$inject = ['$scope', '$rootScope', '$mdDialog', '$state', '$translate',
    'documentSize', 'documentId', 'onReloadDocuments', 'TokenService', 'ExtraOptionsService', 'DocumentService', 'PlagiatValidationService'];

  function AddPriorityController($scope, $rootScope, $mdDialog, $state, $translate, documentSize, documentId, onReloadDocuments,
                                 TokenService, ExtraOptionsService, DocumentService, PlagiatValidationService) {
    var vm = this;
    vm.pending = false;
    vm.initializing = true;
    vm.addPriority = addPriority;
    vm.buyTokensAndAddPriority = buyTokensAndAddPriority;
    vm.closeDialog = closeDialog;
    init();

    function init() {
      TokenService.getTokenPriceList({
        documentId: documentId,
        documentSize: documentSize
      }).then(function (responseData) {
        vm.charactersPerToken = responseData.data.charactersPerToken;
        vm.documentExtraOptions = responseData.data.extraOptions;
        recalculatePrices();
        TokenService.getInfo().then(function (responseData) {
          var availableTokens = responseData.data.availableTokens;
          var priorityOption = vm.documentExtraOptions.find(function (option) {
            return option.code === 'OPTION_PRIORITY'
          });
          vm.tokensNeeded = priorityOption.price;
          vm.tokensNeededToBuy = vm.tokensNeeded > availableTokens ? (vm.tokensNeeded - availableTokens) : 0;
          vm.initializing = false;
        });
      });
    }

    function buyTokensAndAddPriority() {
      TokenService.buyTokens = true;
      TokenService.buyPriority = true;
      TokenService.tokensNedded = vm.tokensNeededToBuy;
      $state.go('payments', {documentId: documentId});
      $mdDialog.hide();
    }

    function addPriority() {
      vm.pending = true;
      DocumentService.addPriority(documentId)
        .then(function () {
          $rootScope.$broadcast("availableTokens");
          onReloadDocuments();
          $mdDialog.hide();
        })
        .catch(function (response) {
          PlagiatValidationService.getModalError(response.data);
          $mdDialog.hide();
      });
    }

    function recalculatePrices() {
      return ExtraOptionsService.recalculatePrices(documentSize, vm);
    }

    function closeDialog() {
      $mdDialog.hide();
    }

  }

  angular
    .module('plagiat2017App')
    .controller('AddPriorityController', AddPriorityController);

})();
