(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('AddFeedbackController', AddFeedbackController);
    
    AddFeedbackController.$inject = ['PeerReviewFeedbackService', 'ModalService', 'AccountService', 'UserService', 'Utils',
        '$localStorage', '$state', '$stateParams', '$mdDialog', '$scope'];

    function AddFeedbackController(PeerReviewFeedbackService, ModalService, AccountService, UserService, Utils,
                                   $localStorage, $state, $stateParams, $mdDialog, $scope) {

        const vm = this;
        vm.loadingValues = true;
        vm.pending = false;

        vm.obj = {};

        vm.save = save;
        vm.submit = submit;
        vm.validateWordCount = validateWordCount;

        this.$onInit = function () {
            if ($stateParams.id) {
                PeerReviewFeedbackService.findOne($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                    vm.loadingValues = false;
                    validateAllAnswers();
                });
            }
        };
        function validateAllAnswers() {
            vm.obj.answers.forEach(function(answer, index) {
                if (answer.questionType === 'text') {
                    validateWordCount(answer, index);
                }
            });
        }

        function validateWordCount(answer, index) {
            answer.currentWordCount = answer.contentText ? answer.contentText.trim().split(/\s+/).length : 0;
            const valid = answer.currentWordCount >= answer.questionTextMinWords;
            if ($scope.form1 && $scope.form1['answer' + index]) {
                const field = $scope.form1['answer' + index];
                field.$setValidity('textMinWords', valid);
                field.$setTouched();
                $scope.form1.$setValidity('textMinWords', valid);
            }
        }

        function save() {
            vm.pending = true;
            PeerReviewFeedbackService.update(vm.obj)
                .then(function () {
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('feedbacks');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                    vm.pending = false;
                });
        }

        function submit(form){
            if (!Utils.validateForm(form)) {
                return;
            }
            vm.pending = true;
            PeerReviewFeedbackService.submit(vm.obj)
              .then(function () {
                  ModalService.showSuccessModal("global.messages.success.label");
                  $state.go('feedbacks');
                  $stateParams.id = null;
              }, function (errorResponse) {
                  ModalService.showErrorModal(errorResponse);
                  vm.pending = false;
              });
        }

        $scope.generateRange = function(start, end) {
            var arr = [];
            for (var i = start; i <= end; i++) {
                arr.push(i);
            }
            return arr;
        };
    }
})();
