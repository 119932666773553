(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('popup-administration', {
            parent: 'account',
            url: '/popup-administration',
            data: {
                authorities: ['PERM_USER_SUPERADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/popup/popup-administration.template.html',
                    controller: 'PopupAdministrationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('popup');
                    return $translate.refresh();
                }]
            }
        })
        .state("popup-administration-add", {
            parent: "popup-administration",
            url: "/add-edit/:id",
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'popup.popup-administration.add.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/popup/popup-administration-add.template.html',
                    controller: 'PopupAdministrationAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('popup');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }
})();
