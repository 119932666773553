(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('OnlineOrderService', OnlineOrderService);

    OnlineOrderService.$inject = ['$http', '$q'];

    function OnlineOrderService($http, $q) {
        var requestUrl = 'api/online-order';
        var service = {
            sendObj: sendObj,
            getProformaInvoice: getProformaInvoice,
            getInitData: getInitData
        };
        return service;

        function sendObj(obj) {
            return $http.post(requestUrl, obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getInitData(currency) {
            return $http.get(requestUrl + '/init-data/' + currency).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getProformaInvoice(confirmationCode, lang) {
            return $http.get(requestUrl + '/proforma-invoice/' + lang + '/' + confirmationCode).then(
                function (success) {
                    var blob = new Blob(
                        [_base64ToArrayBuffer(success.data.pdfArray)],
                        {type: "text/pdf; encoding=UTF-8"}
                    );
                    saveAs(blob, success.data.filename);
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function _base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }
    }
})();
