(function () {
    'use strict';

    function DocumentsSearchIndividualController(documentsSearchIndividualRequest, $log, $timeout, DateService,
                                                 $localStorage, SIMPLE_SEARCH_DELAY, AccountService) {
        var vm = this;
        vm.documentsSearchRequest = new documentsSearchIndividualRequest();
        vm.getDocuments = getDocuments;
        vm.fixDateDcouments = DateService.fixDateDcouments;
        vm.simpleSearch = true;
        vm.toogleSearch = toogleSearch;
        vm.clear = clear;
        vm.simpleSearchDelay = SIMPLE_SEARCH_DELAY;
        vm.clientSettings = AccountService.getClientSettings();
        vm.isLoggedUserOrgUnitAdmin = AccountService.role.isOrgUnitAdmin();
        vm.clientTypeCodeAccount = false;
        vm.clientRegions = [];

        function getDocuments(){
            $localStorage.documentsListLimit = vm.onQueryRequest.limit;
            vm.documentsSearchRequest.simpleSearch.length === 0 ? vm.onGetDocuments(vm.documentsSearchRequest) : '';
            vm.documentsSearchRequest.simpleSearch.length > 2 ? vm.onGetDocumentsSimpleSearch(vm.documentsSearchRequest) : '';
        }

        function toogleSearch() {
            vm.onResetSelection();
            vm.simpleSearch ? vm.documentsSearchRequest.simpleSearch = '' : '';
            vm.simpleSearch = !vm.simpleSearch;
        }

        function clear() {
            vm.documentsSearchRequest = new documentsSearchIndividualRequest();
            getDocuments();
        }


        $timeout(function() {
            getDocuments();
        }, 1);

    }

    angular.module('plagiat2017App')
    .component('documentsSearchIndividual', {
        templateUrl: 'app/account/documents/documents-search/documents-search.template.html',
        controller: DocumentsSearchIndividualController,
        bindings: {
            onGetDocuments: '<',
            onQueryRequest: '=',
            onIsLecturer: '<',
            onIsOperator: '<',
            onIsIndividual: '<',
            onSearchData: '=',
            onResetSelection: '<',
            onGetDocumentsSimpleSearch: '<',
        }
    });

})();
