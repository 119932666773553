(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('PortalOfClientAddEditController', PortalOfClientAddEditController);
    
    PortalOfClientAddEditController.$inject = ['PortalOfClientService', 'ModalService',
        '$localStorage', '$state', '$stateParams', 'PORTAL_OF_CLIENT_TYPES'];

    function PortalOfClientAddEditController(PortalOfClientService, ModalService,
        $localStorage, $state, $stateParams, PORTAL_OF_CLIENT_TYPES) {
        var vm = this;
        vm.pending = false;

        vm.obj = {};
        vm.id = $stateParams.id;
        vm.portalOfClientTypes = PORTAL_OF_CLIENT_TYPES;
        vm.saveOrUpdate = saveOrUpdate;
        
        this.$onInit = function () {
            vm.pending = true;
    
            if ($stateParams.id) {
                PortalOfClientService.getForEditing($stateParams.id).then(function (obj) {
                    vm.obj = obj.data;
                });
            }
            vm.pending = false;
        };
    
        function saveOrUpdate() {
            PortalOfClientService
                .saveOrUpdate(vm.obj)
                .then(function () {
                    vm.obj = null;
                    ModalService.showSuccessModal("global.messages.success.label");
                    $state.go('portal-of-client');
                    $stateParams.id = null;
                }, function (errorResponse) {
                    ModalService.showErrorModal(errorResponse);
                });
        }
    
    }
})();
