(function() {
	'use strict';

	function PaymentStatusController(PaymentsService, $interval, $window, REFRESHING_TIME_FOR_LAST_PAYMENT, ContractService, $rootScope) {
		var vm = this;

		this.$onInit = function () {
			vm.checkingPromise = $interval(getStatusForLastPayment, REFRESHING_TIME_FOR_LAST_PAYMENT);
			getStatusForLastPayment();
		};

		function getStatusForLastPayment() {
			PaymentsService.getStatusForLastPayment().then(function (responseData) {
				vm.lastPayment = responseData.data;
				if(vm.lastPayment.providerStatus === 'payment.status.success') {
					$rootScope.$broadcast("availableTokens");
                    ContractService.broadcast();
					$interval.cancel(vm.checkingPromise);
				}
			});
		}
	}

	angular
			.module('plagiat2017App')
			.component(
					'paymentStatus',
					{
						templateUrl : 'app/account/payments/payment-status/payment-status.template.html',
						controller : PaymentStatusController,
						controllerAs: 'vm',
						bindings: {
							onIsCode: '='
						}
					});

})();
