(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('DatabaseExchangeProgramMembersQueryRequest', function () {

        function DatabaseExchangeProgramMembersQueryRequest() {
            this.order = 'clieName';
            this.page = 1;
            this.limit = 5;
        }

        return DatabaseExchangeProgramMembersQueryRequest;
    });
})();
