(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('database-exchange-program-members', {
            parent: 'account',
            url: '/database-exchange-program-members',
            data: {
                authorities: ['PERM_USER_ADMIN', 'PERM_USER_SUPERADMIN'],
                pageTitle: 'global.menu.account.database.exchange.program.members'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/database-exchange-program-members/database-exchange-program-members-list/database-exchange-program-members.html',
                    controller: 'DatabaseExchangeProgramMembersListController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('users');
                	$translatePartialLoader.addPart('client');
                	$translatePartialLoader.addPart('database');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
