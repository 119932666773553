(function () {
    'use strict';

    function checkLaterController($scope, $rootScope, $state, $translate, $mdDialog, DocumentRequest, DocumentService, TokenService, $log) {
        var vm = this;
        vm.getDocuments = getDocuments;
        vm.resetUploadForm = resetUploadForm;
        vm.checkLater = checkLater;
        vm.documentRequest = new DocumentRequest();
        vm.check = check;
        vm.missingTokens;
        vm.tokens;
        vm.uploadText = uploadText;

        getTokensInfo();

        function uploadText() {
            vm.onUploadText();
        }

        function broadcastTokensInfo() {
            $rootScope.$broadcast("availableTokens");
        }

        function getTokensInfo() {
            TokenService.getInfo().then(function (responseData) {
                vm.availableTokens = responseData.availableTokens;
            });
        }

        function getDocuments() {
            vm.onReloadDocuments();
        }

        function resetUploadForm() {
            vm.onUploadIsActive = false;
            vm.onUploadTextFlag = false;
            vm.documentRequest = new DocumentRequest();
            vm.onGetTheFiles(null, {});
        }

        function checkLater(obj, ev) {
            vm.onUploadTextFlag = false;
            vm.documentRequest.fileId = obj.fileId;
            vm.documentRequest.title = obj.docuTitle;
            vm.documentRequest.author = obj.authorFirstName + ' ' + obj.authorLastName;

            $mdDialog.show(
                $mdDialog.confirm()
                .title($translate.instant("document.messages.checklater"))
                .textContent($translate.instant("document.messages.clickinfo"))
                .ariaLabel($translate.instant("document.messages.clickinfo"))
                .targetEvent(ev)
                .ok('Ok')
                .cancel($translate.instant("global.button.cancel"))

                ).then(function () {
                    DocumentService.save(vm.documentRequest).then(function (responseData) {
                        vm.onUploadIsActive = false;
                        getDocuments();
                        getTokensInfo();
                        broadcastTokensInfo();
                        $mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .title($translate.instant("document.messages.checkconfirm"))
                            .textContent($translate.instant("document.messages.checkconfirm"))
                            .ariaLabel($translate.instant("document.messages.checkconfirm"))
                            .ok('Ok')
                            .targetEvent(ev)
                            );
                    });
                }, function () {

                });
        }

        function check(obj, ev) {
            vm.onUploadTextFlag = false;
            vm.documentRequest.fileId = obj.fileId;
            vm.documentRequest.title = obj.docuTitle;
            vm.documentRequest.author = obj.authorFirstName + ' ' + obj.authorLastName;

            vm.checkInfo = (vm.onUploadObj.tokensNeeded > 0) ? $translate.instant("document.messages.checknowinfo") : $translate.instant("document.messages.checknowinfonotokens");

            $mdDialog.show($mdDialog.confirm()
                    .title($translate.instant("document.messages.checknow"))
                    .textContent(vm.checkInfo)
                    .ariaLabel(vm.checkInfo)
                    .targetEvent(ev)
                    .ok('Ok')
                    .cancel($translate.instant("global.button.cancel"))
                    ).then(function () {
                        vm.isLoading = true;
                        DocumentService.check(vm.documentRequest).then(function (responseData) {
                            vm.isLoading = false;
                            vm.onUploadIsActive = false;
                            if (responseData.used === false) {
                                var confirm = $mdDialog.confirm()
                                .title('')
                                .textContent($translate.instant("global.messages.error.notenough"))
                                .ariaLabel('')
                                .ok($translate.instant("document.buytokens"))
                                .cancel('Ok')
                                .openFrom('#left');
                                $mdDialog.show(confirm).then(function (result) {
                                    $state.go('payments');
                                });
                            } else {
                                $mdDialog.show(
                                  $mdDialog.confirm()
                                  .clickOutsideToClose(true)
                                  .title('')
                                  .textContent($translate.instant("global.messages.info.actionsuccess"))
                                  .ariaLabel('')
                                  .ok('Ok')
                                  .targetEvent(ev)
                                  );
                                getDocuments();
                                broadcastTokensInfo();
                            }
                        }).catch(function (response) {
                            vm.isLoading = false;
                            if (response.status === 400) {
                                $mdDialog.show(
                                  $mdDialog.confirm()
                                  .clickOutsideToClose(true)
                                  .title($translate.instant("global.messages.error.unknown"))
                                  .textContent($translate.instant("global.messages.error.unknown"))
                                  .ariaLabel($translate.instant("global.messages.error.unknown"))
                                  .ok('OK')
                                  );
                            }
                        });
                    });
        }

    }

    angular.module('plagiat2017App')
            .component('checkLater', {
                templateUrl: 'app/account/documents/document-metric/document-metric.template.html',
                controller: checkLaterController,
                bindings: {
                    onUploadIsActive: '=',
                    onUploadTextFlag: '=',
                    onUploadObj: '<',
                    onReloadDocuments: '<',
                    onDocuments: '<',
                    onGetTheFiles: '<',
                    onUploadText: '<',
                    isLoading: '='
                }
            });

})();
