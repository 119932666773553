(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('DictionaryService', DictionaryService);

    DictionaryService.$inject = ['$http', '$q'];

    function DictionaryService($http, $q) {

        var service = {
            queryLanguages: queryLanguages,
            searchLanguages: searchLanguages,
            getLanguagesJsaAutocompleteItems: getLanguagesJsaAutocompleteItems
        };

        var requestUrl = 'api/dictionary/';

        return service;

        function searchLanguages(searchValue) {
            return $http.post(requestUrl + 'language/V1/search', searchValue).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function queryLanguages(req) {
            return $http({
                url: requestUrl + 'language/V1/query',
                method: 'GET',
                params: createRequestOption(req)
            }).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                }
            );
        }

        function getLanguagesJsaAutocompleteItems(autocompleteQuery) {
            if (autocompleteQuery && autocompleteQuery.length > 0) {
                return service.searchLanguages(autocompleteQuery).then(function (responseData) {
                    var languages = responseData.data;
                    languages.push({ id: 0, code: "other"});
                    return languages;
                });
            } else {
                return service.queryLanguages({
                    page: 0,
                    size: 10,
                    sort: ['rank', 'desc']
                }).then(function (responseData) {
                    var languages = responseData.data;
                    languages.push({ id: 0, code: "other"});
                    return languages;
                });
            }
        }

        function createRequestOption(req) {
            return {
                size: req.size,
                page: req.page,
                sort: req.sort
            };
        }
    }

})();
