(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ClientGroupsService', ClientGroupsService);
    
    ClientGroupsService.$inject = ['$http', '$q'];

    function ClientGroupsService($http, $q) {

        var service = {
            search: search,
            getClientGroupForEditing: getClientGroupForEditing,
            saveOrUpdate: saveOrUpdate
        };
        var requestUrl = 'api/client-groups';
        return service;

        function getClientGroupForEditing(id) {
            return $http.get(requestUrl + '/for-editing/' + id).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function saveOrUpdate(obj) {
            return $http.post(requestUrl + '/save-or-update', obj).then(
                function () {},
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function search(searchRequest) {
            return $http.post(requestUrl + '/search', searchRequest).then(
                function (response) {
                    return response.data.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
    }
})();
