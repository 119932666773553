(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('TranslationsService', TranslationsService);

    TranslationsService.$inject = ['$http', 'FileSaver', 'PlagiatValidationService', '$mdDialog', '$translate', 'DateUtils', '$q'];

    function TranslationsService($http, FileSaver, PlagiatValidationService, $mdDialog, $translate, DateUtils, $q) {

        var service = {
            search: search,
            save: save,
            findOne: findOne,
            hasConflictingExternalTranslationCodes: hasConflictingExternalTranslationCodes,
            findHistory: findHistory,
            saveOrUpdate: saveOrUpdate,
            removeTranslations: removeTranslations,
            generateReportXls: generateReportXls,
            uploadTranslationsFile: uploadTranslationsFile,
            saveImportedTranslations: saveImportedTranslations
        };
        var requestUrl = 'api/translations';
        return service;

        function search(pageableFilter) {
            return $http.post(requestUrl + '/search', pageableFilter).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function save(code, lang, translatedText) {
            return $http.post(requestUrl + '/save', {code: code, lang: lang, translatedText: translatedText}).then(
                function () {
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveOrUpdate(obj) {
            return $http.post(requestUrl + '/save-or-update', obj).then(
                function () {
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findOne(code) {
            return $http.get(requestUrl + '?code=' + code).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function hasConflictingExternalTranslationCodes(code) {
            return $http.get(requestUrl + '/has-conflicting-external-translation-codes?code=' + code).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function findHistory(code) {
            return $http.get(requestUrl + '/history?code=' + code).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function removeTranslations(obj) {
            return $http.post(requestUrl + '/remove-multiple', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function generateReportXls(response, selected, searchRequest, languageSelection) {
            var alert = $mdDialog.alert()
                .clickOutsideToClose(false)
                .escapeToClose(false)
                .textContent($translate.instant("translations.report.xls.exporting-in-progress"))
                .ok("OK");
            $mdDialog.show(alert);

            var url = response.selectedAll ? 'api/translations/report/xls/search/return-xls'
                : 'api/translations/report/xls/selected/return-xls';
            var data = response.selectedAll
                ? {langs:languageSelection, filter:searchRequest}
                : {langs:languageSelection, ids:{ids:selected}};

            $http({
                url: url,
                method: "POST",
                data: data,
                headers: {
                    'Content-type': 'application/json'
                },
                responseType: 'arraybuffer'
            }).then(function (success) {
                $mdDialog.hide();
                var blob = new Blob([success.data], {
                    type: "application/vnd.ms-excel"
                });
                saveAs(blob, 'translations_' + DateUtils.getDateTimeForFileName(new Date()) + '.xls');
            }, function (error) {
                $mdDialog.hide();
                PlagiatValidationService.getModalError(error);
            });
        }

        function uploadTranslationsFile(obj, filename) {
            var request = {
                headers: {
                    'Content-Type': undefined,
                    'Charset': 'UTF-8'
                }
            };

            return $http.post(requestUrl + '/upload-file?filename=' + filename, obj, request).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function saveImportedTranslations(obj) {
            return $http.post(requestUrl + '/save-imported', obj).then(
                function () {
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }
})();
