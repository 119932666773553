(function() {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('authExpiredInterceptor', authExpiredInterceptor);

    authExpiredInterceptor.$inject = ['$rootScope', '$location', '$q', '$injector', '$log'];

    function authExpiredInterceptor($rootScope, $location, $q, $injector, $log) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            // If we have an unauthorized request we redirect to the login page
            // Don't do this check on the account API to avoid infinite loop
            //$log.error('authExpiredInterceptor', response)
            if (response.status === 401 && angular.isDefined(response.data.path) && response.data.path.indexOf('/api/account') === -1) {
                var Auth = $injector.get('Auth');
                var to = $rootScope.toState;
                var params = $rootScope.toStateParams;
                var prevState = Auth.getPreviousState();
                var $state = $injector.get('$state');
                if( $rootScope.toState.name === 'landing-page-int' || $rootScope.toState.name === 'landing-page-ro' || $rootScope.toState.name === 'landing-page' || $rootScope.toState.name === 'activate' || $rootScope.toState.name === 'register' || $rootScope.toState.name === 'finishReset' || $rootScope.toState.name === 'requestReset' || $rootScope.toState.name === 'social-auth' || $rootScope.toState.name === 'social-register' ){
                    Auth.storePreviousState(to.name, params);
                } else{
                    Auth.logout();
                    ($location.host() === 'sprawdz-prace.plagiat.pl' 
                        || $location.host() === 'verifica-textul.sistemantiplagiat.ro' 
                        || $location.host() === 'check-paper.strikeplagiarism.com'
                        || $location.host() === 'check-paper-for-plagiarism.strikeplagiarism.com'
                        || $location.host() === 'plagiat-tekseru-antiplagiat.strikeplagiarism.com'
                        || $location.host() === 'prover-rabotu-antiplagiat.strikeplagiarism.com'
                        || $location.host() === 'antiplagiat.strikeplagiarism.com'
                        || $location.host() === 'perevirka-na-plagiat-antiplagiat.strikeplagiarism.com'
                        || $location.host() === 'plagiata-yoxlamaq-antiplagiat.strikeplagiarism.com'
                        || $location.host() === 'plagiatni-tekshiring-antiplagiat.strikeplagiarism.com'
                        || $location.host() === 'dokument-auf-plagiat-prufen.strikeplagiarism.com'
                        || $location.host() === 'sheamotsmet-dokumenti-plagiatis-shesakheb.strikeplagiarism.com'
                        || $location.host() === 'proverete-dokumenta-za-plagiat-stvo.strikeplagiarism.com'
                        || $location.host() === 'intihal-ile-ilgili-belgeyi-kontrol-et.strikeplagiarism.com'
                        || $location.host() === 'comprobar-documento-sobre-plagio.strikeplagiarism.com'
                    )
                        ? $state.go('landing-page') 
                        : window.location.href = './'; 
                }
                if (to.name !== 'accessdenied') {
                    Auth.storePreviousState(to.name, params);
                }
            }
            return $q.reject(response);
        }
    }
})();
