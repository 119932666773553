(function () {
    'use strict';

    angular.module('plagiat2017App')
    .factory('DocumentSearchRequestModel', function () {

        function DocumentSearchRequestModel() {
            this.docuTitle = null;
            this.docuStatus = null;
            this.showOnlyForUser = false;
            this.docuCoordinator = null;
            this.docuKind = null;
            this.docuExtraOptions = null;
            this.simpleSearch = '';
            this.searchUser = null;
            this.organisationalUnit = null;
            this.role = null;
            this.docuStatus = '';
            this.docuSimilarityFactorFrom = '';
            this.docuSimilarityFactorTo = '';
            this.docuSimilarityFactorExtFrom = '';
            this.docuSimilarityFactorExtTo = '';
            this.author = '';
            this.alert = '';
            this.docuSubmitDateFrom = null;
            this.docuSubmitDateTo = null;
            this.orppdStatus = null;
            this.checkingType = null;
            this.assignmentId = null;
        }

        return DocumentSearchRequestModel;
    });
})();
