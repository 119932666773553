(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('users', {
            parent: 'app',
            url: '/users',
            data: {
                authorities: ['PERM_USER_ADMIN', 'PERM_USER_ORG_UNIT_ADMIN', 'PERM_USER_SUPERADMIN', 'PERM_USER_RESELLER', 'PERM_USER_MINISTRY'],
                pageTitle: 'global.menu.users'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/users/users.html',
                    controller: 'UsersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'userName,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('country');
                    $translatePartialLoader.addPart('client');
                    return $translate.refresh();
                }]
            }
        })
        .state("userLoginHistory", {
            url: "/:userId/login-history",
            parent: "users",
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'users.user-login-history.title'
            },
            onEnter: [
                "$mdDialog", "$state", '$stateParams',
                function ($mdDialog, $state, $stateParams) {
                    $mdDialog.show({
                        controller: 'UserLoginHistoryController',
                        controllerAs: 'vm',
                        templateUrl: 'app/account/users/user-login-history/user-login-history.template.html',
                        clickOutsideToClose: true,
                        locals: {
                            userId : $stateParams.userId
                        },
                    }).then(function () {
                        $state.go("users");
                    }, function () {
                        $state.go("users");
                    });
                }
            ]
        })
        .state("userRegions", {
            url: "/:userId/user-regions",
            parent: "users",
            data: {
                authorities: ['PERM_USER_SUPERADMIN'],
                pageTitle: 'users.table.menu.edit-client-regions'
            },
            onEnter: [
                "$mdDialog", "$state", '$stateParams',
                function ($mdDialog, $state, $stateParams) {
                    $mdDialog.show({
                        controller: 'UserRegionsController',
                        controllerAs: 'vm',
                        templateUrl: 'app/account/users/user-regions/user-regions.template.html',
                        clickOutsideToClose: true,
                        locals: {
                            userId : $stateParams.userId
                        },
                    }).then(function () {
                        $state.go("users");
                    }, function () {
                        $state.go("users");
                    });
                }
            ]
        });
    }

})();
