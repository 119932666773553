(function () {
    'use strict';
    angular
        .module('plagiat2017App')
        .controller('AccountDeletionConfirmationController', AccountDeletionConfirmationController);

    AccountDeletionConfirmationController.$inject = ['$stateParams', 'Auth', 'Principal', 'AccountService', 'ModalService'];

    function AccountDeletionConfirmationController($stateParams, Auth, Principal, AccountService, ModalService) {
        var vm = this;
        vm.key = $stateParams.key;
        vm.removalComplete = false;
        if (Principal.isAuthenticated()) {
            Auth.logout();
        }
        AccountService.confirmAccountDeletion(vm.key).then(
            function () {
                vm.removalComplete = true;
            }, function (errorResponse) {
                ModalService.showErrorModal(errorResponse);
            }
        )
    }
})();
