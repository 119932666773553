(function() {
	'use strict';

	function AddFreeTokensController($scope, $mdDialog, UserService, ModalService, ADDFREETOKENSMIN, ADDFREETOKENSMAX) {
		var vm = this;
		vm.cancelDialog = cancelDialog;
		vm.addFreeTokens = addFreeTokens;
		vm.addFreeTokensMin = ADDFREETOKENSMIN;
		vm.addFreeTokensMax = ADDFREETOKENSMAX;
		
		vm.form = {
			userId: UserService.user.id,
			tokens: undefined
		};

		function cancelDialog() {
			$mdDialog.cancel();
		}

		function addFreeTokens(form) {
	        UserService.addFreeTokens(form).then(function(){
	        	$mdDialog.hide();
	        }, function(response){
	        	$mdDialog.cancel().then(function(){
	        		ModalService.showErrorModal(response);
	        	})
	        });
		}

	}

	angular
			.module('plagiat2017App')
			.component(
					'addFreeTokens',
					{
						templateUrl : 'app/account/users/add-free-tokens/add-free-tokens.template.html',
						controller : AddFreeTokensController
					});

})();
