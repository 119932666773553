(function () {
    'use strict';

    angular
    .module('plagiat2017App', [
        'ngStorage',
        'UserRegisterRequestModel',
        'DocumentRequestModel',
        'DocumentsRequestModel',
        'PaymentsRequestModel',
        'PaymentFormRequestModel',
        'tmh.dynamicLocale',
        'pascalprecht.translate',
        'ngMaterial',
        'ngMessages',
        'ngAnimate',
        'ngResource',
        'ngCookies',
        'ngAria',
        'ngSanitize',
        'ngCacheBuster',
        'ngFileUpload',
        'ui.router',
        'ui.tinymce',
        'infinite-scroll',
        'ngMessages',
        'md-steppers',
        'md.data.table',
        'fileUpload',
        'documentMetric',
        'documentButtons',
        'documentProtocol',
        'documentButtonsReport',
        'paymentsList',
        'sideNav',
        'token',
        'detectTabs',
        'bw.paging',
        'angularMoment',
        'ngBrowserUpdate',
        'ngFileSaver',
      // jhipster-needle-angularjs-add-module JHipster will add new module here
        'angular-loading-bar',
        'usersButtons',
        'documentParameterDefinitionsButtons',
        'organisationalUnitsButtons',
        'apKeysButtons',
        'rzSlider',
    ])
    .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
