(function() {
	'use strict';

	function DocumentFormController($scope, $mdDialog, AccountService,
		DocumentService, $localStorage, $log, OrganisationalUnitsService,
		DocumentKindService, DocumentParameterDefinitionService, $q, _, $timeout, PERMISSIONS) {
		var vm = this;
		vm.closeDialog = closeDialog;
		vm.saveData = saveData;
		vm.idDocument = DocumentService.document.id;
		vm.findOrganisationalUnits = findOrganisationalUnits;
		vm.findDocumentKinds = findDocumentKinds;
		vm.addAuthor = addAuthor;
		vm.removeAuthor = removeAuthor;
		vm.selectedItemChange = selectedItemChange;
		vm.setCoordinatorId = setCoordinatorId;
		vm.querySearch = querySearch;
		vm.getDocumentSkipAddress = getDocumentSkipAddress;
		vm.authorsSimplifiedFields = AccountService.account.clientSettings.authorsSimplifiedFields;
		vm.canChangeOrganisationUnit = AccountService.getPermision(PERMISSIONS.PERM_USER_CAN_CHANGE_ORG_UNIT_DURING_ADD_DOCUMENT) || AccountService.role.isAdmin();
		vm.clientTypePublishingHouse = AccountService.clientType.isPublishingHouse();
		vm.clientTypeSchool = AccountService.clientType.isSchool();
		vm.isLecturer = AccountService.role.isLecturer();
		vm.coordinatorLabel = vm.clientTypeSchool ? 'document.metric.client-type.school.teacher' : 'document.model.docu-coordinator';
		vm.canEditPromoter = canEditPromoter;

		//to refactor functionality => adding and updating document form should be in one component
		function selectedItemChange(item) {
			vm.form.coordinatorId = angular.isUndefined(item) ? null : item.value.coordinatorId;
			vm.form.docuCoordinator = angular.isUndefined(item) ? null : item.label;
		}

		function setCoordinatorId() {
			vm.form.coordinatorId = vm.uploadObj.coordinatorInfo.selectItems[0].value.coordinatorId;
		}

		function canEditPromoter() {
			return (vm.isLecturer && AccountService.getClientSettings().userTeacherCanChangePromoterDuringAddDocument)
				|| AccountService.role.isAdmin() || AccountService.role.isOperator() || AccountService.role.isSuperAdmin();
		}

		function lowercase(string) {
			return angular.isString(string) ? string.toLowerCase() : string;
		}

	    function querySearch (query) {
	        vm.promoters = vm.uploadObj.coordinatorInfo.selectItems;
	        var results = query ? _.filter(vm.promoters, function(o) { return lowercase(o.label).indexOf(lowercase(query)) !== -1; }) : vm.promoters;
	        var deferred = $q.defer();
	        $timeout(function () { deferred.resolve( results ); }, Math.random() * 1000, false);
	        return deferred.promise;
	    }
		function getUploadFormConfiguration(obj){
            var deferred = $q.defer();
            DocumentService.operator.getConfiguration(vm.idDocument).then(function(response){
                vm.uploadObj = Object.assign( response.data, obj);
            });
            deferred.resolve(vm.uploadObj);
            return deferred.promise;
        }

		function addAuthor() {
			if (!vm.form) {
				vm.form = {};
			}
			if (!vm.form.documentAuthors) {
				vm.form.documentAuthors = {};
			}
			var authorForm = {
				documentId : vm.idDocument,
				status : 0
			};
			vm.form.documentAuthors.push(authorForm);
		}

		function removeAuthor(index) {
			if (vm.form && vm.form.documentAuthors) {
				vm.form.documentAuthors.splice(index, 1);
			}
			// $(event.target).parent().parent().remove();
		}

		function getMetadataForm(idDocument) {
			DocumentService.getMetadataForm(idDocument).then(
				function(responseData) {
					vm.form = responseData.data;
					if(vm.form.documentSkipAddresses == null){
						vm.form.documentSkipAddresses = [];
					}

					vm.selectedItem = vm.form.docuCoordinator;
					getUploadFormConfiguration(responseData.data).then(function(response){
	                    vm.uploadObj = response;
	                });
				});
		}

		function getDocumentSkipAddress(chip) {
		      return {
		        address: chip
		      };
		};

		function closeDialog() {
			$mdDialog.hide();
		}

		function saveData(form) {
			DocumentService.editDetails(form).then(function(responseData) {
				$mdDialog.hide();
			});
		}

		function findOrganisationalUnits() {
			OrganisationalUnitsService.findAllForClient(vm.idDocument).then(function(response) {
				vm.organisationalUnits = response.data;
			});
		}

		function findDocumentKinds() {
			DocumentKindService.findAll(vm.idDocument).then(function(response) {
				vm.docuKinds = response.data;
			});
		}

		function findSpecializations() {
			var dopadeName = "kierunek";
			DocumentParameterDefinitionService.findOneByName(dopadeName).then(
				function(data) {
					vm.specialization = data;
				});
		}

		if (vm.idDocument !== null) {
			getMetadataForm(vm.idDocument);
		}

		findSpecializations();
		findDocumentKinds();
		findOrganisationalUnits();
	}

	angular
	.module('plagiat2017App')
	.component(
		'documentForm',
		{
			templateUrl : 'app/components/document/document-form/document-form.template.html',
			controller : DocumentFormController
		});

})();
