(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('feedbacks', {
        parent: 'app',
        url: '/feedbacks',
        data: {
          pageTitle: 'global.menu.account.feedbacks'
        },
        views: {
          'content@': {
            templateUrl: 'app/account/assignments/peer-review/feedback/feedback-list.html',
            controller: 'FeedbackListController',
            controllerAs: 'vm'
          }
        },
        params: {
          page: {
            value: '0',
            squash: true
          },
          sort: {
            value: 'id,desc',
            squash: true
          }
        },
        resolve: {
          pagingParams: ['$stateParams', 'PaginationUtil',
            function ($stateParams, PaginationUtil) {
              return {
                page: PaginationUtil.parsePage($stateParams.page),
                sort: $stateParams.sort,
                predicate: PaginationUtil.parsePredicate(
                  $stateParams.sort),
                ascending: PaginationUtil.parseAscending(
                  $stateParams.sort)
              };
            }],
          translatePartialLoader: ['$translate',
            '$translatePartialLoader',
            function ($translate, $translatePartialLoader) {
              $translatePartialLoader.addPart('feedback');
              $translatePartialLoader.addPart('assignment');
              $translatePartialLoader.addPart('document');
              $translatePartialLoader.addPart('peer-review');
              return $translate.refresh();
            }]
        }
      })
      .state("add-feedback", {
        parent: "feedbacks",
        url: "/:id",
        data: {
          pageTitle: "feedback.review.title"
        },
        views: {
          'content@': {
            templateUrl: 'app/account/assignments/peer-review/feedback/add-feedback.html',
            controller: 'AddFeedbackController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('peer-review');
            $translatePartialLoader.addPart('assignment');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      }).state("view-feedback", {
        parent: "feedbacks",
        url: "/:id/view",
        data: {
          pageTitle: "feedback.view.title"
        },
        views: {
          'content@': {
            templateUrl: 'app/account/assignments/peer-review/feedback/view-feedback.html',
            controller: 'ViewFeedbackController',
            controllerAs: 'vm'
          }
        },
        resolve: {
          translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('assignment');
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      });
  }

})();
