(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('ap-keys-add', {
            parent: 'account',
            url: '/ap-keys-add',
            data: {
            	authorities: [],
                pageTitle: 'global.menu.api.keys'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/ap-keys/ap-keys-form/ap-keys-form.html',
                    controller: 'ApiKeysAddController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('users');
                    $translatePartialLoader.addPart('api.keys');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
