(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('ClientSettingService', ClientSettingService);

    ClientSettingService.$inject = ['$http', '$q'];

    function ClientSettingService($http, $q) {

        var requestUrl = 'api/client-setting';

        var service = {
            isSettingEnabledForActualClient: isSettingEnabledForActualClient,
        };
        return service;

        function isSettingEnabledForActualClient(code) {
            return $http.get(requestUrl + '/is-enabled/' + code).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
