(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ApiKeysController', ApiKeysController);

    ApiKeysController.$inject = ['$translate',
    	'apiKeysQueryRequest', 'apiKeysSearchRequest', 'tableSettingsRequest',
    	'ApiKeysService', 'SearchService', '$mdDialog', 'PlagiatValidationService', '$localStorage',
        'ModalService'];

    function ApiKeysController ($translate,
    		apiKeysQueryRequest, apiKeysSearchRequest, tableSettingsRequest,
    		ApiKeysService, SearchService, $mdDialog, PlagiatValidationService, $localStorage, ModalService) {
        var vm = this;
        vm.activeTab='apiKeys';
        vm.queryRequest = new apiKeysQueryRequest();
        vm.queryRequest.limit = $localStorage.apiKeysListLimit ? $localStorage.apiKeysListLimit : vm.queryRequest.limit;
        vm.searchRequest = new apiKeysSearchRequest();
        vm.options = new tableSettingsRequest();
        vm.selected = [];
        vm.pickedIds = [];
        vm.startPaging = startPaging;
        vm.prepareMapping = prepareMapping;
        vm.getData = getData;
        vm.removeItems = removeItems;
        vm.getDetails = getDetails;
        vm.isApikeyActive = isApikeyActive;
        prepareMapping();
        vm.simpleSearch = true;
        vm.clear = clear;
        
        function clear() {
        	vm.searchRequest = new apiKeysSearchRequest();
        	getData(vm.searchRequest);
        }
        
        function prepareMapping(){
        	getData(vm.searchRequest);
        }
        
        function getData(){
            vm.selected.length = 0;
            vm.showDetailsBlock = false;
            vm.searchRequest.pageNumber = (vm.queryRequest.page - 1);
            vm.searchRequest.pageSize = vm.queryRequest.limit;
            vm.searchRequest.sortTab = SearchService.sort(vm.queryRequest);

            vm.isLoadingData = true;
            ApiKeysService.getApiKeys(vm.searchRequest).then(function (responseData) {

                vm.apiKeys = responseData.content;
                vm.totalElements = responseData.totalElements;
                vm.isLoadingData = false;
            }, function (errResponse) {
                vm.isLoadingData = false;
                PlagiatValidationService.getModalError(errResponse);
            });
        }
        
        function startPaging(page) {
         	vm.isLoadingData = true;
             vm.queryRequest.page = page;
             prepareMapping();
         }
         
        function transformSelectedObj() {
            vm.pickedIds.length = 0;
            angular.forEach(vm.selected, function (value) {
                vm.pickedIds.push(value.id);
            });
        }
        
        function removeItems() {
            ModalService.showConfirmModal('global.confirmremove').then(function(){
                transformSelectedObj();
                ApiKeysService.remove(vm.pickedIds).then(function () {
                    vm.selected.length = 0;
                    prepareMapping();
                }, function (response) {
                	PlagiatValidationService.getModalError(response.data, $translate.instant("global.messages.warning.label"));
                });
            });
        }
        
        function getDetails(row) {
            vm.showDetailsBlock = false;
            vm.isLoaderActive = true;
            if (row.id !== ApiKeysService.detailsObj.id) {
                vm.pickedElement = row.id;
                ApiKeysService.detailsObj = row;
                vm.showDetailsBlock = true;
                vm.isLoaderActive = false;
                vm.details = row;
            } else {
                vm.isLoaderActive = false;
                ApiKeysService.detailsObj = {};
            }
        }
        
        function isApikeyActive(row) {
            return (row.contractAntyplagiat && row.contractAntyplagiat.status == 0) 
                && (!row.expiryDate || new Date() < row.expiryDate);
        }
        
    }
})();
