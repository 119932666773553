(function () {
  'use strict';

  angular
    .module('plagiat2017App')
    .directive('threeStateRadio', threeStateRadio);

  function threeStateRadio() {
    return {
      restrict: 'E',
      require: 'ngModel',
      templateUrl: 'app/components/form/three-state-radio/three-state-ratio.template.html',
      link: function ($scope, element, attrs, controller) {
        controller.$validators.threeStateRadioUnset = function (value) {
          return value === true || value === false;
        }

        $scope.modifyValue = function (newValue) {
          if ($scope.nowdisabled) {
            return;
          }
          if (!$scope.immutable) {
            this.inputValue = newValue;
          }
        }

        $scope.$watch(function () {
          if ($scope.onValueChange) {
            $scope.onValueChange();
          }
          return controller.$touched;
        }, function(newValueOfTouched) {
          if ($scope.onValueChange) {
            $scope.onValueChange();
          }
          if (newValueOfTouched) {
            element.addClass('ng-touched');
          }
        });
      },
      scope: {
        inputValue: '=ngModel',
        label: '@',
        onValueChange: '&',
        immutable: '<',
        nowdisabled: '<'
      },
    };
  }
})();
