(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('CurrencyService', CurrencyService);

    CurrencyService.$inject = ['JhiLanguageService', 'InstanceService'];

    function CurrencyService(JhiLanguageService, InstanceService) {
        var service = {
            getDefaultCurrency: getDefaultCurrency
        };

        function getDefaultCurrency() {
            return JhiLanguageService.getCurrent().then(function (currentLanguage) {
                return InstanceService.instance().currencies.langSettings.find(function (value) {
                    if (currentLanguage === value.lang) {
                        return value.currency;
                    }
                }).currency;
            });
        }

        return service;
    }

})();
