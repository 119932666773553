(function () {
  'use strict';

  function ConfirmTermsController($scope, InstanceService, $rootScope, $state, $timeout, $log, $translate, AccountService, $mdDialog, Principal, Auth, $localStorage) {
    var vm = this;
    vm.acceptTerms = true;
    vm.instance = InstanceService.instance().lang;
    vm.logoutToRootInfo = $localStorage.logoutToRootInfo;

    function verifyAcceptTerms() {
      Principal.identity().then(function () {
        vm.acceptTerms = AccountService.account.userAcceptsRegulations;
        vm.clientId = AccountService.account.clientId;
        if (!vm.acceptTerms &&
          (AccountService.role.isIndividual() || AccountService.getClientSettings().regulationsUrl)
          && vm.logoutToRootInfo === undefined) {
          showAcceptTermsModal();
        }
      });
    }

    function showAcceptTermsModal() {
      var templateUrlForClient = null;
      if (AccountService.role.isIndividual()) {
        templateUrlForClient = 'app/components/confirm-terms/confirm-terms-individual.dialog.html';
      } else {
        templateUrlForClient = 'app/components/confirm-terms/confirm-terms-non-individual.dialog.html';
      }
      $mdDialog.show({
        templateUrl: templateUrlForClient,
        clickOutsideToClose: false,
        escapeToClose: false,
        controllerAs: 'vm',
        controller: function DialogController($scope, $mdDialog, AccountService, InstanceService) {
          var vm = this;
          vm.closeDialog = closeDialog;
          vm.saveAcceptTerms = saveAcceptTerms;
          vm.postprocessKey = postprocessKey;
          vm.obj = {};
          vm.instance = InstanceService.instance().lang;
          vm.regulationsUrl = AccountService.getClientSettings().regulationsUrl;
          function closeDialog() {
            AccountService.account = {};
            AccountService.account.userAcceptsRegulations = true;
            $mdDialog.hide();
            $timeout(function () {
              Auth.logout();
              $state.go('login');
            }, 1);

          }

          function postprocessKey(translationKey) {
            return (vm.instance === 'ro') ? (translationKey + '_instance_ro') : translationKey;
          }

          function saveAcceptTerms() {
            $mdDialog.hide();
            AccountService.acceptTerms(vm.obj).then(function (responseData) {
              $mdDialog.hide();
              AccountService.account.userAcceptsRegulations = true;
            });
          }
        }
      }).finally(
        function onModalClose() {
          //verifyAcceptTerms();
        });
    }

    $rootScope.$on('$stateChangeStart',
      function (event, toState, toParams, fromState) {
        if (fromState && !fromState.abstract) {
          verifyAcceptTerms();
        }
    });
    verifyAcceptTerms();
  }

  angular.module('plagiat2017App')
  .component('confirmTerms', {
    templateUrl: 'app/components/confirm-terms/confirm-terms.template.html',
    controller: ConfirmTermsController,
    bindings: {
      onConfirmTerms: '<'
    }
  });

})();
