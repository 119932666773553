(function () {
    'use strict';
    angular
    .module('plagiat2017App')
    .constant('PLAGIAT_VALIDATIONS_CODES', [
        { code: '001', translationCode: '' },
        { code: '002', translationCode: 'reset.request.messages.notfound' },
        { code: '003', translationCode: 'global.messages.error.mailnotsent' },
        { code: '004', translationCode: '' },
        { code: '005', translationCode: 'global.registration.email.reserved' },
        { code: '006', translationCode: '' },
        { code: '007', translationCode: '' },
        { code: '008', translationCode: '' },
        { code: '009', translationCode: 'global.messages.error.wrongpassword' },
        { code: '010', translationCode: '' },
        { code: '011', translationCode: '' },
        { code: '012', translationCode: '' },
        { code: '013', translationCode: 'global.messages.error.docu-size-exceeded' },
        { code: '014', translationCode: '' },
        { code: '015', translationCode: 'global.messages.error.converter-connection-refused' },
        { code: '016', translationCode: 'global.messages.error.document-file-null' },
        { code: '017', translationCode: 'global.messages.error.inactive-contract' },
        { code: '018', translationCode: 'global.messages.error.docu-count-exceeded' },
        { code: '019', translationCode: 'global.messages.error.docu-character-count-exceeded' },
        { code: '020', translationCode: 'global.messages.error.contract-error' },
        { code: '021', translationCode: 'global.messages.error.user-query-limit-exceeded' },
        { code: '022', translationCode: '' },
        { code: '023', translationCode: 'global.messages.error.max-size-with-value' },
        { code: '024', translationCode: 'global.messages.error.max-size-with-value' },
        { code: '025', translationCode: 'global.messages.error.wrongtype' },
        { code: '026', translationCode: 'global.messages.error.notenough' },
        { code: '027', translationCode: 'global.messages.error.emptydocument' },
        { code: '028', translationCode: '' },
        { code: '029', translationCode: 'global.messages.error.wronguser' },
        { code: '030', translationCode: 'global.messages.error.document-title-length-exceeded' },
        { code: '031', translationCode: '' },
        { code: '032', translationCode: '' },
        { code: '033', translationCode: '' },
        { code: '034', translationCode: '' },
        { code: '035', translationCode: '' },
        { code: '036', translationCode: '' },
        { code: '037', translationCode: '' },
        { code: '038', translationCode: '' },
        { code: '039', translationCode: '' },
        { code: '040', translationCode: '' },
        { code: '041', translationCode: '' },
        { code: '042', translationCode: '' },
        { code: '043', translationCode: '' },
        { code: '044', translationCode: 'global.messages.error.authentication' },
        { code: '045', translationCode: 'login.messages.error.activation'},
        { code: '046', translationCode: 'global.messages.error.organisational-unit-already-exists' },
        { code: '047', translationCode: 'global.messages.error.document-parameter-definition-already-exists' },
        { code: '048', translationCode: 'organisational.units.deleting.assigned-to-user' },
        { code: '051', translationCode: 'organisational.units.deleting-multiple.assigned-to-user' },
        { code: '052', translationCode: 'global.invalid.numeric.value' },
        { code: '053', translationCode: 'global.invalid.email.format' },
        { code: '054', translationCode: 'global.messages.error.user.nothibernated' },
        { code: '055', translationCode: 'global.messages.error.user.already.removed' },
        { code: '056', translationCode: 'global.messages.error.user.permission' },
        { code: '057', translationCode: '' },
        { code: '058', translationCode: '' },
        { code: '059', translationCode: 'global.messages.error.user-query-limit-exceeded' },
        { code: '068', translationCode: 'global.messages.error.number.of.users.limit.exceeded' },
        { code: '069', translationCode: 'global.messages.error.could-not-retrieve-polon-units-for-current-client' },
        { code: '070', translationCode: 'global.messages.error.could-not-send-the-document-to-jsa' },
        { code: '071', translationCode: 'global.messages.error.could-not-save-jsa-access-data' },
        { code: '072', translationCode: 'global.messages.error.could-not-send-the-document-to-jsa-bad-request' },
        { code: '073', translationCode: 'global.messages.error.wrong-credentials' },
        { code: '075', translationCode: 'global.messages.error.could-not-send-the-document-to-jsa-institution-code-required' },
        { code: '078', translationCode: 'global.messages.error.could-not-edit-jsa-metadata' },
        { code: '080', translationCode: 'global.mail.reserved' },
        { code: '087', translationCode: 'global.messages.error.invalid-orppd-password' },
        { code: '088', translationCode: 'global.messages.error.document-backup-file-not-found' },
        { code: '089', translationCode: 'global.messages.error.could-not-send-document-to-orppd' },
        { code: '090', translationCode: 'global.messages.error.orppd-is-unavailable' },
        { code: '091', translationCode: 'global.messages.error.orppd-no-access-data' },
        { code: '092', translationCode: 'global.messages.error.jsa-study-not-on-unit' },
        { code: '093', translationCode: 'global.messages.error.jsa-wrong-institution-of-promoter' },
        { code: '094', translationCode: 'global.messages.error.could-not-add-polon-employee' },
        { code: '095', translationCode: 'global.messages.error.jsa-could-not-find-polon-employees-polon-unavailable' },
        { code: '096', translationCode: 'global.messages.error.main-admin-could-not-change-his-email' },
        { code: '097', translationCode: 'global.messages.error.client-setting-code-not-found' },
        { code: '009', translationCode: 'global.messages.error.wrongpassword' },
        { code: '100', translationCode: 'global.messages.error.text-retrieval-error' },
        { code: '101', translationCode: 'global.messages.error.document-import.metadata-incomplete' },
        { code: '103', translationCode: 'global.messages.error.invalid-confirmation-code' },
        { code: '104', translationCode: 'global.messages.error.document-has-no-check-id' },
        { code: '105', translationCode: 'global.messages.error.code-expired' },
        { code: '106', translationCode: 'global.messages.error.code-size-too-small' },
        { code: '107', translationCode: 'global.messages.error.code-has-wrong-status' },
        { code: '108', translationCode: 'translations.messages.error.column-not-found' },
        { code: '109', translationCode: 'translations.messages.error.file-format-error' },
        { code: '110', translationCode: 'statistics.messages.error.date-to-before-date-from' }, 
        { code: '111', translationCode: 'global.messages.error.samepassword' },
        { code: '112', translationCode: 'payments.messages.error.fakturownia' },
        { code: '113', translationCode: 'users.messages.error.can-not-add-admin' },
        { code: '114', translationCode: 'payments.messages.error.bulk.invoice.validation' },
        { code: '118', translationCode: 'global.messages.error.organisational-unit-does-not-belong-to-user' },
        { code: '119', translationCode: 'global.messages.error.cannot-add-priority-by-using-tokens' },
        { code: '121', translationCode: 'global.messages.error.unexpected-zip-content' },
        { code: '122', translationCode: 'reports.messages.error.too-many-rows' },
        { code: '123', translationCode: 'reports.messages.error.too-many-rows-for-zip' },
        { code: '124', translationCode: 'reports.messages.error.no-data' },
        { code: '125', translationCode: 'client.messages.error.wrong-country' },
        { code: '126', translationCode: 'assignment.messages.error.check-unique' },
        { code: '127', translationCode: 'assignment.messages.error.already-added' },
        { code: '128', translationCode: 'assignment.messages.error.assignment-closed' },
        { code: '129', translationCode: 'client.messages.error.region-issue' },

        { code: '999', translationCode: 'global.error.unknown' } //please leave it last, so you wont have to move the semicolon every time
        ]
        );
})();
