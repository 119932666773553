(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('ScrollService', ScrollService);

    ScrollService.$inject = ['$location', '$anchorScroll'];

    function ScrollService($location, $anchorScroll) {


        var service = {
        	scrollTo: scrollTo
        };

        return service;

        function scrollTo(id) {
           $location.hash(id);
           $anchorScroll();
        }

    }


})();
