(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('PlagiatValidationService', PlagiatValidationService);

    PlagiatValidationService.$inject = ['$log', 'PLAGIAT_VALIDATIONS_CODES', '$translate', '_', '$mdDialog', '$state'];

    function PlagiatValidationService($log, PLAGIAT_VALIDATIONS_CODES, $translate, _, $mdDialog, $state) {
        var vm = this;
        var service = {
            getModalError: getModalError,
            getTextError: getTextError,
            getTranslationKeyError: getTranslationKeyError,
            getError: getError,
            getModalErrorDefault: getModalErrorDefault
        };

        return service;

        function lowercase(string) {
            return angular.isString(string) ? string.toLowerCase() : string;
        }

        function getModalError(response, optionalTitle, optionalRedirectionLink, multiple){
            vm.translationKey = 'global.messages.error.error';
            if( response && response.code !== '' ) {
                vm.code = _.filter(PLAGIAT_VALIDATIONS_CODES, function(o) { return lowercase(o.code).indexOf(lowercase(response.code)) !== -1; });
                vm.translationKey = ( vm.code.length > 0 && angular.isDefined(vm.code[0].translationCode) && vm.code[0].translationCode !== '' ) ? vm.code[0].translationCode : 'global.messages.error.error';
            }
            vm.data = '';
            if (response.data){
            	vm.data = '</br>' + formatResponseData(response.data);
            }

            if(!multiple) {
                multiple = false;
            }
            
            vm.title = (optionalTitle || optionalTitle == '') ? optionalTitle : $translate.instant("global.messages.error.label");

            $mdDialog.show(
                $mdDialog.alert()
                    .multiple(multiple)
                    .ok('OK')
                    .clickOutsideToClose(true)
                    .title(vm.title)
                    .htmlContent($translate.instant(vm.translationKey) + vm.data)
                    .ariaLabel('ok')
            ).then(function () {
                if (optionalRedirectionLink) {
                    $state.go(optionalRedirectionLink);
                }
            }, function () {
                if (optionalRedirectionLink) {
                    $state.go(optionalRedirectionLink);
                }
            });
        }

        function formatResponseData(responseData) {
            try {
                return JSON.stringify(responseData);
            } catch (e) {
                return responseData;
            }
        }

        function getTextError(code){
            vm.translationKey = 'global.messages.error.error';
            if( code !== '' ) {
                vm.code = _.filter(PLAGIAT_VALIDATIONS_CODES, function(o) { return lowercase(o.code).indexOf(lowercase(code)) !== -1; });
                vm.translationKey = ( vm.code.length > 0 && angular.isDefined(vm.code[0].translationCode) && vm.code[0].translationCode !== '' ) ? vm.code[0].translationCode : 'global.messages.error.error';
            }
            $log.error($translate.instant(vm.translationKey));
            return $translate.instant(vm.translationKey);
        }
        
        function getTranslationKeyError(code){
            vm.translationKey = 'global.messages.error.error';
            if( code !== '' ) {
                vm.code = _.filter(PLAGIAT_VALIDATIONS_CODES, function(o) { return lowercase(o.code).indexOf(lowercase(code)) !== -1; });
                vm.translationKey = ( vm.code.length > 0 && angular.isDefined(vm.code[0].translationCode) && vm.code[0].translationCode !== '' ) ? vm.code[0].translationCode : 'global.messages.error.error';
            }
            return vm.translationKey;
        }

        function getError(){
            $log.error($translate.instant("global.messages.error.error"));
            return $translate.instant("global.messages.error.error");
        }
        
        function getModalErrorDefault() {
            $mdDialog.show(
                $mdDialog.alert()
                .clickOutsideToClose(true)
                .title($translate.instant("global.messages.error.label"))
                .textContent($translate.instant("global.messages.error.label"))
                .ariaLabel('ok')
                .ok('OK')
                );
        }

    }

})();
