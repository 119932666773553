(function() {
    'use strict';

    angular
            .module('plagiat2017App')
            .factory('DocumentErrors', DocumentErrors);

    DocumentErrors.$inject = ['$translate'];

    function DocumentErrors($translate) {

        var documentsArr = [];
        documentsArr['wrongpassword'] = $translate.instant("global.messages.error.wrongpassword");
        documentsArr['wrongtype'] = $translate.instant("global.messages.error.wrongtype");
        documentsArr['maxSize'] = $translate.instant("global.messages.error.max-size");
        documentsArr['emptydocument'] = $translate.instant("global.messages.error.emptydocument");
        documentsArr['converterConnectionRefused'] = $translate.instant("global.messages.error.converter-connection-refused");

        var models = {
            document: documentsArr
        };
        return models;

    }


})();

// (function () {
//     'use strict';

//     angular.module('plagiat2017App')
//     .service('DocumentErrors', function () {

//         function DocumentErrors($translate) {
//             this.document = [];
//             this.document['wrongtype'] = $translate.instant("global.messages.error.wrongtype");
// 			this.document['maxSize'] = $translate.instant("global.messages.error.max-size");
// 	        this.document['emptydocument'] = $translate.instant("global.messages.error.emptydocument");
// 	        this.document['converterConnectionRefused'] = $translate.instant("global.messages.error.converter-connection-refused");
//
//         }

//         return DocumentErrors;
//     });
// })();

