(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('documents', {
            parent: 'account',
            url: '/documents?key&price&currency',
            data: {
                authorities: [],
                pageTitle: 'global.menu.documents'
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/documents/document.html',
                    controller: 'DocumentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '0',
                    squash: true
                },
                sort: {
                    value: 'docuSubmitDate,asc',
                    squash: true
                }
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort)
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                    $translatePartialLoader.addPart('document');
                    $translatePartialLoader.addPart('codes');
                    $translatePartialLoader.addPart('dictionary');
                    $translatePartialLoader.addPart('clientType');
                    $translatePartialLoader.addPart('validation');
                    $translatePartialLoader.addPart('assignment');
                    return $translate.refresh();
                }]
            }
        })
        .state("send-to-orppd", {
            url: "/:docId/send-to-orppd",
            parent: "documents",
            data: {
                pageTitle: 'client.orppd'
            },
            onEnter: [
                "$mdDialog", "$state", '$stateParams', 'OrppdService',
                function ($mdDialog, $state, $stateParams, OrppdService) {
                    OrppdService.getOrppdForm($stateParams.docId).then(
                        function (response) {
                            $mdDialog.show({
                                controller: 'SendToOrppdController',
                                controllerAs: 'vm',
                                templateUrl: 'app/components/document/send-to-orppd/send-to-orppd.modal.html',
                                clickOutsideToClose: false,
                                locals: {
                                    form: response
                                },
                            }).then(function () {
                                $state.go("documents");
                            }, function () {
                                $state.go("documents");
                            });
                        }, function () {
                            $state.go("documents");
                        }
                    );
                }
            ]
        })
        .state("documentSendToJsa", {
            url: "/:docId/send-to-jsa",
            parent: "documents",
            data: {
                pageTitle: 'global.menu.documents.send-to-jsa'
            },
            onEnter: [
                "$mdDialog", "$state", '$stateParams', 'DocumentService',
                function ($mdDialog, $state, $stateParams, DocumentService) {
                    DocumentService.document.id = $stateParams.docId;
                    $mdDialog.show({
                        controller: 'SendToJSAController',
                        controllerAs: 'vm',
                        templateUrl: 'app/components/document/send-to-jsa/send-to-jsa.template.html',
                        clickOutsideToClose: false,
                        locals: {
                            docId: $stateParams.docId
                        }
                    }).then(function () {
                        $state.go("documents");
                    });
                }
            ]
        }).state("report2020Redirection", {
            url: "/:documentId/report",
            parent: "documents",
            data: {
                pageTitle: 'global.menu.documents.report2020-redirect'
            },
            onEnter: [
                "ModalService", "$state", '$window', '$stateParams', 'ReportDocumentService', '$mdDialog',
                function (ModalService, $state, $window, $stateParams, ReportDocumentService, $mdDialog) {
                    var dialog = ModalService.showPleaseWaitDialog();
                    ReportDocumentService.getReport2020RedirectionUrl($stateParams.documentId).then(function(redirectionUrl) {
                        $window.location.href = redirectionUrl;
                    }, function () {
                        $mdDialog.hide(dialog);
                        $state.go('accessdenied');
                    });
                }
            ]
        })
        ;
    }

})();
