(function () {
    'use strict';
    function DocumentParameterDefinitionsButtonsController($state, $mdDialog, $mdToast, $translate, DocumentParameterDefinitionsService) {
        var vm = this;
        vm.arrIdis = [];

        vm.edit = function(parameterId){
        	$state.go('documentParameterDefinitionsEdit', {parameterId : parameterId});
        }
        
        vm.remove = function(parameterId, ev) {
            var confirm = $mdDialog.confirm()
            .title($translate.instant("global.confirmremove"))
            .textContent($translate.instant("global.areyousure"))
            .ariaLabel($translate.instant("global.areyousure"))
            .ok($translate.instant("global.button.remove"))
            .cancel($translate.instant("global.button.cancel"))
            .openFrom('#left');
            $mdDialog.show(confirm).then(function () {
            	DocumentParameterDefinitionsService.remove(parameterId).then(function () {
                    vm.getData();
                    
            		$mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .title('')
                            .textContent($translate.instant("global.table.delete.msg.success"))
                            .ariaLabel('msgSuccess')
                            .ok('Ok')
                            .targetEvent(ev)
                            );
                }, function () {
                    $mdToast.show($mdToast.simple().textContent($translate.instant("global.table.delete.msg.error")));
                });
            });
        }
    }

    angular.module('documentParameterDefinitionsButtons', [])
    .component('documentParameterDefinitionsButtons', {
        templateUrl: 'app/account/settings/document-parameter-definitions/document-parameter-definitions-buttons/document-parameter-definitions-buttons.template.html',
        controller: DocumentParameterDefinitionsButtonsController,
        bindings: {
        	parameterId: '<',
        	getData: '<',
        	buttonLp: '<'
        }
    });

})();
