(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('JsaReportService', JsaReportService);

    JsaReportService.$inject = ['$http', '$q', '$translate', '$mdToast'];

    function JsaReportService($http, $q, $translate, $mdToast) {

        return {
            acceptReport: acceptReport,
            getReport: getReport
        };

        function acceptReport(documentId) {
            return $http.post('/api/jsa/report/accept?documentId=' + documentId).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getReport(documentId, fullReport, docAuthor) {
            return $http({
                url : '/api/jsa/report/download?documentId=' + documentId + "&fullReport=" + fullReport,
                method : "GET",
                responseType : 'arraybuffer'
            }).then(
                function(success) {
                    var reportName = "JSA_";
                    reportName += docAuthor.replace( /[<>:"\/\\|?*]+/g, '' );
                    onSuccess(success, docAuthor, fullReport, '.pdf', reportName);
                }, function() {
                    showError();
                }
            );
        }

        function onSuccess(success, docAuthor, fullReport, reportExtension, reportName) {
            var blob = new Blob(
                [ success.data ],
                {
                    type: "text/pdf; encoding=UTF-8"
                });
            saveAs(blob, reportName + reportExtension);
        }

        function showError() {
            $mdToast.show($mdToast.simple().textContent($translate.instant("global.messages.error.label")));
        }

    }
})();
