(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .controller('PaymentsController', PaymentsController);

    PaymentsController.$inject = ['$rootScope', '$stateParams', '$state', '$timeout', '$translate', '$window',
        'PaymentsService', '$mdDialog', 'pagingParams', 'paginationConstants', 'AlertService', 'TokenService', '$log', 'InstanceService'];

    function PaymentsController($rootScope, $stateParams, $state, $timeout, $translate, $window, 
                                PaymentsService, $mdDialog, pagingParams, paginationConstants, AlertService, TokenService, $log, InstanceService) {
        var vm = this;

        vm.key = $stateParams.key;
        vm.documentId = $stateParams.documentId;
        moment.locale(InstanceService.instance().lang);

        vm.timezone = InstanceService.instance().timezonePayments;


        vm.account = null;
        vm.isAuthenticated = null;
        vm.success = null;
        vm.uploadIsActive = false;
        vm.uploadObj = {};
        vm.smsStatuses = { statuses: {}, codesNotValid: [] };
        vm.notValidSms = { codes: []};
        vm.initPayment = initPayment;

        vm.formdata = new FormData();

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.search = {};
        vm.paymentRedirect = paymentRedirect;
        vm.paymentRedirectionFormContainerId = 'paymentRedirectionFormContainer';
        vm.paymentParams = null;
        vm.getPaymentParams = getPaymentParams;
        vm.submitMe = submitMe;
        vm.getPrice = getPrice;
        vm.price = 0;
        vm.tokenPrice = {
            price: 0,
            quantity: 0
        };
        vm.invoiceInfo = {};
        vm.getLastInvoiceInfo = getLastInvoiceInfo;
        vm.getPaymentMethods = getPaymentMethods;

        function getPaymentMethods() {
            PaymentsService.getPaymentMethods().then(function (responseData) {
                vm.paymentMethods = responseData.data.methodsOfPayment;
            });
        }

        function afterPayment() {
            
            if (vm.key === 'success' || vm.key === 'error') {
                var dialogContent = (vm.key === 'error') ? "payments.paymenterror" : "payments.paymentok";
                $rootScope.$broadcast("availableTokens");
                var confirm = $mdDialog.confirm()
                .title('')
                .clickOutsideToClose(true)
                .htmlContent($translate.instant(dialogContent))
                .ariaLabel('')
                .ok('Ok')
                .openFrom('#left');
                $mdDialog.show(confirm).then(function (result) {
                });
            }
        }

        function getLastInvoiceInfo() {
            PaymentsService.getLastInvoiceInfo().then(function (responseData) {
                vm.invoiceInfo = responseData.data;
            });
        }

        function getPrice(obj) {
          PaymentsService.getPrice(obj).then(function (responseData) {
            if (responseData.data.price === null) {
                vm.price = 0;
                vm.tokenPrice = {
                    price: 0
                };
            } else {
                vm.price = responseData.data.price;
                vm.tokenPrice = responseData.data;
                vm.tokenPrice.singlePrice = (responseData.data.price/responseData.data.quantity).toFixed(2);
            }
        });
      }

        function trackFbPixel(paymentObj) {
            if ($window.fbq) {
                $window.fbq('track', 'InitiateCheckout', {currency: paymentObj.currency, value: vm.price});
                // console.log('InitiateCheckout tracked! value=' + vm.price);
            }
        }

      function initPayment(paymentObj) {
        trackFbPixel(paymentObj);
        PaymentsService.pay(paymentObj).then(function (responseData) {
            if (responseData.data.paymentRedirectionParams !== null) {
                paymentRedirect(responseData.data);
            }else {
                paymentComplete(responseData.data);
            }
        }, function (responseError) {
            if (responseError.status === 400) {
                        $mdDialog.show(
                            $mdDialog.confirm()
                            .clickOutsideToClose(true)
                            .textContent(responseError.data.message)
                            .ariaLabel('ok')
                            .ok('OK')
                            );
            }
        });
    }

    function paymentRedirect(paymentResponse) {
            
      $mdDialog.show(
        $mdDialog.alert()
        .parent(angular.element(document.querySelector('#popupContainer')))
        .clickOutsideToClose(false)
        .title($translate.instant("global.messages.info.redirect"))
        .textContent($translate.instant("global.messages.info.redirect"))
        .ariaLabel($translate.instant("global.messages.info.redirect"))
        .ok($translate.instant("global.button.cancel"))
        );

      if(paymentResponse.userPaymentType==='TYPE_KASSA_24'){
       	 window.location = paymentResponse.formAction;
       	 return;
      }

      if(paymentResponse.userPaymentType==='TYPE_KASSA_24_CARD'){
       	 window.location = paymentResponse.formAction;
       	 return;
      }

      if (paymentResponse.userPaymentType === 'TYPE_FORTE_KZ') {
       	 window.location = paymentResponse.formAction;
       	 return;
      }

      vm.paymentParams = paymentResponse.paymentRedirectionParams;
      var $redirectFormContainer = angular.element('#paymentForm');
      var $redirectForm = $redirectFormContainer.find('form');

      $redirectForm.attr('method', paymentResponse.formMethod);
      $redirectForm.attr('action', paymentResponse.formAction);

      $timeout(function () {
    	$redirectForm.submit();
      });

  }

  function paymentComplete(response) {
	  if(response.userPaymentType === 'TYPE_BLIK'){
		    if (response.blikOK === null) {
		        confirm = $mdDialog.confirm()
		        .title('')
		        .textContent($translate.instant("payments.paymentbliknotok"))
		        .ariaLabel('')
		        .ok('Ok')
		        .openFrom('#left');
		        $mdDialog.show(confirm);
		    }
	  } else {
            vm.smsStatuses.statuses = angular.extend(vm.smsStatuses.statuses, response.smsStatuses);
            vm.smsStatuses.codesNotValid = vm.smsStatuses.codesNotValid.concat(response.notValidSms);
            var allValid = true;
            var anyValid = false;
            angular.forEach(vm.smsStatuses.statuses, function (value) {
                if(value !== 'valid'){
                    allValid = false;
                } else if(value !== 'valid'){
                    anyValid = true;
                }
            });
            var confirm;
			if (allValid) {
			    confirm = $mdDialog.confirm()
			    .title('')
			    .textContent($translate.instant("payments.paymentsmsok"))
			    .ariaLabel('')
			    .ok('Ok')
			    .openFrom('#left');
			    $mdDialog.show(confirm);
			    TokenService.broadcastTokens();
			    $state.reload();
			}
			if (!allValid) {
			    confirm = $mdDialog.confirm()
			    .title('')
			    .textContent($translate.instant(anyValid ? "payments.paymentsmssomeok" : "payments.paymentsmsnotok"))
			    .ariaLabel('')
			    .ok('Ok')
			    .openFrom('#left');
			    $mdDialog.show(confirm);
                TokenService.broadcastTokens();
			}
	  }
}

function getPaymentParams(paymentParams) {
    return vm.paymentParams;
}

function submitMe(t) {
    var $redirectFormContainer = angular.element('#paymentForm');
    var $redirectForm = $redirectFormContainer.find('form');
    $redirectForm.submit();
}


afterPayment();
getLastInvoiceInfo();

}
})();
