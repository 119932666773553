(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('OrganisationalUnitsAddEditController', OrganisationalUnitsAddEditController);

    OrganisationalUnitsAddEditController.$inject = ['Principal', 'Auth', 'JhiLanguageService', '$translate', 'OrganisationalUnitsService',
    	'InstanceService', '$state', '$cookies', '$stateParams', '$mdDialog', 'PlagiatValidationService', 'AccountService',
        'PolonStudyService'];

    function OrganisationalUnitsAddEditController (Principal, Auth, JhiLanguageService, $translate, OrganisationalUnitsService,
    		InstanceService, $state, $cookies, $stateParams,  $mdDialog, PlagiatValidationService, AccountService,
                                                PolonStudyService) {
        var vm = this;
        vm.instance = InstanceService.instance().lang;
        vm.theme = $cookies.get('plagiat-theme');
        vm.timezone = InstanceService.instance().timezoneDocuments;
        moment.locale(vm.instance);
        vm.key = $stateParams.key;
        vm.save = save;
        vm.backToMainList = backToMainList;
        vm.getMatchingPolonStudies = getMatchingPolonStudies;
        vm.associatePolonStudy = associatePolonStudy;
        vm.formatPolonStudy = formatPolonStudy;
        vm.unassociatedPolonStudies = unassociatedPolonStudies;
        vm.disassociatePolonStudy = disassociatePolonStudy;
        vm.form = {
            id: $stateParams.organisationalUnitId,
            polonStudies: []
        };
        vm.doesClientHaveJsaIntegration = AccountService.getClientSettings().jsaIntegration;

        if ($stateParams.organisationalUnitId) {
            getForm($stateParams.organisationalUnitId).then(function () {
                if (vm.doesClientHaveJsaIntegration) {
                    fetchPolonStudiesEligibleToAssociate();
                }
            });
        } else {
            fetchPolonStudiesEligibleToAssociate();
        }

        function fetchPolonStudiesEligibleToAssociate() {
            PolonStudyService.getAllNotAssignedToOrganisationalUnit().then(
                function (polonStudies) {
                    vm.polonStudies = polonStudies;
                },
                function (response) {
                    PlagiatValidationService.getModalError(response.data);
                }
            )
        }

        function getMatchingPolonStudies(polonStudiesSearchText) {
            return !polonStudiesSearchText ? vm.unassociatedPolonStudies()
                : vm.unassociatedPolonStudies().filter(function (polonUnit) {
                    return polonUnit.name.toLowerCase().indexOf(polonStudiesSearchText.toLowerCase()) !== -1;
                });
        }

        function unassociatedPolonStudies() {
            return vm.polonStudies.filter(function (polonStudy) {
                return !vm.form.polonStudies.find(function (associatedPolonStudy) {
                    return associatedPolonStudy.id === polonStudy.id;
                });
            });
        }

        function associatePolonStudy(polonStudy) {
            if (polonStudy) {
                vm.form.polonStudies.push(polonStudy);
                vm.selectedPolonStudy = null;
            }
        }

        function formatPolonStudy(polonStudy) {
            return ('<b>' + polonStudy.name + '</b>, ' +
                (polonStudy.title ? $translate.instant('document.jsa.title.' + polonStudy.title) + ', ' : '') +
                (polonStudy.form ? polonStudy.form + ', ' : '') +
                'UUID: ' + polonStudy.uid);
        }

        function disassociatePolonStudy(polonStudy) {
            var indexOfStudyToDelete = vm.form.polonStudies.findIndex(function (associatedPolonStudy) {
                return associatedPolonStudy.id === polonStudy.id;
            });
            vm.form.polonStudies.splice(indexOfStudyToDelete, 1);
        }

        function backToMainList(){
        	$state.go('organisational-units');
        }
        
        function getForm(id){
        	return OrganisationalUnitsService.getForm(id).then(function(response){
        		vm.form = response.data;
        	});
        }
        
        function save(form, ev){
            var savePromise = form.id ? OrganisationalUnitsService.edit(form) : OrganisationalUnitsService.add(form);
            savePromise.then(function(){
        		backToMainList();
        		
                $mdDialog.show(
                        $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant(form.id ? "organisational.units.form.edit.msg":
                            "organisational.units.form.new.msg"))
                        .ariaLabel('msgSuccess')
                        .ok('Ok')
                        .targetEvent(ev)
                        );
        	}, function(response){
        		PlagiatValidationService.getModalError(response.data);
        	})
        }
         
    }
})();
