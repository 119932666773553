(function () {
    'use strict';


    var userSchoolTypes = {
        USER_SCHOOL_TYPE_TEACHER: {id: 0, value: 'USER_SCHOOL_TYPE_TEACHER_SCHOOL'},
        USER_SCHOOL_TYPE_STUDENT: {id: 1, value: 'USER_SCHOOL_TYPE_STUDENT_SCHOOL'},
        USER_SCHOOL_TYPE_DEANERY: {id: 2, value: 'USER_SCHOOL_TYPE_DEANERY'},
        USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR: {id: 4, value: 'USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR'},
        USER_SCHOOL_TYPE_SUPERADMIN: {id: 5, value: 'USER_SCHOOL_TYPE_SUPERADMIN'},
        USER_SCHOOL_TYPE_ORG_UNIT_ADMIN: {id: 6, value: 'USER_SCHOOL_TYPE_ORG_UNIT_ADMIN'},
        USER_SCHOOL_TYPE_RESELLER: {id: 7, value: 'USER_SCHOOL_TYPE_RESELLER'},
        USER_SCHOOL_TYPE_MINISTRY: {id: 8, value: 'USER_SCHOOL_TYPE_MINISTRY'},
        USER_SCHOOL_TYPE_INDIVIDUAL: {id: 100, value: 'USER_SCHOOL_TYPE_INDIVIDUAL'}
    };

    angular
        .module('plagiat2017App')
        .constant('USERSCHOOLTYPE', userSchoolTypes)
        .constant('USERROLES', function () {
            var userRoles = {
                INDIVIDUAL: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_INDIVIDUAL,
                        translationKey: 'users.model.role.individual',
                        icons: ['monetization_on']
                    }],
                CLIENT_TYPE_STANDARD: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.teacher',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.deanery',
                        icons: ['account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.deanery_admin',
                        icons: ['brightness_auto', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN,
                        translationKey: 'users.model.role.org_unit_admin',
                        icons: ['turned_in_not', 'account_balance']
                    },
                    {
                        settingDependence: 'userSchoolTypeStudent',
                        type: userSchoolTypes.USER_SCHOOL_TYPE_STUDENT,
                        translationKey: 'users.model.role.student.code-account',
                        icons: ['edit']
                    }],
                CLIENT_TYPE_ASAP: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.teacher',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.deanery',
                        icons: ['account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.deanery_admin',
                        icons: ['brightness_auto', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN,
                        translationKey: 'users.model.role.org_unit_admin',
                        icons: ['turned_in_not', 'account_balance']
                    }],
                CODE_ACCOUNT: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.teacher',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.deanery',
                        icons: ['account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.deanery_admin',
                        icons: ['brightness_auto', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN,
                        translationKey: 'users.model.role.org_unit_admin',
                        icons: ['turned_in_not', 'account_balance']
                    },
                    {
                        settingDependence: 'userSchoolTypeStudent',
                        type: userSchoolTypes.USER_SCHOOL_TYPE_STUDENT,
                        translationKey: 'users.model.role.student.code-account',
                        icons: ['edit']
                    }],
                COLLEGE: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.teacher',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.deanery',
                        icons: ['account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.deanery_admin',
                        icons: ['brightness_auto', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN,
                        translationKey: 'users.model.role.org_unit_admin',
                        icons: ['turned_in_not', 'account_balance']
                    },
                    {
                        settingDependence: 'userSchoolTypeStudent',
                        type: userSchoolTypes.USER_SCHOOL_TYPE_STUDENT,
                        translationKey: 'users.model.role.student.code-account',
                        icons: ['edit']
                    }],
                PUBLISHING_HOUSE: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.user',
                        icons: ['person']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.admin',
                        icons: ['brightness_auto']
                    }],
                CLIENT_TYPE_PORTAL: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.operator',
                        icons: ['person']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.admin',
                        icons: ['brightness_auto']
                    }],
                SCHOOL: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_STUDENT,
                        translationKey: 'users.model.role.student.school',
                        icons: ['edit']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.teacher.school',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.admin',
                        icons: ['brightness_auto']
                    }],
                RESELLER: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_STUDENT,
                        translationKey: 'users.model.role.student.school',
                        icons: ['edit']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.lecturer.or.teacher',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.deanery',
                        icons: ['account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.deanery_admin',
                        icons: ['brightness_auto', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN,
                        translationKey: 'users.model.role.org_unit_admin',
                        icons: ['turned_in_not', 'account_balance']
                    }
                    ],
                SUPERADMIN: [
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_STUDENT,
                        translationKey: 'users.model.role.student.school',
                        icons: ['edit']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_TEACHER,
                        translationKey: 'users.model.role.lecturer.or.teacher',
                        icons: ['school']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_DEANERY,
                        translationKey: 'users.model.role.deanery',
                        icons: ['account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_OPERATOR_ADMINISTRATOR,
                        translationKey: 'users.model.role.deanery_admin',
                        icons: ['brightness_auto', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_ORG_UNIT_ADMIN,
                        translationKey: 'users.model.role.org_unit_admin',
                        icons: ['turned_in_not', 'account_balance']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_SUPERADMIN,
                        translationKey: 'users.model.role.superadmin',
                        icons: ['stars']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_RESELLER,
                        translationKey: 'users.model.role.reseller',
                        icons: ['fingerprint']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_MINISTRY,
                        translationKey: 'users.model.role.ministry',
                        icons: ['dataset']
                    },
                    {
                        type: userSchoolTypes.USER_SCHOOL_TYPE_INDIVIDUAL,
                        translationKey: 'users.model.role.individual',
                        icons: ['monetization_on']
                    }]
            };
            userRoles.DEFAULT = userRoles.CLIENT_TYPE_STANDARD;

            userRoles.getAllUserRoles = function() {
                return userRoles.SUPERADMIN;
            };
            
            userRoles.getRolesForUser = function (user) {
                if (user.userSchoolType === userSchoolTypes.USER_SCHOOL_TYPE_SUPERADMIN.id) {
                    return userRoles.SUPERADMIN;
                }
                if (user.userSchoolType === userSchoolTypes.USER_SCHOOL_TYPE_RESELLER.id) {
                    return userRoles.RESELLER;
                }
                return  userRoles[user.userClientType] ? userRoles[user.userClientType] : userRoles.DEFAULT;
            };

            userRoles.getRolesForCurrentClient = function (AccountService) {
                if (AccountService.role.isSuperAdmin()) {
                    return userRoles.SUPERADMIN;
                }
                if (AccountService.role.isReseller() || AccountService.role.isMinistry()) {
                    return userRoles.DEFAULT;
                }
                var rolesForClientType = userRoles[AccountService.getClientType()] ? userRoles[AccountService.getClientType()] : userRoles.DEFAULT;
                if (AccountService.getClientSettings()) {
                    return rolesForClientType.filter(function (role) {
                        return !role.settingDependence || AccountService.getClientSettings()[role.settingDependence] === true;
                    });
                } else {
                    return rolesForClientType;
                }
            };

            return userRoles;
        }());
})();
