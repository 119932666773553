(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .factory('UserService', UserService);

    UserService.$inject = ['$http', '$q', 'UrlUtils'];

    function UserService($http, $q, UrlUtils) {

        var service = {
            getUsers: getUsers,
            searchUsers:searchUsers,
            searchUsersForClient:searchUsersForClient,
            addUser: addUser,
            getDetails: getDetails,
            userDetailsObj: {},
            editUser: editUser,
            updateUserQueryCounter: updateUserQueryCounter,
            verifyHibernateUsers: verifyHibernateUsers,
            verifyMassRemindPassword: verifyMassRemindPassword,
            setCounters: setCounters,
            activateUsers: activateUsers,
            verifyRemoveUsers: verifyRemoveUsers,
            grantMainAdminRights: grantMainAdminRights,
            revokeMainAdminRights: revokeMainAdminRights,
            loginAs: loginAs,
            logoutToRoot: logoutToRoot,
            user: {
                id: null
            },
            getUserHistory: getUserHistory,
            getUserRegions: getUserRegions,
            getCurrentUserRegions: getCurrentUserRegions,
            saveUserRegions: saveUserRegions,
            addFreeTokens: addFreeTokens,
            confirmEmail: confirmEmail,
            searchObject: null,
            isEmailUsed: isEmailUsed,
            getUserInstance: getUserInsance,
            getAvailableStudentEmails: getAvailableStudentEmails,
            samlRedirectionUrl: samlRedirectionUrl
		};
        
        return service;

        function verifyRemoveUsers(ids, searchRequest, all) {
          return all ? searchAndRemoveUsers(searchRequest) : removeUsers(ids);
        }

        function searchAndRemoveUsers(obj) {
           return $http.put('/api/user/search/remove/', obj).then(
            function (response) {
                   return response.data;
               },
               function (errResponse) {
                   return $q.reject(errResponse);
               });
        }

        function removeUsers(obj) {
           return $http.put('/api/user/remove/', obj).then(
            function (response) {
                   return response.data;
               },
               function (errResponse) {
                   return $q.reject(errResponse);
               });
        }

        function verifyMassRemindPassword(ids, searchRequest, all) {
            return all ? searchAndMassRemindPassword(searchRequest) : massRemindPassword(ids);
        }

        function searchAndMassRemindPassword(obj) {
            return $http.put('/api/user/search/remind/', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function massRemindPassword(obj) {
            return $http.put('/api/user/remind/', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function setCounters(ids, searchRequest, all, value) {
            return $http.put('/api/user/set/counters/' + (all ? 'search/' : '') + value, (all ? searchRequest : ids)).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function verifyHibernateUsers(ids, searchRequest, all) {
            return all ? searchAndHibernateUsers(searchRequest) : hibernateUsers(ids);
        }

        function searchAndHibernateUsers(obj) {
           return $http.put('/api/user/search/hibernate/', obj).then(
            function (response) {
                   return response.data;
               },
               function (errResponse) {
                   return $q.reject(errResponse);
               });
        }

        function hibernateUsers(obj) {
           return $http.put('/api/user/hibernate/', obj).then(
            function (response) {
                   return response.data;
               },
               function (errResponse) {
                   return $q.reject(errResponse);
               });
        }

        function getUsers(obj) {
        	return $http.post('/api/user/list', obj).then(
        			function (response) {
        				return response.data;
        			},
        			function (errResponse) {
        				return $q.reject(errResponse);
        			});
        }

        function searchUsers(obj) {
            return $http.post('/api/user/search', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function searchUsersForClient(obj) {
            return $http.post('/api/user/search-for-client', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
    
        function addUser(obj) {
            return $http.put('api/user/add', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function editUser(obj, id) {
            return $http.put('api/user/edit/' + id, obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function getDetails(id) {
           return $http.get('/api/user/details/' + id).then(
               function (response) {
                   return response.data;
               },
               function (errResponse) {
                   return $q.reject(errResponse);
               });
        }
        
        function updateUserQueryCounter(obj) {
            return $http.put('api/user/query-counter', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function activateUsers(obj) {
           return $http.put('/api/user/activate/', obj).then(
            function (response) {
                   return response.data;
               },
               function (errResponse) {
                   return $q.reject(errResponse);
               });
        }
        
        function grantMainAdminRights(userId) {
            return $http.put('api/user/main-admin/grant-main-admin-rights/' + userId, undefined).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }
        
        function revokeMainAdminRights(userId) {
            return $http.put('api/user/main-admin/revoke-main-admin-rights/' + userId, undefined).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function loginAs(username) {
       		username = UrlUtils.prepareUrlParam(username);
            return $http.get('/api/impersonate/login?username=' + username).then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
         }
        
        function logoutToRoot() {
            return $http.get('/api/impersonate/logout').then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }
        
        function getUserHistory(userId) {
            return $http.get('/api/user-history/list-for-user/' + userId).then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }

        function getUserRegions(userId) {
            return $http.get('/api/user/user-regions/' + userId).then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }

        function getCurrentUserRegions() {
            return $http.get('/api/user/current-user-regions').then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
        }

        function saveUserRegions(obj) {
            return $http.post('/api/user/save-user-regions', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function addFreeTokens(obj) {
            return $http.put('/api/payments/add-free-tokens/', obj).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function confirmEmail(params) {
            return $http.put('api/user/confirm-email/', params).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

        function isEmailUsed(email) {
            return $http.post('api/user/isUserExists', email).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }


        function getUserInsance(email, redirectEnabled) {

            if(!redirectEnabled){
                var data = { data: ''}
                return $q.resolve(data);
            } else {
                return $http.get('api/redirect?mail=' + encodeURIComponent(email)).then(
                    function (response) {
                        return response.data;
                    },
                    function (errResponse) {
                        return $q.reject(errResponse);
                    });
            }
        }

        function getAvailableStudentEmails(emails) {
            var obj = {emails: emails};
            return $http.post('/api/user/available-student-emails', obj).then(
              function (response) {
                  return response.data;
              },
              function (errResponse) {
                  return $q.reject(errResponse);
              });
        }

        function samlRedirectionUrl(partner) {
            return $http.get('/api/saml-redirect/' + partner).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
