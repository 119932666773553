(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('UrlUtils', UrlUtils);

    UrlUtils.$inject = [];

    function UrlUtils() {

        var service = {
            prepareUrlParam: prepareUrlParam
        };

        return service;

        function prepareUrlParam(param) {
        	return param.replace('+', '%2B');
        }

    }

})();
