(function () {
    'use strict';

    angular
    .module('plagiat2017App')
    .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('full-stats', {
            parent: 'account',
            url: '/full-stats',
            data: {
                authorities: ['PERM_USER_SUPERADMIN']
            },
            views: {
                'content@': {
                    templateUrl: 'app/account/settings/full-stats/full-stats.template.html',
                    controller: 'FullStatsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('statistics');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }
})();
