(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('JsaDocumentFormService', JsaDocumentFormService);

    JsaDocumentFormService.$inject = ['$http', 'PolonUnitService', '$mdDialog', 'ModalService', 'AccountService',
        'OrganisationalUnitsService', '$translate', 'DOCUMENT_KINDS', 'PolonStudyService'];

    function JsaDocumentFormService($http, PolonUnitService, $mdDialog, ModalService, AccountService,
                                    OrganisationalUnitsService, $translate, DOCUMENT_KINDS, PolonStudyService) {

        var service = {
            inferOrganisationalUnitId: inferOrganisationalUnitId,
            handleSelectedAuthorChange: handleSelectedAuthorChange,
            refreshAvailableUnits: refreshAvailableUnits,
            formatAuthor: formatAuthor
        };
        return service;

        function inferOrganisationalUnitId(documentFormScope) {
            if (isPhdThesis(documentFormScope)) {
                return getOrganisationalUnitIdForUser(documentFormScope.userId);
            }
            var polonStudyId = documentFormScope.onUploadObj.authors[0].selectedStudy.id;
            return PolonStudyService.getOrganisationalUnitId(polonStudyId).then(function (polonStudyOrganisationalUnit) {
                if (polonStudyOrganisationalUnit) {
                    return polonStudyOrganisationalUnit;
                }
                return getOrganisationalUnitIdForUser(documentFormScope.userId);
            })
        }

        function getOrganisationalUnitIdForUser(userId) {
            var currentUserOrgUnitId = AccountService.account.organisationalUnitId;
            if (AccountService.role.isSuperAdmin()) {
                return OrganisationalUnitsService.findByUserId(userId).then(function (organisationalUnit) {
                    currentUserOrgUnitId = organisationalUnit.id;
                    return useCurrentUserOrgUnitIdAndNotifyUser(currentUserOrgUnitId);
                });
            } else {
                return useCurrentUserOrgUnitIdAndNotifyUser(currentUserOrgUnitId);
            }
        }

        function useCurrentUserOrgUnitIdAndNotifyUser(currentUserOrgUnitId) {
            return OrganisationalUnitsService.getSimpleById(currentUserOrgUnitId).then(function (organisationalUnit) {
                return ModalService.showConfirmModal(null,
                    {customTitle: $translate.instant('global.messages.jsa.no-matching-organisational-unit', {unitName: organisationalUnit.orguniLabel})}
                    ).then(function () {
                        return currentUserOrgUnitId;
                    })
                }
            );
        }

        function handleSelectedAuthorChange(selectedAuthor, index, documentFormScope) {
            if (!documentFormScope.onUploadObj.authors) {
                documentFormScope.onUploadObj.authors = [];
            }
            if (selectedAuthor && selectedAuthor.id) {
                documentFormScope.onUploadObj.authors[index] = selectedAuthor;
                if (isPhdStudent(selectedAuthor)) {
                    documentFormScope.isPhdThesis = true;
                    documentFormScope.onUploadObj.documentKindId = DOCUMENT_KINDS.PHD.id;
                } else {
                    documentFormScope.isPhdThesis = false;
                    documentFormScope.onUploadObj.authors[index].selectedStudy = selectedAuthor.studies[0];
                }
            } else {
                documentFormScope.onUploadObj.authors[index] = null;
                documentFormScope.onUploadObj.documentKindId = null;
                if (index === 0) {
                    documentFormScope.isPhdThesis = false;
                }
            }
            if (index === 0) {
                refreshAvailableUnits(documentFormScope);
            }
        }

        function refreshAvailableUnits(documentFormScope) {
            documentFormScope.availableUnits = [];
            if (!documentFormScope.onUploadObj
                || !documentFormScope.onUploadObj.authors
                || !documentFormScope.onUploadObj.authors[0]) {
                return;
            }
            var mainAuthor = documentFormScope.onUploadObj.authors[0];
            if (isPhdStudent(mainAuthor)) {
                if (AccountService.role.isSuperAdmin()) {
                    PolonUnitService.getAllForClient(documentFormScope.clientId).then(function (result) {
                            documentFormScope.availableUnits = result;
                    })
                } else {
                    PolonUnitService.getAllForCurrentClient().then(function (result) {
                            documentFormScope.availableUnits = result;
                        }
                    );
                }
                return;
            } else {
                documentFormScope.onUploadObj.polonUnitId = null;
            }
            if (!mainAuthor.selectedStudy) {
                return;
            }
            PolonUnitService.getAllByStudyId(mainAuthor.selectedStudy.id).then(function (result) {
                documentFormScope.availableUnits = result;
                    if (angular.isArray(documentFormScope.availableUnits) && documentFormScope.availableUnits.length > 0) {
                        documentFormScope.onUploadObj.polonUnitId = documentFormScope.availableUnits[0].id;
                    } else {
                        ModalService.showDialogModal("jsa.document.no-polon-unit-for-study-do-synchronization");
                    }
                }
            )
        }

        function formatAuthor(author) {
            var result = author.firstName;
            if (author.middleNames) {
                result += ' ' + author.middleNames;
            }
            result += ' ' + author.lastName;
            if (isPhdStudent(author)) {
                result += ' ' + $translate.instant('document.author.phd-student');
            } else {
                result += ', UID: ' + author.uid
            }
            if (author.lastDigitsIdentificator) {
                result += ', ' + $translate.instant('document.author.last.digits')+': ' + author.lastDigitsIdentificator;
            }
            return result;
        }

        function isPhdStudent(author) {
            return !author.uid;
        }

        function isPhdThesis(documentFormScope) {
            var mainAuthor = documentFormScope.onUploadObj.authors[0];
            return isPhdStudent(mainAuthor);
        }

    }
})();
