(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('SimilarityReportSettingsController', SimilarityReportSettingsController);

    SimilarityReportSettingsController.$inject = ['SimilarityReportSettingsService', 'PlagiatValidationService', '$mdDialog', 'AccountService', '$translate'];

    function SimilarityReportSettingsController (SimilarityReportSettingsService, PlagiatValidationService, $mdDialog, AccountService, $translate) {
        var vm = this;
        vm.activeTab = 'similarityReportSettings';
        vm.editing = false;
        vm.editState = editState;
        vm.cancelSaving = cancelSaving;
        vm.getForm = getForm;
        vm.save = save;
        vm.clientId = AccountService.account.clientId;
        getForm(vm.clientId);
        
        function editState(){
        	vm.editing = true;
        }
        
        function cancelSaving(){
        	vm.editing = false;
        	getForm(vm.clientId);
        }
        
        function getForm(clientId){
        	SimilarityReportSettingsService.getForm(clientId).then(function(response){
        		vm.form = response.data;
        	})
        }
        
        function save(form, ev){
        	SimilarityReportSettingsService.edit(form).then(function(){
        		vm.editing = false;

                $mdDialog.show(
                        $mdDialog.confirm()
                        .clickOutsideToClose(true)
                        .title('')
                        .textContent($translate.instant("similarity.report.settings.edit.msg"))
                        .ariaLabel('msgSuccess')
                        .ok('Ok')
                        .targetEvent(ev)
                        );
        	}, function(response){
        		PlagiatValidationService.getModalError(response.data);
        	})
        }
    }
})();
