(function() {
    'use strict';

    angular
        .module('plagiat2017App')
        .controller('ResetFinishController', ResetFinishController);

    ResetFinishController.$inject = ['$stateParams', '$timeout', 'Auth'];

    function ResetFinishController ($stateParams, $timeout, Auth) {
        var vm = this;

        vm.keyMissing = angular.isUndefined($stateParams.key);
        vm.confirmPassword = null;
        vm.doNotMatch = null;
        vm.error = null;
        vm.finishReset = finishReset;
        vm.toggleShowPassword = toggleShowPassword;
        vm.resetAccount = {};
        vm.success = null;
        vm.showPassword = false;
        vm.passwordPattern = Auth.getPasswordPattern();
        
        $timeout(function (){angular.element('#password').focus();});

        function finishReset() {
            vm.doNotMatch = null;
            vm.error = null;
            if (vm.resetAccount.password !== vm.resetAccount.confirmedPassword) {
                vm.doNotMatch = 'ERROR';
            } else {
                Auth.resetPasswordFinish({key: $stateParams.key, password: vm.resetAccount.password, newPassword: vm.resetAccount.confirmedPassword}).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        function toggleShowPassword() {
            vm.showPassword = !vm.showPassword;
        }

    }
})();
