(function () {
    'use strict';

    angular
        .module('plagiat2017App')
        .factory('FinderService', FinderService);

    FinderService.$inject = ['$http', '$q'];

    function FinderService($http, $q) {

        var service = {
            getAllAvailableForClientType: getAllAvailableForClientType,
        };

        return service;

        function getAllAvailableForClientType(clientTypeId, countryId, isTestClient) {
            const testClient = isTestClient ? true : false;
            return $http.get('/api/finder/available?clientTypeId=' + clientTypeId + "&countryId=" + countryId + "&isTestClient=" + testClient).then(
                function (response) {
                    return response.data;
                },
                function (errResponse) {
                    return $q.reject(errResponse);
                });
        }

    }

})();
